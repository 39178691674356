<template>


    <div v-if="loading"   class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div style="background: transparent; opacity: 1;" class="hs-overlay-open:mt-7 ti-modal-box mt-0
        ease-out min-h-[calc(100%-3.5rem)]  items-center modalstyle" >


            <div class="preloader">
                <div class="clear-loading loading-effect-2">
                    <span></span>
                </div>
            </div>


        </div>
    </div>


    <div v-if="loading"   data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>





</template>

<script>
    export default {
        /* eslint-disable */
        name: "loadingcomponent",
        props: {

            loading:true,



        },
        data(){
            return {
                //image:'@/assets/img/Clock-Loading.gif',

            }

        },
    }
</script>








<style scoped>

    .preloader {
        width:100%;
        height:100%;
       /* background-color:#fff;*/
        background-color:transparent;
        position:fixed;
        top:0;
        left:0;
        z-index:999999;
        -webkit-transition:all 0.3s ease-in-out;
        -moz-transition:all 0.3s ease-in-out;
        -ms-transition:all 0.3s ease-in-out;
        -o-transition:all 0.3s ease-in-out;
        transition:all 0.3s ease-in-out;
    }

    .clear-loading {
        text-align:center;
        margin:0 auto;
        position:absolute;
        top:50%;
        left:50%;
        margin-top:-50px;
        margin-left:-50px;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -ms-box-sizing:border-box;
        -o-box-sizing:border-box;
        box-sizing:border-box;
    }

    .loading-effect-2 {
        width:100px;
        height:100px;
    }

    .loading-effect-2 > span,
    .loading-effect-2 > span:before,
    .loading-effect-2 > span:after {
        content:"";
        display:block;
        border-radius:50%;
        border:2px solid #000;
        position:absolute;
        top:50%;
        left:50%;
        -webkit-transform:translate(-50%, -50%);
        -moz-transform:translate(-50%, -50%);
        -ms-transform:translate(-50%, -50%);
        -o-transform:translate(-50%, -50%);
        transform:translate(-50%, -50%);
    }

    .loading-effect-2 > span {
        width:100%;
        height:100%;
        top:0;
        left:0;
        border-left-color:transparent!important;
        -webkit-animation:effect-2 2s infinite linear;
        -moz-animation:effect-2 2s infinite linear;
        -ms-animation:effect-2 2s infinite linear;
        -o-animation:effect-2 2s infinite linear;
        animation:effect-2 2s infinite linear;
    }

    .loading-effect-2 > span:before {
        width:75%;
        height:75%;
        border-right-color:transparent!important;
    }

    .loading-effect-2 > span:after {
        width:50%;
        height:50%;
        border-bottom-color:transparent!important;
    }

    @-webkit-keyframes effect-2 {
        from {
            -webkit-transform:rotate(0deg);
            -moz-transform:rotate(0deg);
            -ms-transform:rotate(0deg);
            -o-transform:rotate(0deg);
            transform:rotate(0deg);
        }

        to {
            -webkit-transform:rotate(360deg);
            -moz-transform:rotate(360deg);
            -ms-transform:rotate(360deg);
            -o-transform:rotate(360deg);
            transform:rotate(360deg);
        }

    }

    @keyframes effect-2 {
        from {
            -webkit-transform:rotate(0deg);
            -moz-transform:rotate(0deg);
            -ms-transform:rotate(0deg);
            -o-transform:rotate(0deg);
            transform:rotate(0deg);
        }

        to {
            -webkit-transform:rotate(360deg);
            -moz-transform:rotate(360deg);
            -ms-transform:rotate(360deg);
            -o-transform:rotate(360deg);
            transform:rotate(360deg);
        }

    }



    .myfloat{
        float: right !important;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        background: transparent;
        width: 450px;
        border-radius: 5px;
        /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);*/
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        margin: 0 auto;
        padding: 20px 30px;
    }
    .modal-footer {
        margin-top: 15px;
    }
    .modal-enter, .modal-leave {
        opacity: 0;
    }
    .modal-enter .modal-container, .modal-leave .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }


    .modal-active{
        display:block;
    }

    .modal-dialog {
        width: 50% !important;
        margin: 10% auto !important;
    }



    @media screen and (max-width: 768px) {

        .myfloat{
            float: none !important;
        }

        .modal-dialog {
            width: 80% !important;
            margin: 20% auto !important;
        }



    }

</style>














