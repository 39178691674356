<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <!--h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3> </h6-->





                    <div class="flex p-4 items-center justify-between border-b dark:border-defaultborder/10">

                        <div v-if="isdisplayviewarticle===true" class="flex items-center">
                            <div class="hs-dropdown ti-dropdown me-1">
                                <button class="ti-btn ti-btn-sm ti-btn-primary" aria-label="button" type="button" aria-expanded="false">
                                    <i class="ri-more-2-fill"></i>
                                </button>
                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden" style="">
                                    <li><a @click="isdisplayviewarticle=false;" class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium" href="javascript:void(0);">Modifier l'entête du document</a></li>

                                </ul>
                            </div>
                            <button aria-label="button" type="button" id="file-close-btn" class="ti-btn ti-btn-icon ti-btn-sm ti-btn-danger xl:hidden block">
                                <i class="ri-close-fill"></i>
                            </button>
                        </div>


                        <div>
                            <h6 class="font-semibold mb-0 text-[1rem]">{{titlemodal}}</h6>
                        </div>

                    </div>





                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body" v-if="isdisplayviewarticle===false">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="geshs-tab-js-behavior-item-1" data-hs-tab="#geshs-tab-js-behavior-1" aria-controls="geshs-tab-js-behavior-1">
                                    Information de base
                                </button>

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                        id="geshs-tab-js-behavior-item-2" data-hs-tab="#geshs-tab-js-behavior-2" aria-controls="geshs-tab-js-behavior-2">
                                    Pieds de document, date de livraison,...
                                </button>


                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                        id="geshs-tab-js-behavior-item-3" data-hs-tab="#geshs-tab-js-behavior-3" aria-controls="geshs-tab-js-behavior-3">
                                    Information libre
                                </button>

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                        id="geshs-tab-js-behavior-item-4" data-hs-tab="#geshs-tab-js-behavior-4" aria-controls="geshs-tab-js-behavior-4">
                                    Adresse d'expédition
                                </button>



                            </nav>
                        </div>

                        <div class="">

                            <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                <div>
                                    {{messagesucces}}
                                </div>
                            </div>

                            <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                <div>
                                    {{messageerror}}
                                </div>
                            </div>


                            <div class="alert alert-info text-center mt-3">
                                <p style="color: black;font-size: 15px;">N° document : {{Items.docnum}}   </p>
                            </div>




                            <div id="geshs-tab-js-behavior-1" role="tabpanel" aria-labelledby="geshs-tab-js-behavior-item-1" class="">


                                <div class="ti-custom-validation mt-5" >


                                    <div  class="grid lg:grid-cols-2 gap-6">


                                        <div    class="space-y-2 mt-3 mb-3">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MAGASIN/DEPÔT/ENTREPÔT</label>

                                            <select v-if="!disableddoc" v-model="Items.mag" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option :value="null">Sélectionner un entrepôt</option>
                                                <option v-for="(magasin,index) of alldata.magasin" :key="index" :value="magasin">{{magasin.name}}</option>

                                            </select>

                                            <input v-else :value="Items.mag.name" type="text" disabled class="firstName my-auto ti-form-input  rounded-sm"     required>
                                        </div>


                                        <div   class="space-y-2 mt-3 mb-3">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Date</label>
                                            <input v-if="!disableddoc" v-model="Items.datedoc" type="date" class="firstName my-auto ti-form-input"   required>
                                            <input v-else :value="Items.datedoc" disabled type="date" class="firstName my-auto ti-form-input"   required>

                                        </div>





                                        <div   class="space-y-2 mt-3 mb-3">
                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-12" >
                                                   <label v-if="typededocument==='vente'" class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Client</label>
                                                    <label v-else class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Fournisseur</label>

                                                    <input :placeholder="'Veuillez sélectionner un '+(typededocument==='vente'?'client':'fournisseur')"  v-if="Items.jsonotherdonne.cltfrs===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.jsonotherdonne.cltfrs.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button v-if="!disableddoc" @click="btnmodallistedescltfrs(typededocument)"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                                    <button v-else disabled class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>
                                            </div>


                                        </div>


                                        <div   v-if="typededocument==='vente'" class="space-y-2 mt-3 mb-3">
                                            <div class="space-y-2 mt-3" >
                                                <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">Type de prix :</label>

                                                <select v-if="!disableddoc" v-model="Items.jsonotherdonne.mtypprixdevente" class="form-control">
                                                    <option value="prixvente">{{datainputlibelleforms.prixvente}}</option>
                                                    <option value="prixvente1">{{datainputlibelleforms.prixvente1}}</option>
                                                    <option value="prixvente2">{{datainputlibelleforms.prixvente2}}</option>
                                                    <option value="prixvente3">{{datainputlibelleforms.prixvente3}}</option>
                                                    <option value="prixvente4">{{datainputlibelleforms.prixvente4}}</option>
                                                    <option value="prixvente5">{{datainputlibelleforms.prixvente5}}</option>
                                                    <option value="prixvente6">{{datainputlibelleforms.prixvente6}}</option>
                                                    <option value="prixvente7">{{datainputlibelleforms.prixvente7}}</option>
                                                </select>

                                                <select v-else disabled class="form-control">
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente'?'selected':''" value="prixvente">{{datainputlibelleforms.prixvente}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente1'?'selected':''" value="prixvente1">{{datainputlibelleforms.prixvente1}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente2'?'selected':''" value="prixvente2">{{datainputlibelleforms.prixvente2}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente3'?'selected':''" value="prixvente3">{{datainputlibelleforms.prixvente3}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente4'?'selected':''" value="prixvente4">{{datainputlibelleforms.prixvente4}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente5'?'selected':''" value="prixvente5">{{datainputlibelleforms.prixvente5}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente6'?'selected':''" value="prixvente6">{{datainputlibelleforms.prixvente6}}</option>
                                                    <option :selected="Items.jsonotherdonne.mtypprixdevente==='prixvente7'?'selected':''" value="prixvente7">{{datainputlibelleforms.prixvente7}}</option>
                                                </select>



                                            </div>

                                        </div>



                                    </div>


                                    <!--div v-if="typededocument==='vente'"   class="space-y-2 mt-3 mb-3">
                                        <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">COLLABORATEUR</label>

                                        <select v-model="Items.jsonotherdonne.collaborateur" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option :value="null">Sélectionner un collaborateur</option>
                                            <option v-for="(option,index) of alldata.collaborateur" :key="index" :value="option">{{option.name }} / {{option.typecollaborateur }}</option>

                                        </select>
                                    </div-->




                                    <div v-if="typededocument==='vente'"  class="space-y-2 mt-3 mb-3">
                                        <div class="grid grid-cols-12  gap-x-6 mt-3">

                                            <div class="xl:col-span-10  col-span-12" >
                                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">COLLABORATEUR</label>

                                                <input placeholder="Veuillez sélectionner un collaborateur"  v-if="Items.jsonotherdonne.collaborateur===null" type="text" class="form-control" disabled>
                                                <input v-else :value="Items.jsonotherdonne.collaborateur.name+'/'+Items.jsonotherdonne.collaborateur.typecollaborateur" type="text" class="form-control" disabled>
                                            </div>
                                            <div class="xl:col-span-2  col-span-12">
                                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                <button  @click="btnmodallistedescltfrs('col')"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                            </div>
                                        </div>


                                    </div>









                                    <div class="space-y-2 mt-3 mb-3">
                                        <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MOTIF / RAISON</label>


                                        <textarea v-model="Items.docobservation" type="text" class="firstName my-auto ti-form-input  rounded-sm"
                                              cols="15" rows="5"></textarea>

                                    </div>




                                </div>

                            </div>

                            <div id="geshs-tab-js-behavior-2"  role="tabpanel" aria-labelledby="geshs-tab-js-behavior-item-2" class="hidden">


                                <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Appliquer la TVA(18%) sur ce document</label>

                                        <select v-model="Items.jsonotherdonne.appliquetva" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  value="0">Non</option>
                                            <option value="1">Oui</option>
                                        </select>
                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">AIB</label>

                                        <select v-model="Items.jsonotherdonne.aib" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  value="0"></option>
                                            <option value="1">AIB (1%)</option>
                                            <option value="3">AIB (3%)</option>
                                            <option value="5">AIB (5%)</option>
                                        </select>
                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de réglement</label>

                                        <select v-model="Items.jsonotherdonne.moderglt" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  :value="null">Séléctionner un mode de réglement</option>
                                            <option v-for="(datavalue,index) of alldata.modedereglement" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                        </select>
                                    </div>



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Condiction de réglement</label>

                                        <select v-model="Items.jsonotherdonne.cdtreglement" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  :value="null">Séléctionner une Condiction</option>
                                            <option v-for="(datavalue,index) of alldata.condictiondereglement" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                        </select>
                                    </div>


                                    <div   class="space-y-2 mt-3 mb-3">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Date de livraison (bon de reception et bon de livraison client)</label>
                                        <input v-model="Items.jsonotherdonne.datedelivraison" type="date" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>
                                    </div>

                                    <div   class="space-y-2 mt-3 mb-3">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Echéancier  (bon de commande frs et facture client)</label>
                                        <input v-model="Items.jsonotherdonne.dateechancefacture" type="date" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>
                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre(Avance)</label>
                                        <input v-model="Items.jsonotherdonne.piedsinfodoc.infolibre" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 1 (Solde)</label>
                                        <input v-model="Items.jsonotherdonne.piedsinfodoc.infolibre1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 2</label>
                                        <input v-model="Items.jsonotherdonne.piedsinfodoc.infolibre2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>
                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 3</label>
                                        <input v-model="Items.jsonotherdonne.piedsinfodoc.infolibre3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>



                                </div>


                            </div>

                            <div id="geshs-tab-js-behavior-3"  role="tabpanel" aria-labelledby="geshs-tab-js-behavior-item-3" class="hidden">


                                <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre1}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre2}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre3}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre4}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre4" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre5}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre5" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre6}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre6" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre7}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre7" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre7}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre8" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre9}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre9" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.informationlibre.informationlibre10}}</label>
                                        <input v-model="Items.jsonotherdonne.infodoc.infolibre10" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>









                                </div>


                            </div>

                            <div id="geshs-tab-js-behavior-4"  role="tabpanel" aria-labelledby="geshs-tab-js-behavior-item-4" class="hidden">



                                <div class="mt-3 grid lg:grid-cols-1 gap-6">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.adresseexpedition.informationlibre}}</label>
                                        <input v-model="Items.jsonotherdonne.adresseexpedition.infolibre" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.adresseexpedition.informationlibre1}}</label>
                                        <input v-model="Items.jsonotherdonne.adresseexpedition.infolibre1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                </div>

                                <div class="mt-3 grid lg:grid-cols-2 gap-6">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.adresseexpedition.informationlibre2}}</label>
                                        <input v-model="Items.jsonotherdonne.adresseexpedition.infolibre2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{libelleachat.adresseexpedition.informationlibre3}} </label>
                                        <input v-model="Items.jsonotherdonne.adresseexpedition.infolibre3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>




                                </div>


                            </div>





                        </div>
                    </div>



                    <div class="box-body" v-if="isdisplayviewarticle===true">


                        <div class="">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                       {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                       {{messageerror}}
                                    </div>
                                </div>



                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                <div :class="(typedoc!=='BL' && typedoc!=='BRF')?'xl:col-span-4':'xl:col-span-8'" class="  col-span-10">

                                    <div class="grid grid-cols-12  gap-x-6 mt-3">
                                    <div class="xl:col-span-10  col-span-10" >
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">ARTICLE</label>

                                        <input placeholder="Veuillez sélectionner un article"  v-if="ItemsArticle.article===null" type="text" class="form-control" disabled>
                                        <input v-else :value="ItemsArticle.article.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-1  col-span-1">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                        <button v-if="!disabled" @click="btnmodallistedesarticles('vente')"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                        <button v-else disabled  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                    </div>

                                </div>

                                </div>

                                <div v-if="typedoc!=='BL' && typedoc!=='BRF'" class="xl:col-span-2  col-span-12 mt-3">
                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Pu</label>
                                    <input v-if="typededocument==='vente'" disabled :value="prixdevente"   type="number" min="0" class="form-control text-center" >
                                    <input v-else v-model="ItemsArticle.pa" :disabled="disabledbytypdoc"  type="number" min="0" class="form-control text-center" >

                                </div>

                                <div class="xl:col-span-2  col-span-12 mt-3">
                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">QUANTITE</label>

                                    <input v-model="storeqtite"   type="number" min="0" class="form-control text-center" >

                                </div>


                                <div v-if="typedoc!=='BL' && typedoc!=='BRF'" class="xl:col-span-2  col-span-12 mt-3">
                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">REMISE</label>

                                    <input v-model="ItemsArticle.remise"   type="text" min="0" class="form-control text-center" >

                                </div>


                                <div  class="xl:col-span-2  col-span-12 mt-3">
                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">MONTANT</label>

                                    <input v-model="montant" disabled  type="text" min="0" class="form-control text-center" >

                                </div>

                                <div v-if="typedoc!=='BL' && typedoc!=='BRF'" class="xl:col-span-12  col-span-12 mt-3">
                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">AUTRE DESIGNATION</label>

                                    <input v-model="ItemsArticle.autredesignation"   type="text" class="form-control " >

                                </div>



                            </div>

                            <hr class="mb-[-15px]">
                            <div class="clearfix"></div>
                            <div class="form-group mt-[20px]">
                                <div class="row flex justify-end">
                                   <div class="grid grid-cols-3 gap-x-6 mt-3">

                                       <button @click="newArticle" class="ti-btn ti-btn-primary-full mt-2 mx-[6px]">
                                           <i class="ri-add-fill"></i> Nouveau
                                       </button>
                                       <button @click="SaveArticle()" class="ti-btn ti-btn-success-full mt-2 mx-[6px]" >
                                           <i class="ri-check-line"></i> Enregistrer
                                       </button>
                                       <button @click="deleteItemArticle()" class="ti-btn ti-btn-danger-full mt-2 mx-[6px]" >
                                           <i class="ri-delete-column"></i> Supprimer
                                       </button>

                                   </div>
                                </div>
                            </div>

                            <div class="box-body !p-0 mybodyheight300px">


                                <div class="table-responsive">

                                    <!-- component -->

                                    <div class="flex flex-col h-screen">
                                        <div class="flex-grow overflow-auto">

                                            <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                <thead>
                                                <tr>

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ETAT</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center" colspan="2">DESIGNATION</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">PU</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">QUANTITE</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">REMISE</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">MONTANT</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ACTION</th>

                                                </tr>
                                                </thead>
                                                <tbody>

                                                <template v-if="listedatatable.length">

                                                <template  v-for="(datavalue,index) in listedatatable" :key="index">
                                                    <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light">

                                                        <td  data-label="ETAT  : "   >

                                                            <span v-if="datavalue.etatline===1 || datavalue.etatline==='1'" class="badge mbadge !rounded-full bg-success/10 text-success">OK</span>
                                                            <span v-if="datavalue.etatline===2 || datavalue.etatline==='2' || datavalue.etatline===4 || datavalue.etatline==='4'" class="badge mbadge !rounded-full bg-danger/10 text-danger">Erreur</span>
                                                            <span v-if="datavalue.etatline===0 || datavalue.etatline==='0'" class="badge mbadge !rounded-full bg-info/10 text-info">En attente</span>

                                                        </td>
                                                        <td  data-label="DESIGNATION  : " colspan="2"  >
                                                             <template v-if="datavalue.autredesignation===''">
                                                                {{ datavalue.article.libelle }}
                                                            </template>
                                                            <template v-else>
                                                                {{ datavalue.article.libelle+' ( '+datavalue.autredesignation+' )'}}
                                                            </template>

                                                        </td>
                                                        <td  data-label="PU  : "   >{{ datavalue.pu }}</td>
                                                        <td  data-label="QUANTITE  : "   >{{ formatNumber2(datavalue.qtite) }}</td>
                                                        <td  data-label="REMISE  : "   >{{ datavalue.remise }}</td>
                                                        <td  data-label="MONTANT  : "   >{{ currencyFormatDE(datavalue.calmtntht) }}</td>
                                                        <!--td  data-label="MONTANT  : "   >{{ calculSomme(datavalue.mtnt,datavalue.remise) }}</td-->


                                                        <td  data-label="ACTION  : "   >

                                                            <button   @click="modifierArticle(datavalue)"  aria-label="anchor"
                                                                    class="ti-btn ti-btn-primary-full">
                                                                <i class="ri-check-line"></i> Sélectionner
                                                            </button>
                                                        </td>

                                                    </tr>
                                                    <tr v-if="datavalue.etatline===2">
                                                        <td colspan="20"><div class="alert alert-danger text-center" v-html="datavalue.linemessage"></div></td>
                                                    </tr>

                                                </template>

                                                </template>


                                                <template v-else>
                                                    <tr >
                                                        <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                    </tr>

                                                </template>



                                                </tbody>

                                                <tfoot v-if="listedatatable.length">


                                                <tr class="border border-defaultborder">
                                                    <td style="text-align: end;" colspan="6" >MONTANT HT</td>
                                                    <td>{{currencyFormatDE(Items.mtnt.doccalmtnttotalht)}}</td>
                                                    <!--td>{{calculSomme(Items.mtnt.docmtnttotalht,Items.mtnt.docmtntremise)}}</td-->
                                                    <td></td>
                                                </tr>

                                                <tr v-if="parseFloat(Items.mtnt.docmtntremise)>0" class="border border-defaultborder">
                                                    <td style="text-align: end;" colspan="6" >REMISE</td>
                                                    <td>{{currencyFormatDE(Items.mtnt.docmtntremise)}}</td>
                                                    <td></td>
                                                </tr>

                                                <tr v-if="parseFloat(Items.mtnt.docmtntaib)!==0 && parseFloat(Items.mtnt.docmtntaib)!==-0" class="border border-defaultborder">
                                                    <td style="text-align: end;" colspan="6" >{{Items.mtnt.libaib}}</td>
                                                    <td>{{currencyFormatDE(Items.mtnt.docmtntaib)}}</td>
                                                    <td></td>
                                                </tr>

                                                <tr v-if="parseFloat(Items.mtnt.doctva)>0" class="border border-defaultborder">
                                                    <td style="text-align: end;" colspan="6" >TVA (18%)</td>
                                                    <td>{{currencyFormatDE(Items.mtnt.doctva)}}</td>
                                                    <td></td>
                                                </tr>

                                                <tr class="border border-defaultborder">
                                                    <td style="text-align: end;" colspan="6" >NET A PAYER</td>
                                                    <td>{{currencyFormatDE(Items.mtnt.docmtnttotalttc)}}</td>
                                                    <td></td>
                                                </tr>


                                                </tfoot>


                                            </table>


                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>




                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button :disabled="compteprocess>0" style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer {{compteprocess===0?'':compteprocess}}
                    </button>
                    <button style="background: green" v-if="typeaction==='add' && isdisplayviewarticle===false"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                       Valider
                    </button>
                    <button style="background: green" v-if="typeaction==='update' && isdisplayviewarticle===false" class="ti-btn ti-btn-primary-full" @click="updateItems">
                       Modifier
                    </button>
                    <button :disabled="compteprocess>0" style="background: green" v-if="isdisplayviewarticle===true" class="ti-btn ti-btn-primary-full" @click="close">
                       Appliquer {{compteprocess===0?'':compteprocess}}
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modallistfrsclt @elementSelectionnecltfrs="handleElementSelectedcltfrs" ref="modallistfrsclt"></modallistfrsclt>
    <modallistedesarticles @elementSelectionnearticle="handleElementSelectedarticle" ref="modallistedesarticles"></modallistedesarticles>



</template>

<script>
   /* import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
   import {cloneobjet, currencyFormatDE, FormatNumber, isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
   import modallistfrsclt from "@/components/views/pages/gestioncommerciale/achatvente/modal_listfrsclt";
   import modallistedesarticles from "@/components/views/pages/stock/mvtstock/modal_listedesarticles";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {

            storeqtite: function(oldvalue,newvalue) {

                if(this.ItemsArticle.article!==null ){
                    let montantpu=0;
                    let mqte=0;
                    let $puprixvente=0;
                    let mpu=0;
                    let mpa=0;

                    this.ItemsArticle.qtite=parseFloat(this.storeqtite);

                    if(this.typededocument==='vente'){

                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}




                        //   if (this.disabled===false && this.isblbr===false){
                        if (this.isblbr===true){




                            if (this.ItemsArticle.qtite!==0 ) // recherche du PU dans PV(gestion des prix tarrifaires)
                            {
                                mqte=this.ItemsArticle.qtite;



                                if (this.ItemsArticle.article.pv.length>0 )
                                {
                                    let vm=this


                                    //  montantpu= this.itemArticle.article.pu
                                    montantpu= $puprixvente
                                    this.ItemsArticle.article.pv.forEach(function (line) {

                                    if(line.mtyprix===vm.Items.jsonotherdonne.mtypprixdevente){


                                            if(parseFloat(line.min)<=parseFloat(mqte)){
                                              if(parseFloat(line.max)>=parseFloat(mqte)){

                                                    montantpu=line.pu

                                                }

                                            }
                                        }
                                    })


                                }
                                else{

                                    // montantpu= this.itemArticle.article.pu
                                    montantpu= $puprixvente
                                }




                            }
                            else{
                                // montantpu= this.itemArticle.article.pu
                                montantpu= $puprixvente

                            }



                        }
                        else{

                            if (this.disabled===false){ // l'article n'existe pas dans la liste de la table
                                //montantpu= this.itemArticle.article.pu
                                montantpu= $puprixvente
                            }else{ //existe
                                montantpu= this.ItemsArticle.pu

                            }
                        }



                        //***************************** le bon ok ******************************//
                        if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                            if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                                if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                    //let cal=   parseFloat(this.itemArticle.article.pu)/1.18
                                    let cal=   parseFloat(montantpu)/1.18
                                    // let cal=   parseFloat(this.itemArticle.article.pu)/parseFloat(this.itemArticle.article.typttc)
                                    let fixedcal=cal.toFixed(8);
                                    //this.itemArticle.pu=Math.round(cal)
                                    //this.itemArticle.pu=Math.round(fixedcal)
                                    this.ItemsArticle.pu=fixedcal
                                    this.ItemsArticle.pa=this.ItemsArticle.article.pu
                                    //this.itemArticle.pa=0

                                }
                                else{

                                    // let cal=   parseFloat(this.itemArticle.article.pu)*0.18
                                    let cal=   parseFloat(montantpu)*0.18
                                    this.ItemsArticle.pu=montantpu
                                    this.ItemsArticle.pa=Math.round(cal)

                                }



                            }
                            else{

                                // this.itemArticle.pu=this.itemArticle.article.pu
                                this.ItemsArticle.pu=montantpu
                                this.ItemsArticle.pa=this.ItemsArticle.article.pu
                                //this.itemArticle.pa=0

                            }






                        }
                        else{

                            //  this.itemArticle.pu=this.itemArticle.article.pu
                            this.ItemsArticle.pu=montantpu
                            this.ItemsArticle.pa=this.ItemsArticle.article.pu
                            //this.itemArticle.pa=0

                        }

                    }
                    else{


                        if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                            if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                                if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                    // let cal=   parseInt(this.itemArticle.article.pa)/1.18
                                    let cal=   parseInt(this.ItemsArticle.pa)/1.18
                                    cal=cal.toFixed(8);
                                    this.ItemsArticle.pa=Math.round(cal)
                                    this.ItemsArticle.pu=this.ItemsArticle.article.pu

                                }
                                else{

                                    let cal=   parseInt(this.ItemsArticle.pa)*0.18
                                    //this.itemArticle.pa=this.itemArticle.pa
                                    this.ItemsArticle.pu=Math.round(cal)

                                }



                            }
                            else{

                                //this.itemArticle.pa=this.itemArticle.pa
                                this.ItemsArticle.pu=this.ItemsArticle.article.pu

                            }






                        }
                        else{

                            //  this.itemArticle.pa=this.itemArticle.article.pa
                            this.ItemsArticle.pu=this.ItemsArticle.article.pu

                        }



                    }



                }



            }


        },

        computed:{


            isblbr: function (){

                if (this.typededocument==='vente'){

                    if (this.typedoc==='BL' || this.typedoc==='BRF')
                    {
                        return false;
                    }else{return true;}

                }
                else{  return true; }

            },

            disabledbytypdoc: function (){
                //achat/bon_de_commande
                if (this.typededocument==='achat'){

                    if (this.typedoc==='BC' || this.typedoc==='PF'){
                        return false;
                    }

                    //else{return true;}
                    else{return false;}

                }
                else{  return true; }

            },


            montant()
            {


                let montancalcul=0;

                if(this.ItemsArticle.article!==null ){


                    if (this.ItemsArticle.qtite!==0 )
                    {

                        if(this.typededocument==='vente'){
                            //console.log('this.itemArticle.pu ',this.itemArticle.pu)

                            montancalcul=parseFloat(this.ItemsArticle.pu)*parseFloat(this.ItemsArticle.qtite);

                        }else{

                            montancalcul=parseFloat(this.ItemsArticle.pa)*parseFloat(this.ItemsArticle.qtite);

                        }



                    }


                    if (isNaN(this.ItemsArticle.remise)) {
                        this.ItemsArticle.remise=0;
                    }


                    if (this.ItemsArticle.remise!==0 )
                    {
                        montancalcul=montancalcul-parseFloat(this.ItemsArticle.remise);
                    }

                }
                else {

                    // this.itemArticle.pu=0;


                    if(this.typededocument==='vente'){
                        this.ItemsArticle.pu=0;
                    }else{

                        this.ItemsArticle.pa=0;
                    }


                }
                montancalcul= Math.round(montancalcul)
                this.ItemsArticle.mtnt=montancalcul;

                return montancalcul


                //return this.formatNumber2(Number(montancalcul))
            },



            prixdevente: function (){

                if (this.ItemsArticle.article===null){
                    return 0;
                }
                else{
                    return this.ItemsArticle.pu;
                }

            },



            disableddoc(){

                return this.Items.docnum!==''?true:false;
            },
            stock: function (){

                if (this.ItemsArticle.article!==null){
                    return parseFloat(this.ItemsArticle.article.rstck);
                    //  return "";
                }else{
                    return 0;
                    // return "disabled";
                }

            },





        },
        components: {
           /* templatesite,
            appheader,
            appsidebar,
            appmenu,*/

            modallistfrsclt,modallistedesarticles

        },

        data() {
            return {


                storeqtite:0,
                compteprocess:0,

                isdisplayviewarticle:false,
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                titlemodal:"",
                typedoc:"",
                typededocument:"",
                typeaction:"add",
                libelleachat: {},
                datainputlibelleforms: {},
                Items: {},
                alldata: [],

                listedatatable:[],
                listedatatableproduit:[],
                listedatatablecltfrs:[],
                mydocmag:'',
                ItemsArticle:{
                    autredesignation:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''





                },

            };
        },
        methods: {

            calcullistedatatable() {

                let vm=this;


                let $montantnontaxable=0,
                    $montantaib=0,
                    $montantcdtionreglemen=0,
                    $montanttva=0,
                    $montanthtarpvht=0,
                    $montanthtarpvttc=0,
                    $montanttotalht=0,
                    $montanttotalttc=0,
                    $montanttotaltrt =0,
                    $smontantremise=0,
                    $istva=0;

                if (this.listedatatable.length>0){

                    this.listedatatable.forEach(function (line) {

                        if (parseFloat(line.etatline)===0 || parseFloat(line.etatline)===1 || parseFloat(line.etatline)===4 ){




                       // $istva=0;
                        let $datajsonarticle=line.article;
                        let $lignemontantht=line.mtnt;
                        let $mtnt_remise=line.remise;

                        if($datajsonarticle.taxedimposition!=="A"){ //verification si l'article est taxable
                            if(vm.Items.jsonotherdonne.appliquetva==="1" || vm.Items.jsonotherdonne.appliquetva===1) /// verification si on applique la tva sur ce document
                            {
                                //si oui

                                $istva=1;

                                if(parseFloat($datajsonarticle.tptva)===1) /// verification de l'application du tva sur cet article
                                {
                                    //si oui
                                    $montanthtarpvttc=parseFloat($montanthtarpvttc)+parseFloat($lignemontantht);  //montant total des montants HT sur lequelle on applique la tva

                                }
                                else{
                                    $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht) ;//montant total des montants HT sur lequelle on pas applique la tva

                                }



                            }
                            else{ // On applique pas la tv a sur le produit


                                $montanthtarpvttc=0;
                                $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht); //montant total des montants HT sur lequelle on pas applique la tva

                            }

                        }
                        else{

                            $montantnontaxable=parseFloat($montantnontaxable)+parseFloat($lignemontantht) ;

                        }

                        $smontantremise=parseFloat($smontantremise)+parseFloat($mtnt_remise);
                        }
                    })



                    // $montanttotalht= floatval($montanthtarpvttc)+ floatval($montanthtarpvht);/// calcul du montant total ht sans le montant non taxable
                    $montanttotalht= parseFloat($montanthtarpvttc)+ parseFloat($montanthtarpvht)+parseFloat($montantnontaxable);/// calcul du montant total ht avec le montant non taxable

                    $montanttotaltrt="";

                    if ( $istva===1){

                        $montanttva= parseFloat($montanthtarpvttc)*0.18;////// calcul du montant de la TVA

                    }

                    let $aib=parseInt(vm.Items.jsonotherdonne.aib); // taux de AIB


                    let  $libaib="AIB";
                    if ($aib!==0){
                        $libaib="Montant AIB ("+$aib+" %)";

                        $montantaib=parseFloat($montanttotalht)*(parseInt($aib)/100);


                    }else {$montantaib=0;}


                    let $netfinancier=0;


                    if (this.typedoc==="DAF" || this.typedoc==="BCF" ||
                        this.typedoc==="RF" ||  this.typedoc==="FR" ||
                        this.typedoc==="FRF")
                    {
                        $montantaib=-$montantaib;
                        $netfinancier=$montanttotalht+$montantaib;



                        if ( $istva===1){
                            $montanttva= parseFloat($netfinancier)*0.18;////// calcul du montant de la TVA
                        }

                        $montanttotalttc=parseFloat($netfinancier)+parseFloat($montanttva);

                    }
                    else{

                        /////// calcul du montant ttc

                        $montanttotalttc=parseFloat($montanttotalht)+parseFloat($montantaib)+parseFloat($montanttva);


                    }


                      vm.Items.mtnt={
                        doctva:Math.round($montanttva),
                        docmtnttotalttc:Math.round($montanttotalttc),
                        docmtntaib:Math.round($montantaib),
                        docmtntremise:Math.round($smontantremise),
                        docmtnttotalht:Math.round($montanttotalht),
                        doccalmtnttotalht:Math.round($montanttotalht)+Math.round($smontantremise),
                        libaib:$libaib
                    };

                }
                else{
                    this.Items.mtnt={
                        doctva:Math.round(0),
                        docmtnttotalttc:Math.round(0),
                        docmtntaib:Math.round(0),
                        docmtntremise:Math.round(0),
                        docmtnttotalht:Math.round(0),
                        doccalmtnttotalht:Math.round(0),
                        libaib:"Montant AIB (0 %)"
                    }

                }




            },


            calculSomme(p1,p2){
                var number=Number(p1)+Number(p2)
                return currencyFormatDE(number)
            },

            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },

            formatNumber2(p1){
                var number=Number(p1)
                return FormatNumber(number)
            },

            btnmodallistedescltfrs(option){


                let listedatable=[],
                    titlemodal="",
                    typedoc="";


                if (option==='clt' || option==='vente'){
                    titlemodal="Liste des clients"
                    listedatable=this.listedatatablecltfrs
                    option='clt'
                }

                if (option==='frs' || option==='achat'){
                    titlemodal="Liste des fournisseurs"
                    listedatable=this.listedatatablecltfrs
                    option='frs'
                }

                if (option==='col'){
                    titlemodal="Liste des collaborateurs"
                    listedatable=this.alldata.collaborateur
                    option='col'
                }


                let $CUSTOMLIBELLEFRSCLT=JwtService.getCUSTOMLIBELLEFRSCLT()
                //appcustomlibelleachat

                if (isset($CUSTOMLIBELLEFRSCLT)){
                    $CUSTOMLIBELLEFRSCLT=JSON.parse($CUSTOMLIBELLEFRSCLT);

                }
                else{

                    $CUSTOMLIBELLEFRSCLT=this.$store.state.appcustomlibellefrsclt;

                    JwtService.setCUSTOMLIBELLEFRSCLT(JSON.stringify(this.customlibelleforms))


                }


                this.$refs.modallistfrsclt.show(titlemodal,listedatable,option,this.datainputlibelleforms,$CUSTOMLIBELLEFRSCLT);

            },

            handleElementSelectedcltfrs(elementSelectionne) {

               /* if (elementSelectionne.typ==="add"){
                    this.jsonarticles.push(elementSelectionne.value)
                    this.newsjsonarticles.push(elementSelectionne.value)
                }*/

                if (elementSelectionne.typ==="col"){

                    this.Items.jsonotherdonne.collaborateur=elementSelectionne.value
                    this.alldata.collaborateur=elementSelectionne.datatable


                }
                else{

                    this.Items.jsonotherdonne.cltfrs=elementSelectionne.value
                    this.listedatatablecltfrs=elementSelectionne.datatable


                }




            },

            btnmodallistedesarticles(option){

                let listedatable=[],
                    titlemodal="Liste des articles",
                    typedoc="";


                this.$refs.modallistedesarticles.show(titlemodal,option,this.listedatatableproduit);

            },

            handleElementSelectedarticle(elementSelectionne) {

                /* if (elementSelectionne.typ==="add"){
                     this.jsonarticles.push(elementSelectionne.value)
                     this.newsjsonarticles.push(elementSelectionne.value)
                 }*/

                this.ItemsArticle.article=elementSelectionne.value

                this.changeItem(this.ItemsArticle.article)




            },


            close(){

                this.listedatatableproduit=[];
                this.listedatatable=[];
                this.ItemsArticle={
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    calmtntht:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    etatline:0,
                    pid:''
                }
                this.isdisplayviewarticle=false;
                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,typedoc,Items,alldata,datainputlibelleforms,typededocument,customlibelleforms){

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.typededocument=typededocument
                this.datainputlibelleforms=datainputlibelleforms
                this.Items=Items
                this.alldata=alldata
                this.libelleachat=customlibelleforms

                this.messagesucces="";
                this.messageerror="";
                this.listedatatablecltfrs=this.alldata.frsclt

                //if (this.Items.pid!==""){this.isdisplayviewarticle=true}

                this.isdisplayviewarticle=false;
                this.isdisplay=true;

            },
          async  showupdate(titlemodal,typeaction,typedoc,Items,alldata,datainputlibelleforms,typededocument,customlibelleforms){

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.Items=Items
                this.alldata=alldata
              this.typededocument=typededocument
              this.datainputlibelleforms=datainputlibelleforms
              this.libelleachat=customlibelleforms
                this.messagesucces="";
                this.messageerror="";


              this.ItemsArticle={
                  docnum:Items.numdoc,
                  article:null,
                  calmtntht:0,
                  qtite:0,
                  diqte:0,
                  pu:0,
                  pa:0,
                  mtnt:0,
                  mtntclt:0,
                  remise:0,
                  doc:'',
                  autredesignation:'',
                  docpid:Items.pid,
                  docnumother:'',
                  docmag:'',
                  doctp:'',
                  linekey:'',
                  etatline:0,
                  pid:''
              }

                if (this.Items.pid!==""){this.isdisplayviewarticle=true}

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/stock/'+this.Items.numdoc+'/'+this.Items.pid+'/upd/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.listedatatableproduit=[];
                            t.listedatatableproduit=res.data.m;

                             t.Items.jsonotherdonne=Items.jsonotherdonne;
                            t.Items.mag=Items.mag;
                            t.Items.pid=Items.pid;
                            t.ItemsArticle.docpid=Items.pid;
                            t.Items.params=Items.params;
                            t.Items.mtnt=Items.mtnt;
                            t.Items.docnum=Items.numdoc;


                            t.Items.docnumother=Items.docnumother;
                            t.Items.docpid=Items.pid;
                            t.Items.pid=Items.pid;
                            t.Items.infodoc=Items.infodoc;
                            t.Items.datedoc=Items.datedoc;
                            t.Items.doctyp=Items.doctyp;
                            t.Items.docobservation=Items.observations;


                            //t.listearticles=response.data.allprod;

                            t.listedatatable=res.data.alllignedoc;
                           t.mydocmag=Items.mag.pid;
                            t.ItemsArticle.docmag=Items.mag.pid;
                            //t.itemArticle.docnum=value.numdoc;
                            t.ItemsArticle.docnum=Items.pid;
                            t.ItemsArticle.doctp=Items.doctyp;
                            t.ItemsArticle.docnumother=Items.docotherref;


                            t.calcullistedatatable();

                            t.isdisplayviewarticle=true;
                            t.isdisplay=true;

                        }
                        else{
                            t.listedatatableproduit=[];
                           // t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })

            },

            newArticle(){
                this. ItemsArticle.docmag=this.mydocmag;
                this. ItemsArticle.article=null;
                this. ItemsArticle.qtite=0;
                this. ItemsArticle.pu=0;
                this. ItemsArticle.remise=0;
                this. ItemsArticle.calmtntht=0;
                this. ItemsArticle.mtnt=0;
                this. ItemsArticle.pid='';
                this.storeqtite=0;
                this. ItemsArticle.autredesignation='';
                this. ItemsArticle.linekey='';
                this. ItemsArticle.etatline=0;
                this.ItemsArticle.spid='';
                this.disabled=false;

            },

            updateEntete(){
                this.entetedoc=true;
                this.articledoc=false;


            },

            modifierArticle(value){

                let t=this;
                ///  let qte=this.formatNumber2(Number(value.qtite));
                let qte=value.qtite;
                var mitemarticle=this.ItemsArticle

                this.disabled=true;
                this.ItemsArticle={
                    article:value.article,
                    qtite:qte,
                    diqte:0,
                    pu:parseFloat(value.ppu),
                    pa:parseFloat(value.pu),
                    mtnt:value.mtnt ,
                   /* docnumother:mitemarticle.docnumother ,*/
                    docnumother:value.docnumother ,
                    remise:value.remise,
                    calmtntht:value.calmtntht,
                    //docnum:value.numdoc,
                    autredesignation:value.autredesignation,
                    doctp:value.doctyp,
                    pid:value.pid,
                    linekey:value.linekey,
                    etatline:value.etatline,
                    docpid:value.docnum,
                    docnum:value.docnum
                };

                this.storeqtite=qte;

            },

            changeItem(value){

                let pid=this.ItemsArticle.pid;
               // let value=event;
                if (pid!=='') return;
                let dataSearch=[];

                if(value!==null){

                    if(this.listedatatable.length !==0){


                        dataSearch = this.listedatatable.filter(function (article) {

                            return  article.article.pid ===value.pid
                            //return article.pidar.includes(value.pid);
                        });


                        if(dataSearch.length !==0){
                            toastr['error']( value.libelle+' existe déjà dans la liste.',"<b></b>");
                            this.ItemsArticle.article=null;
                            this.ItemsArticle.qtite=0;
                            this.ItemsArticle.pu= 0;
                            this.storeqtite=0;

                            let mvalue=dataSearch[0];
                            ///  let qte=this.formatNumber2(Number(value.qtite));
                            let qte=mvalue.qtite;
                            var mitemarticle=this.ItemsArticle
                            this.disabled=true;

                            this.ItemsArticle={
                                article:mvalue.article,
                                qtite:qte,
                                diqte:0,
                                pu:mvalue.ppu,
                                pa:mvalue.pa,
                                mtnt:mvalue.mtnt ,
                                docnumother:mitemarticle.docnumother ,
                                remise:mvalue.remise,
                                calmtntht:mvalue.calmtntht,
                                //docnum:value.numdoc,
                                autredesignation:value.autredesignation,
                                doctp:mvalue.doctyp,
                                pid:mvalue.pid,
                                docpid:mvalue.docnum,
                                docnum:mvalue.docnum
                            };

                            this.storeqtite=qte;






                        }else{
                            // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                            //this.itemArticle.pu= this.itemArticle.article.pu

                            this.gestionPu();


                        }


                    }else{
                        // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                        // this.itemArticle.pu=this.itemArticle.article.pu
                        //this.itemArticle.pu= this.itemArticle.article.pu
                        this.gestionPu();
                    }


                }


            },

            gestionPu(){


                if(this.typededocument==='vente'){

                    let $puprixvente=0;

                    //***************************** le bon ok ******************************
                    if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                        if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                            if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}



                                // let cal=   parseFloat(this.itemArticle.article.pu)/1.18
                                let cal=   parseFloat($puprixvente)/1.18
                                // let cal=   parseFloat(this.itemArticle.article.pu)/parseFloat(this.itemArticle.article.typttc)
                                let fixedcal=cal.toFixed(8);
                                //this.itemArticle.pu=Math.round(cal)
                                //this.itemArticle.pu=Math.round(fixedcal)
                                this.ItemsArticle.pu=fixedcal
                                //this.itemArticle.pa=this.itemArticle.article.pu
                                this.ItemsArticle.pa=0

                            }
                            else{
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                                //let cal=   parseFloat(this.itemArticle.article.pu)*0.18
                                let cal=   parseFloat($puprixvente)*0.18
                                //this.itemArticle.pu=this.itemArticle.article.pu
                                this.ItemsArticle.pu=$puprixvente
                                this.ItemsArticle.pa=Math.round(cal)

                            }



                        }
                        else{

                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                            this.ItemsArticle.pu=$puprixvente
                            this.ItemsArticle.pa=0
                            /*this.itemArticle.pu=this.itemArticle.article.pu
                            this.itemArticle.pa=this.itemArticle.article.pu*/

                        }






                    }
                    else{

                        /* this.itemArticle.pu=this.itemArticle.article.pu
                         this.itemArticle.pa=this.itemArticle.article.pu*/

                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                        this.ItemsArticle.pu=$puprixvente
                        this.ItemsArticle.pa=0
                        /*this.itemArticle.pu=this.itemArticle.article.pu
                        this.itemArticle.pa=this.itemArticle.article.pu*/


                    }

                }
                else{



                    if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                        if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                            if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                let cal=   parseInt(this.ItemsArticle.article.pa)/1.18
                                cal=cal.toFixed(8);
                                this.ItemsArticle.pa=Math.round(cal)
                                this.ItemsArticle.pu=this.ItemsArticle.article.pu

                            }
                            else{

                                let cal=   parseInt(this.ItemsArticle.article.pa)*0.18
                                this.ItemsArticle.pa=this.ItemsArticle.article.pa
                                this.ItemsArticle.pu=Math.round(cal)

                            }



                        }
                        else{

                            this.ItemsArticle.pa=this.ItemsArticle.article.pa
                            this.ItemsArticle.pu=this.ItemsArticle.article.pu

                        }






                    }
                    else{

                        this.ItemsArticle.pa=this.ItemsArticle.article.pa
                        this.ItemsArticle.pu=this.ItemsArticle.article.pu

                    }



                }



                //  this.itemArticle.qtite=1;

            },

            async SaveArticle(){


                if(this.typededocument==='vente'){

                    // console.log("this.itemArticle  ************* ",this.itemArticle)

                    if (this.ItemsArticle.article===null || this.ItemsArticle.qtite===0 || this.ItemsArticle.qtite==='0'
                        || this.ItemsArticle.mtnt===0  || this.ItemsArticle.pu===0 || this.ItemsArticle.pu===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }

                }
                else{

                    if (this.ItemsArticle.article===null || this.ItemsArticle.qtite===0 || this.ItemsArticle.qtite==='0'
                        || this.ItemsArticle.mtnt===0  || this.ItemsArticle.pa===0 || this.ItemsArticle.pa===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }


                }




                const t = this;

                let linekey="",
                    newqtite=0,
                    oldqtite=0,
                    mitems;


                if (t.ItemsArticle.linekey===""){
                    linekey= randomString(8);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;
                            line.qtite=newqtite;
                            line.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);


                            /*

                            autredesignation:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    etatline:0,
                    pid:''


                            */

                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour

                //let response =   axios  .post('/api/84688866mvtdoc78dfdfd/02554additem547885/'+this.$store.state.beartoken, t.itemArticle)


                //this.$store.dispatch('lancerchargement',true)
                let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/vline/'+this.typededocument+'/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                     let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //  t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");

                            //  t.newArticle();

                            //t.itemArticle.pid=res.data.key
                            // t.listedatatable=res.data.alllignedoc;
                            //  t.itemDoc.mtnt=res.data.mtnt;


                            t.compteprocess--;
                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=1;

                                    if (line.pid===""){line.pid=res.data.key;}



                                }
                            })



                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },

            async deleteItemArticle(){


                if(this.typededocument==='vente'){

                    // console.log("this.itemArticle  ************* ",this.itemArticle)

                    if (this.ItemsArticle.article===null || this.ItemsArticle.qtite===0 || this.ItemsArticle.qtite==='0'
                        || this.ItemsArticle.mtnt===0  || this.ItemsArticle.pu===0 || this.ItemsArticle.pu===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }

                }
                else{

                    if (this.ItemsArticle.article===null || this.ItemsArticle.qtite===0 || this.ItemsArticle.qtite==='0'
                        || this.ItemsArticle.mtnt===0  || this.ItemsArticle.pa===0 || this.ItemsArticle.pa===0){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return ;
                    }


                }


                this.ItemsArticle.spid=this.ItemsArticle.docnum


                const t = this;

                let linekey="",
                    newqtite=0,
                    oldqtite=0,
                    mitems;


                if (t.ItemsArticle.linekey===""){
                    /*linekey= randomString(8);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);*/

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;
                            /*line.qtite=newqtite;
                            line.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);
*/


                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                if (t.ItemsArticle.pid===""){ // suppression d'un article non enregistrer dans la base de donnée

                    t.listedatatable.forEach(function (line,index) {

                        if (line.linekey===linekey){

                            t.listedatatable.splice(index,1);
                        }

                    })

                    this.newArticle();
                    this.calcullistedatatable();
                    return
                }












                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour

                //let response =   axios  .post('/api/84688866mvtdoc78dfdfd/02554additem547885/'+this.$store.state.beartoken, t.itemArticle)


                //this.$store.dispatch('lancerchargement',true)
                let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/vline/'+this.typededocument+'/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                     let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //  t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");

                            //  t.newArticle();

                            //t.itemArticle.pid=res.data.key
                            // t.listedatatable=res.data.alllignedoc;
                            //  t.itemDoc.mtnt=res.data.mtnt;


                            t.compteprocess--;
                            t.listedatatable.forEach(function (line,index) {

                                if (line.linekey===linekey){

                                    t.listedatatable.splice(index,1);
                                }
                            })

                            t.calcullistedatatable();



                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                           // t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        //t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },





            async  saveItems(){



                if (this.Items.mag===null || this.Items.datedoc===""  || this.Items.docobservation==="" ||
                    this.Items.jsonotherdonne.cltfrs===null){


                    toastr['error']( "Veuillez saisir toutes les informations (date, entrepôt, client/fournisseur, Motif)","<b></b>");
                    return
                }



                  this.$store.dispatch('lancerchargement',true)

                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/vadd/'+this.typededocument+'/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";


                                t.mydocmag=t.Items.mag.pid;
                                t.ItemsArticle.docmag=t.Items.mag.pid;
                                //t.listearticles=res.data.allprod;
                                t.listedatatableproduit=res.data.m;
                                t.ItemsArticle.docnum=res.data.numdoc;
                                t.Items.docnum=res.data.numdoc;
                                t.Items.docpid=res.data.pid;
                                t.ItemsArticle.docnum=res.data.pid;
                                t.ItemsArticle.docpid=res.data.pid;
                                t.newArticle()

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");

                                t.isdisplayviewarticle=true;
                                t.messagesucces="";
                                t.messageerror="";




                            }
                    else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },
            async  updateItems(){



                if (this.Items.mag===null || this.Items.datedoc===""  || this.Items.docobservation==="" ||
                    this.Items.jsonotherdonne.cltfrs===null){


                    toastr['error']( "Veuillez saisir toutes les informations (date, entrepôt, client/fournisseur, Motif)","<b></b>");
                    return
                }



                  this.$store.dispatch('lancerchargement',true)

                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/vmaj/'+this.typededocument+'/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";


                                t.mydocmag=t.Items.mag.pid;
                                t.ItemsArticle.docmag=t.Items.mag.pid;
                                //t.listearticles=res.data.allprod;
                                t.listedatatableproduit=res.data.m;
                                t.ItemsArticle.docnum=res.data.numdoc;
                                t.Items.docnum=res.data.numdoc;
                                t.Items.docpid=res.data.pid;
                                t.ItemsArticle.docnum=res.data.pid;
                                t.ItemsArticle.docpid=res.data.pid;
                                t.newArticle()

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");



                                let $aib=parseInt(t.Items.jsonotherdonne.aib)

                                if ($aib===0){t.Items.mtnt.docmtntaib=0}



                                t.calcullistedatatable()


                                t.isdisplayviewarticle=true;
                                t.messagesucces="";
                                t.messageerror="";




                            }
                    else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }

                                t.calcullistedatatable()


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },


        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>