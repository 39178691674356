<template>


    <div class="grid grid-cols-12 gap-x-6">



        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                        Evolution du C.A
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtnsuiviedeca='1Y';loadevoluationca()" :class="indexbtnsuiviedeca==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 AN</button>
                        <button type="button" @click="indexbtnsuiviedeca='2Y';loadevoluationca()" :class="indexbtnsuiviedeca==='2Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 ANS</button>
                        <button type="button" @click="indexbtnsuiviedeca='3Y';loadevoluationca()" :class="indexbtnsuiviedeca==='3Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">3 ANS</button>
                    </div>
                </div>
                <div class="box-body">

                    <apexcharts v-if="Object.keys(graphssuivieca).length>0" type="area" height="350" :options="graphssuivieca.chartOptions" :series="graphssuivieca.series"></apexcharts>

                </div>
            </div>
        </div>

        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                        Evolution du chiffre d'affaires suivant les entrpôt / magasin


                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtnsuiviepointdevente=(currentyear-2);loadevoluationpointdevente()" :class="indexbtnsuiviepointdevente===(currentyear-2)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">{{(currentyear-2)}}</button>
                        <button type="button" @click="indexbtnsuiviepointdevente=(currentyear-1);loadevoluationpointdevente()" :class="indexbtnsuiviepointdevente===(currentyear-1)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">{{(currentyear-1)}}</button>
                        <button type="button" @click="indexbtnsuiviepointdevente=(currentyear);loadevoluationpointdevente()" :class="indexbtnsuiviepointdevente===(currentyear)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">{{(currentyear)}}</button>
                    </div>

                </div>
                <div class="box-body">


                    <apexcharts v-if="Object.keys(graphssuiviepointdevente).length>0" type="bar" height="350" :options="graphssuiviepointdevente.chartOptions" :series="graphssuiviepointdevente.series"></apexcharts>


                </div>
            </div>
        </div>

        <div class="xl:col-span-12 col-span-12">
            <div class="box custom-box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        Les 6 articles/services les plus vendus
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtntop6desarticlesvendue=(currentyear-2);loadtop6desarticleslesplusvendues()" :class="indexbtntop6desarticlesvendue===(currentyear-2)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">{{(currentyear-2)}}</button>
                        <button type="button" @click="indexbtntop6desarticlesvendue=(currentyear-1);loadtop6desarticleslesplusvendues()" :class="indexbtntop6desarticlesvendue===(currentyear-1)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">{{(currentyear-1)}}</button>
                        <button type="button" @click="indexbtntop6desarticlesvendue=(currentyear);loadtop6desarticleslesplusvendues()" :class="indexbtntop6desarticlesvendue===(currentyear)?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">{{(currentyear)}}</button>
                    </div>

                </div>
                <div class="box-body">
                    <div class="grid grid-cols-12 gap-x-6">

                        <div class="xl:col-span-6 col-span-12" v-for="(graph,index) of graphsloadtoparticles" :key="index">

                    <apexcharts type="pie" :options="graph.chartOptions" :series="graph.series"></apexcharts>




                     </div>

                    </div>


                </div>
                <div class="box-footer border-t-0 hidden">

                </div>
            </div>
        </div>


        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                        État des créances
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtndelais='1M';loadallcreance()" :class="indexbtndelais==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 MOIS</button>
                        <button type="button" @click="indexbtndelais='3M';loadallcreance()" :class="indexbtndelais==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">3 MOIS</button>
                        <button type="button" @click="indexbtndelais='6M';loadallcreance()" :class="indexbtndelais==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">6 MOIS</button>
                        <button type="button" @click="indexbtndelais='1Y';loadallcreance()" :class="indexbtndelais==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 AN</button>
                        <button type="button" @click="indexbtndelais='2Y';loadallcreance()" :class="indexbtndelais==='2Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 ANS</button>
                        <button type="button" @click="indexbtndelais='3Y';loadallcreance()" :class="indexbtndelais==='3Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">3 ANS</button>
                    </div>
                </div>
                <div class="box-body">


                    <div class="box-body !p-0 mybodyheight400px">
                        <div class="table-responsive">
                            <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                <input v-model="searchwordcreance" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                       placeholder="Rechercher "
                                       aria-label=".form-control-lg example">
                            </div>
                            <div class="flex flex-col h-screen">
                                <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">


                                    <table class="table table-striped table-bordered table-hover
                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CLIENT</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CA   </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREANCES </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OPTION </th>


                                        </tr>
                                        </thead>



                                        <tbody>
                                        <template v-if="filterdatatablecreance.length">

                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                v-for="(datavalue,index) in filterdatatablecreance" :key="index">

                                                <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                <td  data-label="CLIENT : "   >{{ datavalue.namecltfrs }}</td>
                                                <td  data-label="CA  : "   >{{ formatMoney(datavalue.ca) }}</td>
                                                <td  data-label="CREANCES: " >{{ formatMoney(datavalue.creance) }}</td>
                                                <td  data-label="OPTION: " >
                                                    <button  class="ti-btn ti-btn-primary-full"><i class="ri-eye-line"></i></button>

                                                </td>


                                            </tr>




                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="9"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>

                                        <tfoot>
                                        <tr style="font-weight: 600;/*background: khaki;*/">
                                            <td colspan="2" data-label="Total"><h2>Total</h2></td>
                                            <td data-label="Total CA"><h2>{{formatMoney(totalca)}}</h2></td>
                                            <td data-label="Total DETTE"><h2>{{formatMoney(totalcreance)}}</h2></td>
                                            <td></td>
                                        </tr>
                                        </tfoot>

                                    </table>



                                </div>
                            </div>

                        </div>
                    </div>






                </div>
            </div>
        </div>

        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                        État des dettes
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtndelaisdette='1M';loadalldette()" :class="indexbtndelaisdette==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 MOIS</button>
                        <button type="button" @click="indexbtndelaisdette='3M';loadalldette()" :class="indexbtndelaisdette==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">3 MOIS</button>
                        <button type="button" @click="indexbtndelaisdette='6M';loadalldette()" :class="indexbtndelaisdette==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">6 MOIS</button>
                        <button type="button" @click="indexbtndelaisdette='1Y';loadalldette()" :class="indexbtndelaisdette==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 AN</button>
                        <button type="button" @click="indexbtndelaisdette='2Y';loadalldette()" :class="indexbtndelaisdette==='2Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 ANS</button>
                        <button type="button" @click="indexbtndelaisdette='3Y';loadalldette()" :class="indexbtndelaisdette==='3Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">3 ANS</button>
                    </div>
                </div>
                <div class="box-body">


                    <div class="box-body !p-0 mybodyheight400px">
                        <div class="table-responsive">
                            <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                <input v-model="searchworddette" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                       placeholder="Rechercher "
                                       aria-label=".form-control-lg example">
                            </div>
                            <div class="flex flex-col h-screen">
                                <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">


                                    <table class="table table-striped table-bordered table-hover
                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FOURNISSEURS</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CHARGES   </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DETTE </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OPTION </th>


                                        </tr>
                                        </thead>



                                        <tbody>
                                        <template v-if="filterdatatabledette.length">

                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                v-for="(datavalue,index) in filterdatatabledette" :key="index">

                                                <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                <td  data-label="FOURNISSEURS : "   >{{ datavalue.namecltfrs }}</td>
                                                <td  data-label="CHARGES  : "   >{{ formatMoney(datavalue.charges) }}</td>
                                                <td  data-label="DETTE: " >{{ formatMoney(datavalue.dette) }}</td>
                                                <td  data-label="OPTION: " >
                                                    <button  class="ti-btn ti-btn-primary-full"><i class="ri-eye-line"></i></button>

                                                </td>


                                            </tr>




                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="9"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>

                                        <tfoot>
                                        <tr style="font-weight: 600;/*background: khaki;*/">
                                            <td colspan="2" data-label="Total"><h2>Total</h2></td>
                                            <td data-label="Total CHARGES"><h2>{{formatMoney(totalcharges)}}</h2></td>
                                            <td data-label="Total DETTE"><h2>{{formatMoney(totaldette)}}</h2></td>
                                            <td></td>
                                        </tr>
                                        </tfoot>

                                    </table>



                                </div>
                            </div>

                        </div>
                    </div>






                </div>
            </div>
        </div>


        <div class="xl:col-span-3 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        Etat du stock
                    </div>
                    <div class="flex items-center flex-wrap gap-2">
                        <input class="ti-form-control form-control-sm custom-placeholder" v-model="searchwordstock" type="text"
                               placeholder="Rechercher" aria-label=".form-control-sm example">
                        <div class="hs-dropdown ti-dropdown !py-0 !mt-0 !mb-0">
                            <a href="javascript:void(0);"
                               class="ti-btn ti-btn-primary !py-1 !px-2 !text-[0.75rem] !m-0 !gap-0 !font-medium !mb-0"
                               aria-expanded="false">
                                Trier par<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">

                                <li><a @click="orderbystock='asc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant le stock</a></li>

                                <li><a @click="orderbystock='desc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre décroissant suivant le stock</a></li>


                                <li><a @click="orderbystock='ascnom'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les articles</a></li>



                                <li><a @click="orderbystock='ascmag'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les magasins / entrepôts</a></li>


                            </ul>
                        </div>
                    </div>
                </div>
                <div class="box-body !p-0 mybodyheight400px">

                    <div class="table-responsive">

                        <div class="flex flex-col h-screen">
                            <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                <ul class="list-group list-group-flush max-h-[22.5rem]">
                                    <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline" v-for="(datavalue,j) of filterdatatablestock" :key="j">
                                        <div class="flex items-center flex-grow leading-none">

                                            <div class="leading-none flex-grow">
                                                <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.libelle}}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">{{ datavalue.nommaga }}</span>
                                            </div>
                                            <div class="text-end me-3">
                                                                <span class="block font-semibold mb-1">
                                                                    {{formatNumber(datavalue.stock) }}
                                                                </span>
                                                <!--span class="block text-success"><i class="ti ti-arrow-narrow-up"></i>0.14%</span-->
                                            </div>
                                            <!--div>
                                                                            <span class="text-[0.875rem]">
                                                                                <i class="bi bi-star-fill text-[#8c9097] dark:text-white/50 opacity-50"></i>
                                                                            </span>
                                            </div-->
                                        </div>
                                    </li>



                                </ul>






                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </div>

        <div class="xl:col-span-3 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        Stock minimum atteint
                    </div>
                    <div class="flex items-center flex-wrap gap-2">
                        <input class="ti-form-control form-control-sm custom-placeholder" v-model="searchwordstockminimum" type="text"
                               placeholder="Rechercher" aria-label=".form-control-sm example">
                        <div class="hs-dropdown ti-dropdown !py-0 !mt-0 !mb-0">
                            <a href="javascript:void(0);"
                               class="ti-btn ti-btn-primary !py-1 !px-2 !text-[0.75rem] !m-0 !gap-0 !font-medium !mb-0"
                               aria-expanded="false">
                                Trier par<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">

                                <li><a @click="orderbystockminimum='asc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant le stock</a></li>

                                <li><a @click="orderbystockminimum='desc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre décroissant suivant le stock</a></li>


                                <li><a @click="orderbystockminimum='ascnom'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les articles</a></li>



                                <li><a @click="orderbystockminimum='ascmag'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les magasins / entrepôts</a></li>


                            </ul>
                        </div>
                    </div>
                </div>
                <div class="box-body !p-0 mybodyheight400px">

                    <div class="table-responsive">

                        <div class="flex flex-col h-screen">
                            <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                <ul class="list-group list-group-flush max-h-[22.5rem]">
                                    <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline" v-for="(datavalue,j) of filterdatatablestockminimum" :key="j">
                                        <div class="flex items-center flex-grow leading-none">

                                            <div class="leading-none flex-grow">
                                                <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.libelle}}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">{{ datavalue.nommaga }}</span>
                                            </div>
                                            <div class="text-end me-3">
                                                                <span class="block font-semibold mb-1">
                                                                    {{formatNumber(datavalue.stock) }}
                                                                </span>
                                                <!--span class="block text-success"><i class="ti ti-arrow-narrow-up"></i>0.14%</span-->
                                            </div>
                                            <!--div>
                                                                            <span class="text-[0.875rem]">
                                                                                <i class="bi bi-star-fill text-[#8c9097] dark:text-white/50 opacity-50"></i>
                                                                            </span>
                                            </div-->
                                        </div>
                                    </li>



                                </ul>






                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </div>

        <div class="xl:col-span-3 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        Valorisation du stock (<span style="color: red">{{formatMoney(vtotalstock)}}</span>)
                    </div>
                    <div class="flex items-center flex-wrap gap-2">
                        <input class="ti-form-control form-control-sm custom-placeholder" v-model="searchwordstockvalorisation" type="text"
                               placeholder="Rechercher" aria-label=".form-control-sm example">
                        <div class="hs-dropdown ti-dropdown !py-0 !mt-0 !mb-0">
                            <a href="javascript:void(0);"
                               class="ti-btn ti-btn-primary !py-1 !px-2 !text-[0.75rem] !m-0 !gap-0 !font-medium !mb-0"
                               aria-expanded="false">
                                Trier par<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">

                                <li><a @click="orderbystockvalorisation='asc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant la valorisation</a></li>

                                <li><a @click="orderbystockvalorisation='desc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre décroissant suivant la valorisation</a></li>


                                <li><a @click="orderbystockvalorisation='ascnom'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les articles</a></li>



                                <li><a @click="orderbystockvalorisation='ascmag'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les magasins / entrepôts</a></li>


                            </ul>
                        </div>
                    </div>
                </div>
                <div class="box-body !p-0 mybodyheight400px">

                    <div class="table-responsive">

                        <div class="flex flex-col h-screen">
                            <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                <ul class="list-group list-group-flush max-h-[22.5rem]">
                                    <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline" v-for="(datavalue,j) of filterdatatablestockvalorisation" :key="j">
                                        <div class="flex items-center flex-grow leading-none">

                                            <div class="leading-none flex-grow">
                                                <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.libelle}}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">{{ datavalue.nommaga }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Stock :{{formatNumber(datavalue.stock) }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">PU : {{ formatMoney(datavalue.pu) }}</span>
                                            </div>
                                            <div class="text-end flex-row me-3">
                                                                <span class="block font-semibold mb-1">  {{formatMoney(datavalue.vstock) }}  </span>

                                                <!--span class="block text-success"><i class="ti ti-arrow-narrow-up"></i>0.14%</span-->
                                            </div>
                                            <!--div>
                                                                            <span class="text-[0.875rem]">
                                                                                <i class="bi bi-star-fill text-[#8c9097] dark:text-white/50 opacity-50"></i>
                                                                            </span>
                                            </div-->
                                        </div>
                                    </li>



                                </ul>






                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </div>

        <div class="xl:col-span-3 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        La marge bénéficiaire (<span style="color: red">{{formatMoney(totalmargebeneficire)}}</span>)
                    </div>
                    <div class="flex items-center flex-wrap gap-2">
                        <input class="ti-form-control form-control-sm custom-placeholder" v-model="searchwordbenf" type="text"
                               placeholder="Rechercher" aria-label=".form-control-sm example">
                        <div class="hs-dropdown ti-dropdown !py-0 !mt-0 !mb-0">
                            <a href="javascript:void(0);"
                               class="ti-btn ti-btn-primary !py-1 !px-2 !text-[0.75rem] !m-0 !gap-0 !font-medium !mb-0"
                               aria-expanded="false">
                                Trier par<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">

                                <li><a @click="orderbystockbenef='asc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant la marge bénéficiaire</a></li>

                                <li><a @click="orderbystockbenef='desc'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre décroissant suivant la marge bénéficiaire</a></li>


                                <li><a @click="orderbystockbenef='ascnom'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les articles</a></li>



                                <li><a @click="orderbystockbenef='ascmag'" class="ti-dropdown-item !py-2 !px-[0.9375rem]  !text-[0.8125rem] !font-medium block"
                                       href="javascript:void(0);">Ordre croissant suivant les magasins / entrepôts</a></li>


                            </ul>
                        </div>


                        <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                            <button type="button" @click="indexbtndelaisbenefice='1W';loadetatbenefice()" :class="indexbtndelaisbenefice==='1W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  " title="1 SEMAINE">1W</button>
                            <button type="button" @click="indexbtndelaisbenefice='2W';loadetatbenefice()" :class="indexbtndelaisbenefice==='2W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  " title="2 SEMAINES">2W</button>
                            <button type="button" @click="indexbtndelaisbenefice='1M';loadetatbenefice()" :class="indexbtndelaisbenefice==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  " title="1 MOIS">1M</button>
                            <button type="button" @click="indexbtndelaisbenefice='3M';loadetatbenefice()" :class="indexbtndelaisbenefice==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  " title="3 MOIS">3M</button>
                            <button type="button" @click="indexbtndelaisbenefice='6M';loadetatbenefice()" :class="indexbtndelaisbenefice==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  " title="6 MOIS">6M</button>
                            <button type="button" @click="indexbtndelaisbenefice='1Y';loadetatbenefice()" :class="indexbtndelaisbenefice==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none" title="1 AN">1Y</button>
                        </div>

                    </div>
                </div>
                <div class="box-body !p-0 mybodyheight400px">

                    <div class="table-responsive">

                        <div class="flex flex-col h-screen">
                            <div class="flex-grow overflow-auto tableFixHeadindexcomponnent">

                                <ul class="list-group list-group-flush max-h-[22.5rem]">
                                    <li class="list-group-item !border-t-0 !border-s-0 !border-e-0 bottomline" v-for="(datavalue,j) of filterdatatablebenefice" :key="j">
                                        <div class="flex items-center flex-grow leading-none">

                                            <div class="leading-none flex-grow">
                                                <span class="mt-2 mb-2 font-semibold block mb-2">{{datavalue.namearticle}}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">{{ datavalue.nommaga }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Qte : {{formatNumber(datavalue.qtitetotal) }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">PA : {{ formatMoney(datavalue.prixachat) }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">PU : {{ formatMoney(datavalue.pu) }}</span>
                                                <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Remise : {{ formatMoney(datavalue.remisetotal) }}</span>
                                            </div>
                                            <div class="text-end flex-row me-3">
                                                <span class="block font-semibold mb-1">  {{formatMoney(datavalue.benef) }}  </span>

                                                <!--span class="block text-success"><i class="ti ti-arrow-narrow-up"></i>0.14%</span-->
                                            </div>
                                            <!--div>
                                                                            <span class="text-[0.875rem]">
                                                                                <i class="bi bi-star-fill text-[#8c9097] dark:text-white/50 opacity-50"></i>
                                                                            </span>
                                            </div-->
                                        </div>
                                    </li>



                                </ul>






                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </div>



        <div class="xl:col-span-12 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        MOUVEMENT DES ARTICLES
                    </div>
                    <div class="flex flex-wrap gap-2">



                    </div>
                </div>
                <div class="box-body">



                    <div class="grid grid-cols-12  gap-x-6">


                        <div class="xl:col-span-12  col-span-12 mt-4 mb-4">
                            <select @change="load" v-model="selectedmagasin" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;"
                                    class="tabulator-page-size w-full"
                                    title="Tous les magasins / entrepôt">
                                <option :value="liste" v-for="(liste,index) in datamagasin" :key="index">{{liste}}</option>

                            </select>
                        </div>
                        <div class="xl:col-span-6  col-span-12 mt-4 mb-4">
                            <input class="form-control w-full" type="date" v-model="datedebut" placeholder="Date début">
                        </div>
                        <div class="xl:col-span-6  col-span-12 mt-4 mb-4">
                            <input class="form-control w-full" type="date" v-model="datefin" placeholder="Date fin">
                        </div>




                        <div class=" xl:col-span-12  col-span-12 mt-4 mb-4">
                            <input v-model="searchwordstockvalorisation" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                   placeholder="Rechercher"
                                   aria-label=".form-control-lg example">
                        </div>



                    </div>





                    <div class="table-responsive tableFixHeadindexcomponnent">

                        <table  class="relative w-full border table table-bordered mytable table-hover whitespace-nowrap min-w-full">
                            <thead>
                            <tr>
                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th-->
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MAGASIN</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DATE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°DOC</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FOURNISSEUR / CLIENT</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SAISIR PAR</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ARTICLE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">STOCK INIT</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ANCIENNE QTE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">QTE ENTREE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">QTE SORTIE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">STOCK FINAL</th>

                            </tr>
                            </thead>
                            <tbody>

                            <template v-if="filterdatatablesuivistock.length">

                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatablesuivistock" :key="index">

                                    <td  data-label="MAGASIN/DEPÔT/ENTREPÔT  : "   >{{ datavalue.nommaga }}</td>
                                    <td  data-label="DATE  : "   >{{ datavalue.docdate }}</td>
                                    <td  data-label="N°DOC  : "   >{{ datavalue.docref }}</td>
                                    <td  data-label="FOURNISSEUR / CLIENT  : "   >{{ datavalue.societefrsclt }}</td>
                                    <td  data-label="SAISIR PAR  : "   >{{ datavalue.saisiepar }}</td>
                                    <td  data-label="ARTICLE  : "   >{{ datavalue.libelle }}</td>
                                    <td class="text-center" style="text-align: center"  data-label="STOCK INIT : "   >{{ datavalue.stck_init }}</td>
                                    <td class="text-center" style="text-align: center"  data-label="ANCIENNE QTE : "   >{{ datavalue.stockold }}</td>
                                    <td class="text-center" style="text-align: center"  data-label="QTE ENTREE : "   >{{ datavalue.qte_entre }}</td>
                                    <td class="text-center" style="text-align: center"  data-label="QTE SORTIE : "   >{{ datavalue.qte_sorti }}</td>
                                    <td class="text-center" style="text-align: center"  data-label="STOCK FINAL : "   >{{ datavalue.sctk_final }}</td>

                                </tr>



                            </template>


                            <template v-else>
                                <tr >
                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                </tr>

                            </template>



                            </tbody>
                        </table>




                    </div>
                </div>
            </div>
        </div>


    </div>




</template>

<script>
    import {
        currencyFormatDE,
        currencyFormatMonney,
        currentYear, isValidDateString,
        randomString,
        triecroissant,
        triedecroissant
    } from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');
    import VueApexCharts from "vue3-apexcharts";


    export default {
        /* eslint-disable */
        name: "general",
        computed: {
            filterdatatablecreance(){

                let mvalue=this.datatableallcreance;

                this.perPage=this.DefaultperPage;

                if (this.searchwordcreance!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {

                        return  post.namecltfrs.toLowerCase().includes(t.searchwordcreance.toLowerCase())

                    })

                }

                this.totalca=0;
                this.totalcreance=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalca=parseInt(t.totalca)+parseInt(items.ca);
                    t.totalcreance=parseInt(t.totalcreance)+parseInt(items.creance);
                })




                return mvalue ;
            },

            filterdatatabledette(){

                let mvalue=this.datatablealldette;

                this.perPage=this.DefaultperPage;

                if (this.searchwordcreance!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {

                        return  post.namecltfrs.toLowerCase().includes(t.searchwordcreance.toLowerCase())

                    })

                }

                this.totalcharges=0;
                this.totaldette=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalcharges=parseInt(t.totalcharges)+parseInt(items.charges);
                    t.totaldette=parseInt(t.totaldette)+parseInt(items.dette);
                })




                return mvalue ;
            },

            filterdatatablestock(){

                let mvalue=this.datatablestock;

                this.perPage=this.DefaultperPage;

                if (this.searchwordstock!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.nommaga.toLowerCase().includes(t.searchwordstock.toLowerCase()) ||
                            post.libelle.toLowerCase().includes(t.searchwordstock.toLowerCase())
                    })

                }

                if (this.orderbystock==='ascnom'){

                    mvalue = triecroissant(mvalue,'libelle')

                }


                if (this.orderbystock==='asc'){

                    mvalue = triecroissant(mvalue,'stock')

                }


                if (this.orderbystock==='ascmag'){

                    mvalue = triecroissant(mvalue,'nommaga')

                }


                if (this.orderbystock==='desc'){

                    mvalue = triedecroissant(mvalue,'stock')

                }





                return mvalue ;
            },

            filterdatatablestockminimum(){

                let mvalue=this.datatablestockminimum;

                this.perPage=this.DefaultperPage;

                if (this.searchwordstockminimum!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.nommaga.toLowerCase().includes(t.searchwordstockminimum.toLowerCase()) ||
                            post.libelle.toLowerCase().includes(t.searchwordstockminimum.toLowerCase())
                    })

                }

                if (this.orderbystockminimum==='ascnom'){

                    mvalue = triecroissant(mvalue,'libelle')

                }


                if (this.orderbystockminimum==='asc'){

                    mvalue = triecroissant(mvalue,'stock')

                }


                if (this.orderbystockminimum==='ascmag'){

                    mvalue = triecroissant(mvalue,'nommaga')

                }


                if (this.orderbystockminimum==='desc'){

                    mvalue = triedecroissant(mvalue,'stock')

                }





                return mvalue ;
            },

            filterdatatablestockvalorisation(){

                let mvalue=this.datatablestockvalorisation;

                this.perPage=this.DefaultperPage;

                if (this.searchwordstockvalorisation!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.nommaga.toLowerCase().includes(t.searchwordstockvalorisation.toLowerCase()) ||
                            post.libelle.toLowerCase().includes(t.searchwordstockvalorisation.toLowerCase())
                    })

                }

                if (this.orderbystockvalorisation==='ascnom'){

                    mvalue = triecroissant(mvalue,'libelle')

                }


                if (this.orderbystockvalorisation==='asc'){

                    mvalue = triecroissant(mvalue,'vstock')

                }


                if (this.orderbystockvalorisation==='ascmag'){

                    mvalue = triecroissant(mvalue,'nommaga')

                }


                if (this.orderbystockvalorisation==='desc'){

                    mvalue = triedecroissant(mvalue,'vstock')

                }





                return mvalue ;
            },

            filterdatatablesuivistock(){

                let mvalue=this.datatablesuivistock;

                this.perPage=this.DefaultperPage;

                if (this.selectedmagasin!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.nommaga.toLowerCase().includes(t.selectedmagasin.toLowerCase())
                    })

                }

                if (this.searchwordstockvalorisation!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.libelle.toLowerCase().includes(t.searchwordstockvalorisation.toLowerCase())
                    })

                }


                if (isValidDateString(this.datedebut) && isValidDateString(this.datefin)){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        if (post.docdate!==""){
                            // if (t.mvtdatedebut<=post.docdate && post.docdate <=t.mvtdatefin){
                            if (t.datedebut<=post.docdate && post.docdate <=t.datefin){

                                return post
                            }
                        }

                    })


                }






                /*  if (this.orderbystockvalorisation==='ascnom'){

                      mvalue = triecroissant(mvalue,'libelle')

                  }


                  if (this.orderbystockvalorisation==='asc'){

                      mvalue = triecroissant(mvalue,'vstock')

                  }


                  if (this.orderbystockvalorisation==='ascmag'){

                      mvalue = triecroissant(mvalue,'nommaga')

                  }


                  if (this.orderbystockvalorisation==='desc'){

                      mvalue = triedecroissant(mvalue,'vstock')

                  }*/





                return mvalue ;
            },

            filterdatatablebenefice(){

                let mvalue=this.datatablebenefice;

                this.perPage=this.DefaultperPage;


                if (this.searchwordbenf!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return post.namearticle.toLowerCase().includes(t.searchwordbenf.toLowerCase())
                    })

                }



                 if (this.orderbystockbenef==='ascnom'){

                      mvalue = triecroissant(mvalue,'namearticle')

                  }


                  if (this.orderbystockbenef==='asc'){

                      mvalue = triecroissant(mvalue,'benef')

                  }


                  if (this.orderbystockbenef==='ascmag'){

                      mvalue = triecroissant(mvalue,'nommaga')

                  }


                  if (this.orderbystockbenef==='desc'){

                      mvalue = triedecroissant(mvalue,'benef')

                  }




                this.totalmargebeneficire=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalmargebeneficire=parseInt(t.totalmargebeneficire)+parseInt(items.benef);
                })




                return mvalue ;
            },


        },
        components: {
            apexcharts: VueApexCharts,
        },
        data() {
            return {
                isdisplay:false,
                datamagasin:[],
                datatable:[],
                datatablebenefice:[],
                datatablestock:[],
                datatablestockminimum:[],
                datatableallcreance:[],
                datatablestockvalorisation:[],
                datatablealldette:[],
                datatablesuivistock:[],
                graphsloadtoparticles:[],
                currentyear:currentYear(),
                graphssuivieca:{},
                graphssuiviepointdevente:{},
                orderbystock:'ascnom',
                orderbystockvalorisation:'ascnom',
                orderbystockminimum:'ascnom',
                orderbystockbenef:'ascnom',
                selectedmagasin:'',
                searchwordbenf:'',
                searchwordcreance:'',
                searchworddette:'',
                searchwordstock:'',
                searchwordsuiviestock:'',
                searchwordstockvalorisation:'',
                searchwordstockminimum:'',
                indexbtnsuiviedeca:'1Y',
                indexbtndelais:'1M',
                indexbtndelaisdette:'1M',
                indexbtndelaisbenefice:'2W',
                indexbtnsuiviepointdevente:currentYear(),
                indexbtntop6desarticlesvendue:currentYear(),
                datedebut:'',
                datefin:'',

                totalmargebeneficire:0,
                vtotalstock:0,
                totalca:0,
                totalcreance:0,
                totalcharges:0,
                totaldette:0,



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            async loadevoluationca() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/suivica/'+this.indexbtnsuiviedeca+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.graphssuivieca=response.data.allgraphs

                        }
                        else{


                            t.graphssuivieca={}

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadevoluationpointdevente() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/pointdevente/'+this.indexbtnsuiviepointdevente+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
 
                        if (response.data.success){

                            t.graphssuiviepointdevente=response.data.allgraphs

                        }
                        else{


                            t.graphssuiviepointdevente={}

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadtop6desarticleslesplusvendues() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/top6desarticlesvendu/'+this.indexbtntop6desarticlesvendue+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.graphsloadtoparticles=response.data.allgraphs

                        }
                        else{


                            t.graphsloadtoparticles=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadallcreance() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/pointcreance784517788/'+this.indexbtndelais+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatableallcreance=response.data.dm

                        }
                        else{


                            t.datatableallcreance=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadalldette() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/pointdette784517788/'+this.indexbtndelais+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatablealldette=response.data.dm

                        }
                        else{


                            t.datatablealldette=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },


            async loadetatdustock(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/45745458656ddffallstock784517788/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){



                            t.datatablestock=[];
                            t.datamagasin=[];
                            t.datatablesuivistock=[];
                            t.datatablestockminimum=[];
                            t.datatablestockvalorisation=[];
                            t.datatablestock=res.data.stck;
                            t.datatablestockminimum=res.data.stckmin;
                            t.datatablestockvalorisation=res.data.vstck;
                            t.datatablesuivistock=res.data.suivistck;
                            t.datamagasin=res.data.mg;
                            t.vtotalstock=res.data.vtotalstock;

                            if (t.datamagasin.length>0){
                                t.selectedmagasin=t.datamagasin[0]
                            }



                        }
                        else{
                            t.vtotalstock=0;
                            t.datatablestock=[];
                            t.datatablestockminimum=[];
                            t.datatablestockvalorisation=[];
                            t.datatablesuivistock=[];

                        }


                    })
                    .catch((error)=>{

                          //  t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                       // t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadetatbenefice() {

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/587999fff9ddbenef784517788/'+this.indexbtndelaisbenefice+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatablebenefice=response.data.dm
                            t.totalmargebeneficire=response.data.tdm
                            // t.loaddatatable1=true;

                        }
                        else{
                            t.datatablebenefice=[]
                            t.totalmargebeneficire=0

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



            formatMoney(value){
                return currencyFormatDE(value)
            },

            formatNumber(value){
                return currencyFormatMonney(value)
            },




        },
        mounted() {

            this.datedebut=this.$store.state.appserverdate
            this.datefin=this.$store.state.appserverdate

             this.loadevoluationca();
             this.loadevoluationpointdevente();
             this.loadtop6desarticleslesplusvendues();
             this.loadallcreance();
             this.loadalldette();
             this.loadetatdustock();
            this.loadetatbenefice();
        }
    }
</script>

<style scoped>

    .bottomline {
        border-bottom: 1px solid rgb(var(--default-border)); /* Changez 2px à la largeur de ligne souhaitée */
    }




</style>