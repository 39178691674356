<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <!--appmenu></appmenu-->
            <!-- end::menu -->

            <div class="content" >
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <!--div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> Empty</h3>
                        </div-->

                    </div>


                    <div class="grid grid-cols-1 gap-6">




                        <div class="box custom-box">
                            <div class="box-header justify-between">
                                <div class="box-title">
                                    Liste Des Caisses Autorisés
                                </div>

                            </div>
                            <div class="box-body">


                                <div class="ti-list-group">
                                    <ul class="list-none">


                                        <template v-for="(caisse,index) in listeallcaisses" :key="index">

                                        <li @click="selectCaisse(caisse)"  style="cursor: pointer;" class="ti-list-group-item ti-list-group-item-action">


                                            <a  href="javascript:void(0);"
                                               :title="'Sélectionner la caisse : '+caisse.name"
                                               aria-current="true">
                                                <div class="grid sm:grid-cols-2 gap-6">
                                                    <div class="space-y-2 text-left">
                                                        <h3 style="font-weight: bold">{{caisse.name}}</h3>
                                                    </div>

                                                    <div class="space-y-2 text-right">
                                                        <span v-if="caisse.opencaisse===1"  class="badge bg-success text-white">Ouvert</span>
                                                        <span v-else class="badge bg-danger text-white">Fermer</span>
                                                    </div>
                                                </div>
                                            </a>

                                        </li>

                                        </template>

                                    </ul>
                                </div>



                            </div>
                            <div class="box-footer border-t-0 hidden">

                            </div>
                        </div>



                    </div>








                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import {mapGetters} from 'vuex';


    /*import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    const axios =require('axios');*/


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,

        },
        computed:{
            ...mapGetters(['listeallcaisses']),


        },

        data() {
            return {
                datatablefindecontratpermannent:[],


            };
        },
        methods: {

            selectCaisse(caisse){
                //this.$store.state.caisseencour=idcaisse;

                this.$store.dispatch('caisseencour',0);
                this.$store.dispatch('montantencaisse',0);
                this.$store.dispatch('dateouverturecaisse',"");
                this.$store.dispatch('ouverturedelacaisse',0);
                this.$store.dispatch('caisseinfo',{});

                this.$store.dispatch('caisseencour',caisse.pid);

                this.$router.push({ name: 'caisseencour',params:{societe:this.$store.state.appsociete} });


            }


        },
        mounted() {

          // this.loadfincontratdans6mois();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>