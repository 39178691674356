<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">

                        <!--div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>
                            <div class="flex flex-wrap gap-2">

                                <div class="mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>


                            </div>


                        </div-->


                        <div class="box-body !p-0">


                            <div class="space-y-2 mt-3" >
                                <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">Type de prix :</label>

                                    <select v-model="mypv.mtyprix" class="form-control">
                                        <option value="prixvente">{{datainputlibelleforms.prixvente}}</option>
                                        <option value="prixvente1">{{datainputlibelleforms.prixvente1}}</option>
                                        <option value="prixvente2">{{datainputlibelleforms.prixvente2}}</option>
                                        <option value="prixvente3">{{datainputlibelleforms.prixvente3}}</option>
                                        <option value="prixvente4">{{datainputlibelleforms.prixvente4}}</option>
                                        <option value="prixvente5">{{datainputlibelleforms.prixvente5}}</option>
                                        <option value="prixvente6">{{datainputlibelleforms.prixvente6}}</option>
                                        <option value="prixvente7">{{datainputlibelleforms.prixvente7}}</option>
                                    </select>


                            </div>

                            <div class="mt-3 grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">Quantité minimum</label>
                                    <input v-model="mypv.min" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                </div>

                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">Quantité maximum</label>
                                    <input v-model="mypv.max" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                                </div>



                            </div>



                            <div class="space-y-2 mt-3">
                                <label class="text-capitalize ti-form-label
                                     dark:text-defaulttextcolor/70 mb-0">Prix de vente</label>
                                <input v-model="mypv.pu" min="0" type="number" class="firstName my-auto ti-form-input
                                             rounded-sm"    required>

                            </div>








                        </div>


                    </div>






                </div>
                <div class="ti-modal-footer">

                    <!--button   class="ti-btn ti-btn-primary-full" @click="selected(null)" style="background: greenyellow">
                       Effacer la sélection
                    </button-->


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>

                    <button style="background: green" @click="ajouterselectedmagasin"  class="ti-btn ti-btn-primary-full" >
                        Ajouter
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>


    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

               // console.log("  oldvalue  searchtrierletableau        **************** ",oldvalue)
               // console.log("  newvalue   searchtrierletableau       **************** ",newvalue)

            }

        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

               /* if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                return this.paginate(mvalue) ;
            },
        },
        components: {

            /*appmenu,*/

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datapv:[],
                datainputlibelleforms:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                btnaction:"",

                mypv:{
                    min:0,
                    max:0,
                    mtyprix:'prixvente',
                    pu:0
                },
                indextable:0,


                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {


            ajouterselectedmagasin(){

                if (parseFloat(this.mypv.min)>parseFloat(this.mypv.max)){
                    toastr['error']( "les quantités sont pas valide","<b></b>");

                    return
                }


                let countnbre=this.datapv.length
                let isok=0

                if (countnbre>0){

                    for (let i=0;i<countnbre;i++){

                        if(parseFloat(this.datapv[i].min)<=parseFloat(this.mypv.min)){

                            if(parseFloat(this.datapv[i].max)>=parseFloat(this.mypv.min)){

                                if(this.datapv[i].mtyprix===this.mypv.mtyprix){

                                    isok=1

                                }


                            }

                        }


                    }
//" Cette quantité est déjà comprise dans une des intervalles de quantités définir précédemment.  "
                    /* for (let i=0;i<countnbre;i++){

                         if(parseFloat(this.datapv[i].min)<=parseFloat(this.mypv.max)){

                             if(parseFloat(this.datapv[i].max)>=parseFloat(this.mypv.max)){

                                 isok=1

                             }

                         }


                     }*/



                    for (let i=0;i<countnbre;i++){

                        if(parseFloat(this.datapv[i].min)<=parseFloat(this.mypv.max)){

                            if(parseFloat(this.datapv[i].max)>=parseFloat(this.mypv.max)){

                                if(this.datapv[i].mtyprix===this.mypv.mtyprix){

                                    isok=1

                                }
                            }

                        }


                    }





                }



                else{isok=0}

                if (isok===1){
                    toastr['error']( "l'intervalle de quantité définie pour ce prix n'est pas valide.","<b></b>");

                    return
                }



                this.selected(this.btnaction,this.mypv)

                this.mypv={
                    min:0,
                    max:0,
                    pu:0,
                    mtyprix:'prixvente'
                }





            },



            close(){

                this.isdisplay=false;

            },




            selected(option,datavalue){




                this. mypv={
                    min:0,
                        max:0,
                        mtyprix:'prixvente',
                        pu:0
                }



                this.datapv.push(datavalue);


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ:option, value: this.datapv,index:this.indextable};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionnegestiontarifdevente', elementSelectionne);


                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },

            show(titlemodal,datainputlibelleforms,datapv,index,btnaction){
                this.messagesucces="";
                this.messageerror="";
                this.titlemodal=titlemodal
                this.btnaction=btnaction

                //this.datatable=listdata
                this.datainputlibelleforms=datainputlibelleforms
                this.datapv=datapv
                this.indextable=index
                this. mypv={
                    min:0,
                    max:0,
                    mtyprix:'prixvente',
                    pu:0
                }

                this.isdisplay=true;

            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

   .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 70%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>