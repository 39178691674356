<template>

    <div class="grid grid-cols-12 gap-x-6">


        <div class="xl:col-span-12 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                      Historique des documents
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtnhistorique='1W';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 SEMAINE</button>
                        <button type="button" @click="indexbtnhistorique='2W';loadhistoriquebyperiode()" :class="indexbtnhistorique==='2W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 SEMAINES</button>
                        <button type="button" @click="indexbtnhistorique='1M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='3M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">3 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='6M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">6 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='1Y';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">1 AN</button>
                    </div>
                </div>
                <div class="box-body">

                    <div class="grid grid-cols-12  gap-x-6">


                        <div class="xl:col-span-6  col-span-12 mt-4 mb-4">
                            <select v-model="searchtrierhistoriquedata" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;"
                                    class="ti-form-select rounded-sm !py-2 !px-3 form-control w-full" >
                                <option value="">TRIER LA LISTE</option>
                                <option value="MOUVEMENT D'ENTREE">MOUVEMENT D'ENTREE</option>
                                <option value="MOUVEMENT DE SORTIE">MOUVEMENT DE SORTIE</option>
                                <option value="MOUVEMENT DE TRANSFERT">MOUVEMENT DE TRANSFERT</option>
                                <option value="MOUVEMENT D'INVENTAIRE">MOUVEMENT D'INVENTAIRE</option>
                            </select>
                        </div>

                        <div class=" xl:col-span-6  col-span-12 mt-4 mb-4">
                            <input v-model="searchwordhistorique" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                   placeholder="Rechercher"
                                   aria-label=".form-control-lg example">
                        </div>



                    </div>





                    <div class="table-responsive tableFixHeadindexcomponnent">


                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                            <thead>
                            <tr>
                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th-->
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">TYPE DE DOCUMENT</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° DOCUMENT</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MAGASIN/DEPÔT/ENTREPÔT</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OBSERVATION</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°BON</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREER LE</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREER PAR</th>
                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                            </tr>
                            </thead>
                            <tbody>

                            <template v-if="filterdatatablehistorique.length">

                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatablehistorique" :key="index">

                                    <!--td data-label="N°  : ">{{index+1}}</td-->
                                    <td  data-label="TYPE DE DOCUMENT  : "   >{{ datavalue.mtypdoc }}</td>
                                    <td  data-label="N° DOCUMENT  : "   >{{ datavalue.numdoc }}</td>
                                    <td  data-label="MAGASIN/DEPÔT/ENTREPÔT  : "   >{{ datavalue.magname }}</td>
                                    <td  data-label="OBSERVATION  : "   >{{ datavalue.observations }}</td>
                                    <td  data-label="N°BON  : "   >{{ datavalue.numbon }}</td>
                                    <td  data-label="CREER LE  : "   >{{ datavalue.creerler }}</td>
                                    <td  data-label="CREER PAR  : "   >{{ datavalue.saisiepar }}</td>

                                    <td  data-label="Action  : "   >

                                        <button style="width: 100%!important;" @click="btnopen(datavalue)"  aria-label="anchor"
                                                class="ti-btn ti-btn-primary-full">
                                            <i class="ri-eye-line"></i>
                                        </button>
                                    </td>

                                </tr>



                            </template>


                            <template v-else>
                                <tr >
                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                </tr>

                            </template>



                            </tbody>
                        </table>



                    </div>
                </div>
            </div>
        </div>




        <modalopenform ref="modalopenform"  ></modalopenform>

    </div>


</template>

<script>

    import {getPeriod,  randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modalopenform from "@/components/views/pages/stock/mvtstock/modal_openform";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "stock",
        computed: {

            filterdatatablehistorique(){

                let mvalue=this.datatablehistorique;


                if (this.searchtrierhistoriquedata!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.mtypdoc.toLowerCase()===t.searchtrierhistoriquedata.toLowerCase()

                    })

                }


                if (this.searchwordhistorique!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return    post.numdoc.toLowerCase().includes(t.searchwordhistorique.toLowerCase())
                            || post.magname.toLowerCase().includes(t.searchwordhistorique.toLowerCase())

                    })

                }



                return mvalue ;
            },

        },
        components: {
            modalopenform,
        },
        data() {
            return {
                initcomponnent:false,
                datamagasin:[],
                datatablehistorique:[],
                datatablebenefice:[],



                searchtrierhistoriquedata:'',
                searchwordhistorique:'',
                indexbtnhistorique:'1W',

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            loadhistoriquebyperiode(){

                let $res=getPeriod(this.indexbtnhistorique)
                this.loadtablehistorique($res.dateDebut,$res.dateFin);

            },

            async loadtablehistorique(datedebut,datefin){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/stock/sansvalidation/historique/'+datedebut+'/'+datefin+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){

                        if(res.data.success){


                            t.datatablehistorique=[];
                            t.datatablehistorique=res.data.m;


                        }
                        else{
                            t.datatablehistorique=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },

            loadinitcomponnent(){

                if (this.initcomponnent===false){

                    this.loadhistoriquebyperiode();

                    this.initcomponnent=true;
                }

            },


        }
    }
</script>

<style scoped>

</style>