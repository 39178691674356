<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">&nbsp;&nbsp;</span>
            </a>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    <li v-if="$store.state.accescomponent.ccstockproduit===true">
                        <a @click="btnmodalindexarticle" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent" aria-current="page">Liste des articles</a>
                    </li>

                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Stock<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li v-if="$store.state.accescomponent.ccstockmvtentree===true">
                                    <a @click="btnmodalmvtstocksansvalidationindex('mvt_entree')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Mouvement d'entrée
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstockmvtdesortie===true">
                                    <a @click="btnmodalmvtstocksansvalidationindex('mvt_sortie')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Mouvement de sortie
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstockmvtdinventaire===true">
                                    <a @click="btnmodalmvtstocksansvalidationindex('mvt_d_inventaire')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Mouvement d'inventaire
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstockmvtdetransfert===true">
                                    <a @click="btnmodalmvtstocksansvalidationindex('mvt_transfert')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Mouvement de transfert
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des expressions de besions
                                    </a>
                                </li>


                            </ul>
                        </div>

                    </li>


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                               Rapport<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li v-if="$store.state.accescomponent.ccstocketathistorique===true">
                                    <a @click="btnmodalhistoriquedocument" class="ti-dropdown-item" href="javascript:void(0);">
                                        Historique des documents de stock
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstocketatstockdesarticles===true">
                                    <a @click="btnmodaletatdocstock" class="ti-dropdown-item" href="javascript:void(0);">
                                        Etat du stock des articles
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstocketatstockdesarticles===true">
                                    <a @click="btnmodalrapportetatdestocksuruneperiode" class="ti-dropdown-item" href="javascript:void(0);">
                                        Etat du stock des articles sur une période
                                    </a>
                                </li>


                                <li  v-if="$store.state.accescomponent.ccstocketatmvtdesarticles===true">
                                    <a @click="btnmodalrapportmvtdesarticles" class="ti-dropdown-item" href="javascript:void(0);">
                                        Etat des mouvements des articles
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.ccstocketatfichepreinventaire===true">
                                    <a @click="btnmodalrapportfichedepreinventaire" class="ti-dropdown-item" href="javascript:void(0);">
                                        Fiche d'état de pré-inventaire
                                    </a>
                                </li>


                                <!--li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Récapitulatif des entrées et sorties des articles
                                    </a>
                                </li>



                                <li>
                                    <a title="" class="ti-dropdown-item" href="javascript:void(0);">
                                        Fiche de point de vente (Récapitulatif du stock)
                                    </a>
                                </li-->






                            </ul>
                        </div>

                    </li>







                </ul>
            </div>
        </div>



    </nav>


    <modalhistoriquedocument ref="modalhistoriquedocument"></modalhistoriquedocument>
    <modalrapportmvtdesarticles ref="modalrapportmvtdesarticles"></modalrapportmvtdesarticles>
    <modalrapportetatdestocksuruneperiode ref="modalrapportetatdestocksuruneperiode"></modalrapportetatdestocksuruneperiode>
    <modaletatdocstock ref="modaletatdocstock"></modaletatdocstock>
    <modalrapportfichedepreinventaire ref="modalrapportfichedepreinventaire"></modalrapportfichedepreinventaire>
    <modalindexarticle ref="modalindexarticle"></modalindexarticle>
    <modalmvtstocksansvalidationindex ref="modalmvtstocksansvalidationindex"></modalmvtstocksansvalidationindex>


</template>


<script>

    import modalindexarticle from "@/components/views/pages/stock/articles/index";
    import modalmvtstocksansvalidationindex from "@/components/views/pages/stock/mvtstock/sansvalidation/index";
    import modalhistoriquedocument from "@/components/views/pages/stock/rapport/modal_historiquedocument";
    import modaletatdocstock from "@/components/views/pages/stock/rapport/modal_rapport_etatdustock";
    import modalrapportfichedepreinventaire from "@/components/views/pages/stock/rapport/modal_rapport_fichedepreinventaire";
    import modalrapportmvtdesarticles from "@/components/views/pages/stock/rapport/modal_rapport_mvtdesarticles";
    import modalrapportetatdestocksuruneperiode from "@/components/views/pages/stock/rapport/modal_rapport_etatdestocksuruneperiode";








    export default {
        /* eslint-disable */
        name: "mymenu",
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        components: {
            modalindexarticle,
            modalmvtstocksansvalidationindex,

            modalhistoriquedocument,
            modaletatdocstock,
            modalrapportfichedepreinventaire,
            modalrapportmvtdesarticles,
            modalrapportetatdestocksuruneperiode,

        },
        methods: {


            btnmodalrapportetatdestocksuruneperiode(){

                let titlemodal ="ETAT DU STOCK SUR UNE PERIODE"


                this.$refs.modalrapportetatdestocksuruneperiode.show(titlemodal);
            },

            btnmodalrapportmvtdesarticles(){

                let titlemodal ="MOUVEMENT DES ARTICLES"


                this.$refs.modalrapportmvtdesarticles.show(titlemodal);
            },


            btnmodaletatdocstock(){

                let titlemodal ="Etat du stock des articles"


                this.$refs.modaletatdocstock.show(titlemodal);
            },

            btnmodalhistoriquedocument(){

                let titlemodal ="Historique des documents de stock"


                this.$refs.modalhistoriquedocument.show(titlemodal);
            },




            btnmodalrapportfichedepreinventaire(){

                let titlemodal ="Fiche de pré inventaire"


                this.$refs.modalrapportfichedepreinventaire.show(titlemodal);
            },


            btnmodalindexarticle(){

                let titlemodal ="Liste des articles"


                this.$refs.modalindexarticle.show(titlemodal);
            },

            btnmodalmvtstocksansvalidationindex(value){

                let titlemodal


                if (value==='mvt_entree'){
                  titlemodal="Liste des  mouvements d'entrée"
                }

                if (value==='mvt_sortie'){
                  titlemodal="Liste des  mouvements de sortie"
                }

                if (value==='mvt_d_inventaire'){
                  titlemodal="Liste des  mouvements d'inventaires"
                }

                if (value==='mvt_transfert'){
                  titlemodal="Liste des  mouvements de transfert"
                }

                if (value==='depreciation'){
                  titlemodal="Liste des dépréciation de stocks"
                }



                this.$refs.modalmvtstocksansvalidationindex.show(titlemodal,value);
            },



            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },
            toggleDropdown() {

                this.isDropdownOpen = !this.isDropdownOpen;

            }
        }
    };
</script>

<style scoped>

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }


</style>