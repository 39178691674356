<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>


            <div class="content" >

                <div class="main-content">

                    <div class="block justify-between page-header md:flex">


                    </div>


                    <div class="grid grid-cols-1 gap-6">




                        <div class="box custom-box">
                            <div class="box-header justify-between">
                                <div class="box-title">
                                    Liste des exercices comptables
                                </div>

                            </div>
                            <div class="box-body">


                                <div class="ti-list-group">
                                    <ul class="list-none">

                                        <template v-if="listedesexercicescomptables.length>0" >



                                        <template  v-for="(exercice,index) in listedesexercicescomptables" :key="index">

                                        <li @click="selectExercice(exercice)"  style="cursor: pointer;" class="ti-list-group-item ti-list-group-item-action">


                                            <a  href="javascript:void(0);"
                                               :title="'Sélectionner l\'exercice : '+exercice.name"
                                               aria-current="true">
                                                <div class="grid sm:grid-cols-2 gap-6">
                                                    <div class="space-y-2 text-left">
                                                        <h3 style="font-weight: bold">Exercice {{exercice.name}}</h3>
                                                    </div>

                                                    <div class="space-y-2 text-right">
                                                        <span v-if="exercice.opencaisse===1"  class="badge bg-success text-white">Ouvert</span>
                                                        <span v-else class="badge bg-danger text-white">Fermer</span>
                                                    </div>
                                                </div>
                                            </a>

                                        </li>

                                        </template>

                                        </template>
                                        <template v-else>



                                            <li  style="cursor: pointer;" class="ti-list-group-item ti-list-group-item-action">


                                                <a  href="javascript:void(0);"
                                                    :title="'Sélectionner l\'exercice : 2023'"
                                                    aria-current="true">
                                                    <div class="grid sm:grid-cols-3 gap-6">
                                                        <div class="space-y-2 text-left">
                                                            <h3 style="font-weight: bold">Exercice 01/01/2023 - 31/12/2023</h3>
                                                        </div>

                                                        <div class="space-y-2 text-right">
                                                            <span   class="badge bg-success text-white">Ouvert</span>

                                                        </div>

                                                        <div class="space-y-2 text-right">


                                                            <div class="ti-btn-group">
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            aria-expanded="false">
                                                                        Action<i
                                                                            class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                    </button>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                        aria-labelledby="dropdownMenuButton1">


                                                                        <li>
                                                                            <button @click="selectExercicedemo"  style="width: 100%!important;"
                                                                                    aria-label="anchor"  class=" ti-btn ti-btn-sm ">
                                                                                <i class="ri-folder-open-line"></i> Ouvrir
                                                                            </button>
                                                                        </li>

                                                                        <li>
                                                                            <button style="width: 100%!important;" aria-label="anchor"  class=" ti-btn ti-btn-sm ">
                                                                                <i class="ri-save-2-line"></i> Sauvegarde
                                                                            </button>
                                                                        </li>

                                                                        <li>
                                                                            <button style="width: 100%!important;"
                                                                                    aria-label="anchor"  class=" ti-btn ti-btn-sm ">
                                                                                <i class="ri-refresh-line"></i> Restauration
                                                                            </button>
                                                                        </li>


                                                                    </ul>
                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </a>

                                            </li>



                                        </template>




                                    </ul>
                                </div>



                            </div>
                            <div class="box-footer border-t-0 hidden">

                            </div>
                        </div>



                    </div>








                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import {mapGetters} from 'vuex';


    /*import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    const axios =require('axios');*/


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,

        },
        computed:{
            ...mapGetters(['listedesexercicescomptables']),


        },

        data() {
            return {
                datatablefindecontratpermannent:[],


            };
        },
        methods: {

            selectExercice(exercice){

                this.$store.dispatch('exercicecomptable',"");
                this.$store.dispatch('anneexercicecomptable',"");
                this.$store.dispatch('infoexercicecomptable',{});

                this.$store.dispatch('exercicecomptable',exercice.pid);

                this.$router.push({ name: 'caisseencour',params:{societe:this.$store.state.appsociete} });


            },

            selectExercicedemo(){

                this.$store.dispatch('exercicecomptable',"");
                this.$store.dispatch('anneexercicecomptable',"2023-2024");
                this.$store.dispatch('infoexercicecomptable',{});



                this.$router.push({ name: 'tmenucomptable',params:{periode:'2023-2024'} });


            },


        },
        mounted() {

          // this.loadfincontratdans6mois();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>