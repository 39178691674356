<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                       {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                       {{messageerror}}
                                    </div>
                                </div>


                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;">Date : {{Items.datedoc}} </p>
                                </div>





                                <div class="ti-custom-validation mt-5" >
                                    <div class="grid lg:grid-cols-2 gap-6">

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">N°FACTURE</label>
                                            <input :value="Items.numfacture" type="text" class="form-control" disabled>

                                        </div>

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"><template v-if="typedoc==='CLT'">CLIENT</template><template v-else>FOURNISSEUR</template></label>
                                            <input :value="Items.namecltfrs" type="text" class="form-control" disabled>

                                        </div>

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">MONTANT TOTAL</label>
                                            <input  :value="formatMoney(Items.mtntotal)" type="text" class="form-control" disabled>

                                        </div>

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">AVANCE</label>
                                            <select v-model="Items.avance" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option :value="null">Sélectionner une avance</option>
                                                <option v-for="(avance,index) of alldata.avance" :key="index" :value="avance">{{avance.numdoc+' / '+avance.docnum+' ( '+formatMoney(avance.montant)+' )'}}</option>

                                            </select>
                                        </div>

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0" style="text-transform: uppercase;">MONTANT RESTANT à PAYER</label>
                                            <input :value="formatMoney(mtntrestantapayer)" type="text" class="form-control" disabled>

                                        </div>

                                        <div v-if="typereglement==='COMPTE'" class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0" style="text-transform: uppercase;">SOLDE EN COMPTE CLIENT</label>
                                            <input :value="formatMoney(Items.solde)" type="text" class="form-control" disabled>

                                        </div>

                                        <div v-if="typereglement!=='COMPTE'" class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0" style="text-transform: uppercase;">PARAMETRE D'INDICATION</label>
                                            <select v-model="Items.params" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option :value="null">Sélectionner un paramétre d'indication</option>
                                                <option v-for="(magasin,index) of alldata.paramcaisse" :key="index" :value="magasin">{{magasin.name}}</option>

                                            </select>
                                        </div>





                                    </div>

                                    <div class="grid  gap-6 mt-5"  :class="typereglement!=='COMPTE'?'lg:grid-cols-1':'lg:grid-cols-2'">

                                    <div v-if="typereglement==='COMPTE'" class="space-y-2  mt-5">
                                        <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0" style="text-transform: uppercase;">PARAMETRE D'INDICATION</label>
                                        <select v-model="Items.params" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option :value="null">Sélectionner un paramétre d'indication</option>
                                            <option v-for="(magasin,index) of alldata.paramcaisse" :key="index" :value="magasin">{{magasin.name}}</option>

                                        </select>
                                    </div>

                                    <div class="space-y-2 mt-5">
                                        <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0" style="text-transform: uppercase;">REGLER PAR</label>
                                        <select @change="checkreglement()"  v-model="Items.reglepar" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option value="Caisse">Caisse</option>
                                            <option value="Banque">Banque</option>
                                        </select>
                                    </div>

                                    </div>


                                    <div class="grid lg:grid-cols-2 gap-6 mt-5" v-if="checkvalue">

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"
                                                    style="text-transform: uppercase;">BANQUE</label>
                                            <select v-model="Items.reglementbanque" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option :value="null">Sélectionner une banque</option>
                                                <option v-for="(banque,index) of alldata.banque" :key="index" :value="banque">{{banque.name}}</option>

                                            </select>
                                        </div>

                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"
                                                    style="text-transform: uppercase;">MODE DE REGLEMENT</label>
                                            <select v-model="Items.modereglement" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option :value="null">Sélectionner un mode réglement</option>
                                                <option v-for="(modedereglement,index) of datamodereglement" :key="index" :value="modedereglement">{{modedereglement.name}}</option>

                                            </select>
                                        </div>





                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">{{deposantname}} </label>
                                            <input v-model="Items.deposantname" type="text" class="form-control">

                                        </div>



                                        <div class="space-y-2">
                                            <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">N°CHEQUE </label>
                                            <input v-model="Items.numcheque" type="text" class="form-control">

                                        </div>





                                    </div>



                                    <div v-if="!checkvalue" class="space-y-2 mt-5">
                                        <label  class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"
                                                style="text-transform: uppercase;">MODE DE REGLEMENT</label>
                                        <select v-model="Items.modereglement" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option :value="null">Sélectionner un mode de réglement</option>

                                            <option v-for="(modedereglement,index) of datamodereglement" :key="index" :value="modedereglement">{{modedereglement.name}}</option>

                                        </select>
                                    </div>



                                    <div class="space-y-2 mt-5">
                                        <div class=" mt-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"
                                                   style="text-transform: uppercase;">Raison / Motif : </label>
                                            <textarea rows="3" id="mvtobservation" maxlength="225"
                                                      v-model="Items.docobservation"  type="text"
                                                      class="form-control"></textarea>
                                        </div>


                                    </div>

                                    <div v-if="typereglement!=='COMPTE'"  class="space-y-2 mt-5">
                                        <div class=" mt-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0"
                                                   style="text-transform: uppercase;" >Montant : </label>

                                            <input   v-model="Items.montant"  type="number" class="form-control">
                                        </div>


                                    </div>



                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" >
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <validationouinon ref="validationouinon" @aftervalidation="aftervalidation"></validationouinon>
    <modalregelementavance @closed="close" ref="modalregelementavance" ></modalregelementavance>


</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
    import {currencyFormatDE, isset} from "@/allfunction";
   // import * as JwtService from "@/common/jwt.service";
    import validationouinon from "@/components/views/pages/caisse/reglement/validationouinon";
    import modalregelementavance from "@/components/views/pages/caisse/rapport/modal_rapport_regelement_avance";


  //  const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{


            datamodereglement(){

                let mvalue=this.alldata.modedereglement;

                if (mvalue!==null){

                    if (this.Items.reglepar!==''){
                        let t=this;
                        mvalue = mvalue.filter( function (post) {
                            return    post.code.toLowerCase().includes(t.Items.reglepar.toLowerCase())
                        })

                    }



                }

                return mvalue

            },

            mtntrestantapayer(){
                let mvalue=0,$montantsaisi=0


                if (this.Items.avance!==null){
                    mvalue=parseInt(this.Items.mtntrestantapayertrt)-parseInt(this.Items.avance.montant)-$montantsaisi
                }
                else{
                    mvalue=parseInt(this.Items.mtntrestantapayertrt)-$montantsaisi

                }

                return mvalue;
            }


        },
        components: {

            modalregelementavance,
            validationouinon

        },

        data() {
            return {
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                classcaisse:"",
                checkvalue:false,
                titlemodal:"",
                alldata:{},
                typedoc:"",
                deposantname:'',
                typereglement:'',
                authoriationplslignedepense:0,
                typeaction:"add",
                Items: {},
            };
        },
        methods: {

            formatMoney(value){
                return currencyFormatDE(value)

            },

            checkreglement(){
                this.Items.reglementbanque=null
                this.Items.modereglement=null
                if (this.Items.reglepar==="Caisse"){

                    this.classcaisse="col-md-12 mt-2";
                    this.checkvalue=false;

                }else{

                    this.classcaisse="col-md-6 mt-2";
                    this.checkvalue=true;
                }


            },



            aftervalidation(elementSelectionne){


               this.Items.docnum=elementSelectionne.cnumdoc

                let t=this;

                let libelcltfrs
                if (this.typedoc==="FRS"){

                  //  this.deposantname="NOM DU DEPOSANT"
                    libelcltfrs="Fournisseur"

                }
                else{
                   // this.deposantname="NOM DU BENEFICIAIRE"
                    libelcltfrs="Client"
                }

                let tabvalue={
                    mtypdoc:"REG"+this.typedoc,
                    numdoc:t.Items.docnum,
                    numfacture:t.Items.numfacture,
                    namecltfrs:t.Items.namecltfrs,
                    montant:t.Items.montant,
                    mtnttotaldoc:t.Items.mtntotal,
                    mtntrestantapayer:t.Items.mtntrestantapayertrt,
                    mtntrestant:parseFloat(t.mtntrestantapayer)-parseFloat(t.Items.montant),
                    date:t.Items.datedoc,
                    docobservation:t.Items.docobservation,
                    deposantname:t.Items.deposantname,
                    numcheque:t.Items.numcheque,
                    reglepar:t.Items.reglepar,
                    modereglement:isset(t.Items.modereglement)?t.Items.modereglement.name:"",
                    reglementbanque:isset(t.Items.reglementbanque)?t.Items.reglementbanque.name:"",
                    reglementcaisse:t.Items.reglementcaisse,
                    avance:isset(t.Items.avance)?t.Items.avance.numdoc+' / '+t.Items.avance.docnum+' ('+this.formatMoney(t.Items.avance.montant)+' )':"",


                    libelcltfrs:libelcltfrs,
                    libeldeposantname:this.deposantname,
                    docsaisiepar:t.$store.state.appusername,


                }



                this.$refs.modalregelementavance.show(tabvalue);



            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,typedoc,Items,alldata,typereglement){


                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.Items=Items
                this.typereglement=typereglement
                this.alldata=alldata
                this.messagesucces="";
                this.messageerror="";

                if (this.typedoc==="FRS"){

                    this.deposantname="NOM DU DEPOSANT"

                }
                else{
                    this.deposantname="NOM DU BENEFICIAIRE"
                }

                this.checkreglement();




                this.isdisplay=true;

            },

            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },

              async  saveItems(){

                   // this.$store.dispatch('lancerchargement',true)

                  if (this.typereglement==="COMPTE"){

                      if (this.Items.solde===0 ){

                          toastr['error']( "Le montant en compte du client n'est pas suffisant pour passer le réglement.","<b></b>");
                          return


                      }

                      this.Items.montant=this.Items.solde;

                  }




                  if (this.Items.modereglement===null ){

                      toastr['error']( 'Veuillez sélectionner le mode de réglement.',"<b></b>");
                      return ;

                  }


                  if (this.Items.reglepar==="Banque" ){

                      if (this.Items.reglementbanque===null ){

                          toastr['error']( 'Veuillez sélectionner une banque.',"<b></b>");
                             return ;

                      }



                      if (this.Items.deposantname==='' ){

                          toastr['error']( 'Veuillez saisir le '+this.deposantname+' .',"<b></b>");

                          return ;

                      }



                      if (this.Items.numcheque==='' ){

                          toastr['error']( 'Veuillez saisir le numéro de chèque.',"<b></b>");

                          return ;

                      }





                  }



                 // if (this.Items.docobservation===""  || parseFloat(this.Items.montant)===0){
                  if (this.Items.docobservation===""  ){

                      toastr['error']( "Veuillez saisir toutes les informations (motif / raison)","<b></b>");

                      return


                  }


                  // if (this.doccaisse.mtntrestantapayer<this.doccaisse.mtntrecu ){

                  if (this.mtntrestantapayer>0){
                      if (this.Items.montant===0 || this.Items.montant===''){

                          toastr['error']( "Veuillez saisir toutes les informations (motif / raison et le montant du réglement)","<b></b>");

                          return


                      }

                  }
                  else{
                      if (this.mtntrestantapayer<0){
                          toastr['error']( "Le montant restant ne doit pas être négatif. veuillez vérifier.","<b></b>");

                          return

                      }
                  }


                  if (this.typereglement!=="COMPTE"){
                  if (this.mtntrestantapayer<this.Items.montant ){


                      toastr['error']( "Le montant reçu est supérieur au montant restant à payer.","<b></b>");
                      this.$store.dispatch('lancerchargement',false)
                      return
                  }
                  }



                  if (this.typedoc==="FRS"){



                      if (this.Items.reglepar!=="Banque" ){

                          if (this.$store.state.montantencaisse<this.Items.montant ){


                              toastr['error']( "Montant en caisse insuffisant pour effectuer le réglement. Montant en caisse :"+this.currencyFormatDE(this.$store.state.montantencaisse),"<b></b>");

                              return
                          }
                      }



                  }




                  this.Items.cf=this.$store.state.caisseinfo
                  this.Items.document=this.typedoc

                  if (this.typereglement==="COMPTE"){
                      this.Items.document="REGVIACOMPTE"
                  }
                  else{

                      this.Items.mtntrestantapayer=this.mtntrestantapayer

                  }




                  let titlemodal="";

                  if (this.typedoc==='FRS'){

                      titlemodal=`

                      <h4>
                               Voulez-vous vraiment éffectuer le règlement fournisseur de <span style="color: red;font-weight: bold;"> ${this.currencyFormatDE(this.Items.montant)} </span>
                                au nom de <span style="color: black;font-weight: bold;">${this.Items.namecltfrs} ?</span>
                      </h4>

                      `;

                  }

                  if (this.typedoc==='CLT'){

                      titlemodal=`

                      <h4>
                                Voulez-vous vraiment éffectuer le règlement client de <span style="color: red;font-weight: bold;"> ${this.currencyFormatDE(this.Items.montant)} </span>
                                au nom de <span style="color: black;font-weight: bold;">${this.Items.namecltfrs} ?</span>
                                </h4>

                      `;

                  }



                  this.$refs.validationouinon.show(titlemodal,this.Items,this.typedoc);


              },

        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>