<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>


                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">Portrait</option>
                                        <option value="A4landscape">Paysage</option>
                                        <!--option value="A5">A5</option>
                                        <option value="A5landscape">A5 landscape</option-->
                                    </select>
                                </div>


                                <div  class="space-y-2">
                                    <div class="w-full flex grid-cols-2">

                                        <div class="box-title mb-2 sm:mb-0"></div>

                                        <div class="inline-flex rounded-md shadow-sm w-full" role="group" aria-label="Basic example">

                                            <div  v-if="typedoc!=='global'" class="grid lg:grid-cols-2 gap-6 w-full">
                                                <div class="space-y-2">
                                                    <label >&nbsp;&nbsp;</label>
                                                    <input class="w-full" type="date"  @change="refresh" v-model="datedebut" placeholder="Date début">
                                                </div>

                                                <div class="space-y-2">
                                                    <label >&nbsp;&nbsp;</label>
                                                    <input class="w-full" type="date" @change="refresh" v-model="datefin" placeholder="Date fin">
                                                </div>
                                            </div>
                                            <div  v-else class="grid lg:grid-cols-1 gap-6 w-full">
                                                <div class="space-y-2">
                                                    <label >&nbsp;&nbsp;</label>
                                                    <input class="w-full" type="date"  @change="refresh" v-model="datedebut" placeholder="Date début">
                                                </div>

                                            </div>


                                        </div>
                                    </div>


                                </div>



                            </div>


                            <div class="grid lg:grid-cols-3 gap-6 mt-4">

                                <div class="space-y-2">
                                    <button @click="print4()" class="ti-btn ti-btn-success-full w-full"> Imprimer </button>


                                </div>

                                <div class="space-y-2">
                                    <button    @click="ExportExcel('xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>
                                </div>


                                <div v-if="typedoc!=='global'" class="space-y-2">
                                    <select class="form-control mb-3 w-full" v-model="selectedaffichage">
                                        <option value="">Affichage simple</option>
                                        <option value="groupby">Affichage grouper par client</option>
                                    </select>
                                </div>


                            </div>








                            <div class="table-responsive mybodyheight300px">


                                <div ref="printdoc"  class="my-5 page pagebreak"  >


                                    <div class="p-5">

                                        <section class="top-content mb-5 bb d-flex justify-content-between">
                                            <div class="logo">
                                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" style="width: 10%" class="img-fluid">
                                            </div>
                                            <div class="top-left">
                                                <div class="logo">

                                                </div>
                                            </div>
                                        </section>

                                        <section class="mt-5">
                                            <div class="grid lg:grid-cols-2 gap-6 row">

                                                <div class="col-6">
                                                    <table class="table table-bordered">
                                                        <tbody >

                                                        <tr>
                                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-6 ">


                                                    <table class="table table-bordered w-full">
                                                        <tbody >
                                                        <tr>
                                                            <td style="text-align: center" colspan="2" >{{soustitre}}</td>

                                                        </tr>
                                                        <tr>
                                                            <td v-if="viewperiode" style="text-align: center">PERIODE</td>
                                                            <td v-else style="text-align: center">JOURNEE DU</td>
                                                            <td style="text-align: center">IMPRIMER PAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="viewperiode" style="text-align: center">{{datedebut+' - '+datefin}}</td>
                                                            <td v-else style="text-align: center">{{datejournee}}</td>
                                                            <td style="text-align: center">{{$store.state.appusername}}</td>
                                                        </tr>

                                                        <tr v-if="!viewperiode">
                                                            <td style="text-align: center" colspan="2" >PERIODE {{datedebut+' - '+datefin}}</td>

                                                        </tr>




                                                        </tbody>
                                                    </table>


                                                </div>

                                            </div>


                                        </section>


                                        <section   class="product-area mt-3">


                                            <template v-if="typedoc==='global'">


                                                <table style="font-size: 14px;height: 600px;" class="table table-bordered w-full">

                                                    <tbody>

                                                    <tr>
                                                        <td ><strong>Etat des caisses</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index"  style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index"  style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;"><strong>{{caisse.__caisseouvert}}</strong></td>

                                                                </tr>

                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td ><strong>Solde à l'ouverture</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index"  style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__soldedouverture)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalsoldealoouverture)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td ><strong>Espèces reçu</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index"  style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index) in dataespecesrecu" :key="index"  style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__totalappro)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalespecesrecu)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td ><strong>Paiement effectué</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index) in datasoldealoouverture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index)  in datapaiementeffectue" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__depsensetotal)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalpaiementeffectue)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td ><strong>Montant physique</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index)  in datasoldealoouverture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index)  in datamontantphysique" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__physiquemontant)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalphysique)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td ><strong>Montant système à la fermeture</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index)  in datamontantalafermerture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index)  in datamontantalafermerture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__fermeturemontant)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalfermeture)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td ><strong>Ecart</strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index)  in datasoldealoouverture" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.__caissename}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index)  in dataecart" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.__caisseecart)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalecart)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td ><strong>Solde en caisse à la date du {{dateencour}} </strong></td>
                                                        <td style="width:400px">
                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>


                                                                <tr v-for="(caisse,index)  in datasolde" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td><strong>{{caisse.name}}</strong></td>

                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td><strong>Totale</strong></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td>

                                                            <table style="width: 100%;height: 100%;">
                                                                <tbody>
                                                                <tr v-for="(caisse,index)  in datasolde" :key="index" style="border-top: 2px solid white;border-left: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(caisse.solde)}}</td>
                                                                </tr>

                                                                <tr  style="border-bottom: 2px solid white;border-left: 2px solid white;border-right: 2px solid white;">
                                                                    <td style="text-align: right;font-weight: 800;">{{formatMoney(montanttotalsolde)}}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>


                                                    </tbody>

                                                </table>






                                            </template>
                                            <template v-else>


                                            <table ref="exportable_table" v-if="selectedaffichage===''" class="mb-3 table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable w-full">
                                                <thead>
                                                <tr>
                                                    <th>N°</th>
                                                    <th>Caisse</th>
                                                    <th>Etat caisse</th>
                                                    <th>Journée</th>
                                                    <th>Ouvrir par</th>
                                                    <th>Solde à l'ouverture</th>
                                                    <th>Total des dépenses</th>
                                                    <th>Total des recettes</th>
                                                    <th>Solde à la fermerture</th>
                                                    <th>Montant physique</th>
                                                    <th>Ecart</th>
                                                    <th v-if="hidecolums">Action</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-if="filterdatatable.length">

                                                    <tr style="font-weight: 600;/*background: khaki;*/"
                                                        v-for="(datavalue,index) in filterdatatable" :key="index">

                                                        <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                        <td  data-label="Caisse  : "   >{{ datavalue.__caissename }}</td>
                                                        <td  data-label="Etat caisse  : "   >{{ datavalue.__caisseouvert }}</td>
                                                        <td  data-label="Journée  : "   >{{ datavalue.__journee }}</td>
                                                        <td  data-label="Ouvrir par  : "   >{{ datavalue.__ouvrirpar }}</td>
                                                        <td  data-label="Solde à l'ouverture  : "   >{{ formatMoney(datavalue.__soldedouverture) }}</td>
                                                        <td  data-label="Total des dépenses  : "   >{{ formatMoney(datavalue.__depsensetotal )}}</td>
                                                        <td  data-label="Total des recettes  : "   >{{ formatMoney(datavalue.__totalappro) }}</td>
                                                        <td  data-label="Solde à la fermerture : "   >{{ formatMoney(datavalue.__fermeturemontant) }}</td>
                                                        <td  data-label="Montant physique : "   >{{ formatMoney(datavalue.__montantphysique) }}</td>
                                                        <td  data-label="Ecart  : "   >{{ formatMoney(datavalue.__caisseecart) }}</td>

                                                        <td v-if="hidecolums"  data-label="Action : " >


                                                            <div class="ti-btn-group">
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                            id="dropdownMenuButton1"
                                                                            aria-expanded="false">
                                                                        Action<i
                                                                            class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                    </button>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                        aria-labelledby="dropdownMenuButton1">



                                                                        <li>
                                                                            <button style="width: 100%!important;" @click="printlineetatdepaiement(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                                <i class="ri-printer-fill"></i> Imprimer l'état de paiement
                                                                            </button>
                                                                        </li>

                                                                        <li>
                                                                            <button style="width: 100%!important;" @click="printlinebielletage(datavalue)"   aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                                <i class="ri-printer-fill"></i> Imprimer le billetage
                                                                            </button>
                                                                        </li>



                                                                    </ul>
                                                                </div>
                                                            </div>



                                                        </td>


                                                    </tr>


                                                </template>



                                                <template v-else>
                                                    <tr >
                                                        <td colspan="15"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>




                                                </tbody>

                                                <tfoot>

                                                <tr>
                                                    <td colspan="5"><strong>Total</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalsoldeouverture)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotaldepense)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalrecette)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalfermeture)}}</strong></td>
                                                    <td ><strong></strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalecart)}}</strong></td>
                                                    <td v-if="hidecolums" ><strong></strong></td>
                                                </tr>

                                                </tfoot>

                                            </table>

                                            <table ref="exportable_table" v-if="selectedaffichage==='groupby'" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable w-full">
                                                <thead>
                                                <tr>
                                                    <th>N°</th>
                                                    <th>Caisse</th>
                                                    <th>Etat caisse</th>
                                                    <th>Journée</th>
                                                    <th>Ouvrir par</th>
                                                    <th>Solde à l'ouverture</th>
                                                    <th>Total des dépenses</th>
                                                    <th>Total des recettes</th>
                                                    <th>Solde à la fermerture</th>
                                                    <th>Montant physique</th>
                                                    <th>Ecart</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <template v-if="Object.keys(filterdatatable).length">

                                                    <template v-for="(myvalue,index) in filterdatatable" :key="index">


                                                        <tr style="font-weight: 600;/*background: khaki;*/" >
                                                            <td colspan="11" style="text-align: center"> <h4>Caisse : {{ myvalue[0].__caissename }} </h4> </td>
                                                        </tr>
                                                        <template v-for="(datavalue,index) in myvalue" :key="index">


                                                         <tr>
                                                             <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                             <td  data-label="Caisse  : "   >{{ datavalue.__caissename }}</td>
                                                             <td  data-label="Etat caisse  : "   >{{ datavalue.__caisseouvert }}</td>
                                                             <td  data-label="Journée  : "   >{{ datavalue.__journee }}</td>
                                                             <td  data-label="Ouvrir par  : "   >{{ datavalue.__ouvrirpar }}</td>
                                                             <td  data-label="Solde à l'ouverture  : "   >{{ formatMoney(datavalue.__soldedouverture) }}</td>
                                                             <td  data-label="Total des dépenses  : "   >{{ formatMoney(datavalue.__depsensetotal )}}</td>
                                                             <td  data-label="Total des recettes  : "   >{{ formatMoney(datavalue.__totalappro) }}</td>
                                                             <td  data-label="Solde à la fermerture : "   >{{ formatMoney(datavalue.__fermeturemontant) }}</td>
                                                             <td  data-label="Montant physique : "   >{{ formatMoney(datavalue.__montantphysique) }}</td>
                                                             <td  data-label="Ecart  : "   >{{ formatMoney(datavalue.__caisseecart) }}</td>

                                                         </tr>


                                                        </template>

                                                        <tr>
                                                            <td colspan="5"><strong>Total</strong></td>
                                                            <td ><strong>{{formatMoney(myvalue[0].montanttotalsoldeouverturegroupby)}}</strong></td>
                                                            <td ><strong>{{formatMoney(myvalue[0].montanttotaldepensegroupby)}}</strong></td>
                                                            <td ><strong>{{formatMoney(myvalue[0].montanttotalrecettegroupby)}}</strong></td>
                                                            <td ><strong>{{formatMoney(myvalue[0].montanttotalfermeturegroupby)}}</strong></td>
                                                            <td ><strong></strong></td>
                                                            <td ><strong>{{formatMoney(myvalue[0].montanttotalecartgroupby)}}</strong></td>
                                                        </tr>


                                                    </template>


                                                </template>



                                                <template v-else>
                                                    <tr >
                                                        <td colspan="18"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>




                                                </tbody>

                                                <tfoot>
                                                <tr>
                                                    <td colspan="5"><strong>Total</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalsoldeouverturegroupby)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotaldepensegroupby)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalrecettegroupby)}}</strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalfermeturegroupby)}}</strong></td>
                                                    <td ><strong></strong></td>
                                                    <td ><strong>{{formatMoney(montanttotalecartgroupby)}}</strong></td>
                                                </tr>
                                                </tfoot>

                                            </table>

                                            </template>
                                        </section>

                                        <section class="balance-info">
                                            <div class="col-12">


                                            </div>
                                        </section>
                                        <section class="balance-info mt-3">
                                            <div class="grid grid-cols-2  gap-x-6 mt-3 row">
                                                <div class="col-6 ">



                                                    <!--div style="float: left;    margin-left: 80px;">
                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> Caissier </p>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <p style="text-align: center;" >{{}}</p>
                                                    </div>

                                                </div>

                                                <div class="col-6 ">

                                                    <div style="float: right;margin-right: 85px;">
                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> RESPONSABLE TRESORERIE </p>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <br>
                                                        <p style="text-align: center;" > {{$store.state.paramsapp.signataire.caisseresponsablefinancier.name}}</p>
                                                    </div-->

                                                </div>

                                            </div>
                                        </section>



                                    </div>




                                </div>




                            </div>


                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalpiecesetatdepaiement ref="modalpiecesetatdepaiement"></modalpiecesetatdepaiement>
    <modalpiecesdebiettage ref="modalpiecesdebiettage"></modalpiecesdebiettage>


</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear, groupByKey,
        impression, isValidDateString, NumbertoLettre,
        randomString
    } from "@/allfunction";

    import * as JwtService from "@/common/jwt.service";
    import qrcode from 'qrcode.vue'
    const axios =require('axios');


    import modalpiecesetatdepaiement from "@/components/views/pages/caisse/rapport/modal_piecesetatdepaiement";
    import modalpiecesdebiettage from "@/components/views/pages/caisse/rapport/modal_piecesdebiettage";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            soustitre(){
                this.taillemodel='A4';  return this.titlemodal
            },
            viewall(){
                if (this.mmois==="" || this.manne===""){

                    return true

                }
                else{

                    return false

                }


            },


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                if (this.typedoc==="global"){return }

                let mvalue=this.datatable,$dataretun=[];

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                if (this.searchnomcaisse!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.__caissename.toLowerCase().includes(t.searchnomcaisse.toLowerCase())

                    })

                }


                if (this.selectedaffichage===''){

                    this.montanttotaldepense=0;
                    this.montanttotalrecette=0;
                    this.montanttotalecart=0;
                    this.montanttotalfermeture=0;
                    this.montanttotalsoldeouverture=0;
                    /*  mvalue.forEach(function () {

                      })*/

                    mvalue.forEach(item => {
                        this.montanttotaldepense=parseInt(item.__depsensetotal)+parseInt(this.montanttotaldepense)
                        this.montanttotalrecette=parseInt(item.__totalappro)+parseInt(this.montanttotalrecette)
                        this.montanttotalecart=parseInt(item.__caisseecart)+parseInt(this.montanttotalecart)
                        this.montanttotalfermeture=parseInt(item.__fermeturemontant)+parseInt(this.montanttotalfermeture)
                        this.montanttotalsoldeouverture=parseInt(item.__soldedouverture)+parseInt(this.montanttotalsoldeouverture)

                    })

                    $dataretun=mvalue

                }
                else {

                    this.montanttotaldepensegroupby=0;
                    this.montanttotalrecettegroupby=0;
                    this.montanttotalecartgroupby=0;
                    this.montanttotalfermeturegroupby=0;
                    this.montanttotalsoldeouverturegroupby=0;

                    mvalue.forEach(item => {
                        this.montanttotaldepensegroupby=parseInt(item.__depsensetotal)+parseInt(this.montanttotaldepensegroupby)
                        this.montanttotalrecettegroupby=parseInt(item.__totalappro)+parseInt(this.montanttotalrecettegroupby)
                        this.montanttotalecartgroupby=parseInt(item.__caisseecart)+parseInt(this.montanttotalecartgroupby)
                        this.montanttotalfermeturegroupby=parseInt(item.__fermeturemontant)+parseInt(this.montanttotalfermeturegroupby)
                        this.montanttotalsoldeouverturegroupby=parseInt(item.__soldedouverture)+parseInt(this.montanttotalsoldeouverturegroupby)

                    })

                    let $datagroupby=groupByKey(mvalue,'__caissename')


                    for (let [index, $line] of Object.entries($datagroupby)) {


                        let t=this,
                            montanttotaldepensegroupby=0,
                            montanttotalrecettegroupby=0,
                            montanttotalecartgroupby=0,
                            montanttotalfermeturegroupby=0,
                            montanttotalsoldeouverturegroupby=0
                        $line.forEach(function (item) {

                            montanttotaldepensegroupby=parseInt(item.__depsensetotal)+parseInt(montanttotaldepensegroupby)
                            montanttotalrecettegroupby=parseInt(item.__totalappro)+parseInt(montanttotalrecettegroupby)
                            montanttotalecartgroupby=parseInt(item.__caisseecart)+parseInt(montanttotalecartgroupby)
                            montanttotalfermeturegroupby=parseInt(item.__fermeturemontant)+parseInt(montanttotalfermeturegroupby)
                            montanttotalsoldeouverturegroupby=parseInt(item.__soldedouverture)+parseInt(montanttotalsoldeouverturegroupby)


                        })

                        $line.forEach(function (item) {
                            item.montanttotaldepensegroupby=montanttotaldepensegroupby
                            item.montanttotalrecettegroupby=montanttotalrecettegroupby
                            item.montanttotalecartgroupby=montanttotalecartgroupby
                            item.montanttotalfermeturegroupby=montanttotalfermeturegroupby
                            item.montanttotalsoldeouverturegroupby=montanttotalsoldeouverturegroupby

                        })

                        //$line.montanttolal=montanttolal

                    }


                    $dataretun=$datagroupby

                }

                return $dataretun;


            },



            datasoldealoouverture(){

                if (this.typedoc!=="global"){return }


                let mvalue=[]
                mvalue=this.datatable;
                this.montanttotalsoldealoouverture=0

                mvalue.forEach(item => {

                    this.montanttotalsoldealoouverture=parseInt(item.__soldedouverture)+parseInt(this.montanttotalsoldealoouverture)

                })


                return mvalue;
            },
            dataespecesrecu(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]
                mvalue=this.datatable;


                this.montanttotalespecesrecu=0


                mvalue.forEach(item => {

                    this.montanttotalespecesrecu=parseInt(item.__totalappro)+parseInt(this.montanttotalespecesrecu)

                })


                return mvalue;
            },
            datapaiementeffectue(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]
                mvalue=this.datatable;

                this.montanttotalpaiementeffectue=0


                mvalue.forEach(item => {

                    this.montanttotalpaiementeffectue=parseInt(item.__depsensetotal)+parseInt(this.montanttotalpaiementeffectue)

                })



                return mvalue;
            },

            datasolde(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]


                if (this.datatable.length>0){

                    if (this.datatable[0].allsoldecaisse.length>0){

                        mvalue=this.datatable[0].allsoldecaisse;
                    }



                }


                this.montanttotalsolde=0



                mvalue.forEach(item => {

                    this.montanttotalsolde=parseInt(item.solde)+parseInt(this.montanttotalsolde)

                })

                return mvalue;
            },

            dataecart(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]
                mvalue=this.datatable;


                this.montanttotalecart=0



                mvalue.forEach(item => {

                    this.montanttotalecart=parseInt(item.__caisseecart)+parseInt(this.montanttotalecart)

                })

                return mvalue;
            },

            datamontantphysique(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]
                mvalue=this.datatable;


                this.montanttotalphysique=0



                mvalue.forEach(item => {

                    this.montanttotalphysique=parseInt(item.__physiquemontant)+parseInt(this.montanttotalphysique)

                })

                return mvalue;
            },

            datamontantalafermerture(){
                if (this.typedoc!=="global"){return }
                let mvalue=[]
                mvalue=this.datatable;


                this.montanttotalfermeture=0



                mvalue.forEach(item => {

                    this.montanttotalfermeture=parseInt(item.__fermeturemontant)+parseInt(this.montanttotalfermeture)

                })

                return mvalue;
            },


        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            modalpiecesetatdepaiement,
            modalpiecesdebiettage,
            qrcode

        },

        data() {
            return {

                mfontsize:10,
                taillemodel:'A4landscape',
                mgetyear:getYear(),
                datenow:currentDate(),
                hidecolums:true,
                isdisplay:false,
                viewperiode:false,
                titlemodal:"",
                datatable:[],
                viewdrop:false,
                tabvalue:{},
                selectedaffichage:'',
                datejournee:"",
                datedebut:"",
                numpidcaisse:"",
                datefin:"",
                searchnomcaisse:"",
                searchbeneficiaire:"",
                typedoc:"",

                caisseresponsablefinancier:{name:'',titre:'RESPONSABLE FINANCIÈRE' },
                caisseresponsable:{name:'',titre:'RESPONSABLE CAISSIÈRE' },


                montanttotaldepense:0,
                montanttotalrecette:0,
                montanttotalecart:0,
                montanttotalfermeture:0,
                montanttotalsoldeouverture:0,

                montanttotaldepensegroupby:0,
                montanttotalrecettegroupby:0,
                montanttotalecartgroupby:0,
                montanttotalfermeturegroupby:0,
                montanttotalsoldeouverturegroupby:0,


                montanttotalsoldealoouverture:0,
                montanttotalespecesrecu:0,
                montanttotalpaiementeffectue:0,
                montanttotalsolde:0,
                montanttotalphysique:0,




                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {



            formatMonney(value){
                return currencyFormatMonney(value)

            },


            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },


            btnviewdrop(){

                if(this.viewdrop===true){

                    this.viewdrop=false;

                }else{

                    this.viewdrop=true;

                }



            },


            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {

                this.hidecolums=false;
                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                }

                if (this.taillemodel==="A4" || this.taillemodel==="A5" ){


                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                        // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }






                setTimeout(() => {

                    const printContent = this.$refs.printdoc.innerHTML;

                    impression(printContent,$style,window.document.title)
                        .then(result => {
                            console.log(result); // "Impression terminée avec succès"
                            this.hidecolums=true;
                        })
                        .catch(error => {
                            console.error(error);
                            this.hidecolums=true;
                        });

                }, 500);




            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },



            printlineetatdepaiement(value)
            {


                 let tabvalue={

                    docsaisiepar:value.__ouvrirpar,
                    caisse:value.__caissename,
                    numdoc:value.__numetatpaiement,
                    date:value.__journee,
                    soldealouverture:value.__soldedouverture,
                    especesrecu:value.__autreappro,
                    especesrecubaque:value.__bankappro,
                    especesrecuvente:value.__venteappro,
                    especesrecuautre:value.__autreappro,
                    totalespecerecu:value.totalespecerecu,
                    paiementbanque:value.__depottransfert,
                    paiementdepense:value.__depense,
                    paiementachat:value.__reglfrs,
                    totalpaiement:value.totalpaiement,
                    montantalafermeture:value.__fermeturemontant,
                    montantphysique:value.__montantphysique,
                    montantattendu:value.__fermeturemontant,
                    montantecart:value.__caisseecart,
                    typdoc:'',
                     caisseresponsable:value.__caisseresponsable

                }

                this.$refs.modalpiecesetatdepaiement.show(tabvalue);



            },

            printlinebielletage(value)
            {


                 let tabvalue={

                     docsaisiepar:value.__ouvrirpar,
                     caisse:value.__caissename,
                     numdoc:value.__numetatpaiement,
                     date:value.__journee,
                     soldealouverture:value.__soldedouverture,
                     especesrecu:value.__autreappro,
                     especesrecubaque:value.__bankappro,
                     especesrecuvente:value.__venteappro,
                     especesrecuautre:value.__autreappro,
                     totalespecerecu:value.totalespecerecu,
                     paiementbanque:value.__depottransfert,
                     paiementdepense:value.__depense,
                     paiementachat:0,
                     totalpaiement:value.totalpaiement,
                     billetage:value.__billetage,
                     montantalafermeture:value.__fermeturemontant,
                     montantphysique:value.__montantphysique,
                     montantattendu:value.__fermeturemontant,
                     montantecart:value.__caisseecart,
                     typdoc:'',
                     caisseresponsable:value.__caisseresponsable



                }

                this.$refs.modalpiecesdebiettage.show(tabvalue);



            },





            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

            async show(titlemodal,numpidcaisse,datedouverture,typedoc){

                this.datatable=[]

                this.titlemodal=titlemodal
                this.typedoc=typedoc

                /*this.numpidcaisse=numpidcaisse
                this.datedebut=(datedouverture!=="")?datedouverture:this.$store.state.appserverdate
                this.datefin=(datedouverture!=="")?datedouverture:this.$store.state.appserverdate*/

                if (datedouverture!==""){

                    this.viewperiode=false;
                    this.datejournee=datedouverture
                    this.datedebut=datedouverture
                    this.datefin=datedouverture
                    this.numpidcaisse=numpidcaisse

                    this.refresh()

                }else{

                    this.viewperiode=true;
                    this.datedebut=currentDate()
                    this.datefin=currentDate()
                    this.numpidcaisse=numpidcaisse

                    this.refresh()

                }

                this.isdisplay=true;
            },


            async refresh(){

                if (this.typedoc==="global"){this.datedebut=this.datefin }

                if (!isValidDateString(this.datedebut) || !isValidDateString(this.datefin)){return }


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/c/rapport1247ifufjf85/'+this.typedoc+'/'+this.datedebut+'/'+this.datefin+'/'+this.numpidcaisse+'/'+this.$store.state.appsociete+'/8954fddpap18rapportcaisse519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m

                        }
                        else{
                            t.datatable=[];


                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },


            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>