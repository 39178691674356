<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>


                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">Portrait</option>
                                        <option value="A4landscape">Paysage</option>
                                        <!--option value="A5">A5</option>
                                        <option value="A5landscape">A5 landscape</option-->
                                    </select>
                                </div>


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;&nbsp;</label>
                                    <select v-model="typview" @change="changetypview" class="form-control">
                                        <template v-if="caissebanque==='ALL'">
                                            <option value="CAISSE">SOLDE EN CAISSE</option>
                                            <option value="BANQUE">SOLDE EN BANQUE</option>
                                        </template>
                                        <template v-else>
                                            <option value="BANQUE">SOLDE EN BANQUE</option>
                                        </template>

                                    </select>



                                </div>

                            </div>


                            <div class="grid lg:grid-cols-3 gap-6 mt-4">

                                <div class="space-y-2">
                                    <button @click="print4" class="ti-btn ti-btn-success-full w-full"> Imprimer</button>


                                </div>

                                <div class="space-y-2">
                                    <button    @click="ExportExcel('xlsx',`${titlemodal} période (${datedebut} - ${datefin})`)"
                                               class="ti-btn ti-btn-danger-full  w-full"> Excel</button>
                                </div>




                            </div>



                            <div class="table-responsive mybodyheight300px">


                                <div ref="printdoc"  class="my-5 page pagebreak"  >


                                    <div class="p-5">

                                        <section class="top-content mb-5 bb d-flex justify-content-between">
                                            <div class="logo">
                                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" style="width: 10%" class="img-fluid">
                                            </div>
                                            <div class="top-left">
                                                <div class="logo">

                                                </div>
                                            </div>
                                        </section>

                                        <section class="mt-5">
                                            <div class="grid lg:grid-cols-2 gap-6 row">

                                                <div class="col-6">
                                                    <table class="table table-bordered">
                                                        <tbody >

                                                        <tr>
                                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-6 ">


                                                    <table class="table table-bordered w-full">
                                                        <tbody >
                                                        <tr>
                                                            <td style="text-align: center;text-transform: uppercase" colspan="2" >{{soustitre}}</td>

                                                        </tr>
                                                        <tr>

                                                            <td style="text-align: center">IMPRIMER LE</td>
                                                            <td style="text-align: center">IMPRIMER PAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td  style="text-align: center">{{$store.state.appserverdate}}</td>
                                                            <td style="text-align: center">{{$store.state.appusername}}</td>
                                                        </tr>






                                                        </tbody>
                                                    </table>


                                                </div>

                                            </div>


                                        </section>


                                        <section   class="product-area mt-3">


                                            <table ref="exportable_table" class="table table-striped table-bordered table-hover
                                            dataTable no-footer dtr-inline mytable w-full">
                                                <thead>
                                                <tr>
                                                    <th style="text-align: center" v-if="typview==='CAISSE'">Caisse</th>
                                                    <th style="text-align: center" v-else>Banque</th>
                                                    <th style="text-align: center">Montant</th>
                                                </tr>
                                                </thead>


                                                <tbody>
                                                <template v-if="filterdatatable.length">

                                                    <tr style="font-weight: 600;/*background: khaki;*/"
                                                        v-for="(datavalue,index) in filterdatatable" :key="index">


                                                        <td   data-label="-  : "   >{{ datavalue.name }}</td>
                                                        <td style="text-align: center" data-label="Montant : " >{{  formatMoney(datavalue.solde) }}</td>

                                                    </tr>

                                                    <tr>
                                                        <td><strong>TOTAL </strong></td>
                                                        <td style="text-align: center"><strong>{{  formatMoney(montanttolal) }}</strong></td>
                                                    </tr>

                                                </template>



                                                <template v-else>
                                                    <tr >
                                                        <td colspan="2"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>




                                                </tbody>

                                            </table>






                                        </section>

                                        <section class="balance-info">
                                            <div class="col-12">


                                            </div>
                                        </section>
                                        <section class="balance-info mt-3">
                                            <div class="grid grid-cols-2  gap-x-6 mt-3 row">
                                                <div class="col-6 ">


                                                </div>

                                            </div>
                                        </section>



                                    </div>




                                </div>




                            </div>


                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear,
        impression,  NumbertoLettre,
        randomString
    } from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import qrcode from 'qrcode.vue'
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            soustitre(){
                this.taillemodel='A4';


                if (this.typview==='BANQUE'){
                    this.titlemodal='Solde des banques' ;
                   // this.typview='BANQUE'
                }else{

                    this.titlemodal='Solde des caisses ' ;
                    //this.typview='CAISSE'
                }

                return this.titlemodal


            },

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable,$dataretun=[];

                this.montanttolal=0

                let t=this
                mvalue.forEach(function (item) {
                    t.montanttolal=parseFloat(t.montanttolal)+parseFloat(item.solde)

                })


                return mvalue;


            },


        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            qrcode

        },

        data() {
            return {

                mfontsize:10,
                taillemodel:'A4',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                viewperiode:false,
                titlemodal:"",
                datatable:[],
                datatablecaisse:[],
                datatablebanque:[],
                viewdrop:false,
                tabvalue:{},
                selectedaffichage:'',
                datejournee:"",
                caissebanque:"",
                typview:"",
                datedebut:"",
                numpidcaisse:"",
                datefin:"",
                searchnomcaisse:"",
                searchbeneficiaire:"",
                typedoc:"",


                montanttolal:0,
                montanttolalgroupby:0,


                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {

            changetypview(){
                let t=this

                if (this.caissebanque==="ALL"){


                    if (this.typview==="CAISSE"){
                        t.datatable=t.datatablecaisse


                    }else{t.datatable=t.datatablebanque}

                }else{

                    t.datatable=t.datatablebanque
                }


            },



            formatMonney(value){
                return currencyFormatMonney(value)

            },


            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },


            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                }

                if (this.taillemodel==="A4" || this.taillemodel==="A5" ){


                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                        // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }

                const printContent = this.$refs.printdoc.innerHTML;
                impression(printContent,$style,window.document.title)

            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

            async show(caissebanque){

                this.refresh()

                this.datatable=[]
                if (caissebanque==="BANQUE"){
                    this.titlemodal='Solde des banques' ;
                    this.typview='BANQUE'
                }else{

                    this.titlemodal='Solde des caisses ' ;
                    this.typview='CAISSE'
                }

                this.caissebanque=caissebanque


                this.isdisplay=true;
            },


            async refresh(){

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/c/rapport/ss/'+this.$store.state.appsociete+'/8954fddpap18ss519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatablecaisse=[];
                        t.datatablebanque=[];

                        if(res.data.success){

                            t.datatablecaisse=res.data.m.cc
                            t.datatablebanque=res.data.m.bk


                            t.changetypview();

                        }
                        else{
                            t.datatablecaisse=[];
                            t.datatablebanque=[];


                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },


            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>