<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>


            <!--appmenu></appmenu-->


            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">
                    <div class="md:flex block items-center justify-between my-[1.5rem] page-header-breadcrumb">
                        <div>


                        </div>
                        <div class="btn-list md:mt-0 mt-2">
                            <!--button @click="init" type="button" class="ti-btn bg-primary text-white btn-wave !font-medium !me-[0.375rem] !ms-0 !text-[0.85rem] !rounded-[0.35rem] !py-[0.51rem] !px-[0.86rem] shadow-none">
                                <i class="ri-refresh-fill  inline-block"></i>Actualiser
                            </button-->
                        </div>
                    </div>

                    <div class="grid grid-cols-12 gap-x-6">
                        <div class="xl:col-span-12  col-span-12 mt-5">
                            <div class="box">
                                <div class="box-body">
                                    <div class="md:flex block flex-wrap items-center justify-between">
                                        <div class="flex-grow">
                                            <nav class="nav nav-pills nav-style-3 flex md:mb-0 mb-4" aria-label="Tabs">
                                                <a style="    cursor: pointer;" class="nav-link text-defaulttextcolor !py-[0.35rem] !px-4 text-sm !font-medium text-center rounded-md hover:text-primary active"
                                                   id="portfolio-item" data-hs-tab="#Stocksportfolio" aria-controls="Stocksportfolio">
                                                    Général
                                                </a>
                                                <a @click="btninitcomponnentstock" style="    cursor: pointer;" class="nav-link text-defaulttextcolor !py-[0.35rem] !px-4 text-sm !font-medium text-center rounded-md hover:text-primary"
                                                   id="market-item" data-hs-tab="#Stocksmarket" aria-controls="Stocksmarket">
                                                    Stock
                                                </a>
                                                <a @click="btninitcomponnentstcaisse" style="    cursor: pointer;" class="nav-link text-defaulttextcolor !py-[0.35rem] !px-4 text-sm !font-medium text-center rounded-md hover:text-primary"
                                                   id="caisse-item" data-hs-tab="#caisse" aria-controls="caisse">
                                                    Caisse
                                                </a>
                                            </nav>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-x-6">
                        <div class="xl:col-span-12 col-span-12">
                            <div class="tab-content">
                                <div class="tab-pane show active !p-0 !border-0" id="Stocksportfolio" aria-labelledby="portfolio-item" role="tabpanel">

                                    <general></general>

                                </div>

                                <div class="tab-pane hidden !p-0 !border-0" id="Stocksmarket" aria-labelledby="market-item" role="tabpanel">

                                    <stock ref="stockcomponnent"></stock>

                                </div>
                                <div class="tab-pane hidden !p-0 !border-0" id="caisse" aria-labelledby="caisse-item" role="tabpanel">

                                    <caisse ref="caissecomponnent"></caisse>


                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </template>

    </templatesite>


</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";


    import stock from "@/components/views/pages/tableaudebord/stock";
    import general from "@/components/views/pages/tableaudebord/general";
    import caisse from "@/components/views/pages/tableaudebord/caisse";




    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,


            stock,
            general,
            caisse,


        },
        watch: {

        },

        computed:{



        },

        data() {
            return {



            };
        },
        methods: {

            btninitcomponnentstock(){
                 this.$refs.stockcomponnent.loadinitcomponnent()
            },

            btninitcomponnentstcaisse(){
                 this.$refs.caissecomponnent.loadinitcomponnent()
            },



        },
        mounted() {

        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>