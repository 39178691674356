/* eslint-disable no-console */

import { register } from 'register-service-worker'
//const SERVICE_WORKER_VERSION = process.env.VUE_APP_V_SW; // La version du service worker


if (process.env.NODE_ENV === 'production') {
  /*
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      const registration = registrations[0]; // On prend le premier enregistrement

      console.log("registration ********** ",registration)

      if (registration) {
        // Vérifiez si la version active est la même que la version définie
        const currentVersion = registration.active ? registration.active.scriptURL : '';
        console.log("currentVersion ********** ",currentVersion)
        if (!currentVersion.includes(SERVICE_WORKER_VERSION)) {
          // Si la version ne correspond pas, désenregistrez le service worker
          registration.unregister().then(() => {
            console.log('Service Worker désenregistré.');

            // Videz le cache
            return caches.keys().then((cacheNames) => {
              return Promise.all(
                  cacheNames.map((cacheName) => caches.delete(cacheName))
              );
            });
          }).then(() => {
            // Enregistrez le nouveau service worker
            return navigator.serviceWorker.register(`${process.env.VUE_APP_URL}service-worker.js`);
          }).then((registration) => {
            console.log('Nouveau Service Worker enregistré:', registration);
          }).catch((error) => {
            console.error('Erreur lors de la mise à jour du Service Worker:', error);
          });
        } else {
          console.log('La version du Service Worker est à jour.');
        }
      }
      else {
        // Si aucun service worker n'est enregistré, enregistrez-le
        navigator.serviceWorker.register(`${process.env.VUE_APP_URL}service-worker.js`).then((registration) => {
          console.log('Service Worker enregistré:', registration);
        }).catch((error) => {
          console.error('Erreur lors de l\'enregistrement du Service Worker:', error);
        });
      }
    });
  }

*/

  register(`${process.env.VUE_APP_URL}service-worker.js`, {
     ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.');
      // Vérifiez si un service worker est déjà en cours d'exécution
      checkServiceWorkerUpdate();


    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound (registration) {
      console.log('New content is downloading.')



      // Ici, vous pouvez gérer le téléchargement du nouveau service worker
      const newWorker = registration.installing; // Obtenez le nouveau worker

      newWorker.onstatechange = () => {
        if (newWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // Un nouveau service worker est installé, mais pas encore actif
            console.log('New content is available; please refresh.');
            // Optionnel : vous pouvez ici informer l'utilisateur et recharger la page
            if (confirm("Une nouvelle version est disponible. Voulez-vous recharger la page ?")) {
              window.location.reload();
            }
          } else {
            // Le service worker est installé pour la première fois
            console.log('Content is cached for offline use.');
          }
        }
      };



    },
    updated (registration) {
      /*console.log('New content is available; please refresh.')
      if (confirm("Une nouvelle version est disponible. Voulez-vous recharger la page ?")) {
        window.location.reload(); // Recharge la page
      }*/


      console.log('New content is available; please refresh.');
      // Informez l'utilisateur d'une nouvelle version disponible
      notifyUserAboutUpdate(registration);

    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}


function checkServiceWorkerUpdate() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration.waiting) {
          // Si un service worker est en attente, cela signifie qu'il a été installé
          console.log('Service Worker est prêt à être activé.');
          notifyUserAboutUpdate(registration);
        }
      });
    });
  }
}

function notifyUserAboutUpdate(registration) {
  // Affichez une notification à l'utilisateur ou utilisez un modal
  // Par exemple, avec une boîte de dialogue ou une notification
  if (confirm('Une nouvelle version de l\'application est disponible. Voulez-vous recharger la page ?')) {
    registration.waiting.postMessage({ action: 'skipWaiting' });
    window.location.reload();
  }
}

// Dans votre service-worker.js, écoutez le message pour activer immédiatement le nouveau worker
self.addEventListener('message', (event) => {
  if (event.data.action === 'skipWaiting') {
    self.skipWaiting();
  }
});
