<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">

                            <div class="grid grid-cols-12  gap-x-6">

                                <div class="xl:col-span-12 mt-3  col-span-12">
                                    <div v-if="mytyp==='CLT'" style="    text-align: center;font-size: 14px;padding: 14px;
    font-weight: bold;color: black;" class="alert alert-info">
                                        Le relevé des factures est un résumé de l'ensemble des opérations ayant eu lieu avec un client sur une période.

                                    </div>
                                    <div v-else style="    text-align: center;font-size: 14px;padding: 14px;
                          font-weight: bold;color: black;" class="alert alert-info">
                                        Le relevé des factures est un résumé de l'ensemble des opérations ayant eu lieu avec un fournisseur sur une période.

                                    </div>
                                </div>

                                <div class="xl:col-span-4 mt-3  col-span-12">
                                    <input @change="load" v-model="datedebut" type="date" class="form-control" >
                                </div>
                                <div class="xl:col-span-4 mt-3  col-span-12">
                                    <input @change="load" v-model="datefin" type="date" class="form-control" >
                                </div>

                                <div class="xl:col-span-4 mt-3  col-span-12">

                                    <select class="form-control" @change="load()" v-model="selectedclient">
                                        <option :value="null">Séléctionner un client</option>
                                        <option v-for="(client,index) in dataclient" :key="index" :value="client">{{client.name}}</option>
                                    </select>

                                </div>



                            </div>

                            <div class="w-full flex grid-cols-2 mt-4">

                                <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">

                                    <div class="grid lg:grid-cols-2 gap-6">

                                        <div class="space-y-2">

                                            <button @click="ExportExcel('xlsx',titlemodal)"  class="ti-btn ti-btn-success-full w-full"><i class="ri-file-excel-fill"></i> Exporter en excel</button>

                                        </div>

                                        <div class="space-y-2">
                                            <button @click="apercuavantimpression(titlemodal,'exportable_table')"  class="ti-btn ti-btn-primary-full w-full"><i class="ri-printer-fill"></i> Aperçu avant impression</button>

                                        </div>





                                    </div>


                                </div>
                            </div>

                        </div>

                            <div class="table-responsive">

                                <div class="flex flex-col h-screen tableFixHeadindexcomponnent">
                                    <div class="flex-grow overflow-auto">

                                   <table ref="exportable_table" class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>

                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer par</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer le</th>
                                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date</th-->
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Client</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° document</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Etat normalisation</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Taux AIB(%)</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant HT (FCFA)  </th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant TVA (FCFA)  </th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant  AIB (FCFA) </th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant (FCFA)   </th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant restant (FCFA) </th>


                                            </tr>
                                            </thead>
                                            <tbody>


                                                <template v-if="filterdatatable.length">

                                                    <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" style="font-weight: 600;/*background: khaki;*/"
                                                        v-for="(datavalue,index) in filterdatatable" :key="index">


                                                        <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                        <td  data-label="Créer par : "   >{{ datavalue.creerpar }}</td>
                                                        <td  data-label="Créer le  : "   >{{ datavalue.creerle }}</td>
                                                        <!--td  data-label="Date : "   >{{ datavalue.datedoc }}</td-->
                                                        <td  data-label="Client : "   >{{ datavalue.clientfrs }}</td>
                                                        <td  data-label="N°Document  : "   >{{ datavalue.numdoc }}</td>
                                                        <td  data-label="Etat normalisation : "   >

                                                            <template v-if="datavalue.normalisation==='1' || datavalue.normalisation===1">Facture Normalisée</template>
                                                            <template v-else><span style="color: red">Facture non Normalisée</span></template>

                                                        </td>
                                                        <td  data-label="Taux AIB  : "   >{{ datavalue.tauxaib }}</td>
                                                        <td  data-label="Montant HT  : "   >{{ FormatNumber(datavalue.montantht) }}</td>
                                                        <td  data-label="Montant TVA : "   >{{ FormatNumber(datavalue.montanttva) }}</td>
                                                        <td  data-label="Montant AIB : "   >{{ FormatNumber(datavalue.montantaib) }}</td>
                                                        <td  data-label="Montant  : "   >{{ FormatNumber(datavalue.debit) }}</td>
                                                        <td  data-label="Montant restant : " >{{ FormatNumber(datavalue.credit) }}</td>



                                                    </tr>

                                                </template>

                                                <template v-else>
                                                    <tr >
                                                        <td class="border-t hover:bg-gray-200 dark:hover:bg-light" colspan="20"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>





                                            </tbody>
                                       <tfoot>
                                       <tr>


                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" colspan="7" data-label="Total"><span>Total</span></th>
                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" data-label="Montant total HT"><span>{{FormatNumber(montanttotalht)}}</span></th>
                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" data-label="Montant total TVA"><span>{{FormatNumber(montanttotaltva)}}</span></th>
                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" data-label="Montant total AIB"><span>{{FormatNumber(montanttotalaib)}}</span></th>
                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" data-label="Montant"><span>{{FormatNumber(montanttotal)}}</span></th>
                                           <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" data-label="Montant restant"><span>{{FormatNumber(montanttotalrestant)}}</span></th>



                                       </tr>
                                       </tfoot>
                                        </table>


                                    </div>
                                </div>

                            </div>



                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <modalprintdefault ref="modalprintdefault"></modalprintdefault>


</template>

<script>


    import modalprintdefault from "@/components/views/pages/modal_printdefault";

    const axios =require('axios');


    import {currentDateTime, ExportExcel, FormatNumber, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },

            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                let t=this


                t.montanttotalht=0
                t.montanttotaltva=0
                t.montanttotalaib=0
                t.montanttotalremise=0
                t.montanttotal=0
                t.montanttotalrestant=0
                    mvalue.forEach(function (post) {
                    t.montanttotalht=parseInt(t.montanttotalht)+parseInt(post.montantht)
                    t.montanttotaltva=parseInt(t.montanttotaltva)+parseInt(post.montanttva)
                    t.montanttotalaib=parseInt(t.montanttotalaib)+parseInt(post.montantaib)
                    t.montanttotalremise=parseInt(t.montanttotalremise)+parseInt(post.montantremise)
                    t.montanttotal=parseInt(t.montanttotal)+parseInt(post.debit)
                    t.montanttotalrestant=parseInt(t.montanttotalrestant)+parseInt(post.credit)
                })


                return mvalue ;
                //return this.paginate(mvalue) ;
            },
        },
        components: {
            modalprintdefault

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                mmontanttotal:0,

                montanttotalremise:0,
                montanttotalht:0,
                montanttotaltva:0,
                montanttotalaib:0,
                montanttotal:0,
                montanttotalrestant:0,

                datedebut:this.$store.state.appserverdate,
                datefin:this.$store.state.appserverdate,
                dataclient:[],
                datatable:[],
                mytyp:'',

                selectedclient:null,



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {


            apercuavantimpression(valuetitre,tableref){
                let $tableref=this.$refs[tableref];


                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    total1:0,
                    total2:0,
                    total3:0,
                    total4:0,
                    tableref:$tableref.toString(),
                }

                this.$refs.modalprintdefault.show(tabvalue,$tableref);



            },





            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },






            async load(){

                if (this.selectedclient===null){

                    return

                }

                if (this.datedebut===null || this.datedebut==="" ||
                    this.datefin===null || this.datefin==="" ||
                    Object.keys(this.selectedclient).length<=0){

                    return

                }


                this.$store.dispatch('lancerchargement',true)

                const t = this;
                //let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/rapport/gescom/'+this.manne+'/'+this.mmois+'/'+this.$store.state.appsociete+'/suivie_ca_0287845854/'+randomString();

                let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/rapport/gescom/'+this.selectedclient.pid+'/'+this.datedebut+'/'+this.datefin+'/'+this.$store.state.appsociete+'/rttu44releverfct785488kjuii_0287845854/'+randomString();

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            t.datatable=[];

                            t.datatable=res.data.dt1;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadclient(value){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/compta/edition/othetdskd8755dsdhisd/'+value+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){



                            t.dataclient=[];
                            t.dataclient=res.data.otherdata.beneficiairecl;


                        }
                        else{
                            t.dataclient=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            show(typ){


                this.messagesucces="";
                this.messageerror="";


                if(typ==='CLT'){
                    ///this.titlemodal=" Relevé de compte client" ;
                    this.titlemodal=" CA client sur une période " ;



                }
                else{
                    //this.titlemodal=" Relevé de compte fournisseur" ;
                    this.titlemodal=" CA fournisseur sur une période " ;



                }

                this.datatable=[];
                this.dataclient=[];

                this.loadclient(typ)

                this.mytyp=typ;
                this.isdisplay=true;





            },


            FormatNumber(num) {

                return  FormatNumber(num)
            },



            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>