<template>
    <router-view/>
    <vloadingcomponent :loading="$store.state.lancerchargement"></vloadingcomponent>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vloadingcomponent from "./loadingcomponent";

export default {

  name: 'App',
    created() {



        // Initialize the screen size
        this.screenSize = window.innerWidth;
        this.screenHeight = window.innerHeight;

        // Watch for screen size changes
        window.addEventListener('resize', this.handleResize);
    },
   /* destroyed() {
        // Clean up the event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },*/

    watch: {

        screenSize(newSize) {
            //document.body.removeAttribute('class')

            let html = document.querySelector("html");


            if (html.getAttribute("data-vertical-style") !== 'overlay'){



                html.setAttribute("data-vertical-style", "overlay");

            }


            if (newSize <= 990) { // ecran mobile
                // Execute your code here for screen size <= 990px
                this.$store.dispatch('myscreenSize',newSize)
               /* this.$store.dispatch('myscreenSize',newSize)


                this.$store.dispatch('classdefautmenu',"sidebar-mini layout-fixed  sidebar-closed sidebar-collapse")
                this.$store.dispatch('niveauclickmenu',parseInt(0))
                document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-closed', 'sidebar-collapse')

                if (this.isAuthenticated===false){
                    this.$store.dispatch('classdefautmenu',"login-page")
                    document.body.classList.add('login-page')
                }else {

                    this.$store.dispatch('classdefautmenu'," sidebar-closed sidebar-collapse")
                    document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-closed', 'sidebar-collapse')

                }*/


                if (html.getAttribute("data-toggled") === 'icon-overlay-close'  ){

                    html.removeAttribute('data-toggled');
                    html.setAttribute("data-toggled", "close");

                }


                if (html.getAttribute("data-toggled") !== 'icon-overlay-close' &&
                    html.getAttribute("data-toggled") !== 'close'){

                    html.removeAttribute('data-toggled');
                    html.setAttribute("data-toggled", "close");

                }




            }
            else{//ecran large

                if (html.getAttribute("data-toggled") === 'close'  ){

                    html.removeAttribute('data-toggled');

                }






                this.$store.dispatch('myscreenSize',newSize)


                /*

                this.$store.dispatch('niveauclickmenu',parseInt(1))

                if (this.isAuthenticated===false){
                    this.$store.dispatch('classdefautmenu',"login-page")
                    document.body.classList.add('login-page')
                }
                else{

                    this.$store.dispatch('classdefautmenu',"sidebar-mini layout-fixed sidebar-collapse")
                    document.body.classList.add('sidebar-mini', 'layout-fixed','sidebar-collapse')
                }*/

            }
        },


        screenHeight(newHeight) {
            newHeight=parseFloat(newHeight)-5
            this.$store.dispatch('myscreenHeight',newHeight)
        },





        isAuthenticated(newVal) {
            if (newVal) {
                this.startInterval();
            } else {
                this.stopInterval();
            }
        },
    },
    data(){
        return {
            screenSize: null,
            screenHeight: null,
            intervalId: null, // ID pour stocker l'intervalle

            totalConsumption: 0, // Consommation totale des ressources en Mo
            resourceDetails: []  // Détails par type de ressources consommées


        }

    },
    computed:{
        ...mapGetters(['lancerchargement','classmenupc','classdefautmenu',
            'niveauclickmenu','myscreenSize','myscreenHeight','isAuthenticated']),



    },
    components:{
        vloadingcomponent
    },
    mounted() {

        // Vérifiez et enregistrez le service worker

        this.initapp();
        this.getResourceConsumption(); // Suivi lors du chargement initial de l'application
        this.setupRouteWatcher(); // Mettre en place le suivi lors des changements de route

    },

    methods: {



        initapp(){

            //console.log("process.env.VUE_APP_ETAT ",process.env.VUE_APP_ETAT)
           // console.log("baseurlenv ",this.$store.state.baseurlenv)
           // console.log("window.location.host ",window.location.host)

            if (this.$store.state.baseurlenv==='production'){


                //*******************************************************************//

                document.oncontextmenu = new Function("return false");

                document.onkeydown = function ()
                {
                    if(event.keyCode==123) //F11
                    {
                        event.keyCode = 0;
                        event.returnValue = false;
                        event.cancelBubble = true;
                        return false;
                    }

                    if (event.ctrlKey &&
                        (event.keyCode === 85 ))//ctrl+u
                    {
                        return false;
                    }

                    // "apostrophe" key
                    if (event.key==="'") {
                        //disabledEvent(e);
                        return false;
                    }


                }
                window.onload = function () {
                    document.addEventListener("contextmenu", function (e) {
                        e.preventDefault();
                    }, false);
                    document.addEventListener("keydown", function (e) {
                        //document.onkeydown = function(e) {
                        // "I" key
                        if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                            disabledEvent(e);
                        }
                        // "J" key
                        if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
                            disabledEvent(e);
                        }
                        // "S" key + macOS
                        if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                            disabledEvent(e);
                        }
                        // "U" key
                        if (e.ctrlKey && e.keyCode == 85) {
                            disabledEvent(e);
                        }
                        // "F12" key
                        if (event.keyCode == 123) {
                            disabledEvent(e);
                        }

                        // "apostrophe" key
                        if (event.key==="'") {
                            disabledEvent(e);
                        }

                    }, false);
                    function disabledEvent(e) {
                        if (e.stopPropagation) {
                            e.stopPropagation();
                        } else if (window.event) {
                            window.event.cancelBubble = true;
                        }
                        e.preventDefault();
                        return false;
                    }
                }
                //edit: removed ";" from last "}" because of javascript error




                // verifier si je suis sur le bon url https://hsigcom.com pour eviter  les erreurs cors
                if (window.location.host === 'www.hsigcom.com' || window.location.host !== 'hsigcom.com') {
                    window.location.replace('https://hsigcom.com' + window.location.pathname);
                }

            }


        },


        // Fonction pour obtenir la consommation de toutes les ressources chargées
        getResourceConsumption() {
            const resources = performance.getEntriesByType('resource');
          /*  const networkData = resources.map(entry => ({
                name: entry.name,
                startTime: entry.startTime,
                duration: entry.duration,
                transferSize: entry.transferSize,
                encodedBodySize: entry.encodedBodySize,
                decodedBodySize: entry.decodedBodySize,
                initiatorType: entry.initiatorType
            }));*/
            let totalBytes = 0;
            const details = {};

           // console.log("networkData ****************** ",networkData)

            resources.forEach(resource => {
                // Utilisation de transferSize si disponible, sinon fallback sur encodedBodySize
                const resourceSize = resource.transferSize || resource.encodedBodySize || 0;
                totalBytes += resourceSize;

                // Catégorisation par type de ressource
                const resourceType = resource.initiatorType || 'autres';
                if (!details[resourceType]) {
                    details[resourceType] = 0;
                }
                details[resourceType] += resourceSize;
            });

            // Mise à jour de la consommation totale (en Mo)
            this.totalConsumption = (totalBytes / (1024 * 1024)).toFixed(2);

          //  console.log("this.totalConsumption ******** ",this.totalConsumption);

            // Mise à jour des détails par type de ressource (en Mo)
            this.resourceDetails = Object.keys(details).map(type => {
                return {
                    type,
                    size: (details[type] / (1024 * 1024)).toFixed(2)
                };
            });


           // console.log("this.resourceDetails ******** ",this.resourceDetails);



        },
        // Mise en place d'un écouteur pour détecter les changements de route
        setupRouteWatcher() {
            this.$router.beforeEach((to, from, next) => {
                this.getResourceConsumption(); // Exécuter le suivi des ressources à chaque changement de route
                next(); // Continuer la navigation
            });
        },






        handleResize() {
            this.screenSize = window.innerWidth;
            this.screenHeight = window.innerHeight;
        },


        startInterval() {
            try {
                this.intervalId = setInterval(() => {
                    // Code qui pourrait lancer une erreur
                   // console.log('Fonction exécutée toutes les 30 secondes');

                    this.$store.dispatch('loadevoluationdata')

                    // Simuler une erreur pour l'exemple
                    // throw new Error('Something went wrong');
                }, 600000); // 30 000 ms = 30 s // 600.000 ms= 10min
            } catch (error) {
                console.error('Erreur dans l\'intervalle:', error);
                this.stopInterval(); // Nettoyer l'intervalle en cas d'erreur
            }
        },
        stopInterval() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },




    },
}
</script>

<style>
#app {
 /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;*/
}
</style>
