import axios from "axios";
const ID_API_TOKEN = '__actk';
const USER_TOKEN = '__nme';
const IDTOKEN = 'cleurl';
const ROLETOKEN = '__rl';
const EXPIRESIN = 'expires_in';
const SOCIETE = 'societe';
const VALUEDATAPRICE = 'valuedataprice';
const CUSTOMLIBELLE = 'customlibelle';
const CUSTOMLIBELLEFRSCLT = 'customlibellefrsclt';




export const getCUSTOMLIBELLE = () => {
    return window.localStorage.getItem(CUSTOMLIBELLE)
};

export const getVALUEDATAPRICE = () => {
    return window.localStorage.getItem(VALUEDATAPRICE)
};
export const getCUSTOMLIBELLEFRSCLT = () => {
    return window.localStorage.getItem(CUSTOMLIBELLEFRSCLT)
};

export const getToken = () => {
    return window.localStorage.getItem(ID_API_TOKEN)
};

export const setToken = token => {
    window.localStorage.setItem(ID_API_TOKEN, token);
};

export const unsetToken = () => {
    window.localStorage.removeItem(ID_API_TOKEN);
};


export const getTokenUser = () => {
    return window.localStorage.getItem(USER_TOKEN)
};

export const setTokenUser = USER => {
    window.localStorage.setItem(USER_TOKEN, USER);
};

export const unsetTokenUser = () => {
    window.localStorage.removeItem(USER_TOKEN);
};


export const getTokenID = () => {

    return window.localStorage.getItem(IDTOKEN)
};

export const setTokenID = idtoken => {
    window.localStorage.setItem(IDTOKEN, idtoken);
};

export const unsetTokenID = () => {
    window.localStorage.removeItem(IDTOKEN);
};



export const getTokenRole = () => {
    return window.localStorage.getItem(ROLETOKEN)
};

export const setTokenRole = role => {
    window.localStorage.setItem(ROLETOKEN, role);
};

export const unsetTokenRole = () => {
    window.localStorage.removeItem(ROLETOKEN);
};



export const getTokenEXPIRESIN = () => {
    return window.localStorage.getItem(EXPIRESIN)
};

export const setTokenEXPIRESIN = expirein => {
    window.localStorage.setItem(EXPIRESIN, expirein);
};

export const setCUSTOMLIBELLE = value => {
    window.localStorage.setItem(CUSTOMLIBELLE, value);
};

export const setVALUEDATAPRICE = value => {
    window.localStorage.setItem(VALUEDATAPRICE, value);
};

export const setCUSTOMLIBELLEFRSCLT = value => {
    window.localStorage.setItem(CUSTOMLIBELLEFRSCLT, value);
};

export const unsetTokenEXPIRESIN = () => {
    window.localStorage.removeItem(EXPIRESIN);
};

export function setAuthorization(token) {

    axios.defaults.headers = {
            'Authorization': 'Bearer ' + token
    }

}


export const getTokenSociete = () => {
    return window.localStorage.getItem(SOCIETE)
};
export const setTokenSociete = societe => {
   //// console.log('eee',societe)
    window.localStorage.setItem(SOCIETE, societe);

};


export const unsetTokenSociete = () => {
    window.localStorage.removeItem(SOCIETE);
};

export const unsetCUSTOMLIBELLE = () => {
    window.localStorage.removeItem(CUSTOMLIBELLE);
};

export const unsetCUSTOMLIBELLEFRSCLT = () => {
    window.localStorage.removeItem(CUSTOMLIBELLEFRSCLT);
};







export default {
    setAuthorization,getToken, setToken, unsetToken,
    getTokenUser,setTokenUser,unsetTokenUser,
    getTokenID,setTokenID,unsetTokenID,
    getTokenEXPIRESIN,setTokenEXPIRESIN,unsetTokenEXPIRESIN,
    getTokenRole,setTokenRole,unsetTokenRole,
    getTokenSociete,setTokenSociete,unsetTokenSociete,
    getVALUEDATAPRICE,setVALUEDATAPRICE,
    getCUSTOMLIBELLEFRSCLT,setCUSTOMLIBELLEFRSCLT,
    setCUSTOMLIBELLE,getCUSTOMLIBELLE,
    unsetCUSTOMLIBELLE,unsetCUSTOMLIBELLEFRSCLT,



}
