<template>


    <div class="grid grid-cols-12 gap-x-6">

        <div @click="btnmodalhistoriquedocument" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">HISTORIQUE DES DOCUMENT</h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div @click="btnmodaletatdocstock" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                éTATS DU STOCK DES ARTICLES
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div @click="btnmodalrapportetatdestocksuruneperiode" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                éTAt du stock des articles sur une période
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div @click="btnmodalrapportmvtdesarticles" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des mouvements des articles
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>





    </div>


    <modalhistoriquedocument ref="modalhistoriquedocument"></modalhistoriquedocument>
    <modaletatdocstock ref="modaletatdocstock"></modaletatdocstock>
    <modalrapportmvtdesarticles ref="modalrapportmvtdesarticles"></modalrapportmvtdesarticles>
    <modalrapportetatdestocksuruneperiode ref="modalrapportetatdestocksuruneperiode"></modalrapportetatdestocksuruneperiode>



</template>

<script>
    import modalhistoriquedocument from "@/components/views/pages/stock/rapport/modal_historiquedocument";
    import modaletatdocstock from "@/components/views/pages/stock/rapport/modal_rapport_etatdustock";
    import modalrapportmvtdesarticles from "@/components/views/pages/stock/rapport/modal_rapport_mvtdesarticles";
    import modalrapportetatdestocksuruneperiode
        from "@/components/views/pages/stock/rapport/modal_rapport_etatdestocksuruneperiode";

    export default {

        /* eslint-disable */
        name: "stock",
        components: {
            modalhistoriquedocument,
            modaletatdocstock,
            modalrapportmvtdesarticles,
            modalrapportetatdestocksuruneperiode

        },
        watch: {

        },

        computed:{



        },
        data() {
            return {



            };
        },
        methods: {


            btnmodalrapportetatdestocksuruneperiode(){

                let titlemodal ="ETAT DU STOCK SUR UNE PERIODE"


                this.$refs.modalrapportetatdestocksuruneperiode.show(titlemodal);
            },

            btnmodalrapportmvtdesarticles(){

                let titlemodal ="MOUVEMENT DES ARTICLES"


                this.$refs.modalrapportmvtdesarticles.show(titlemodal);
            },


            btnmodaletatdocstock(){

                let titlemodal ="Etat du stock des articles"


                this.$refs.modaletatdocstock.show(titlemodal);
            },

            btnmodalhistoriquedocument(){

                let titlemodal ="Historique des documents de stock"


                this.$refs.modalhistoriquedocument.show(titlemodal);
            },



        },
        mounted() {

        }

    }
</script>

<style scoped>

</style>