<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>


                            </nav>
                        </div>

                        <div class="">

                            <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                <div v-html="messagesucces"></div>
                            </div>

                            <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                <div v-html="messageerror"></div>
                            </div>


                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div class="ti-custom-validation mt-5" >
                                    <div class="grid lg:grid-cols-1 gap-6">

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Profil utilisateur</label>
                                            <input v-if="!disabled"  v-model="Items.profil" type="text"
                                                   class="firstName my-auto ti-form-input  rounded-sm"
                                                   placeholder="profil utilisateur"   required>

                                            <input v-else disabled="disabled"  :value="Items.profil" type="text"
                                                   class="firstName my-auto ti-form-input  rounded-sm"
                                                   placeholder="profil utilisateur" >

                                           <span class="firstNameError text-red-500 text-xs hidden">error</span>
                                        </div>



                                        <div  class="space-y-2">
                                           <button v-if="!disabled" @click="saveItems" class="ti-btn ti-btn-primary-full">Valider</button>
                                        </div>


                                    </div>


                                    <div  class="clearfix"> </div>

                                    <hr>


                                    <div class="col-md-12">

                                        <div class="form-group">
                                            <div class="col-md-12 mt-2 mb-3">
                                                <label  >Accès pour la :</label>
                                                <select class="form-control" v-model="selectedaccess" :disabled="!disabled">
                                                    <option v-for="(line,index) of dataoptions" :value="line.value" :key="index">{{line.name}}</option>
                                                    <!--option value="#">Sélectionner un élément</option>
                                                    <option value="gestiondefinition">Définition</option>
                                                    <option value="gestiontableaudebord">Tableau de bord</option>
                                                    <option value="gestiondestock">Gestion des Stocks</option>
                                                    <option value="gestiondesconsommationinternes">Gestion des consommations internes</option>
                                                    <option value="gestiondeproduction">Gestion de la production</option>
                                                    <option value="gestioncommercial">Gestion commercial</option>
                                                    <option value="gestioncaisse">Gestion de la caisse</option>
                                                    <option value="gestioncomptabilite">Gestion de la comptabilité</option>
                                                    <option value="gestionbudgetaire">Gestion Budgetaire</option>
                                                    <option value="gestiondessuivieetrelance">Gestion suivi et relance</option>
                                                    <option value="gestionboutiqueenligne">Boutique en ligne</option>
                                                    <option value="gestiondesetatsspecifiques">Gestion des états spécifique</option>
                                                    <option value="gestionparametre">Gestion des paramétres système</option-->




                                                </select>
                                            </div>

                                        </div>


                                        <div class="table-responsive-md">

                                            <table class="table table-bordered w-full" v-if="disabled" >

                                                <thead>
                                                <tr>
                                                    <th scope="col">Page</th>
                                                    <th scope="col">Accès</th>
                                                    <th scope="col">Bouton</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <template v-if="listeaccesbyfiler.length">

                                                    <tr v-for="(datavalue,index) in listeaccesbyfiler" :key="index">
                                                        <td>{{datavalue.page}}</td>
                                                        <td style="width: 10%;">
                                                            <label class="switch">
                                                                <input v-model="datavalue.permission" type="checkbox" @click="toggleCheckbox">
                                                                <div class="slider round"></div>
                                                            </label>

                                                        </td>
                                                        <td style="width: 40%;">

                                                            <table class="table table-bordered w-full">
                                                                <tbody>
                                                                <template v-if="datavalue.btn.length">

                                                                    <tr v-for="(btn,j) in datavalue.btn" :key="j">
                                                                        <td>{{btn.intitule}}</td>
                                                                        <td style="width: 10%;">
                                                                            <label class="switch">
                                                                                <input v-model="btn.permission" type="checkbox" @click="toggleCheckbox">
                                                                                <div class="slider round"></div>
                                                                            </label>

                                                                        </td>

                                                                    </tr>

                                                                </template>

                                                                </tbody>
                                                            </table>





                                                        </td>
                                                    </tr>


                                                </template>

                                                </tbody>


                                                <tfoot>
                                                <tr>
                                                    <td colspan="3">
                                                        <center>
                                                            <button v-if="selectedaccess!=='#'" type="button" class="ti-btn ti-btn-success"
                                                                    @click="appliquermodificationacces">Appliquer les modifications</button>
                                                        </center>
                                                    </td>

                                                </tr>
                                                </tfoot>

                                            </table>

                                        </div>




                                    </div>












                                </div>
                            </div>

                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" @click="updateItems">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";
   // import allfunction from "@/allfunction";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{

            disabled:function () {




                if (this.resItems.profil==='' || this.resItems.profil===null || this.resItems.profil===undefined){
                          return false
                }
                else{

                    return true

                }


            },


            listeaccesbyfiler() {
                let mvalue = [];

                if (this.resItems.accessprofil!==null && this.resItems.accessprofil!==undefined){


                    if (this.selectedaccess!=='#'){


                        let t=this

                        for (let line of this.resItems.accessprofil )
                        {


                            if (line.access===t.selectedaccess){
                                mvalue.push(line)  ;
                            }

                        }



                    }

                }


                return mvalue;
            },





        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,

        },

        data() {
            return {
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                titlemodal:"",
                typedoc:"",
                typeaction:"add",
                alldata:[],
                dataoptions:[],
                dataniveaudacces:[],
                datanewniveaudacces:[],
                nbremg:false,
                Items: {},
                resItems: {},

                selectedaccess:'#'
            };
        },
        methods: {

            toggleCheckbox() {
                this.checkbox = !this.checkbox
                this.$emit('setCheckboxVal', this.checkbox)
            },



            async appliquermodificationacces(){
                this.$store.dispatch('lancerchargement',true)
                this.loading = true;
                const t = this;
                //  this.caisse.beartoken=this.$store.state.beartoken;
                let response =   axios  .post('/api/param665155ezf5ef198fz15f1ef6/gkfjgppk8_djskdj/'+this.$store.state.beartoken, this.caisse)
                let res= await response
                    .then(function (res){

                        if(res.data.success){
                            t.$store.dispatch('lancerchargement',false)
                            t.$store.dispatch('beartoken',res.data.beartoken)
                            toastr['success']( res.data.message,"<b>Modification</b>");
                            t.loading=false
                            t.$emit('closed');
                            t.isdisplay=false


                        }else{

                            t.$store.dispatch('beartoken',res.data.beartoken)
                            toastr['error']( res.data.message,"<b>Modification</b>");
                            t.$store.dispatch('lancerchargement',false)
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))


            },




            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,Items,alldata,dataoptions ){
                this.selectedaccess='#';
                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.dataoptions=dataoptions
                this.Items=Items
                this.messagesucces="";
                this.messageerror="";
                this.alldata=alldata


                if (typeaction==='update'){
                    this.resItems= {
                        accessprofil:this.Items.accessprofil,
                        profil:this.Items.profil


                    }

                }


                this.isdisplay=true;

            },

              async  saveItems(){

                    this.$store.dispatch('lancerchargement',true)

                    if (this.Items.profil===""

                    ){

                        toastr['error']( 'Veuillez saisir le nom du profil',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return

                    }

                  //m//:fftoken//:keyuser/saveprofiluser/:ftoken
                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/onoeofienfneioefezp849418119815191981dsdsc1dscds9/'+randomString(200)+'/profil/'+this.$store.state.appsociete+'/saveprofiluser/'+randomString(200);
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";

                                 t.resItems=res.data.m

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");

                            }
                           else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },


              async  updateItems(){

                    this.$store.dispatch('lancerchargement',true)


                  if (this.Items.codebanque==="" || this.Items.nombanque==="" ||
                      this.Items.numerodecompte==="" ||
                      this.Items.numerodecompte===0

                  ){

                      toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                      this.$store.dispatch('lancerchargement',false)
                      return

                  }

                  this.Items.niveaudacces=this.dataniveaudacces;
                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/egl7admincaissee771714dfe/banque/'+this.$store.state.appsociete+'/89541updatecaisse519151911515/'+randomString(200);
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })


                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");


                                t.close()

                            }
                            else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },


        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>