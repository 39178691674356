<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <router-link to="/caisse" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">&nbsp;&nbsp;</span>
            </router-link>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">


                    <template v-if="$store.state.ouverturedelacaisse===1">

                        <li >
                            <a @click="btnbeneficiaire" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                               aria-current="page">Bénéficiaire</a>
                        </li>
                        <li >
                            <a  href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                               aria-current="page">Rapport de caisse</a>
                        </li>

                        <li >
                            <a @click="btnmoadalfermercaisse"  href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                               aria-current="page">Fermer la caisse</a>
                        </li>




                    </template>

                    <template v-else>


                        <li >
                            <a @click="btnmoadalouverturecaisse" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                                aria-current="page">Ouvrir la caisse</a>
                        </li>


                    </template>


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                               aria-expanded="false">
                                Rapports / Editions<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li @click="btnmodalpointdelacaisse">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Point de la caisse
                                    </a>
                                </li>


                                <li @click="btnmodaldepotclientautre('depot')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des dépôt client effectuées au cours d'une période
                                    </a>
                                </li>


                                <li @click="btnmodaldepotclientautre('reference')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des retours de fond de caisse effectuées au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodaldepotclientautre('depense')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des dépenses effectuées au cours d'une période
                                    </a>
                                </li>


                                <li @click="btnmodallistedesfacturactionsalacaisse('FVC')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des facturations effectuées au cours d'une période
                                    </a>
                                </li>


                                <li @click="btnmodalrecapitulatifca">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Récapitulatif du chiffre d'affaire au cours d'une période
                                    </a>
                                </li>


                                <li @click="btnmodalapprocaissebanque('d1')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des approvisionnements par la banque / par la caisse au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodalreglementfacture">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des factures par réglements au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodalreglementclientfournisseur('fournisseur')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des réglements fournisseur au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodalreglementclientfournisseur('client')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des réglements client au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodalreglementclientfournisseur('vfournisseur')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des avances fournisseur au cours d'une période
                                    </a>
                                </li>
                                <li @click="btnmodalreglementclientfournisseur('vclient')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des avances client au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodaloperationbanquaire('bop1')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des opérations bancaire au cours d'une période
                                    </a>
                                </li>

                                <li  @click="btnmodalapprocaissebanque('d2')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Listes des dépôts de fond de banque / fond de caisse au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodaloperationbanquaire('Rop1')">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des bordereaux de banque au cours d'une période
                                    </a>
                                </li>

                                <li @click="btnmodalallreglementbcd">
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des réglements BCD interne à la caisse au cours d'une période
                                    </a>
                                </li>

                                <li>
                                    <a class="ti-dropdown-item" href="javascript:void(0);">
                                        Etat des facturations de vente détaillée au cours d'une période
                                    </a>
                                </li>



                            </ul>
                        </div>

                    </li>







                </ul>
            </div>
        </div>
    </nav>

    <moadalouverturecaisse ref="moadalouverturecaisse"></moadalouverturecaisse>
    <modalpointdelacaisse ref="modalpointdelacaisse"></modalpointdelacaisse>
    <modaldepotclientautre ref="modaldepotclientautre"></modaldepotclientautre>
    <modallistedesfacturactionsalacaisse ref="modallistedesfacturactionsalacaisse"></modallistedesfacturactionsalacaisse>
    <modalapprocaissebanque ref="modalapprocaissebanque"></modalapprocaissebanque>
    <modalreglementfacture ref="modalreglementfacture"></modalreglementfacture>
    <modalreglementclientfournisseur ref="modalreglementclientfournisseur"></modalreglementclientfournisseur>
    <modaloperationbanquaire ref="modaloperationbanquaire"></modaloperationbanquaire>
    <modalallreglementbcd ref="modalallreglementbcd"></modalallreglementbcd>
    <modalbeneficiaire ref="modalbeneficiaire"></modalbeneficiaire>
    <modalrecapitulatifca ref="modalrecapitulatifca"></modalrecapitulatifca>

    <fermerturecaisseouinon ref="fermerturecaisseouinon" ></fermerturecaisseouinon>


</template>


<script>
    import moadalouverturecaisse from "@/components/views/pages/caisse/moadal_ouverturecaisse";
    import modalpointdelacaisse from "@/components/views/pages/caisse/rapport/modal_pointdelacaisse";
    import modalrecapitulatifca from "@/components/views/pages/caisse/rapport/modal_recapitulatifCA";
    import modaldepotclientautre from "@/components/views/pages/caisse/rapport/modal_depotclientautre";
    import modallistedesfacturactionsalacaisse from "@/components/views/pages/caisse/rapport/modal_listedesfacturactionsalacaisse";
    import modalapprocaissebanque from "@/components/views/pages/caisse/rapport/modal_approcaissebanque";
    import modalreglementfacture from "@/components/views/pages/caisse/rapport/modal_reglementfacture";
    import modalreglementclientfournisseur from "@/components/views/pages/caisse/rapport/modal_reglementclientfournisseur";
    import modaloperationbanquaire from "@/components/views/pages/caisse/rapport/modal_operationbanquaire";
    import modalallreglementbcd from "@/components/views/pages/caisse/rapport/modal_allreglementbcd";
    import modalbeneficiaire from "@/components/views/pages/caisse/beneficiaire/index";
    import fermerturecaisseouinon from "@/components/views/pages/caisse/fermerturecaisseouinon";


    export default {
        /* eslint-disable */
        name: "mymenu",
        components:{
            fermerturecaisseouinon,
            moadalouverturecaisse,
            modalpointdelacaisse,
            modaldepotclientautre,
            modallistedesfacturactionsalacaisse,
            modalapprocaissebanque,
            modalreglementfacture,
            modalreglementclientfournisseur,
            modaloperationbanquaire,
            modalallreglementbcd,
            modalbeneficiaire,
            modalrecapitulatifca,


        },
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        methods: {
            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },
            toggleDropdown() {


                this.isDropdownOpen = !this.isDropdownOpen;

            },



            btnmoadalfermercaisse(){


                this.$refs.fermerturecaisseouinon.show();

            },


            btnmodalrecapitulatifca(){

                let titlemodal="",numpidcaisse="",datedouverture=""

                // if (value==='FVC'){}

                titlemodal="RECAPITULATIF DU CHIFFRE D'AFFAIRE"


                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                        datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }


                this.$refs.modalrecapitulatifca.show(titlemodal,numpidcaisse,datedouverture);

            },




            btnbeneficiaire(){

                let titlemodal="Liste des bénéficiaires"

                this.$refs.modalbeneficiaire.show(titlemodal);
            },

            btnmoadalouverturecaisse(){

                let titlemodal="Ouverture de la caisse à la date du "+this.$store.state.appserverdate

                this.$refs.moadalouverturecaisse.show(titlemodal);
            },

            btnmodalpointdelacaisse(){

                let titlemodal="POINT DE LA CAISSE",numpidcaisse="",datedouverture=""



                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modalpointdelacaisse.show(numpidcaisse,datedouverture);
            },

            btnmodaldepotclientautre(value){

                let titlemodal="",numpidcaisse="",datedouverture=""

                if (value==='depot'){titlemodal="LISTE DES DÉPÔTS CLIENTS"}
                if (value==='reference'){titlemodal="LISTE DES RETOURS DE FONDS DE CAISSE"}
                if (value==='depense'){titlemodal="LISTE DES DÉPENSES"}



                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modaldepotclientautre.show(titlemodal,numpidcaisse,datedouverture,value);
            },

            btnmodallistedesfacturactionsalacaisse(value){

                let titlemodal="",numpidcaisse="",datedouverture=""

                if (value==='FVC'){titlemodal="LISTE DES FACTURATIONS AU COMPTOIRE"}



                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modallistedesfacturactionsalacaisse.show(titlemodal,numpidcaisse,datedouverture,value);
            },

            btnmodalapprocaissebanque(value){

                let titlemodal="",numpidcaisse="",datedouverture=""


                    if (value==="d1"){
                        titlemodal="LISTE DES APPROVISIONNEMENTS"
                    }
                    else{
                        titlemodal="LISTE DES DEPÔTS"
                    }




                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modalapprocaissebanque.show(titlemodal,numpidcaisse,datedouverture,value);
            },

            btnmodalreglementfacture(){

                let titlemodal="",numpidcaisse="",datedouverture=""


                titlemodal="LISTE DES REGLEMENTS FACTURE"


                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modalreglementfacture.show(titlemodal,numpidcaisse,datedouverture);
            },

            btnmodalreglementclientfournisseur(value){

                let titlemodal="",numpidcaisse="",datedouverture=""


                if (value==="fournisseur"){  titlemodal="LISTE DES REGLEMENTS FOURNISSEURS"}
                if (value==="client"){  titlemodal="LISTE DES REGLEMENTS CLIENTS"}
                if (value==="vfournisseur"){  titlemodal="LISTE DES AVANCES FOURNISSEURS"}
                if (value==="vclient"){  titlemodal="LISTE DES AVANCES CLIENTS"}





                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modalreglementclientfournisseur.show(titlemodal,numpidcaisse,datedouverture,value);
            },

            btnmodaloperationbanquaire(value){

                let titlemodal="",numpidcaisse="",datedouverture=""


                /*if (value==="fournisseur"){  titlemodal="LISTE DES REGLEMENTS FOURNISSEURS"}
                if (value==="client"){  titlemodal="LISTE DES REGLEMENTS CLIENTS"}
                if (value==="vfournisseur"){  titlemodal="LISTE DES AVANCES FOURNISSEURS"}
                if (value==="vclient"){  titlemodal="LISTE DES AVANCES CLIENTS"}*/



                if (value==="bop1"){ titlemodal="LISTE DES OPERATIONS BANCAIRES"}
                if (value==="Rop1"){ titlemodal="LISTE DES BORDEREAUX DE BANQUE"}



                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modaloperationbanquaire.show(titlemodal,numpidcaisse,datedouverture,value);
            },

            btnmodalallreglementbcd(){

                let titlemodal="LISTE DES REGLEMENTS BCD INTERNE",numpidcaisse="",datedouverture=""


                /*if (value==="bop1"){ titlemodal="LISTE DES OPERATIONS BANCAIRES"}
                if (value==="Rop1"){ titlemodal="LISTE DES BORDEREAUX DE BANQUE"}*/



                if (Object.keys(this.$store.state.caisseinfo).length>0){

                    numpidcaisse=this.$store.state.caisseinfo.cpid,
                    datedouverture=this.$store.state.caisseinfo.dateouverture

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }

                this.$refs.modalallreglementbcd.show(titlemodal,numpidcaisse,datedouverture);
            },




        }
    };
</script>

<style scoped>

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }


</style>