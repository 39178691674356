<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">&nbsp;&nbsp;</span>
            </a>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Tiers<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li v-if="$store.state.accescomponent.cccommercialclients===true">
                                    <a @click="btnmodalclt('clt')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Clients
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialfournisseurs===true">
                                    <a @click="btnmodalclt('frs')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Fournisseurs
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialcollaborateur===true">
                                    <a @click="btnmodalclt('col')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Collaborateur
                                    </a>
                                </li>


                            </ul>
                        </div>

                    </li>


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Achat<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li v-if="$store.state.accescomponent.cccommercialachatdemandeachat===true">
                                    <a @click="btnmodaldefaultachatvente('DAF','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Demande d'achat
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialachatbondecommande===true">
                                    <a @click="btnmodaldefaultachatvente('BCF','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Bon de commande
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialachatbondereception===true">
                                    <a @click="btnmodaldefaultachatvente('BRF','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Bon de réception
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialachatfacture===true">
                                    <a @click="btnmodaldefaultachatvente('FR','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Facture
                                    </a>
                                </li>

                                <li v-if="$store.state.accescomponent.cccommercialventefacture===true">
                                    <a @click="btnmodaldefaultachatvente('FRG','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Consolidation des documents pour la facturation
                                    </a>
                                </li>

                                <li v-if="$store.state.accescomponent.cccommercialachatretoursurachat===true">
                                    <a @click="btnmodaldefaultachatvente('RF','achat')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Retours sur achats
                                    </a>
                                </li>


                            </ul>
                        </div>

                    </li>


                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Vente<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li v-if="$store.state.accescomponent.cccommercialventedevis===true">
                                    <a @click="btnmodaldefaultachatvente('PF','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Devis
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialventebondecommande===true">
                                    <a @click="btnmodaldefaultachatvente('BC','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Bon de commande
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialventebondepreparationlivraison===true">
                                    <a @click="btnmodaldefaultachatvente('BPL','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Bon de préparation de livraison
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialventebondelivraison===true">
                                    <a @click="btnmodaldefaultachatvente('BL','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Bon de livraison
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialventefacture===true">
                                    <a @click="btnmodaldefaultachatvente('F','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Facture
                                    </a>
                                </li>

                                <li v-if="$store.state.accescomponent.cccommercialventefacture===true">
                                    <a @click="btnmodaldefaultachatvente('FG','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Consolidation des documents pour la facturation
                                    </a>
                                </li>

                                <li v-if="$store.state.accescomponent.cccommercialventeretoursurvente===true">
                                    <a @click="btnmodaldefaultachatvente('BR','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                       Retours sur vente
                                    </a>
                                </li>

                                <li v-if="$store.state.accescomponent.cccommercialachatdemandeachat===true">
                                    <a @click="btnmodaldefaultachatvente('FR','vente')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Facture d'avoir
                                    </a>
                                </li>


                                <li v-if="$store.state.accescomponent.cccommercialachatdemandeachat===true">
                                    <a  class="ti-dropdown-item" href="javascript:void(0);">
                                        Facture d'avoir financière ( <span style="color: red">Bientôt disponible</span>)
                                    </a>
                                </li>


                            </ul>
                        </div>

                    </li>



                    <li>
                        <div class="hs-dropdown ti-dropdown">
                            <a href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                               Rapports<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a @click="btnmodalhistoriquedocument" class="ti-dropdown-item" href="javascript:void(0);">
                                        Historique des documents commerciaux
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalpointarticleparbon" class="ti-dropdown-item" href="javascript:void(0);">
                                        Rapports des articles par bon
                                    </a>
                                </li>


                                <li>
                                    <a @click="btnmodalrapportsurvente" class="ti-dropdown-item" href="javascript:void(0);">
                                        Rapports sur ventes
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalrapportsurachat" class="ti-dropdown-item" href="javascript:void(0);">
                                        Rapports sur achats
                                    </a>
                                </li>



                            </ul>
                        </div>

                    </li>



                </ul>
            </div>
        </div>
    </nav>


    <modalfrsclt ref="modalfrsclt"></modalfrsclt>
    <modaldefaultachatvente ref="modaldefaultachatvente"></modaldefaultachatvente>
    <modalhistoriquedocument ref="modalhistoriquedocument"></modalhistoriquedocument>
    <modalrapportsurvente ref="modalrapportsurvente"></modalrapportsurvente>
    <modalrapportsurachat ref="modalrapportsurachat"></modalrapportsurachat>
    <modalpointarticleparbon ref="modalpointarticleparbon"></modalpointarticleparbon>

</template>


<script>
    import modalfrsclt from "@/components/views/pages/gestioncommerciale/frscltcolb/index";
    import modaldefaultachatvente from "@/components/views/pages/gestioncommerciale/achatvente/default/index";
    import modalhistoriquedocument from "@/components/views/pages/gestioncommerciale/rapport/modal_historiquedocument";
    import modalrapportsurvente from "@/components/views/pages/gestioncommerciale/rapport/modal_rapportsurvente";
    import modalrapportsurachat from "@/components/views/pages/gestioncommerciale/rapport/modal_rapportsurachat";

    import modalpointarticleparbon from "@/components/views/pages/comptabilite/editions/modal_point_article_parbon";


    export default {
        /* eslint-disable */
        name: "mymenu",
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        components: {
            modalfrsclt,

            modaldefaultachatvente,
            modalpointarticleparbon,
            modalhistoriquedocument,
            modalrapportsurvente,
            modalrapportsurachat,
        },
        methods: {

            btnmodalpointarticleparbon() {

                this.$refs.modalpointarticleparbon.show();

            },

            btnmodalrapportsurvente(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalrapportsurvente.show(titlemodal);
            },

            btnmodalrapportsurachat(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalrapportsurachat.show(titlemodal);
            },

            btnmodalhistoriquedocument(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalhistoriquedocument.show(titlemodal);
            },




            btnmodalclt(value){

                let titlemodal


                if (value==='clt'){
                    titlemodal="Liste des clients"
                }

                if (value==='frs'){
                    titlemodal="Liste des fournisseurs"
                }

                if (value==='col'){
                    titlemodal="Liste des collaborateurs"
                }


                this.$refs.modalfrsclt.show(titlemodal,value);
            },




            btnmodaldefaultachatvente(value,typededocument){

                let titlemodal


                if (value==='DAF'){
                    titlemodal="Liste des demandes d'achat"
                }

                if (value==='BCF'){
                    titlemodal="Liste des bons de commande fournisseur"
                }

                if (value==='BRF'){
                    titlemodal="Liste des bons de réception fournisseur"
                }

                if (value==='FR'){
                    titlemodal="Liste des factures d'avoir client"

                                     }

                if (value==='FRG'){
                    titlemodal="Liste des factures consolidées (fournisseur)"
                }

                if (value==='RF'){
                    titlemodal="Liste des bons de retour fournisseur"
                }

                if (value==='PF'){
                    titlemodal="Liste des devis"
                }

                if (value==='BC'){
                    titlemodal="Liste des bon de commande client"
                }

                if (value==='BPL'){
                    titlemodal="Liste des bons de préparation de livraison"
                }

                if (value==='BL'){
                    titlemodal="Liste des bons de livraisson clients"
                }

                if (value==='F'){
                    titlemodal="Liste des factures clients"
                }

                if (value==='FG'){
                    titlemodal="Liste des factures consolidées (clients)"
                }

                if (value==='BR'){
                    titlemodal="Liste des bons de retour clients"
                }

                if (value==='FA'){
                    titlemodal="Liste des factures d'avoir clients"
                }

                if (value==='FRF'){
                    mname="Liste des factures d'avoir FRS"
                }




                this.$refs.modaldefaultachatvente.show(titlemodal,value,typededocument);
            },









            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },
            toggleDropdown() {
                console.log("1122")

                this.isDropdownOpen = !this.isDropdownOpen;

                console.log("1122 this.isDropdownOpen ",this.isDropdownOpen)
            }
        }
    };
</script>

<style scoped>

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }


</style>