<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">

                            <div class="grid grid-cols-12  gap-x-6">

                                <div class="xl:col-span-12 mt-3  col-span-12">
                                    <div  style="    text-align: center;font-size: 14px;padding: 14px;
    font-weight: bold;color: black;" class="alert alert-info">
                                      CA PAR ARTICLES / SERVICES SUR UNE PERIODE
                                    </div>

                                </div>

                                <div class="xl:col-span-4 mt-3  col-span-12">
                                    <input @change="load" v-model="datedebut" type="date" class="form-control" >
                                </div>
                                <div class="xl:col-span-4 mt-3  col-span-12">
                                    <input @change="load" v-model="datefin" type="date" class="form-control" >
                                </div>

                                <div class="xl:col-span-4 mt-3  col-span-12">

                                    <select class="form-control"   @change="load()" v-model="selectedclient">
                                        <option :value="null">Séléctionner un produit</option>
                                        <option value='all'>Tout les Articles / services</option>
                                        <option v-for="(client,index) in dataclient" :value="client" :key="index">{{client.libelle}}</option>

                                    </select>

                                </div>

                                <div class="xl:col-span-12 mt-3  col-span-12">

                                    <select class="form-control"  v-model="tableview">
                                        <option value='1'>CA CUMULLES PAR ARTICLES / SERVICES</option>
                                        <option value='2'>CA DÉTAILLÉS PAR ARTICLES / SERVICES</option>

                                    </select>

                                </div>



                            </div>

                            <div class="w-full flex grid-cols-2 mt-4">

                                <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">

                                    <div class="grid lg:grid-cols-2 gap-6">

                                        <div class="space-y-2">

                                            <button @click="ExportExcel('xlsx',titlemodal)" class="ti-btn ti-btn-success-full w-full"><i class="ri-file-excel-fill"></i> Exporter en excel</button>

                                        </div>

                                        <div class="space-y-2">
                                            <button  @click="apercuavantimpression(titlemodal,'exportable_table')"  class="ti-btn ti-btn-primary-full w-full"><i class="ri-printer-fill"></i> Aperçu avant impression</button>

                                        </div>





                                    </div>


                                </div>
                            </div>

                        </div>



                            <div class="table-responsive">

                                <div class="flex flex-col h-screen tableFixHeadindexcomponnent">
                                    <div class="flex-grow overflow-auto">



                                   <table ref="exportable_table" v-if="tableview===1 || tableview==='1'" class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">

                                       <template v-if="tableview1.length">

                                           <template v-for="(linedatamonth,indexline) in tableview1" :key="indexline">

                                               <tr>
                                                   <td colspan="20"><span><strong>Mois : {{linedatamonth.yearmonth}} </strong></span></td>
                                               </tr>
                                               <tr>


                                                   <td colspan="20">


                                                       <template v-for="(linedatanormalisation,indexlinenormalisation) in linedatamonth.datas" :key="indexlinenormalisation">

                                                           <table class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline">
                                                               <tbody>
                                                               <tr><td colspan="20"> <template v-if="linedatanormalisation.etatnormalisation==='1' ||
                                                                              linedatanormalisation.etatnormalisation===1"><h4><strong>Facture Normalisée</strong></h4></template>
                                                                   <template v-else>
                                                                       <span><strong><span style="color: red">Facture non Normalisée</span></strong></span>
                                                                   </template>

                                                               </td></tr>
                                                               <tr><td colspan="20">


                                                                   <table class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline">
                                                                       <thead>
                                                                       <tr>
                                                                           <th>N°</th>
                                                                           <th>Article / service</th>
                                                                           <th>Etat normalisation</th>
                                                                           <th>Quantité</th>
                                                                           <th>Montant HT (FCFA)  </th>
                                                                           <th>Montant TVA (FCFA)  </th>
                                                                           <th>Montant  AIB (FCFA) </th>
                                                                           <th>Montant  TTC (FCFA) </th>

                                                                       </tr>
                                                                       </thead>


                                                                       <tbody>

                                                                       <tr style="font-weight: 600;/*background: khaki;*/"
                                                                           v-for="(datavalue,index) in linedatanormalisation.datasnormalisation" :key="index">

                                                                           <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                                           <td  data-label="Article / service : "   >{{ datavalue.article }}</td>
                                                                           <td  data-label="Etat normalisation : "   >
                                                                               <template v-if="datavalue.normalisation==='1' || datavalue.normalisation===1">Facture Normalisée</template>
                                                                               <template v-else>Facture non Normalisée</template>
                                                                           </td>
                                                                           <td  data-label="Quantitité  : "   >{{ datavalue.qte }}</td>
                                                                           <td  data-label="Montant HT (FCFA)   : "   >{{ FormatNumber(datavalue.montantht) }}</td>
                                                                           <td  data-label="Montant TVA (FCFA)  : "   >{{ FormatNumber(datavalue.montanttva) }}</td>
                                                                           <td  data-label="Montant AIB (FCFA)  : "   >{{ FormatNumber(datavalue.montantaib) }}</td>
                                                                           <td  data-label="Montant TTC (FCFA)  : "   >{{ FormatNumber(datavalue.montantttc) }}</td>


                                                                       </tr>






                                                                       </tbody>

                                                                       <tfoot>
                                                                       <tr style="font-weight: 600;/*background: khaki;*/">
                                                                           <td colspan="3" data-label="Total"><span>Total</span></td>
                                                                           <td data-label="Quantité"><span>{{(linedatanormalisation.linenormalisationqtite)}}</span></td>
                                                                           <td data-label="Montant total HT"><span>{{FormatNumber(linedatanormalisation.linenormalisationmontantht)}}</span></td>
                                                                           <td data-label="Montant total TVA"><span>{{FormatNumber(linedatanormalisation.linenormalisationmontanttva)}}</span></td>
                                                                           <td data-label="Montant total AIB"><span>{{FormatNumber(linedatanormalisation.linenormalisationmontantaib)}}</span></td>
                                                                           <td data-label="Montant total TTC"><span>{{FormatNumber(linedatanormalisation.linenormalisationmontantttc)}}</span></td>
                                                                       </tr>
                                                                       </tfoot>

                                                                   </table>


                                                               </td></tr>
                                                               </tbody>
                                                           </table>

                                                       </template>




                                                   </td>


                                               </tr>



                                           </template>


                                       </template>



                                       <template v-else>
                                           <tr >
                                               <td colspan="20"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                           </tr>

                                       </template>



                                   </table>


                                   <table ref="exportable_table" v-if="tableview===2 || tableview==='2'" class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>

                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer par</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Reference</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° Bon </th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Article / service</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Etat normalisation</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Taux AIB</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Quantitité</th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant HT (FCFA)  </th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant TVA (FCFA)  </th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant  AIB (FCFA) </th>
                                                <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant  TTC (FCFA) </th>

                                            </tr>
                                            </thead>
                                            <tbody>


                                                <template v-if="tableview2.length">

                                                    <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" style="font-weight: 600;/*background: khaki;*/"
                                                        v-for="(datavalue,index) in tableview2[0].datas" :key="index">


                                                        <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                        <td  data-label="Créer par : "   >{{ datavalue.creerpar }}</td>
                                                        <td  data-label="Date : "   >{{ datavalue.datedoc }}</td>
                                                        <td  data-label="Réference : "   >{{ datavalue.reference }}</td>
                                                        <td  data-label="N° Bon : "   >{{ datavalue.otherref }}</td>
                                                        <td  data-label="Article / service : "   >{{ datavalue.article }}</td>

                                                        <td  data-label="Etat normalisation : "   >

                                                            <template v-if="datavalue.normalisation==='1' || datavalue.normalisation===1">Facture Normalisée</template>
                                                            <template v-else><span style="color: red">Facture non Normalisée</span></template>

                                                        </td>
                                                        <td  data-label="Taux AIB  : "   >{{ datavalue.tauxaib }}</td>
                                                        <td  data-label="Quantitité  : "   >{{ datavalue.qte }}</td>
                                                        <td  data-label="Montant HT  : "   >{{ FormatNumber(datavalue.montantht) }}</td>
                                                        <td  data-label="Montant TVA : "   >{{ FormatNumber(datavalue.montanttva) }}</td>
                                                        <td  data-label="Montant AIB : "   >{{ FormatNumber(datavalue.montantaib) }}</td>
                                                        <td  data-label="Montant TTC : "   >{{ FormatNumber(datavalue.montantttc) }}</td>



                                                    </tr>

                                                </template>

                                                <template v-else>
                                                    <tr >
                                                        <td class="border-t hover:bg-gray-200 dark:hover:bg-light" colspan="20"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>


                                            </tbody>


                                       <template v-if="tableview2.length">
                                           <tfoot>
                                           <tr style="font-weight: 600;/*background: khaki;*/">
                                               <td colspan="9" data-label="Total"><span>Total</span></td>
                                               <td data-label="Montant total HT"><span>{{FormatNumber(tableview2[0].totalmontantht)}}</span></td>
                                               <td data-label="Montant total TVA"><span>{{FormatNumber(tableview2[0].totalmontanttva)}}</span></td>
                                               <td data-label="Montant total AIB"><span>{{FormatNumber(tableview2[0].totalmontantaib)}}</span></td>
                                               <td data-label="Montant TTC"><span>{{FormatNumber(tableview2[0].totalmontantttc)}}</span></td>
                                           </tr>
                                           </tfoot>

                                       </template>


                                        </table>

                                    </div>
                                </div>

                            </div>



                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <modalprintdefault ref="modalprintdefault"></modalprintdefault>



</template>

<script>


    import modalprintdefault from "@/components/views/pages/modal_printdefault";

    const axios =require('axios');


    import {currentDateTime, ExportExcel, FormatNumber, groupByKey, randomString, triecroissant} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },

            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                let t=this


                t.montanttotalht=0
                t.montanttotaltva=0
                t.montanttotalaib=0
                t.montanttotalremise=0
                t.montanttotal=0
                t.montanttotalrestant=0
                    mvalue.forEach(function (post) {
                    t.montanttotalht=parseInt(t.montanttotalht)+parseInt(post.montantht)
                    t.montanttotaltva=parseInt(t.montanttotaltva)+parseInt(post.montanttva)
                    t.montanttotalaib=parseInt(t.montanttotalaib)+parseInt(post.montantaib)
                    t.montanttotalremise=parseInt(t.montanttotalremise)+parseInt(post.montantremise)
                    t.montanttotal=parseInt(t.montanttotal)+parseInt(post.debit)
                    t.montanttotalrestant=parseInt(t.montanttotalrestant)+parseInt(post.credit)
                })


                return mvalue ;
                //return this.paginate(mvalue) ;
            },
        },
        components: {
            modalprintdefault

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                mmontanttotal:0,

                montanttotalremise:0,
                montanttotalht:0,
                montanttotaltva:0,
                montanttotalaib:0,
                montanttotal:0,
                montanttotalrestant:0,

                dmontanttotalht:0,
                dmontanttotaltva:0,
                dmontanttotalaib:0,
                dmontanttotal:0,

                datedebut:this.$store.state.appserverdate,
                datefin:this.$store.state.appserverdate,
                dataclient:[],
                datatable:[],
                tableview1:[],
                tableview2:[],
                tableview:'1',

                selectedclient:null,



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {


            apercuavantimpression(valuetitre,tableref){
                let $tableref=this.$refs[tableref];


                let tabvalue={
                    titres:valuetitre,
                    numdoc:'',
                    date:currentDateTime(),
                    datedebut:this.datedebut,
                    datefin:this.datefin,
                    total1:0,
                    total2:0,
                    total3:0,
                    total4:0,
                    tableref:$tableref.toString(),
                }

                this.$refs.modalprintdefault.show(tabvalue,$tableref);



            },



            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async load(){

                if (this.selectedclient===null){

                    return

                }

                if (this.datedebut===null || this.datedebut==="" ||
                    this.datefin===null || this.datefin==="" ){

                    return

                }

                let pidcltfrs

                if (this.selectedclient==='all'){

                    pidcltfrs='all'
                }else{
                    pidcltfrs=this.selectedclient.keyline
                }



                this.$store.dispatch('lancerchargement',true)

                const t = this;

                let url=process.env.VUE_APP_API_URL+'/compta/edition/caitems/'+this.datedebut+'/'+this.datefin+'/'+pidcltfrs+'/'+this.$store.state.appsociete+'/'+randomString();

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            let $groupebyyearmonth=groupByKey(res.data.datas,'yearandmonth');
                            var $datares=[];
                            var $datares2=[];


                            for ( let [$index, $lineyearmonth]   of Object.entries($groupebyyearmonth)){

                                let datavalues=[];


                                let $groupebynormalisation=groupByKey($lineyearmonth,'normalisation');
                                for ( let [$indexnormalisation, $linenormalisation]   of Object.entries($groupebynormalisation)){ //grouper les facture suivants letat de normalisation

                                    let $datanormalisation=[],
                                        $linenormalisationmontantttc=0,
                                        $linenormalisationmontantht=0,
                                        $linenormalisationmontanttva=0,
                                        $linenormalisationmontantaib=0,
                                        $linenormalisationqtite=0;


                                    let $groupebyarticles=groupByKey($linenormalisation,'keyarline');
                                    for ( let [$indexarticle, $linearticles]   of Object.entries($groupebyarticles)){ // grouper par article

                                        let datavaluearticles=[];
                                        let $namearticles="",
                                            $tauxaib=0,
                                            $montantttc=0,
                                            $montantht=0,
                                            $montanttva=0,
                                            $qte=0,
                                            $montantaib=0;


                                        for(let $mlinearticle of $linearticles){

                                            $namearticles=$mlinearticle.article;
                                            $qte=parseFloat($mlinearticle.qtite)+parseFloat($qte);
                                            $tauxaib=parseFloat($mlinearticle.tauxaib);

                                            $montantht=parseFloat($mlinearticle.mtntht)+parseFloat($montantht);

                                            let tauxtva=parseFloat($mlinearticle.tauxtva);
                                            let mtva=parseFloat($mlinearticle.mtntht)*tauxtva

                                            let mmontantaib=0

                                            if ($tauxaib>0){
                                                mmontantaib=parseFloat($mlinearticle.mtntht)*($tauxaib/100)
                                            }

                                            let mmontantttc=parseFloat($mlinearticle.mtntht)+mtva+mmontantaib;

                                            $montanttva= parseFloat(mtva)+parseFloat($montanttva);
                                            $montantttc=parseFloat(mmontantttc)+parseFloat($montantttc);
                                            $montantaib=parseFloat(mmontantaib)+parseFloat($montantaib);


                                        }



                                        $linenormalisationmontantttc=parseFloat($linenormalisationmontantttc)+parseFloat($montantttc)
                                        $linenormalisationmontantht=parseFloat($linenormalisationmontantht)+parseFloat($montantht)
                                        $linenormalisationmontanttva=parseFloat($linenormalisationmontanttva)+parseFloat($montanttva)
                                        $linenormalisationmontantaib=parseFloat($linenormalisationmontantaib)+parseFloat($montantaib)
                                        $linenormalisationqtite=parseFloat($linenormalisationqtite)+parseFloat($qte)

                                        $datanormalisation.push({
                                            normalisation:$linearticles[0].normalisation,
                                            article:$namearticles,
                                            tauxaib:$tauxaib,
                                            montantht:$montantht,
                                            montantttc:$montantttc,
                                            montanttva:$montanttva,
                                            montantaib:$montantaib,
                                            qte:$qte,
                                            dataarticles:$linearticles
                                        })

                                        //  console.log("datavalues----------------  11111111111111111   ",datavalues)

                                    }



                                    datavalues.push({
                                        etatnormalisation:$linenormalisation[0].normalisation,
                                        datasnormalisation:$datanormalisation,
                                        linenormalisationmontantttc:$linenormalisationmontantttc,
                                        linenormalisationmontantht:$linenormalisationmontantht,
                                        linenormalisationmontanttva:$linenormalisationmontanttva,
                                        linenormalisationmontantaib:$linenormalisationmontantaib,
                                        linenormalisationqtite:$linenormalisationqtite,

                                    })

                                }

                                $datares.push({
                                    yearmonth:$index,
                                    datas:datavalues
                                })


                            }


                            t.tableview1=$datares

                            $datares2=triecroissant(res.data.datas,'article');


                            t.tableview2=[];
                            let $newarraytableview2=[];
                            let
                                $totalmontantht=0,
                                $totalmontanttva=0,
                                $totalmontantaib=0,
                                $totalmontantttc=0;
                            for (let $line of $datares2){


                                let $tauxaib=parseFloat($line.tauxaib);

                                let $montantht=parseFloat($line.mtntht);

                                let tauxtva=parseFloat($line.tauxtva);
                                let mtva=parseFloat($montantht)*tauxtva

                                let mmontantaib=0

                                if ($tauxaib>0){
                                    mmontantaib=parseFloat($montantht)*($tauxaib/100)
                                }

                                let mmontantttc=parseFloat($montantht)+mtva+mmontantaib;



                                $newarraytableview2.push({
                                    otherref:$line.otherref,
                                    reference:$line.reference,
                                    creerpar:$line.creepar,
                                    creerle:$line.linecreatedat,
                                    datedoc:$line.doccreatedat,
                                    article:$line.article,
                                    normalisation:$line.normalisation,
                                    tauxaib:$line.tauxaib,
                                    qte:$line.qtite,
                                    montantht:$line.mtntht,
                                    montanttva:mtva,
                                    montantaib:mmontantaib,
                                    montantttc:mmontantttc,
                                })


                                $totalmontantht=parseFloat($totalmontantht)+parseFloat($line.mtntht),
                                    $totalmontanttva=parseFloat($totalmontanttva)+parseFloat(mtva),
                                    $totalmontantaib=parseFloat($totalmontantaib)+parseFloat(mmontantaib),
                                    $totalmontantttc=parseFloat($totalmontantttc)+parseFloat(mmontantttc);


                            }


                            t.tableview2.push({
                                datas:$newarraytableview2,
                                totalmontantht:$totalmontantht,
                                totalmontanttva:$totalmontanttva,
                                totalmontantaib:$totalmontantaib,
                                totalmontantttc:$totalmontantttc,
                            })

                            //t.listecatalogue=$datares;





                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadclient(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/ar85474524otherdatadofodfidfoioienf55485/compta/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){



                            t.dataclient=[];
                            t.dataclient=res.data.m;


                        }
                        else{
                            t.dataclient=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            show(){


                this.messagesucces="";
                this.messageerror="";


              this.titlemodal="  CA PAR ARTICLES / SERVICES SUR UNE PERIODE " ;



                this.datatable=[];
                this.dataclient=[];

                this.loadclient();
                this.tableview="1";
                this.isdisplay=true;





            },


            FormatNumber(num) {

                return  FormatNumber(num)
            },



            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>