<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>


                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">Portrait</option>
                                        <option value="A4landscape">Paysage</option>
                                    </select>
                                </div>



                            </div>



                            <button @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>



                            <div class="table-responsive mybodyheight300px">


                                <div ref="printorderdatadeux"  class="my-5 page pagebreak"  >


                                    <div class="p-5">

                                        <section class="grid lg:grid-cols-2 gap-6 top-content mb-5 bb d-flex justify-content-between">

                                            <div class="logo">
                                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" style="width: 10%" class="img-fluid">
                                            </div>
                                            <div class="top-left">
                                                <div class="logo">
                                                    <!--img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid"-->

                                                    <!--qrcode :value="textToEncode" :size="size" :level="level"></qrcode-->
                                                    <qrcode :value="tabvalue.numdoc" :size="size" :level="level"></qrcode>

                                                </div>
                                            </div>

                                        </section>

                                        <section class="mt-5">
                                            <div class="grid lg:grid-cols-2 gap-6 row">

                                                <div class="col-6">
                                                    <table class="table table-bordered">
                                                        <tbody >

                                                        <tr>
                                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-6 ">

                                                    <table class="table table-bordered w-full">
                                                        <tbody >
                                                        <tr>
                                                            <td style="text-align: center" colspan="2" >{{soustitre}}</td>

                                                        </tr>
                                                        <tr>
                                                            <td  style="text-align: center">PERIODE</td>
                                                            <td style="text-align: center">IMPRIMER PAR</td>
                                                        </tr>
                                                        <tr>
                                                            <td  style="text-align: center">{{tabvalue.datedebut+' - '+tabvalue.datefin}}</td>
                                                            <td style="text-align: center">{{$store.state.appusername}}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>


                                                </div>

                                            </div>


                                        </section>


                                        <section   class="product-area mt-3">


                                            <table ref="exportable_table" class="table table-striped table-bordered
                                            table-hover dataTable no-footer dtr-inline mytable w-full">
                                                <thead>
                                                <tr>
                                                    <th style="width: 35px;text-align: center;text-transform: uppercase;">N°</th>
                                                    <th style="text-align: center;text-transform: uppercase;">N° document</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Date</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Réference BCD</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Fournisseur</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Motif</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Saisir par</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Montant</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Montant réglé</th>
                                                    <th style="text-align: center;text-transform: uppercase;">Montant restant</th>

                                                </tr>
                                                </thead>


                                                <tbody class="w-full">
                                                <template v-if="filterdatatable.length">
                                                    <template  v-for="(item,index) in filterdatatable" :key="index">

                                                    <tr >
                                                        <td data-label="N° : "   style="text-align: center;"> {{index+1}}</td>
                                                        <td  data-label="N°Document  : "   >{{ item.numdoc }}</td>
                                                        <td  data-label="Date  : "   >{{ item.datedoc }}</td>
                                                        <td data-label="Réference BCD : " >{{  item.numreference }}</td>
                                                        <td data-label="Fournisseur : " >{{  item.itemdoc.namefournisseur }}</td>
                                                        <td data-label="Motif : " >{{  item.motif }}</td>
                                                        <td data-label="Saisir par : " >{{  item.saisirpar }}</td>
                                                        <td style="text-align: center" data-label="Montant : " >{{   formatMoney(item.montantbcd) }}</td>
                                                        <td style="text-align: center" data-label="Montant réglé : " >{{ formatMoney(parseFloat(item.montantbcd)-parseFloat(item.montantbcdtrt)) }}</td>
                                                        <td style="text-align: center" data-label="Montant restant : " >{{   formatMoney(item.montantbcdtrt) }}</td>

                                                    </tr>


                                                        <template v-if="item.historiquereglement.length>0 && typelement==='reglement'">

                                                            <tr>
                                                                <td colspan="10">

                                                                    <table style=" width: 100%;"
                                                                           class="table table-striped table-bordered table-hover">

                                                                        <thead>
                                                                        <tr>

                                                                            <th>N°</th>
                                                                            <th>N°Document</th>
                                                                            <th>Date</th>
                                                                            <th>Régler par</th>
                                                                            <th>N°règlement</th>
                                                                            <th>Mode de règlement</th>
                                                                            <th>Saisir par</th>
                                                                            <th>Caisse</th>
                                                                            <th>Banque</th>
                                                                            <th>Montant</th>


                                                                        </tr>
                                                                        </thead>


                                                                        <tbody>
                                                                        <tr style="font-weight: 600;/*background: khaki;*/"  v-for="(mdatavalue,mindex) in item.historiquereglement" :key="mindex">


                                                                            <td style="text-align: center"  data-label="N° : "   >{{ mindex+1 }}</td>
                                                                            <td  data-label="N°Document  : "   >{{ item.numdoc  }}</td>
                                                                            <td  data-label="Date  : "   >{{ mdatavalue.cdateop }}</td>
                                                                            <td data-label="Régler par : " >{{  mdatavalue.reglepar }}</td>
                                                                            <td data-label="N°règlement : " >{{  mdatavalue.numdoc }}</td>
                                                                            <td data-label="Mode de règlement : " >{{  mdatavalue.modereglement }}</td>
                                                                            <td data-label="Saisir par : " >{{  mdatavalue.saisirpar }}</td>
                                                                            <td data-label="Caisse : " >{{  mdatavalue.caisse }}</td>
                                                                            <td data-label="Banque : " >{{  mdatavalue.banque }}</td>
                                                                            <td style="text-align: center" data-label="Montant : " >{{   formatMoney(mdatavalue.montant) }}</td>

                                                                        </tr>
                                                                        </tbody>

                                                                    </table>






                                                                </td>


                                                            </tr>




                                                        </template>


                                                    </template>
                                                </template>


                                                </tbody>


                                            </table>



                                        </section>

                                        <section class="balance-info">
                                            <div class="col-12">


                                            </div>
                                        </section>
                                        <section class="balance-info mt-3">
                                            <div class="grid grid-cols-2  gap-x-6 mt-3 row">


                                            </div>
                                        </section>



                                    </div>


                                </div>




                            </div>


                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear,
        impression, NumbertoLettre,
    } from "@/allfunction";
    import qrcode from 'qrcode.vue'

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            soustitre(){

                 this.taillemodel='A4landscape'; return "Liste des BCD interne"



            },

            nbreemplaire(){
                this.nbrecopie=1
                if (this.taillemodel==="A4" || this.taillemodel==="A5"){
                    return [1]
                }

                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape"){
                    return [1,2]
                }


            },



            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                return this.tabvalue.items;

            },
        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            qrcode

        },

        data() {
            return {

                typetableau:1,
                nbrecopie:1,
                mfontsize:10,
                taillemodel:'A4landscape',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                typelement:"",
                titlemodal:"",
                datatable:[],
                typbulletin:"",
                messageerror:"",
                messagesucces:"",
                viewdrop:false,
                tabvalue:{},

                datedebut:"",
                numpidcaisse:"",
                datefin:"",
                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {



            formatMonney(value){
                return currencyFormatMonney(value)

            },


            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },


            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                }

                if (this.taillemodel==="A4" || this.taillemodel==="A5" ){


                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                       // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }

              /*/  $style= [

                    process.env.VUE_APP_URL+'print/custom_print_a4.css',
                ]*/

                const printContent = this.$refs.printorderdatadeux.innerHTML;
                impression(printContent,$style,window.document.title)

            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },


            async show(tabvalue,typelement){


                this.typelement=typelement
                this.tabvalue=tabvalue
                this.isdisplay=true;
            },


        },


    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>