<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>
                            <div class="flex flex-wrap gap-2">

                                <div class="mt-4 mb-4">

                                </div>

                                <div class="mt-4 mb-4">

                                </div>


                            </div>





                            <div class="mt-3 grid lg:grid-cols-4 gap-6">

                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                    dark:text-defaulttextcolor/70 mb-0">Type de feuilles</label>
                                    <select  v-model="typedefeuille" class="form-control mb-3 w-full">
                                        <option value="3">11*4</option>
                                        <option value="6">8*2</option>
                                    </select>


                                </div>
                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                    dark:text-defaulttextcolor/70 mb-0">Orientation</label>
                                    <select  v-model="taillemodel" class="form-control mb-3 w-full">
                                        <option value="A4">Portait</option>
                                        <option value="A4landscape">Paysage</option>
                                    </select>


                                </div>

                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                    dark:text-defaulttextcolor/70 mb-0">Nombre d'imprimable</label>
                                    <input type="number" v-model="nombreimprimable" class="form-control">


                                </div>

                                <div class="space-y-2">
                                    <label class="text-capitalize ti-form-label
                                    dark:text-defaulttextcolor/70 mb-0">La taille du titre</label>
                                    <input type="number" v-model="titletaille" class="form-control">


                                </div>

                            </div>


                        </div>


                        <div class="box-body !p-0 mybodyheight300px">



                            <div ref="printcodebarrep" id="printcodebarrep">
                                <div  ref="printcodebarre" class="printcodebarre row grid grid-cols-12  gap-x-6 mt-3" >

                                    <div v-for="(item, key) in skus" :key="key" :class="`cards col-md-${typedefeuille}  xl:col-span-${typedefeuille}  col-span-${typedefeuille}`">

                                        <div  class="card"  :key="key">
                                            <h5 :style="{   fontSize: titletaille+'px' }" class="card-title" v-text="item.value"></h5>
                                            <img :alt='item.title'
                                                 :src="'https://barcode.tec-it.com/barcode.ashx?data='+item.title+'&code=Code128&translate-esc=true'"/>

                                        </div>

                                    </div>



                                </div>
                            </div>


                        </div>

                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>
                    <button   class="ti-btn ti-btn-primary-full" @click="printcodebarre" style="background: greenyellow">
                        Imprimer
                    </button>




                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>


</template>

<script>
    import {impression, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

                // console.log("  oldvalue  searchtrierletableau        **************** ",oldvalue)
                // console.log("  newvalue   searchtrierletableau       **************** ",newvalue)

            }

        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /* if (this.searchtrierletableau!==''){
                     let t=this;

                     mvalue = mvalue.filter( function (post) {

                         return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                     })

                 }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                //return this.paginate(mvalue) ;
                return mvalue;
            },

            skus(){

                let mvalue=[];


                let nombreimprimable=parseFloat(this.nombreimprimable);

                for(let i=0; i<nombreimprimable;i++){

                    /* this.skus.push({format: 'Code128', title: valuename, value: valuecodebarre})*/
                    mvalue.push({format: 'ean13', title: this.namecode, value: this.codebarre})


                }


                //  JsBarcode('.barcode').init();


                return mvalue;
            }
        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                taillemodel:'A4',
                typedefeuille:'3',
                nombreimprimable:44,
                titletaille:13,


                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },



            show(titlemodal,codebarre){
                this.messagesucces="";
                this.messageerror="";
               // this.titlemodal=titlemodal
                //this.datatable=listdata
                //this.typedoc=typedoc

                this.name="Générer le code barre pour "+titlemodal

                this.codebarre=codebarre
                this.namecode=titlemodal

                this.titlemodal="Générer le code barre pour "+titlemodal

                    this.nombreimprimable=0;
                    this.titletaille=0;

                    this.nombreimprimable=44;
                    this.titletaille=13;

                this.isdisplay=true;

            },

            printcodebarreok() {


                let $style=[
                    'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css',
                    process.env.VUE_APP_URL+'/stylecodebarre.css',

                ];



                setTimeout(() => {
                    const printContent = this.$refs.printcodebarrep.innerHTML;
                    impression(printContent,$style,window.document.title)

                }, 500);

            },

            async printcodebarre () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" ){

                    $style= [
                        'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css',
                        process.env.VUE_APP_URL+'/stylecodebarre.css',
                        process.env.VUE_APP_URL+'/landscape.css'

                    ]

                }

                if (this.taillemodel==="A4" ){

                    $style= [
                        'https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css',
                        process.env.VUE_APP_URL+'/stylecodebarre.css',
                        process.env.VUE_APP_URL+'/portrait.css'
                             ]

                }

              /*  const printContent = this.$refs.printorderdataetatstock.innerHTML;
                impression(printContent,$style,window.document.title)
*/


                setTimeout(() => {
                    const printContent = this.$refs.printcodebarrep.innerHTML;
                    impression(printContent,$style,window.document.title)

                }, 500);

            },





            async loadreturnotherdata(){


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/ar85474524otherdatadofodfidfoioienf55485/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                           // t.datatable=[];

                           // t.otherdata=res.data.otherdata;

                            if (t.typedoc==='cat'){
                                t.datatable=[];
                                 t.datatable=res.data.otherdata.catalogue
                            }

                            if (t.typedoc==='unite'){
                               t.datatable=[];
                                 t.datatable=res.data.otherdata.unite
                            }

                            if (t.typedoc==='emballage'){
                              t.datatable=[];
                                 t.datatable=res.data.otherdata.emballage

                            }




                        }
                        else{
                            t.datatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },







            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .cards {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: auto auto auto;
        justify-content: center;
        border-style: dotted;
        border-color: blue;
    }

    .card-title {
        margin: 10px 0 0 0;
        font-size: 8px;
        text-align: center;
    }




    svg {
        width: 100%;
    }



    a {
        color: #ccc;
    }

    .container {
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        text-align: center;
    }

    .heading {
        text-align: center;
    }
    .heading h1 {
        background: -webkit-linear-gradient(#fff, #999);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-align: center;
        margin: 0 0 5px 0;
        font-weight: 900;
        font-size: 4rem;
        color: #fff;
    }
    .heading h4 {
        color: #a990cc;
        text-align: center;
        margin: 0 0 35px 0;
        font-weight: 400;
        font-size: 24px;
    }

    .btn {
        outline: none !important;
    }

    .btn.btn-primary {
        background-color: #5c4084;
        border-color: #5c4084;
        outline: none;
    }
    .btn.btn-primary:hover {
        background-color: #442f62;
        border-color: #442f62;
    }
    .btn.btn-primary:active, .btn.btn-primary:focus {
        background-color: #684895;
        border-color: #684895;
    }
    .btn.btn-primary .fa {
        padding-right: 4px;
    }

    .form-group {
        margin-bottom: 25px;
    }







    .ti-modal .ti-modal-box {
        max-width: 80%!important;
    }


    /* Définir les dimensions du cadre */
    .frame {
        width: 200px;
        height: 100px;
        border: 1px solid #000;
        padding: 10px;
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Code128', sans-serif; /* Police de code-barres */
    }
    /* Style du code-barres */
    .barcode {
        font-size: 30px;
        text-align: center;
    }
    /* Texte sous le code-barres */
    .barcode-text {
        font-size: 12px;
        margin-top: 5px;
    }
    @media print {
        /* Ajuster pour imprimer sans marge */
        @page {
            margin: 0;
        }
    }




    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }




    .modal-active{
        display:block;
    }

    .modal-dialog {
        width: 90% !important;
        margin: 10% auto !important;
        max-width: 100% !important;
    }


    .modalform{
        margin: 5% auto !important;height:95%;overflow: auto;
    }

    .modal-container {
        background: #fff;
        width: 450px;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        margin: 0 auto;
        padding: 20px 30px;
    }

    @media screen and (max-width: 768px) {

        .modal-dialog {
            width: 95% !important;
            margin: 20% auto !important;
        }


        .modalform{
            margin: 20% auto !important;height:95%;overflow: auto;
        }


    }






</style>