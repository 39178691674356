<template>

    <div class="grid grid-cols-12 gap-x-6">


        <!--div class="xl:col-span-12 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        HISTORIQUE DES DOCUMENTS
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                        <button type="button" @click="indexbtnhistorique='1W';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 SEMAINE</button>
                        <button type="button" @click="indexbtnhistorique='2W';loadhistoriquebyperiode()" :class="indexbtnhistorique==='2W'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">2 SEMAINES</button>
                        <button type="button" @click="indexbtnhistorique='1M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='3M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">3 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='6M';loadhistoriquebyperiode()" :class="indexbtnhistorique==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">6 MOIS</button>
                        <button type="button" @click="indexbtnhistorique='1Y';loadhistoriquebyperiode()" :class="indexbtnhistorique==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">1 AN</button>
                    </div>
                </div>
                <div class="box-body">

                    <div class="table-responsive tableFixHeadindexcomponnent">


                        <div  >
                            <apexcharts type="line" height="350" :options="chartOptions" :series="series"></apexcharts>
                        </div>


                    </div>


                </div>





            </div>
        </div-->


        <div class="xl:col-span-12 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title">
                        Suivie des dépenses et récettes des caisses
                    </div>
                    <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">
                         <button type="button" @click="indexbtnsuiviecaisse='1M';loadsuivieallcaissegraph()" :class="indexbtnsuiviecaisse==='1M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1M</button>
                        <button type="button" @click="indexbtnsuiviecaisse='3M';loadsuivieallcaissegraph()" :class="indexbtnsuiviecaisse==='3M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">3M</button>
                        <button type="button" @click="indexbtnsuiviecaisse='6M';loadsuivieallcaissegraph()" :class="indexbtnsuiviecaisse==='6M'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">6M</button>
                        <button type="button" @click="indexbtnsuiviecaisse='1Y';loadsuivieallcaissegraph()" :class="indexbtnsuiviecaisse==='1Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  ">1Y</button>
                        <button type="button" @click="indexbtnsuiviecaisse='2Y';loadsuivieallcaissegraph()" :class="indexbtnsuiviecaisse==='2Y'?'ti-btn-primary-full text-white':'ti-btn-primary'" class="ti-btn-group !border-0 !text-xs !py-2 !px-3  !rounded-s-none">2Y</button>
                    </div>
                </div>
                <div class="box-body grid grid-cols-12 gap-x-6">





                    <div v-for="(graph,index) of graphsuiviecaisse" :key="index" class="xl:col-span-6 col-span-12">


                                    <apexcharts type="line" height="350" :options="graph.chartOptions" :series="graph.series"></apexcharts>



                    </div>








                </div>





            </div>
        </div>


        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                       Caisses
                    </div>
                    <div>
                        <button @click="loadsoldebanque" type="button" class="ti-btn ti-btn-primary ti-btn-wave !font-medium">
                            <i class="ri-refresh-line me-1 align-middle inline-block"></i></button>
                    </div>

                </div>
                <div class="box-body">


                    <div class="box-body !p-0 mybodyheight300px">
                        <div class="table-responsive">

                            <div class="flex flex-col h-screen">
                                <div class="flex-grow overflow-auto ">


                                    <table class="table table-striped table-bordered table-hover
                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CAISSE</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SOLDE   </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OPTION </th>
                                        </tr>
                                        </thead>



                                        <tbody>
                                        <template v-if="filterdatasoldecaisse.length">

                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                v-for="(datavalue,index) in filterdatasoldecaisse" :key="index">

                                                <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                <td  data-label="CAISSE : "   >{{ datavalue.name }}</td>
                                                <td  data-label="SOLDE  : "   >{{ formatMoney(datavalue.mlt) }}</td>
                                                <td  data-label="OPTION: " >
                                                    <button @click="btnmodalpointdelacaisse(datavalue.pid)" class="ti-btn ti-btn-primary-full"><i class="ri-eye-line"></i></button>

                                                </td>


                                            </tr>




                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="9"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>

                                        <tfoot>
                                        <tr style="font-weight: 600;/*background: khaki;*/">
                                            <td colspan="2" data-label="Total"><h2>Total</h2></td>
                                            <td data-label="Total solde"><h2>{{formatMoney(totalecaisse)}}</h2></td>
                                            <td></td>
                                        </tr>
                                        </tfoot>

                                    </table>



                                </div>
                            </div>

                        </div>
                    </div>






                </div>
            </div>
        </div>

        <div class="xl:col-span-6 col-span-12">
            <div class="box">
                <div class="box-header justify-between">
                    <div class="box-title mb-2 sm:mb-0">
                       Banques
                    </div>
                    <div>
                        <button @click="loadsoldebanque" type="button" class="ti-btn ti-btn-primary ti-btn-wave">
                            <i class="ri-refresh-line"></i></button>
                    </div>

                </div>
                <div class="box-body">


                    <div class="box-body !p-0 mybodyheight300px">
                        <div class="table-responsive">

                            <div class="flex flex-col h-screen">
                                <div class="flex-grow overflow-auto ">


                                    <table class="table table-striped table-bordered table-hover
                    dataTable no-footer dtr-inline mytable w-full">
                                        <thead>
                                        <tr>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">BANQUE</th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SOLDE   </th>
                                            <th  scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">OPTION </th>
                                        </tr>
                                        </thead>




                                        <tbody>
                                        <template v-if="filterdatasoldebanque.length">

                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                v-for="(datavalue,index) in filterdatasoldebanque" :key="index">

                                                <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                <td  data-label="BANQUE : "   >{{ datavalue.name }}</td>
                                                <td  data-label="SOLDE  : "   >{{ formatMoney(datavalue.mlt) }}</td>
                                                <td  data-label="OPTION: " >
                                                    <button  class="ti-btn ti-btn-primary-full"><i class="ri-eye-line"></i></button>

                                                </td>


                                            </tr>




                                        </template>



                                        <template v-else>
                                            <tr >
                                                <td colspan="9"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                            </tr>

                                        </template>




                                        </tbody>

                                        <tfoot>
                                        <tr style="font-weight: 600;/*background: khaki;*/">
                                            <td colspan="2" data-label="Total"><h2>Total</h2></td>
                                            <td data-label="Total solde"><h2>{{formatMoney(totalebanque)}}</h2></td>
                                            <td></td>
                                        </tr>
                                        </tfoot>

                                    </table>



                                </div>
                            </div>

                        </div>
                    </div>






                </div>
            </div>
        </div>






        <modalpointdelacaisse ref="modalpointdelacaisse"></modalpointdelacaisse>

    </div>


</template>

<script>

    import {currencyFormatDE, currencyFormatMonney, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');
    import VueApexCharts from "vue3-apexcharts";
    import modalpointdelacaisse from "@/components/views/pages/caisse/rapport/modal_pointdelacaisse";

    export default {
        /* eslint-disable */
        name: "stock",
        computed: {
            filterdatasoldecaisse(){

                let mvalue=this.datatablesoldecaisse;

                this.totalecaisse=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalecaisse=parseInt(t.totalecaisse)+parseInt(items.mlt);
                })




                return mvalue ;
            },
            filterdatasoldebanque(){



                let mvalue=this.datatablebanque;




                this.totalebanque=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalebanque=parseInt(t.totalebanque)+parseInt(items.mlt);
                })




                return mvalue ;
            },




        },
        components: {
            apexcharts: VueApexCharts,
            modalpointdelacaisse,
        },
        data() {
            return {
                initcomponnent:false,
                datamagasin:[],
                datatablehistorique:[],
                datatablesoldecaisse:[],
                datatablebanque:[],
                graphsuiviecaisse:[],

                totalecaisse:0,
                totalebanque:0,
                searchtrierhistoriquedata:'',
                searchwordhistorique:'',
                indexbtnsuiviecaisse:'1M',


                series: [{
                    name: 'Income',
                    type: 'column',
                    data: [140000, 2000, 1000, 1.5, 2.5, 2.8, 3.8, 4.6]
                }, {
                    name: 'Cashflow',
                    type: 'column',
                    data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
                },
                    {
                    name: 'Revenue',
                    type: 'line',
                    data: [20, 29, 37, 36, 0, 0, 50, 58]
                },

                    {
                    name: 'Revenue222',
                    type: 'line',
                    data: [200, 29, 300, 36, 50, -2000, -10000, 58]
                }

                ],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        stacked: false
                    },
                   /* dataLabels: {
                        enabled: true
                    },*/
                    /*stroke: {
                        width: [1, 1, 1]
                    },*/
                    title: {
                        text: 'XYZ - Stock Analysis (2009 - 2016)',
                        align: 'left',
                        offsetX: 110
                    },
                    xaxis: {
                        categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
                    },
                    yaxis: [
                        {
                            seriesName: 'Income',
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#008FFB'
                            },
                            labels: {
                                style: {
                                    colors: '#008FFB',
                                }
                            },
                            title: {
                                text: "Income (thousand crores)",
                                style: {
                                    color: '#008FFB',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: 'Cashflow',
                            opposite: true,
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#00E396'
                            },
                            labels: {
                                style: {
                                    colors: '#00E396',
                                }
                            },
                            title: {
                                text: "Operating Cashflow (thousand crores)",
                                style: {
                                    color: '#00E396',
                                }
                            },
                        },
                        {
                            seriesName: 'Revenue',
                            opposite: true,
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#FEB019'
                            },
                            labels: {
                                style: {
                                    colors: '#FEB019',
                                },
                            },
                            title: {
                                text: "Revenue (thousand crores)",
                                style: {
                                    color: '#FEB019',
                                }
                            }
                        },
                        {
                            seriesName: 'Revenue222',
                            opposite: true,
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#FFF819'
                            },
                            labels: {
                                style: {
                                    colors: '#FFF819',
                                },
                            },
                            title: {
                                text: "Revenue (thous888and crores)",
                                style: {
                                    color: '#FFF819',
                                }
                            }
                        },
                    ],
                    /*tooltip: {
                        fixed: {
                            enabled: true,
                            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                            offsetY: 30,
                            offsetX: 60
                        },
                    },
                    legend: {
                        horizontalAlign: 'bottomCenter',
                        offsetX: 40
                    }*/
                },

            };
        },
        methods: {

            btnmodalpointdelacaisse(numpidcaisse){

                let titlemodal="POINT DE LA CAISSE",datedouverture=""



                this.$refs.modalpointdelacaisse.show(numpidcaisse,datedouverture);
            },


            formatMoney(value){
                return currencyFormatDE(value)
            },

            formatNumber(value){
                return currencyFormatMonney(value)
            },

            async loadsuivieallcaissegraph() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/587999fffsuiviedescaisses784517788/'+this.indexbtnsuiviecaisse+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.graphsuiviecaisse=response.data.allgraphs

                        }
                        else{


                            t.graphsuiviecaisse=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



            async loadsoldebanque() {


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/dash/graph/587999fffsssdfffsuiviedescaisses784517788/'+randomString(280)+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.datatablesoldecaisse=response.data.cc
                            t.datatablebanque=response.data.bb

                        }
                        else{


                            t.datatablesoldecaisse=[]
                            t.datatablebanque=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },




            loadinitcomponnent(){

                if (this.initcomponnent===false){

                    this.loadsuivieallcaissegraph();
                    this.loadsoldebanque();

                    this.initcomponnent=true;
                }

            },


        }
    }
</script>

<style scoped>

</style>