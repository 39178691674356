<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <appmenu></appmenu>

            <div class="content" style="margin-top: 0">

                <div class="main-content">


                    <div class="container">
                        <!-- Page Header -->
                        <div class="block justify-between page-header md:flex">
                            <div>
                                   </div>
                            <ol class="flex items-center whitespace-nowrap min-w-0">

                            </ol>
                        </div>

                        <div class="grid grid-cols-12 gap-6 mb-[3rem]">
                            <div class="xl:col-span-12 col-span-12">
                                <div class="box">
                                    <div class="box-header sm:flex block !justify-start">
                                        <nav aria-label="Tabs" class="sm:flex block !justify-start whitespace-nowrap">

                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer
                                            text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium
                                            rounded-md hover:text-primary active" id="informationentreprise-item" data-hs-tab="#informationentreprise"
                                               aria-controls="informationentreprise">
                                                Informations de l'entreprise
                                            </a>
                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer
                                            text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                            hover:text-primary " id="account-item" data-hs-tab="#account-settings" aria-controls="account-settings">
                                                Gestion des autorisations système
                                            </a>
                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md hover:text-primary " id="email-item" data-hs-tab="#email-settings" aria-controls="email-settings">
                                                Gestion des signataires des états d'éditions
                                            </a>

                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md hover:text-primary " id="labels-item" data-hs-tab="#labels" aria-controls="labels">
                                                Téléchargement des signatures
                                            </a>
                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer
                                            text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium
                                            rounded-md hover:text-primary " id="labels-notification" data-hs-tab="#gestnotification" aria-controls="gestnotification">
                                                Gestion des notifications
                                            </a>
                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary cursor-pointer
                                            text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium
                                            rounded-md hover:text-primary " id="labels-libelle" data-hs-tab="#gestlibelle" aria-controls="gestlibelle">
                                                Gestion des libellés
                                            </a>

                                        </nav>
                                    </div>
                                    <div class="box-body">
                                        <div class="tab-content">


                                            <div class="tab-pane dark:border-defaultborder/10 " id="informationentreprise" aria-labelledby="informationentreprise-item" role="tabpanel">

                                                <div class="grid grid-cols-12 gap-4">



                                                    <div class="xl:col-span-12 col-span-12">

                                                        <div class="grid grid-cols-12 gap-4">

                                                            <div class="xl:col-span-6 col-span-12">
                                                                <label class="input input--file">
                                                                    <span class="input__text">Fichier <sup title="required">*</sup></span>
                                                                    <input type="file" id="filelogo" ref="logofile" v-on:change="logopreviewImage" class="input__field" accept="image/*" required>
                                                                    <span class="input__support">
                                                                    <span class="input__helper"></span>
                                                                  </span>
                                                                </label>
                                                            </div>

                                                            <div class="xl:col-span-6 col-span-12">

                                                                <div class="border p-2 mt-3">
                                                                    <p>Aperçu du fichier :</p>
                                                                    <template v-if="logopreview">
                                                                        <img :src="logopreview" class="img-fluid" />
                                                                        <p class="mb-0">Nom du fichier: {{ logoimage.name }}</p>
                                                                        <p class="mb-0">Taille du fichier: {{ logoimage.size/1024 }}KB</p>


                                                                        <div class="xl:col-span-12 col-span-12 mt-7">
                                                                            <div class="progress progress-xs">
                                                                                <div :class="`progress-bar !bg-info w-[${logouploadPercentage}%]`" role="progressbar" aria-valuenow="58" aria-valuemin="0" aria-valuemax="100"> </div>
                                                                            </div>

                                                                            <h3 ref="logostatus" id="logostatus"></h3>
                                                                            <p ref="logoloaded_n_total" id="logoloaded_n_total"></p>
                                                                        </div>



                                                                    </template>
                                                                    <template v-else>

                                                                        <img v-if="etatinformationentreprise.logo!==null" :src="$store.state.baseurlapifile+etatinformationentreprise.logo" class="img-fluid" />

                                                                    </template>
                                                                </div>



                                                            </div>


                                                        </div>


                                                        <div class="grid grid-cols-12 gap-4">

                                                            <div class="xl:col-span-6 col-span-12">
                                                                <label >IFU </label>
                                                                <input type="text" class="form-control w-full"  v-model="etatinformationentreprise.ifu">
                                                            </div>

                                                            <div class="xl:col-span-6 col-span-12">
                                                                <label >RCCM </label>
                                                                <input type="text" class="form-control w-full"  v-model="etatinformationentreprise.rccm">
                                                            </div>


                                                        </div>

                                                        <div class="xl:col-span-12 col-span-12">
                                                            <label >Raison sociale </label>
                                                            <input type="text" class="form-control w-full"  v-model="etatinformationentreprise.raisonsociale">
                                                        </div>

                                                        <div class="xl:col-span-12 col-span-12">
                                                            <label >Adresse </label>
                                                            <input type="text" class="form-control w-full"  v-model="etatinformationentreprise.adresse">
                                                        </div>

                                                        <div class="xl:col-span-12 col-span-12">
                                                            <label >Téléphone </label>
                                                            <input type="text" class="form-control w-full"  v-model="etatinformationentreprise.telephonne">
                                                        </div>


                                                    </div>


                                                    <div class="xl:col-span-12 col-span-12">
                                                        <div class="ltr:float-right rtl:float-left">

                                                            <button @click="submitFileinformationentreprise" type="button" class="ti-btn bg-primary text-white m-1">
                                                                Enregistrer les modifications
                                                            </button>
                                                        </div>
                                                    </div>




                                                </div>



                                            </div>

                                            <div class="tab-pane dark:border-defaultborder/10 hidden" id="account-settings" aria-labelledby="account-item"
                                                 role="tabpanel">

                                                 <div class="">


                                                 <div class="grid grid-cols-12 gap-4">
                                                    <div class="xl:col-span-12 col-span-12">
                                                        <div class="box  shadow-none mb-0 border dark:border-defaultborder/10">
                                                            <div class="box-body">

                                                                <div class="block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Autoriser la normalisation des factures ? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">
                                                                             <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                <input id="authorizenormalisation" v-model="autorisationsystem.authorizenormalisation" type="checkbox" checked>
                                                                                <label for="authorizenormalisation" class="label-primary mb-1"></label>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Autoriser l'utilisation des signatures électroniques? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizesignatureelectronique" v-model="autorisationsystem.authorizesignatureelectronique" type="checkbox" checked>
                                                                                    <label for="authorizesignatureelectronique" class="label-primary mb-1"></label>
                                                                                </div>
                                                                           </div>

                                                                    </div>

                                                                </div>
                                                               </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Autoriser le payement partiel des factures de vente ? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizefacturactionpartiel" v-model="autorisationsystem.authorizefacturactionpartiel" type="checkbox" checked>
                                                                                    <label for="authorizefacturactionpartiel" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Accepter que le montant reçu soit égale à 0 ? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizeprix0" v-model="autorisationsystem.authorizeprix0" type="checkbox" checked>
                                                                                    <label for="authorizeprix0" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Autoriser la validation des documents(Gestion commerciale) ? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizevalidationdoc" v-model="autorisationsystem.authorizevalidationdoc" type="checkbox" checked>
                                                                                    <label for="authorizevalidationdoc" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Autoriser la gestion des points de vente? :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizepointdevente" v-model="autorisationsystem.authorizepointdevente" type="checkbox" checked>
                                                                                    <label for="authorizepointdevente" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Utiliser la procédure de validation des documents de stock :</span>
                                                                            <p class="text-[0.75rem] text-[#8c9097] dark:text-white/50 mb-0"> Si oui (tous les documents de stock crée doivent subir une validation avant d'impacter le stock)</p>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizelaproceduredevalidationdocstock" v-model="autorisationsystem.authorizelaproceduredevalidationdocstock" type="checkbox" checked>
                                                                                    <label for="authorizelaproceduredevalidationdocstock" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>




                                                                        </div>

                                                                    </div>

                                                                </div>


                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-8 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Validation des documents
                                                                                <br>
                                                                                (<span style="color: red;font-weight: bold;">NB : En choisissant cette option, si le document n'est pas validé après la demande,
                                                                                  vous ne pourrez pas lancer la transformation, l'impression ou la modification du document.</span>)
                                                                                :
                                                                              <span class="help-block" style="margin-top: 5px;font-size: 10px">
                                                                                        Documents de vente :
                                                                                    <ul>
                                                                                        <li>Bon de commande</li>
                                                                                        <li>Bon de préparation de livraison</li>
                                                                                        <li>Bon de livraison</li>
                                                                                        <li>Facture</li>
                                                                                        <li>Retours sur ventes</li>
                                                                                    </ul>
                                                                                </span>


                                                                            </span>
                                                                        </div>
                                                                        <div class="xl:col-span-4 col-span-12">

                                                                            <div class="xl:col-span-12 col-span-12">
                                                                                <div class="custom-toggle-switch sm:ms-2 ms-0">
                                                                                    <input id="authorizelavalidationdesdocuments" v-model="autorisationsystem.authorizelavalidationdesdocuments" type="checkbox" checked>
                                                                                    <label for="authorizelavalidationdesdocuments" class="label-primary mb-1"></label>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>




                                                                <div class=" block items-center mb-6 justify-between">
                                                                    <div class="grid grid-cols-12 gap-4">
                                                                        <div class="xl:col-span-4 col-span-12">
                                                                            <span class="text-[0.875rem] font-semibold mb-0">Utiliser la procédure de vente (Gestion commerciale) :</span>
                                                                        </div>
                                                                        <div class="xl:col-span-8 col-span-12">


                                                                            <div class="form-check !ps-0">
                                                                                <input class="form-check-input" type="radio"
                                                                                       v-model="autorisationsystem.authorizelaproceduredevente"
                                                                                       name="authorizelaproceduredevente"
                                                                                       value="1"
                                                                                       id="authorizelaproceduredevente1">
                                                                                <label class="form-check-label" for="authorizelaproceduredevente1">
                                                                                    1- Devis -> Bon de commande -> Bon de préparation de livraison -> Bon de livraison (MS) -> Facture (MS)
                                                                                </label>
                                                                            </div>


                                                                            <div class="form-check !ps-0">
                                                                                <input class="form-check-input" type="radio"
                                                                                       v-model="autorisationsystem.authorizelaproceduredevente"
                                                                                       name="authorizelaproceduredevente"
                                                                                       value="2"
                                                                                       id="authorizelaproceduredevente2">
                                                                                <label class="form-check-label" for="authorizelaproceduredevente2">
                                                                                    2- Devis -> Bon de commande -> Facture ->Bon de préparation de livraison -> Bon de livraison (MS)
                                                                                </label>
                                                                            </div>

                                                                            <div class="form-check !ps-0">
                                                                                <input class="form-check-input" type="radio"
                                                                                       v-model="autorisationsystem.authorizelaproceduredevente"
                                                                                       name="authorizelaproceduredevente"
                                                                                       value="3"
                                                                                       id="authorizelaproceduredevente3">
                                                                                <label class="form-check-label" for="authorizelaproceduredevente3">
                                                                                    3- Facture (Facturation de produits variés pour plusieurs magasins, principalement pour les clients des marchés(MS).)
                                                                                </label>
                                                                            </div>

                                                                            <div class="form-check !ps-0">
                                                                                <input class="form-check-input" type="radio"
                                                                                       v-model="autorisationsystem.authorizelaproceduredevente"
                                                                                       name="authorizelaproceduredevente"
                                                                                       value="4"
                                                                                       id="authorizelaproceduredevente4">
                                                                                <label class="form-check-label" for="authorizelaproceduredevente4">
                                                                                    4- Des dépôts sobebra avec la gestion des emballges(casier)
                                                                                </label>
                                                                            </div>





                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                     <div class="xl:col-span-12 col-span-12">
                                                     <div class="ltr:float-right rtl:float-left">

                                                         <button @click="Saveautorisationupdate" type="button" class="ti-btn bg-primary text-white m-1">
                                                             Enregistrer les modifications
                                                         </button>
                                                     </div>
                                                     </div>

                                                 </div>


                                                </div>








                                            </div>

                                            <div class="tab-pane dark:border-defaultborder/10 !p-0 hidden" id="email-settings" aria-labelledby="email-item" role="tabpanel">

                                                <template v-if="Object.keys(datasignataire).length>0">

                                                <div class="grid grid-cols-12 gap-4">
                                                    <div class="xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                                        <nav aria-label="Tabs" class="  block !justify-start whitespace-nowrap">

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary active"
                                                               @click="doctype='caisse'"
                                                               id="ddoccaisse" data-hs-tab="#doccaisse" aria-controls="doccaisse">
                                                                Caisse / Document
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='demandedachat'"
                                                               id="ddemandedachat" data-hs-tab="#demandedachat" aria-controls="demandedachat">
                                                                Achat / Demande d'achat
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='achatbcd'"
                                                               id="ddachatbcd" data-hs-tab="#achatbcd" aria-controls="achatbcd">
                                                                Achat / Bon de commande
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='achatbondereception'"
                                                               id="dachatbondereception" data-hs-tab="#achatbondereception" aria-controls="achatbondereception">
                                                                Achat / Bon de réception
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='achatfacture'"
                                                               id="dachatfacture" data-hs-tab="#achatfacture" aria-controls="achatfacture">
                                                                Achat / Facture
                                                            </a>
                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='achatfacture'"
                                                               id="dachatretoursurachat" data-hs-tab="#achatretoursurachat" aria-controls="achatretoursurachat">
                                                                Achat / Retour sur achat
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='vente_devis'"
                                                               id="dvente_devis" data-hs-tab="#vente_devis" aria-controls="vente_devis">
                                                                Vente / Devis
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='ventebcd'"
                                                               id="dventebcd" data-hs-tab="#ventebcd" aria-controls="ventebcd">
                                                                Vente / Bon de commande
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='ventebpl'"
                                                               id="dventebpl" data-hs-tab="#ventebpl" aria-controls="ventebpl">
                                                                Vente / Bon de prération de livraison
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='ventebl'"
                                                               id="dventebl" data-hs-tab="#ventebl" aria-controls="ventebl">
                                                                Vente / Bon de livraison
                                                            </a>

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='ventef'"
                                                               id="dventef" data-hs-tab="#ventef" aria-controls="ventef">
                                                                Vente / Facture
                                                            </a>
                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem] flex-grow font-medium rounded-md
                                                            hover:text-primary "
                                                               @click="doctype='venteretourvente'"
                                                               id="dventeretourvente" data-hs-tab="#venteretourvente" aria-controls="venteretourvente">
                                                                Vente / retour sur vente
                                                            </a>


                                                        </nav>
                                                    </div>

                                                    <div class="xl:col-span-9 col-span-12">

                                                        <div class="tab-pane dark:border-defaultborder/10" id="doccaisse" aria-labelledby="ddoccaisse"
                                                             role="tabpanel">

                                                            <div class="alert alert-info text-center"> <h2>Caisse / Document de caisse</h2> </div>


                                                            <div class="grid grid-cols-12 gap-4 ">

                                                                <div class="xl:col-span-6 col-span-12 mt-3">
                                                                    <div class="form-group">
                                                                        <label >Titre du responsable des caisses</label>
                                                                        <input     v-model="datasignataire.caisseresponsable.titre"
                                                                                   type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 mt-3">
                                                                    <div class="form-group">
                                                                        <label >Nom du responsable des caisses</label>
                                                                        <input     v-model="datasignataire.caisseresponsable.name"
                                                                                   type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 mt-3">
                                                                    <div class="form-group">
                                                                        <label >Titre du responsable financier</label>
                                                                        <input     v-model="datasignataire.caisseresponsablefinancier.titre"
                                                                                   type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 mt-3">
                                                                    <div class="form-group">
                                                                        <label >Nom du responsable financier</label>
                                                                        <input     v-model="datasignataire.caisseresponsablefinancier.name"
                                                                                   type="text" class="form-control" >
                                                                    </div>
                                                                </div>






                                                            </div>




                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="demandedachat" aria-labelledby="ddemandedachat"
                                                             role="tabpanel">

                                                            <div class="alert alert-info text-center"> <h2>Achat / Demande d'achat</h2> </div>
                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12 ">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.demandedachat.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12 ">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12 ">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-3 col-span-12 ">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12  mb-3">  <button @click="ajouterline(datasignataire.demandedachat.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12 ">
                                                                    <table class="table table-responsive table-bordered w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.demandedachat.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.demandedachat.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.demandedachat.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.demandedachat.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="achatbcd" aria-labelledby="dachatbcd"
                                                             role="tabpanel">

                                                            <div class="alert alert-info text-center"> <h2>Achat / Bon de commande</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.achatbcd.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.achatbcd.signataires)"
                                                                class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.achatbcd.signataires.length" >
                                                                            <tr v-for="(datavalue,index) in datasignataire.achatbcd.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.achatbcd.signataires,index)"
                                                                                            :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.achatbcd.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>




                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="achatbondereception" aria-labelledby="dachatbondereception"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Achat / Bon de réception</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.achatbondereception.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.achatbondereception.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.achatbondereception.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.achatbondereception.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.achatbondereception.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.achatbondereception.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>






                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="achatfacture" aria-labelledby="dachatfacture"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Achat / Facture</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.achatfacture.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.achatfacture.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.achatfacture.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.achatfacture.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.achatfacture.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.achatfacture.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>






                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="achatretoursurachat" aria-labelledby="dachatretoursurachat"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Achat / Retour sur achat</h2> </div>


                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.achatretour.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.achatretour.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.achatretour.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.achatretour.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.achatretour.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.achatretour.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>









                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="vente_devis" aria-labelledby="dvente_devis"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / Devis</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.ventedevis.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12mb-3">  <button @click="ajouterline(datasignataire.ventedevis.signataires)" class="ti-btn ti-btn-primary-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.ventedevis.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.ventedevis.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.ventedevis.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.ventedevis.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>












                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="ventebcd" aria-labelledby="dventebcd"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / Bon de commande</h2> </div>


                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.ventebcd.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12" v-if="$store.state.authorizelavalidationdesdocuments===0 || $store.state.authorizelavalidationdesdocuments==='0'">
                                                                    <div class="form-group">
                                                                        <label >Nom du signataire </label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>

                                                                <!--div class="xl:col-span-4 col-span-12" v-else>
                                                                    <div class="form-group">

                                                                        <label >Nom du signataire </label>
                                                                        <select v-model="jsonmnomsignataire" class="form-control">
                                                                            <option value="">Veuillez séléctionner un signataire</option>
                                                                            <option v-for="signataire in datausers" :value="signataire">{{signataire.name}}</option>
                                                                        </select>

                                                                    </div>
                                                                </div-->



                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Url signature électronique</label>
                                                                        <input     v-model="urlfilesignataire" disabled type="text" class="form-control" >

                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.ventebcd.signataires)" class="ti-btn ti-btn-primary mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.ventebcd.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.ventebcd.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >
                                                                                    <a v-if="datavalue.msignaturesignataire!==''" :href="datavalue.msignaturesignataire" target="_blank">
                                                                                        <img :src="datavalue.msignaturesignataire" style="width: 80px" alt="">
                                                                                    </a>
                                                                                </td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.ventebcd.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.ventebcd.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>














                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="ventebpl" aria-labelledby="dventebpl"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / Bon de prération de livraison</h2> </div>


                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.ventebpl.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.ventebpl.signataires)" class="ti-btn ti-btn-primary w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.ventebpl.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.ventebpl.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.ventebpl.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.ventebpl.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="ventebl" aria-labelledby="dventebl"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / Bon de livraison</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.ventebl.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.ventebl.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.ventebl.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.ventebl.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.ventebl.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.ventebl.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>





                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="ventef" aria-labelledby="dventef"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / Facture</h2> </div>

                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.ventefacture.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.ventefacture.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.ventefacture.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.ventefacture.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.ventefacture.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.ventefacture.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>





                                                        </div>

                                                        <div class="tab-pane dark:border-defaultborder/10 hidden"
                                                             id="venteretourvente" aria-labelledby="dventeretourvente"
                                                             role="tabpanel">
                                                            <div class="alert alert-info text-center"> <h2>Vente / retour sur vente</h2> </div>


                                                            <div class="grid grid-cols-12 gap-4">
                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="form-group">
                                                                        <label  >Titre du document</label>
                                                                        <input    v-model="datasignataire.venteretour.titredoc" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Titre du signataire</label>
                                                                        <input    v-model="mtitresignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Nom du signataire</label>
                                                                        <input     v-model="mnomsignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div>
                                                                <!--div class="xl:col-span-4 col-span-12">
                                                                    <div class="form-group">
                                                                        <label >Url signature électronique</label>
                                                                        <input     v-model="msignaturesignataire" type="text" class="form-control" >
                                                                    </div>
                                                                </div-->

                                                                <div class="xl:col-span-4 col-span-12 mb-3">  <button @click="ajouterline(datasignataire.venteretour.signataires)" class="ti-btn ti-btn-primary-full w-full mt-4">Ajouter</button></div>
                                                                <div class="clearfix"></div>

                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <table class="table table-responsive table-bordered w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>Titre du signataire</th>
                                                                            <th>Nom du signataire</th>
                                                                            <th>Signature électronique</th>
                                                                            <th>Action</th>
                                                                        </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                        <template v-if="datasignataire.venteretour.signataires.length">
                                                                            <tr v-for="(datavalue,index) in datasignataire.venteretour.signataires" :key="index">
                                                                                <td data-label="Titre du signataire  : " >{{datavalue.mtitresignataire}}</td>
                                                                                <td data-label="Nom du signataire  : " >{{datavalue.mnomsignataire}}</td>
                                                                                <td data-label="Signature électronique  : " >{{datavalue.msignaturesignataire}}</td>
                                                                                <td data-label="Action:">
                                                                                    <button @click="retirerline(datasignataire.venteretour.signataires,index)" :title="'retirer '+datavalue.mtitresignataire" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="8"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>
                                                                        </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>





                                                                <div class="xl:col-span-6 col-span-12 mb-3">
                                                                    <label >Document d'édition</label>
                                                                    <select v-model="datasignataire.venteretour.document" class="form-control" >
                                                                        <option value="0">Document par défaut</option>
                                                                        <option :disabled="nbrededocumentactive<1" value="1">Document 1</option>
                                                                        <option :disabled="nbrededocumentactive<2" value="2">Document 2</option>
                                                                        <option :disabled="nbrededocumentactive<3" value="3">Document 3</option>
                                                                        <option :disabled="nbrededocumentactive<4" value="4">Document 4</option>
                                                                        <option :disabled="nbrededocumentactive<5" value="5">Document 5</option>
                                                                    </select>
                                                                </div>
                                                                <div class="xl:col-span-6 col-span-12 ">
                                                                    <a :href="urletatpicture" target="_blank">
                                                                        <img  class="img-responsive imgsize" :src="urletatpicture" alt="">
                                                                    </a>
                                                                </div>
                                                            </div>





                                                        </div>





                                                    </div>


                                                    <div class="xl:col-span-12 col-span-12">
                                                        <div class="ltr:float-right rtl:float-left">

                                                            <button @click="Savesignataire" type="button" class="ti-btn bg-primary text-white m-1">
                                                                Enregistrer les modifications
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>



                                                </template>



                                            </div>

                                            <div class="tab-pane dark:border-defaultborder/10 hidden" id="labels" aria-labelledby="labels-item" role="tabpanel">

                                                <div class="grid grid-cols-12 gap-4">

                                                    <div class="xl:col-span-6 col-span-12">

                                                        <div class="form-group">
                                                            <label class="input input--file">
                                                                <span class="input__text">Fichier <sup title="required">*</sup></span>
                                                                <input type="file" id="file" ref="file" v-on:change="previewImage" class="input__field" accept="image/*" required>
                                                                <span class="input__support">
                                                                    <span class="input__helper"></span>
                                                                  </span>
                                                            </label>
                                                        </div>


                                                        <div class="form-group">
                                                            <label >Titre </label>
                                                            <input type="text" class="form-control" v-model="titreuploadsignature">
                                                        </div>


                                                    </div>
                                                    <div class="xl:col-span-6 col-span-12">

                                                        <div class="border p-2 mt-3">
                                                            <p>Aperçu du fichier :</p>
                                                            <template v-if="preview">
                                                                <img :src="preview" class="img-fluid" />
                                                                <p class="mb-0">Nom du fichier: {{ image.name }}</p>
                                                                <p class="mb-0">Taille du fichier: {{ image.size/1024 }}KB</p>


                                                                <div class="xl:col-span-12 col-span-12 mt-7">
                                                                    <div class="progress progress-xs">
                                                                        <div :class="`progress-bar !bg-info w-[${uploadPercentage}%]`" role="progressbar" aria-valuenow="58" aria-valuemin="0" aria-valuemax="100"> </div>
                                                                    </div>

                                                                    <h3 ref="status" id="status"></h3>
                                                                    <p ref="loaded_n_total" id="loaded_n_total"></p>
                                                                </div>



                                                            </template>
                                                        </div>



                                                    </div>


                                                    <div class="xl:col-span-12 col-span-12" style="margin-top: 20px;margin-bottom: 20px">

                                                        <button class="ti-btn ti-btn-primary-full w-full" v-on:click="SaveUploadsignature()"><i class="ri-upload-2-fill"></i> Télécharger</button>
                                                    </div>


                                                    <div class="xl:col-span-12 col-span-12 mt-3" style="height: 100%;">
                                                        <div class="xl:col-span-12 col-span-12 mt-3 tableFixHead">
                                                            <table class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable w-full">
                                                                <thead>
                                                                <tr>
                                                                    <th>N°</th>
                                                                    <th>Titre</th>
                                                                    <th>Url</th>
                                                                    <th>Image signature</th>
                                                                    <th>Action</th>

                                                                </tr>
                                                                </thead>


                                                                <tbody>
                                                                <template v-if="filterdatableuploadsignature.length">


                                                                    <tr style="font-weight: 600;/*background: khaki;*/"
                                                                        v-for="(datavalue,index) in filterdatableuploadsignature" :key="index">

                                                                        <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                                        <td  data-label="Utilisateur  : "   >{{ (datavalue.tsignature) }}</td>
                                                                        <td  data-label="Url : "   >{{$store.state.baseurlapifile+datavalue.file }}</td>
                                                                        <td  data-label="Image signature : "   >
                                                                            <a :href="$store.state.baseurlapifile+datavalue.file" target="_blank">
                                                                                <img :src="$store.state.baseurlapifile+datavalue.file" alt="" style="width: 100px">
                                                                            </a>
                                                                        </td>
                                                                        <td v-if="datavalue.useline===0" data-label="Action  : "  >
                                                                            <button @click="deletelinesignatureUpload(datavalue)"   class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                        </td>
                                                                        <td v-else data-label="Action  : "  >
                                                                        </td>


                                                                    </tr>


                                                                </template>



                                                                <template v-else>
                                                                    <tr >
                                                                        <td colspan="7"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                    </tr>

                                                                </template>




                                                                </tbody>

                                                            </table>

                                                        </div>
                                                    </div>









                                                </div>

                                            </div>

                                            <div class="tab-pane dark:border-defaultborder/10 hidden" id="gestnotification" aria-labelledby="labels-notification" role="tabpanel">


                                                <div class="grid grid-cols-12 gap-4">
                                                    <div class="xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                                        <nav aria-label="Tabs" class="  block !justify-start whitespace-nowrap">

                                                            <a class="m-1 block w-full hs-tab-active:bg-primary/10 hs-tab-active:text-primary
                                                            cursor-pointer text-defaulttextcolor dark:text-defaulttextcolor/70 py-2 px-3 text-[0.75rem]
                                                            flex-grow font-medium rounded-md
                                                            hover:text-primary active"

                                                               id="dutilisationnotification" data-hs-tab="#utilisationnotification" aria-controls="utilisationnotification">
                                                                Utilisateur -> Type notification
                                                            </a>


                                                        </nav>
                                                    </div>

                                                    <div class="xl:col-span-9 col-span-12">

                                                        <div class="tab-pane dark:border-defaultborder/10" id="utilisationnotification" aria-labelledby="dutilisationnotification"
                                                             role="tabpanel">

                                                        <div class="grid grid-cols-12 gap-4">

                                                            <div class="xl:col-span-6 col-span-12">

                                                                <div class="space-y-2">
                                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Utilisateur</label>
                                                                    <select v-model="notification.username" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm
                                                                    !py-2 !px-3">
                                                                        <option :value="null">Sélectionner un utilisateur</option>
                                                                        <option v-for="(user,index) of alldatanotification.users" :value="user" :key="index">{{user.username}}</option>

                                                                    </select>
                                                                </div>


                                                            </div>
                                                            <div class="xl:col-span-6 col-span-12">


                                                                <div class="space-y-2">
                                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Type de notification</label>
                                                                    <select v-model="notification.tpnotif" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm
                                                                    !py-2 !px-3">
                                                                        <option :value="null">Sélectionner une notification</option>
                                                                        <option v-for="(notif,index) of alldatanotification.tpnotif" :value="notif" :key="index">{{notif.name}}</option>

                                                                    </select>
                                                                </div>


                                                            </div>

                                                             <div class="xl:col-span-12 col-span-12" style="margin-top: 20px;margin-bottom: 20px">
                                                                        <button @click="ajouterlinetonotification" class="ti-btn ti-btn-primary-full w-full" >
                                                                            <i class="ri-add-box-fill"></i> Ajouter</button>
                                                              </div>

                                                                <div class="xl:col-span-12 col-span-12" style="margin-top: 20px;margin-bottom: 20px">

                                                                    <table class="table table-striped table-bordered table-hover
                                                                    dataTable no-footer dtr-inline mytable w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th>N°</th>
                                                                            <th>Utilisateur</th>
                                                                            <th>Type de notification</th>
                                                                            <th>Action</th>

                                                                        </tr>
                                                                        </thead>


                                                                        <tbody>
                                                                        <template v-if="filterdatablelistenotification.length">

                                                                            <tr style="font-weight: 600;/*background: khaki;*/"
                                                                                v-for="(datavalue,index) in filterdatablelistenotification" :key="index">

                                                                                <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                                                <td  data-label="Utilisateur  : "   >{{ (datavalue.username) }}</td>
                                                                                <td  data-label="Type de notification  : "   >{{ datavalue.notif.name }}</td>
                                                                                <td data-label="Action  : "  >
                                                                                    <button @click="deletelinenotification(datavalue)" class="ti-btn ti-btn-danger-full w-full"><i class="ri-delete-row"></i></button>
                                                                                </td>


                                                                            </tr>


                                                                        </template>



                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="7"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                            </tr>

                                                                        </template>




                                                                        </tbody>

                                                                    </table>


                                                                </div>







                                                        </div>
                                                        </div>


                                                    </div>
                                                </div>





                                            </div>

                                            <div class="tab-pane dark:border-defaultborder/10 hidden" id="gestlibelle" aria-labelledby="labels-libelle" role="tabpanel">


                                                <div class="grid grid-cols-12 gap-4">

                                                    <div class="xl:col-span-12 col-span-12">

                                                        <div class="space-y-2">
                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Type de libellé / formulaire</label>
                                                            <select v-model="selectedItemslibelle" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm
                                                                    !py-2 !px-3">
                                                                <option :value="null">Sélectionner un type</option>
                                                                <option v-for="(line,index) of alldatalibelle" :key="index" :value="line">{{line.linename}}</option>
                                                            </select>
                                                        </div>


                                                    </div>



                                                    <div class="xl:col-span-12 col-span-12" style="margin-top: 20px;margin-bottom: 20px">

                                                        <template v-if="selectedItemslibelle!==null">
                                                            <template v-if="selectedItemslibelle.dataline.typform==='prixdevente'">

                                                                <table class="table table-striped table-bordered table-hover
                                                                dataTable no-footer dtr-inline mytable w-full">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>N°</th>
                                                                        <th>Libellé par défaut</th>
                                                                        <th>Libellé personnalisé</th>

                                                                    </tr>
                                                                    </thead>


                                                                    <tbody>


                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >1</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >2</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente1) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente1"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >3</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente2) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente2"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >4</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente3) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente3"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >5</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente4) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente4"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >6</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente5) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente5"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >7</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente6) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente6"></td>

                                                                    </tr>

                                                                    <tr style="font-weight: 600;/*background: khaki;*/" >

                                                                        <td class="text-center"  data-label="N°  : "   >8</td>
                                                                        <td  data-label="Libellé par défaut  : "   >{{ (selectedItemslibelle.linedefault.prixvente7) }}</td>
                                                                        <td  data-label="Libellé personnalisé : "   ><input type="text" class="form-control"
                                                                                                                            v-model="selectedItemslibelle.dataline.valuedata.prixvente7"></td>

                                                                    </tr>




                                                                    </tbody>

                                                                </table>



                                                                <div class="xl:col-span-12 col-span-12">
                                                                    <div class="ltr:float-right rtl:float-left">

                                                                        <button @click="Savelinenotification" type="button" class="ti-btn bg-primary text-white m-1">
                                                                            Enregistrer les modifications
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </template>
                                                        </template>
                                                    </div>







                                                </div>



                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>














                </div>
            </div>
        </template>

    </templatesite>


    <modalopenform ref="modalopenform"  ></modalopenform>

</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/setting/menu";


    import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    import modalopenform from "@/components/views/pages/stock/mvtstock/modal_openform";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalopenform,

        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },

        computed:{

            urletatpicture(){
                let mvalue='';
                let dossiervalue=0

                if (this.doctype==='demandedachat'){
                    dossiervalue=parseInt(this.datasignataire.demandedachat.document)

                    if (parseInt(this.datasignataire.demandedachat.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.demandedachat.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.demandedachat.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.demandedachat.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.demandedachat.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.demandedachat.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='achatbcd'){
                    dossiervalue=parseInt(this.datasignataire.achatbcd.document)
                    if (parseInt(this.datasignataire.achatbcd.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.achatbcd.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbcd.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbcd.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbcd.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbcd.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='achatbondereception'){
                    dossiervalue=parseInt(this.datasignataire.achatbondereception.document)
                    if (parseInt(this.datasignataire.achatbondereception.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.achatbondereception.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbondereception.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbondereception.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbondereception.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatbondereception.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='achatfacture'){
                    dossiervalue=parseInt(this.datasignataire.achatfacture.document)
                    if (parseInt(this.datasignataire.achatfacture.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.achatfacture.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatfacture.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatfacture.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatfacture.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatfacture.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='achatretoursurachat'){
                    dossiervalue=parseInt(this.datasignataire.achatretour.document)
                    if (parseInt(this.datasignataire.achatretour.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.achatretour.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatretour.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatretour.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatretour.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.achatretour.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }





                if (this.doctype==='vente_devis'){
                    dossiervalue=parseInt(this.datasignataire.ventedevis.document)

                    if (parseInt(this.datasignataire.ventedevis.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.ventedevis.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventedevis.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventedevis.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventedevis.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventedevis.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='ventebcd'){

                    dossiervalue=parseInt(this.datasignataire.ventebcd.document)

                    if (parseInt(this.datasignataire.ventebcd.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';
                    }

                    if (parseInt(this.datasignataire.ventebcd.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebcd.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebcd.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebcd.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebcd.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/d0.PNG';

                    }



                }


                if (this.doctype==='ventebpl'){

                    dossiervalue=parseInt(this.datasignataire.ventebpl.document)

                    if (parseInt(this.datasignataire.ventebpl.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';
                    }

                    if (parseInt(this.datasignataire.ventebpl.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebpl.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebpl.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebpl.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebpl.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/bpl0.PNG';

                    }



                }


                if (this.doctype==='ventebl'){

                    dossiervalue=parseInt(this.datasignataire.ventebl.document)

                    if (parseInt(this.datasignataire.ventebl.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';
                    }

                    if (parseInt(this.datasignataire.ventebl.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebl.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebl.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebl.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';

                    }


                    if (parseInt(this.datasignataire.ventebl.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/bl0.PNG';

                    }



                }


                if (this.doctype==='ventef'){

                    dossiervalue=parseInt(this.datasignataire.ventefacture.document)

                    if (parseInt(this.datasignataire.ventefacture.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/f0.png';
                    }

                    if (parseInt(this.datasignataire.ventefacture.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/f0.png';

                    }


                    if (parseInt(this.datasignataire.ventefacture.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/f0.png';

                    }


                    if (parseInt(this.datasignataire.ventefacture.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/f0.png';

                    }


                    if (parseInt(this.datasignataire.ventefacture.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/f0.png';

                    }


                    if (parseInt(this.datasignataire.ventefacture.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/f0.png';

                    }



                }



                if (this.doctype==='venteretourvente'){

                    dossiervalue=parseInt(this.datasignataire.venteretour.document)

                    if (parseInt(this.datasignataire.venteretour.document)===0){


                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';
                    }

                    if (parseInt(this.datasignataire.venteretour.document)===1){

                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';

                    }


                    if (parseInt(this.datasignataire.venteretour.document)===2){

                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';

                    }


                    if (parseInt(this.datasignataire.venteretour.document)===3){

                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';

                    }


                    if (parseInt(this.datasignataire.venteretour.document)===4){

                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';

                    }


                    if (parseInt(this.datasignataire.venteretour.document)===5){

                        mvalue='/gsketat/'+dossiervalue+'/rv0.PNG';

                    }



                }


                return mvalue;
            },
            urlfilesignataire(){


                if (this.jsonmnomsignataire!==""){


                    this.mnomsignataire=this.jsonmnomsignataire.name;
                    this.msignaturesignataire=this.$store.state.baseurlapi+'file/'+this.jsonmnomsignataire.signature;


                    return  this.msignaturesignataire

                }else {

                    this.mnomsignataire="";
                    this.msignaturesignataire="";


                    return ''
                }

            },








            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatablestockminimum(){

                let mvalue=this.datatablestockminimum;

                return this.paginate(mvalue) ;

            },

            filterdatableuploadsignature(){

                let mvalue=this.datatableuploadsignature;

                return mvalue ;

            },
            filterdatablelistenotification(){

                let mvalue=this.datatablenotification;

                return mvalue ;

            },
        },

        data() {
            return {
                searchword:"",
                datatablefindecontratpermannent:[],
                datatabledocday:[],
                alldatalibelle:[],
                datatablestockminimum:[],
                alldatanotification:{
                    users:[],
                    tpnotif:[],
                },
                datatablenotification:[],
                autorisationsystem:{
                    pid:"",
                    tp:"",
                    authorizesignatureelectronique:false,
                    authorizechangementprix:false,
                    authorizenormalisation:false,
                    authorizeprix0:false,
                    authorizefacturactionpartiel:false,
                    authorizevalidationdoc:false,
                    authorizepointdevente:false,
                    authorizelaproceduredevente:1,
                    authorizelaproceduredevalidationdocstock:1,
                    authorizelavalidationdesdocuments:0,

                },
                doctype:"caisse",
                selectedItemslibelle:null,


                mtitresignataire:'',
                mnomsignataire:'',
                jsonmnomsignataire:'',
                msignaturesignataire:'',
                nbrededocumentactive:6,
                datasignataire:{},
                datasignatairep:{},

                notification:{
                    username:null,
                    tpnotif:null,
                    pid:"",
                },




                nameuploadsignature:'',
                titreuploadsignature:'',
                alldata:[],
                datatableuploadsignature:[],


                Itemssignature:{
                    pid:"",
                    username:null,
                    tpnotif:null,
                },

                etatinformationentreprise:{
                    logo:null,
                    ifu:"",
                    rccm:"",
                    raisonsociale:"",
                    adresse:"",
                    telephonne:"",
                },


                logofile: null,
                logouploadPercentage: 0,
                logopreview: null,
                logoimage: null,


                file: null,
                uploadPercentage: 0,
                preview: null,
                image: null,
                preview_list: [],
                image_list: [],




                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],

            };
        },
        methods: {



            reset: function() {
                this.uploadPercentage = 0;
                this.titresignature = "";
                this.image = null;
                this.file = null;
                this.preview = null;
                this.image_list = [];
                this.preview_list = [];
            },

            previewImage: function(event) {
                this.image=null;
                this.file=null;
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview = e.target.result;
                    }
                    this.image=input.files[0];
                    this.file=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            handleFileUpload( event ){
                this.file = event.target.files[0];
            },

            logopreviewImage: function(event) {
                this.logoimage=null;
                this.logofile=null;
                var input = event.target;
                if (input.files) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.logopreview = e.target.result;
                        this.etatinformationentreprise.logo = e.target.result;
                    }
                    this.logoimage=input.files[0];
                    this.logofile=input.files[0];
                    reader.readAsDataURL(input.files[0]);
                }
            },
            logohandleFileUpload( event ){
                this.logofile = event.target.files[0];
            },
            logoreset: function() {
                this.logouploadPercentage = 0;
                this.logoimage = null;
                this.logofile = null;
                this.logopreview = null;
            },

             ajouterline(arrayadd){

                if (arrayadd.length>=6){

                    toastr['error']("Vous ne pouvez pas ajouter de signataire supplémentaire, car vous avez déjà atteint " +
                        "la limite de six (06) signataires autorisés.","<b></b>")
                    this.mtitresignataire="";
                    this.mnomsignataire="";
                    this.msignaturesignataire="";
                    this.jsonmnomsignataire="";
                    return
                }
                arrayadd.push({
                    mtitresignataire:this.mtitresignataire,
                    mnomsignataire:this.mnomsignataire,
                    msignaturesignataire:this.msignaturesignataire,
                    jsonmnomsignataire:this.jsonmnomsignataire,
                })
                this.mtitresignataire="";
                this.mnomsignataire="";
                this.msignaturesignataire="";
                this.jsonmnomsignataire="";
            },

            retirerline(arrayadd,index){
                if (arrayadd.length<=0) return;
                arrayadd.splice(index, 1);

            },

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },

            async loadfincontratdans6mois(){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/personnel/'+this.$store.state.appsociete+'/findecontrat/personnel/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){

                        t.datatablefindecontratpermannent=[];


                        if(res.data.success){

                            t.datatablefindecontratpermannent=res.data.m;

                        }
                        else{
                            t.datatablefindecontratpermannent=[];

                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },

            init(){
                this.inittableaustock();
                this.inittableauelistedocday();

            },



            async loadinformationentreprise() {

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/onoeofienfneioefezp849418119815191981dsdsc1dscds9/'+randomString()+'/infosociete/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
                        if (response.data.success){

                            t.etatinformationentreprise=response.data.m.etatinformationentreprise
                            t.autorisationsystem=response.data.m.etatautorisation

                        }
                        else{

                        }
                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },
            async loaduploadsignature() {
                   const t = this;
                let url=process.env.VUE_APP_API_URL+'/onoeofienfneioefezp84941811sc1dscds9/'+randomString()+'/785488allsign88454ffdre/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
                        if (response.data.success){

                            t.datatableuploadsignature=response.data.m

                        }
                        else{

                        }
                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },
            submitFileinformationentreprise(){

                this.logouploadPercentage = 0;

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                /*
                    Initialize the form data
                */
                let formData = new FormData();
                formData.append('file', this.logofile);
                formData.append('ifu', this.etatinformationentreprise.ifu);
                formData.append('rccm', this.etatinformationentreprise.rccm);
                formData.append('raisonsociale', this.etatinformationentreprise.raisonsociale);
                formData.append('adresse', this.etatinformationentreprise.adresse);
                formData.append('telephonne', this.etatinformationentreprise.telephonne);
                formData.append('rref', randomString(180));
                formData.append('pid', randomString(280));

                /*
                    Make the request to the POST /single-file URL
                */

                let url=this.$store.state.baseurlapi+"/onoeofienfneioefezp84941811sc1dscds9/"+randomString(130)+"/updinfosociete/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,formData,
                    {
                    withCredentials: true,
                        headers: {


                            'Content-Type':'multipart/form-data',
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete


                        },
                        onUploadProgress: function( progressEvent ) {
                            if (this.logofile!==null){
                            this.logouploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))-20
                            //_("loaded_n_total").innerHTML = "Télécharger " + progressEvent.loaded + " bytes of " + progressEvent.total;
                            this.$refs.logoloaded_n_total.innerHTML = "Télécharger " + progressEvent.loaded + " bytes of " + progressEvent.total;
                            var percent =( (progressEvent.loaded / progressEvent.total) * 100)-20;
                            // _("progressBar").value = Math.round(percent);
                            //_("status").innerHTML = Math.round(percent) + "% Téléchargement...  <i class=\"fa fa-refresh fa-spin fa-2x fa-fw\"></i>";
                            this.$refs.logostatus.innerHTML = Math.round(percent) + "% Téléchargement...  <i class=\"ti-spinner\"></i>";

                            }

                        }.bind(this)



                    }
                )
                    .then(function(response){

                    t.$store.dispatch('lancerchargement',false)

                    if(response.data.success){

                        if (t.logofile!==null){

                        t.$refs.logostatus.innerHTML = Math.round(100) + "% Télécharger..."
                        t.logouploadPercentage=parseInt( Math.round( 100 ))

                        }

                        this.logoimage=null;
                        this.logofile=null;


                        toastr['success']( response.data.message,"<b></b>");
                    }
                    else{

                        let
                            $mdataerror=response.data.mdataerror,
                            $messageerrorr="";

                        if ($mdataerror.length>0){

                            $mdataerror.forEach(function ($line) {

                                if ($messageerrorr===""){

                                    $messageerrorr=$line;
                                }else{

                                    $messageerrorr="\n"+$line;

                                }

                            })

                        }
                        else{$messageerrorr=response.data.message;}

                        toastr['error']( $messageerrorr,"<b></b>");


                    }

                })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },
            Saveautorisationupdate(){


                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                this.autorisationsystem.pid=randomString(180);
                this.autorisationsystem.tp=randomString(80);



                let url=this.$store.state.baseurlapi+"/onoeofienfneioefezp84941811sc1dscds9/"+randomString(130)+"/updauto787dfdf/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,this.autorisationsystem,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete


                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    }
                )
                    .then(function(response){

                    t.$store.dispatch('lancerchargement',false)

                    if(response.data.success){


                        toastr['success']( response.data.message,"<b></b>");
                    }
                    else{

                        let
                            $mdataerror=response.data.mdataerror,
                            $messageerrorr="";

                        if ($mdataerror.length>0){

                            $mdataerror.forEach(function ($line) {

                                if ($messageerrorr===""){

                                    $messageerrorr=$line;
                                }else{

                                    $messageerrorr="\n"+$line;

                                }

                            })

                        }
                        else{$messageerrorr=response.data.message;}

                        toastr['error']( $messageerrorr,"<b></b>");


                    }

                })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },
            Savesignataire(){


                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                this.datasignataire.pid=randomString(180);
                this.datasignataire.tp=randomString(80);



                let url=this.$store.state.baseurlapi+"/onoeofienfneioefezp84941811sc1dscds9/"+randomString(130)+"/updautoedition787dfdf/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,this.datasignataire,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete


                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    }
                )
                    .then(function(response){

                    t.$store.dispatch('lancerchargement',false)

                    if(response.data.success){


                        toastr['success']( response.data.message,"<b></b>");
                    }
                    else{

                        let
                            $mdataerror=response.data.mdataerror,
                            $messageerrorr="";

                        if ($mdataerror.length>0){

                            $mdataerror.forEach(function ($line) {

                                if ($messageerrorr===""){

                                    $messageerrorr=$line;
                                }else{

                                    $messageerrorr="\n"+$line;

                                }

                            })

                        }
                        else{$messageerrorr=response.data.message;}

                        toastr['error']( $messageerrorr,"<b></b>");


                    }

                })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },
            SaveUploadsignature(){

                this.uploadPercentage = 0;

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                /*
                    Initialize the form data
                */
                let formData = new FormData();

                /*
                    Add the form data we need to submit
                */
                formData.append('file', this.file);
                formData.append('tsignature', this.titreuploadsignature);
                formData.append('rref', randomString(180));
                formData.append('pid', randomString(180));


                if (this.file===null ){
                    $dataerror.push("Veuillez choisir la signature.....")
                }

                if (this.titreuploadsignature==="" || this.titreuploadsignature===null){
                    $dataerror.push("Veuillez saisir le titre de la signature.....")
                }

                if ($dataerror.length>0){

                    this.$store.dispatch('lancerchargement',false)

                    $dataerror.forEach(function ($line) {

                        if ($messageerrorr===""){

                            $messageerrorr=$line;
                        }else{

                            $messageerrorr+="\n"+$line;

                        }

                    })

                    toastr['error']( $messageerrorr,"<b></b>");

                    return
                }

                /*
                    Make the request to the POST /single-file URL
                */


                let url=this.$store.state.baseurlapi+"/onoeofienfneioefezp84941811sc1dscds9/"+randomString(130)+"/updsignat854552144775556988/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,formData,
                    {
                    withCredentials: true,
                        headers: {


                            'Content-Type':'multipart/form-data',

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        },
                        onUploadProgress: function( progressEvent ) {
                            if (this.file!==null){
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))-20
                                //_("loaded_n_total").innerHTML = "Télécharger " + progressEvent.loaded + " bytes of " + progressEvent.total;
                                this.$refs.loaded_n_total.innerHTML = "Télécharger " + progressEvent.loaded + " bytes of " + progressEvent.total;
                                var percent =( (progressEvent.loaded / progressEvent.total) * 100)-20;
                                // _("progressBar").value = Math.round(percent);
                                //_("status").innerHTML = Math.round(percent) + "% Téléchargement...  <i class=\"fa fa-refresh fa-spin fa-2x fa-fw\"></i>";
                                this.$refs.status.innerHTML = Math.round(percent) + "% Téléchargement...  <i class=\"ti-spinner\"></i>";
                            }

                        }.bind(this)



                    }
                )
                    .then(function(response){
                    // console.log('SUCCESS!!');


                    t.$store.dispatch('lancerchargement',false)

                    if(response.data.success){

                        t.loaduploadsignature();
                        t.$refs.status.innerHTML = Math.round(100) + "% Télécharger..."
                        t.uploadPercentage=parseInt( Math.round( 100 ))

                        toastr['success']( response.data.message,"<b></b>");



                    }
                    else{

                        let
                            $mdataerror=response.data.mdataerror,
                            $messageerrorr="";

                        if ($mdataerror.length>0){

                            $mdataerror.forEach(function ($line) {

                                if ($messageerrorr===""){

                                    $messageerrorr=$line;
                                }else{

                                    $messageerrorr="\n"+$line;

                                }

                            })

                        }
                        else{$messageerrorr=response.data.message;}

                        toastr['error']( $messageerrorr,"<b></b>");


                    }

                })
                    .catch(function(){
                        //    console.log('FAILURE!!');
                    });
            },


            async  deletelinenotification(value){

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)


                let url=this.$store.state.baseurlapi+"/12478855remonotifuser2iofdofiloadexp2/spdel/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,value,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete

                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification

                    }
                )
                    .then(function(response){

                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            t.loadnotificationusers();

                            toastr['success']( response.data.message,"<b></b>");

                        }
                        else{

                            let
                                $mdataerror=response.data.mdataerror,
                                $messageerrorr="";

                            if ($mdataerror.length>0){

                                $mdataerror.forEach(function ($line) {

                                    if ($messageerrorr===""){

                                        $messageerrorr=$line;
                                    }else{

                                        $messageerrorr="\n"+$line;

                                    }

                                })

                            }
                            else{$messageerrorr=response.data.message;}

                            toastr['error']( $messageerrorr,"<b></b>");


                        }

                    })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },

            async  deletelinesignatureUpload(value){

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)


                let url=this.$store.state.baseurlapi+"/onoeofienfneioefezp84941811sc1dscds9/"+randomString(130)+"/suppsignat854552144775556988/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,value,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete


                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification

                    }
                )
                    .then(function(response){

                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            t.loaduploadsignature();

                            toastr['success']( response.data.message,"<b></b>");

                        }
                        else{

                            let
                                $mdataerror=response.data.mdataerror,
                                $messageerrorr="";

                            if ($mdataerror.length>0){

                                $mdataerror.forEach(function ($line) {

                                    if ($messageerrorr===""){

                                        $messageerrorr=$line;
                                    }else{

                                        $messageerrorr="\n"+$line;

                                    }

                                })

                            }
                            else{$messageerrorr=response.data.message;}

                            toastr['error']( $messageerrorr,"<b></b>");


                        }

                    })
                    .catch(function(){
                        //    console.log('FAILURE!!');
                    });
            },

            async loadnotificationusers() {

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/12478855notifuser2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
                        if (response.data.success){

                            t.alldatanotification =response.data.m.alldata;
                            t.datatablenotification =response.data.m.datas;

                        }
                        else{
                            t.alldatanotification =[];
                            t.datatablenotification =[];
                        }
                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            async loadlibelle() {

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/12478855libelinputindex2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
                        if (response.data.success){

                            t.alldatalibelle =response.data.datas;
                            t.selectedItemslibelle=null;

                            //selectedItemslibelle

                        }
                        else{
                            t.alldatalibelle =[];
                            t.selectedItemslibelle =null;
                        }
                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },

            ajouterlinetonotification(){


                if (this.notification.username===null){return;}
                if (this.notification.tpnotif===null){return;}

                let checkline=0;

                for (let i = 0; i < this.datatablenotification.length; i++) {

                    if (this.datatablenotification[i].userkey === this.notification.username.pid) {

                        if (this.datatablenotification[i].notif.pid === this.notification.tpnotif.pid) {
                            checkline=1

                        }


                    }

                    //  console.log(array[i])
                }


                if (checkline===1){
                    toastr['error']("Cette configuration existe déjà.","<b></b>");
                    return
                }

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                this.notification.kpid=randomString(180);
                this.notification.tp=randomString(80);



                let url=this.$store.state.baseurlapi+"/12478855sssnotifuser2iofdofiloadexp2/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,this.notification,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete


                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    }
                )
                    .then(function(response){

                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            t.loadnotificationusers()

                            t.notification.username=null
                            t.notification.tpnotif=null

                            toastr['success']( response.data.message,"<b></b>");
                        }
                        else{

                            let
                                $mdataerror=response.data.mdataerror,
                                $messageerrorr="";

                            if ($mdataerror.length>0){

                                $mdataerror.forEach(function ($line) {

                                    if ($messageerrorr===""){

                                        $messageerrorr=$line;
                                    }else{

                                        $messageerrorr="\n"+$line;

                                    }

                                })

                            }
                            else{$messageerrorr=response.data.message;}

                            toastr['error']( $messageerrorr,"<b></b>");


                        }

                    })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },


            Savelinenotification(){

                if (this.selectedItemslibelle===null){return ;}

                let $dataerror=[],$messageerrorr="";

                this.$store.dispatch('lancerchargement',true)

                this.selectedItemslibelle.kpid=randomString(180);
                this.selectedItemslibelle.tp=randomString(80);

                let url=this.$store.state.baseurlapi+"/12478855libelinputindex2iofdofiloadexp2update/"+this.$store.state.appsociete+'/'+randomString(200);
                let t=this;

                axios.post( url,this.selectedItemslibelle,
                    {
                        headers: {

                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete



                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    }
                )
                    .then(function(response){

                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){


                            toastr['success']( response.data.message,"<b></b>");
                        }
                        else{

                            let
                                $mdataerror=response.data.mdataerror,
                                $messageerrorr="";

                            if ($mdataerror.length>0){

                                $mdataerror.forEach(function ($line) {

                                    if ($messageerrorr===""){

                                        $messageerrorr=$line;
                                    }else{

                                        $messageerrorr="\n"+$line;

                                    }

                                })

                            }
                            else{$messageerrorr=response.data.message;}

                            toastr['error']( $messageerrorr,"<b></b>");


                        }

                    })
                    .catch(function(){
                        t.$store.dispatch('lancerchargement',false)
                    });
            },




        },
        mounted() {

            this.datasignataire= JSON.parse(JSON.stringify(this.$store.state.paramsapp.signataire))

            this.loadinformationentreprise()
            this.loaduploadsignature()
            this.loadnotificationusers()
            this.loadlibelle()



           //this.inittableaustock();
           //this.inittableauelistedocday();
        }






    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/icon?family=Material+Icons');


    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }



    .img-fluid {
        max-width: 150px!important;
        height: auto;
    }


    .input {
        display: block;
        margin-bottom: 1.5em;
        min-width: 10em;
    }
    .input:hover .input__support {
        border-color: #9fa8b2;
    }
    .input:hover .input__support:before {
        color: #7d8a96;
    }
    .input__text {
        display: block;
        padding-bottom: 0.5em;
        margin-left: 0.18em;
    }
    .input__text sup {
        color: #fe4f3e;
        cursor: help;
    }
    .input .input__support:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .input .input__support, .input .input__field {
        border-radius: 0;
        display: block;
        width: 100%;
        padding: 0.5rem 0.75rem;
        font-size: 1rem;
        line-height: 1.25;
        color: #464a4c;
        background-color: #fff;
        background-image: none;
        background-clip: padding-box;
        border: 1px solid #c1c7cd;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .input__field:focus + .input__support {
        border-color: #037DE4;
    }
    .input__field:valid + .input__support {
        border-color: #4DD171;
    }
    .input__field:valid + .input__support:before {
        content: "done";
        color: #4DD171;
    }
    .input__field:valid + .input__support .input__helper:before {
        content: "Fichier sélectionné";
        color: #7d8a96;
    }
    .input__field:invalid + .input__support:before {
        content: "file_upload";
    }
    .input__field:invalid + .input__support .input__helper:before {
        content: "Veuillez sélection le fichier de la signature";
    }
    .input__support {
        display: flex !important;
        align-items: center;
    }
    .input__support:before {
        content: "file_upload";
        display: inline-block;
        color: #9fa8b2;
        width: 1.2rem;
        height: 1.2rem;
        margin: -0.5rem 0.5rem -0.5rem -0.2rem;
        transition: color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .input__helper:before {
        font-weight: 200;
        color: #9fa8b2;
        content: "Select file";
    }
    .input--file {
        cursor: pointer;
    }
    .input--file input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        padding: 0;
    }

    .ti-spinner {
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;
    }




</style>