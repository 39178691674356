<template>


    <nav class="menutop bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">&nbsp;&nbsp;</span>
            </a>
            <button @click="toggleNavbar" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center
            text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" :aria-expanded="isNavbarOpen.toString()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
            <div :class="{ 'hidden': !isNavbarOpen, 'block': isNavbarOpen }" class="w-full md:block md:w-auto" id="navbar-dropdown">
                <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a href="javascript:void(0);" @click="btnmodalindexmagasin"  class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Magasin</a>
                    </li>


                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('cat')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Catalogue</a>
                    </li>

                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('unite')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Unité</a>
                    </li>


                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('depreciation')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Dépréciation</a>
                    </li>



                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('mdreglement')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Mode de réglement</a>
                    </li>



                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('condiction_reglement')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Condiction de réglement</a>
                    </li>


                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <a @click="btnmodalindex('charge')" href="javascript:void(0);"   class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                         md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700
                         dark:hover:text-white md:dark:hover:bg-transparent"
                           aria-current="page">Liste des charges</a>
                    </li>



                    <li >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Dépôt de boisson<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a @click="btnmodalindex('emballage')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Liste des emballages
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </li>

                    <li >
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Restaurant<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a @click="btnmodalindex('accompagnement')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Accompagnement des plats
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalindex('table')" class="ti-dropdown-item" href="javascript:void(0);">
                                       Table
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </li>



                    <li v-if="$store.state.accescomponent.ccparamsystem===true">
                        <div class="hs-dropdown ti-dropdown">
                            <a   href="javascript:void(0);" class="sti-dropdown-toggle block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"

                                    aria-expanded="false">
                                Paramètres d'indications<i
                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                            </a>
                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                aria-labelledby="dropdownMenuButton1">


                                <li>
                                    <a @click="btnmodalindex('paramstock')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Stock
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalindex('paramcom')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Commerciale
                                    </a>
                                </li>

                                <li>
                                    <a @click="btnmodalindex('paramcaisse')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Caisse
                                    </a>
                                </li>

                                <!--li>
                                    <a @click="btnmodalindex('paramcaissedep')" class="ti-dropdown-item" href="javascript:void(0);">
                                        Dépenses
                                    </a>
                                </li-->





                            </ul>
                        </div>

                    </li>



                </ul>
            </div>
        </div>
    </nav>


    <modalindexmagasin ref="modalindexmagasin"></modalindexmagasin>
    <modalindex ref="modalindex"></modalindex>

</template>


<script>

    import modalindexmagasin from "@/components/views/pages/definition/magasin/index";
    import modalindex from "@/components/views/pages/definition/fichiersdebase/index";



    export default {
        /* eslint-disable */
        name: "mymenu",
        data() {
            return {
                isNavbarOpen: false,
                isDropdownOpen: true
            };
        },
        components: {
            modalindex,
            modalindexmagasin,
        },
            methods: {

                btnmodalindex(value){

                    let titlemodal

                    if (value==='centre_de_consommation'){
                        //="Ajouter"
                        titlemodal="Liste des centres de consommations"
                    }


                    if (value==='paramstock'){
                        //="Ajouter"
                        titlemodal="Liste des paramétres d'indication pour la gestion de stock"
                    }

                    if (value==='paramcaisse'){
                        //="Ajouter"
                        titlemodal="Liste des paramétres d'indication pour la gestion des caisses"
                    }

                    if (value==='paramcaissedep'){
                        //="Ajouter"
                        titlemodal="Liste des paramétres d'indication de dépense pour la gestion des caisses"
                    }

                    if (value==='paramcom'){
                        //="Ajouter"
                        titlemodal="Liste des paramétres d'indication"
                    }

                    if (value==='table'){
                        //="Ajouter"
                        titlemodal="Liste des tables"
                    }

                    if (value==='accompagnement'){
                        //="Ajouter"
                        titlemodal="Liste des accompagnements de plat"
                    }

                    if (value==='depreciation'){
                        //="Ajouter"
                        titlemodal="Liste des types de dépréciation"
                    }

                    if (value==='mdreglement'){
                        //="Ajouter"
                        titlemodal="Liste des mode de réglements"
                    }

                    if (value==='cat'){
                        //="Ajouter"
                        titlemodal="Liste des catalogues"
                    }
                    if (value==='unite'){
                        //="Ajouter"
                        titlemodal="Liste des unites"
                    }


                    if (value==='condiction_reglement'){
                        //="Ajouter"
                        titlemodal="Liste des condictions de réglement"
                    }



                    if (value==='emballage'){
                        //="Ajouter"
                        titlemodal="Liste des emballages"
                    }



                    if (value==='charge'){
                        //="Ajouter"
                        titlemodal="Liste des charges"
                    }

                    this.$refs.modalindex.show(titlemodal,value);
                },

                btnmodalindexmagasin(){

                    let titlemodal


                        titlemodal="Liste des magasins"

                    this.$refs.modalindexmagasin.show(titlemodal);
                },



            toggleNavbar() {
                this.isNavbarOpen = !this.isNavbarOpen;
            },
            toggleDropdown() {
                console.log("1122")

                this.isDropdownOpen = !this.isDropdownOpen;

                //console.log("1122 this.isDropdownOpen ",this.isDropdownOpen)
            }
        }
    };
</script>

<style scoped>

     /*@import url('https://cdn.tailwindcss.com');
    @import url("@/assets/css/tailwindv2_0_1.css");*/

    .menutop{
        /*margin-top: 15px!important;*/
        margin-top: 4% !important;
    }

     button:focus {

         outline: 0px auto -webkit-focus-ring-color!important;
     }

     @media screen and (max-width: 800px) {
         .menutop{
             /*margin-top: 15px!important;*/
             margin-top: 12.5% !important;
         }
     }


</style>