<template>
    <aside class="app-sidebar" id="sidebar" @mouseenter="mouseEntered" @mouseleave="mouseLeave">

        <!-- Start::main-sidebar-header -->
        <div class="main-sidebar-header">
            <router-link :to="{name:'acceuilpage'}" class="header-logo">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-logo">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-logo">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-dark">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-dark">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-white">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-white">
            </router-link>


        </div>

        <div class="main-sidebar" id="sidebar-scroll" data-simplebar="init">


            <nav class="main-menu-container nav nav-pills flex-column sub-open">
                <div class="slide-left" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
                                                             height="24" viewBox="0 0 24 24">
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                </svg></div>
                <ul class="main-menu">
                    <!-- Start::slide__category -->
                    <li class="slide__category"><span class="category-name">Menu</span></li>
                    <!-- End::slide__category -->



                    <li class="slide">
                        <router-link @click="clickmenu" :to="{name: 'acceuilpage'}" class="side-menu__item">
                            <i class="bx bx-home side-menu__icon"></i>
                            <span class="side-menu__label">ACCUEIL</span>
                        </router-link>
                    </li>



                    <li class="slide" v-if="viewmenuaccesstableaudebord">
                        <router-link @click="clickmenu" :to="{name: 'tableaudebord'}" class="side-menu__item">
                            <i class="bx bx-home side-menu__icon"></i>
                            <span class="side-menu__label">TABLEAU DE BORD</span>
                        </router-link>
                    </li>


                    <li class="slide" v-if="$store.state.accescomponent.ccparamsystem===true">
                        <router-link @click="clickmenu" :to="{name: 'tbdefinition'}"  class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">DEFINITION</span>
                        </router-link>
                    </li>


                    <li class="slide" v-if="viewmenuaccessstock">
                        <router-link @click="clickmenu" :to="{name:'tstock'}" class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DU <br> STOCK</span>
                        </router-link>
                    </li>


                    <li class="slide" v-if="viewmenuaccesscommercial">
                        <router-link @click="clickmenu" :to="{name:'tgestioncommerciale'}"   class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION <br> COMMERCIALE</span>
                        </router-link>
                    </li>



                    <li class="slide" v-if="viewmenuaccesscaisse">
                        <router-link @click="clickmenu" :to="{name:'tcaisse'}"   class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DE <br> LA CAISSE</span>
                        </router-link>
                    </li>

                    <li class="slide" v-if="viewmenuaccessproduction">
                        <router-link @click="clickmenu" to="#"   class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DE <br> LA PRODUCTION</span>
                        </router-link>
                    </li>


                    <li class="slide" v-if="viewmenuaccesscomptabilite">
                        <router-link @click="clickmenu" :to="{name:'tcomptable'}"   class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DE <br> LA COMPTABILITE</span>
                        </router-link>
                    </li>


                    <li class="slide" v-if="viewmenuaccessetatspecifique">
                        <router-link @click="clickmenu" :to="{name:'trapport'}" class="side-menu__item">
                          <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">RAPPORT</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" to="#" class="side-menu__item">
                          <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">BOUTIQUE EN LIGNE</span>
                        </router-link>
                    </li>



                    <li class="slide" v-if="viewmenuaccessparamsystem">
                        <router-link @click="clickmenu" :to="{name:'tsetting'}" class="side-menu__item">
                            <i class="bx bx-cog header-link-icon animate-spin-slow side-menu__icon"></i>
                            <span class="side-menu__label">PARAMETRES SYSTEME</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" to="#" class="side-menu__item">
                            <i class="bx bxs-contact header-link-icon side-menu__icon"></i>
                            <span class="side-menu__label">SUPPORT</span>
                        </router-link>
                    </li>



                </ul>
                <div class="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
                                                               height="24" viewBox="0 0 24 24">
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                </svg>
                </div>
            </nav>
            <!-- End::nav -->



        </div>
        <!-- End::main-sidebar -->

    </aside>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {

        /* eslint-disable */
        name: "appsidebar",
        computed:{
            ...mapGetters(['lancerchargement','classmenupc','classdefautmenu','niveauclickmenu','myscreenSize','isAuthenticated']),

            viewmenuaccessdefinition(){

                if (this.$store.state.accescomponent.ccdefinition===false
                ) {

                    return false
                }else{ return  true}
            },

            viewmenuaccessproduction(){

                if (this.$store.state.accescomponent.ccproduction===false
                ) {

                    return false
                }else{ return  true}
            },

            viewmenuaccesstableaudebord(){

                if (this.$store.state.accescomponent.tableaudebord===false
                ) {

                    return false
                }else{ return  true}
            },


            viewmenuaccessstock(){

                if (this.$store.state.accescomponent.ccstockproduit===false &&
                    this.$store.state.accescomponent.ccstockmagasin===false &&
                    this.$store.state.accescomponent.ccstockmvtentree===false &&
                    this.$store.state.accescomponent.ccstockmvtdesortie===false &&
                    this.$store.state.accescomponent.ccstockmvtdinventaire===false &&
                    this.$store.state.accescomponent.ccstockmvtdetransfert===false &&
                    this.$store.state.accescomponent.ccstocketathistorique===false &&
                    this.$store.state.accescomponent.ccstocketatstockdesarticles===false &&
                    this.$store.state.accescomponent.ccstocketatmvtdesarticles===false &&
                    this.$store.state.accescomponent.ccstocketatfichepreinventaire===false
                ) {

                    return false
                }else{ return  true}




            },

            viewmenuaccesscaisse(){

                if (this.$store.state.accescomponent.cccaisse===false
                ) {

                    return false
                }else{ return  true}
            },


            viewmenuaccessparamsystem(){

                if (this.$store.state.accescomponent.ccparamsystem===false
                ) {

                    return false
                }else{ return  true}
            },


            viewmenuaccesscomptabilite(){

                if (this.$store.state.accescomponent.cccomptabilite===false
                ) {

                    return false
                }else{ return  true}
            },


            viewmenuaccessetatspecifique(){

                if (this.$store.state.accescomponent.ccetatspecifique===false
                ) {

                    return false
                }else{ return  true}
            },


            viewmenuaccesscommercial(){


                //******************** gestion des accès **********************//

                if (this.$store.state.accescomponent.cccommercialclients===false &&
                    this.$store.state.accescomponent.cccommercialfournisseurs===false &&
                    this.$store.state.accescomponent.cccommercialcollaborateur===false &&
                    this.$store.state.accescomponent.cccommercialachatdemandeachat===false &&
                    this.$store.state.accescomponent.cccommercialachatbondecommande===false &&
                    this.$store.state.accescomponent.cccommercialachatbondereception===false &&
                    this.$store.state.accescomponent.cccommercialachatfacture===false &&
                    this.$store.state.accescomponent.cccommercialachatretoursurachat===false &&
                    this.$store.state.accescomponent.cccommercialventedevis===false &&
                    this.$store.state.accescomponent.cccommercialventebondecommande===false &&
                    this.$store.state.accescomponent.cccommercialventebondepreparationlivraison===false &&
                    this.$store.state.accescomponent.cccommercialventebondelivraison===false &&
                    this.$store.state.accescomponent.cccommercialventefacture===false &&
                    this.$store.state.accescomponent.cccommercialventeretoursurvente===false
                )  {

                    return false
                }else{ return  true}




            },




        },
        data:()=>({

            menuli1:"",
            menuli2:"",
            menuli3:"",
            menuli4:"",
            menuli5:"",
            menuli6:"",
            menuli7:"",
            menuli8:"",
            menuli9:"",
            menuli10:"",

            menua1:"",
            menua2:"",
            menua3:"",
            menua4:"",
            menua5:"",
            menua6:"",
            menua7:"",
            menua8:"",
            menua9:"",
            menua10:"",


            menuul1:"",
            menuul2:"",
            menuul3:"",
            menuul4:"",
            menuul5:"",
            menuul6:"",
            menuul7:"",
            menuul8:"",
            menuul9:"",
            menuul10:"",



        }),
        components: {

        },
        methods: {










            mouseEntered() {
        let html = document.querySelector("html");
        html.setAttribute("icon-overlay", "open");
    },
             mouseLeave() {
        let html = document.querySelector("html");
        html.removeAttribute("icon-overlay");
    },

            toggleClassli(value) {

               /* this.menua1="";
                this.menua2="";
                this.menua3="";
                this.menua4="";
                this.menua5="";
                this.menua6="";
                this.menua7="";
                this.menua8="";
                this.menua9="";
                this.menua10="";*/

                 if (value==="li1"){




                     if (this.menuli1===""){
                         this.menuli1="click";
                         this.menuul1="view";

                         this.menuli2="";
                         this.menuli3="";
                         this.menuli4="";
                         this.menuli5="";
                         this.menuli6="";
                         this.menuli7="";
                         this.menuli8="";
                         this.menuli9="";
                         this.menuli10="";

                         this.menuul2="";
                         this.menuul3="";
                         this.menuul4="";
                         this.menuul5="";
                         this.menuul6="";
                         this.menuul7="";
                         this.menuul8="";
                         this.menuul9="";
                         this.menuul10="";

                     }else{
                         this.menuli1="";
                         this.menuul1="";

                         this.menuli2="";
                         this.menuli3="";
                         this.menuli4="";
                         this.menuli5="";
                         this.menuli6="";
                         this.menuli7="";
                         this.menuli8="";
                         this.menuli9="";
                         this.menuli10="";

                         this.menuul2="";
                         this.menuul3="";
                         this.menuul4="";
                         this.menuul5="";
                         this.menuul6="";
                         this.menuul7="";
                         this.menuul8="";
                         this.menuul9="";
                         this.menuul10="";

                     }



                 }



/*
                if (liElementClass==="slide has-sub") {
                   // liElement.classList.remove('slide has-sub');
                    //liElement.classList.add('slide has-sub active open');

                    liElement.classList.replace('slide has-sub', 'slide has-sub active open');


                }
                else {


                 //  liElement.classList.remove('slide has-sub active open');
                   // liElement.classList.add('slide has-sub');

                    liElement.classList.replace('slide has-sub active open','slide has-sub');



                }
*/

            //    const slideHasSub = document.querySelectorAll(".nav > ul > .slide.has-sub");


                console.log("this.menuli1   ",this.menuli1)

            },

            clickmenu(){

                if (this.myscreenSize <= 990) { // ecran mobile
                let html = document.querySelector("html");
                let responsiveoverlay = document.querySelector("#responsive-overlay");

                html.removeAttribute('data-toggled');
                responsiveoverlay.classList.remove("active");

                html.setAttribute("data-toggled", "close");
                }
            }





        },
        mounted() {


        }

    }
</script>

<style scoped>

    .sousmenuactive{
        position: relative;
        left: 0px;
        top: 0px;
        margin: 0px;
        box-sizing: border-box;
        transform: translate3d(119.2px, 8px, 0px);
        display: block;


    }




</style>