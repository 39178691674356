<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <div class="content">
                <div class="main-content">
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70 dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> Notifications</h3>
                        </div>

                    </div>


                    <div class="container">

                        <div class="grid grid-cols-12 gap-6 !mx-auto">
                            <div class="xxl:col-span-2 col-span-12"></div>
                            <div class="xxl:col-span-8 xl:col-span-12 lg:col-span-12 md:col-span-12 sm:col-span-12 col-span-12">
                                <ul class="list-none mb-0 notification-container">
                                    <li v-for="(message,index) of allnotification" :key="index">
                                        <div class="box un-read">
                                            <div class="box-body !p-4">
                                                <a href="javascript:void(0);">
                                                    <div class="flex items-start mt-0 flex-wrap">
                                                        <!--div class="leading-top">
                                                        <span class="avatar avatar-md online me-4 avatar-rounded">
                                                            <img alt="avatar" src="assets/images/faces/4.jpg">
                                                        </span>
                                                        </div-->
                                                        <div class="flex-grow">
                                                            <div class="sm:flex items-center">
                                                                <div class="sm:mt-0 mt-2">
                                                                    <p class="mb-0 text-[.875rem] font-semibold">{{message.typnotification}}</p>
                                                                    <p class="mb-0 text-[#8c9097] dark:text-white/50" v-html="message.messagetexte ">

                                                                    </p>
                                                                    <span class="mb-0 block text-[#8c9097] dark:text-white/50 text-[0.75rem]"><h2 class="alert-info alert pull-right">{{message.creele}}</h2></span>
                                                                </div>
                                                                <div class="ms-auto">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                                <div class="!text-center mb-4">

                                    <button v-if="chargerplus" type="button" class="ti-btn ti-btn-info ti-btn-loader">Loading
                                        <span class="ti-spinner !h-4 !w-4" role="status"></span>
                                    </button>

                                    <button @click="loadingnotification" v-else type="button" class="ti-btn ti-btn-info ti-btn-loader">
                                        <span class="ri-refresh-line" role="status"></span> Afficher plus de notifications
                                    </button>



                                </div>
                            </div>
                            <div class="xxl:col-span-2 col-span-12"></div>
                        </div>

                    </div>

                </div>
            </div>


        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";

    import {mapGetters} from "vuex";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "convertisseur",
        components: {
            templatesite,
            appheader,
            appsidebar,

        },

        computed:{
            ...mapGetters(['datanotification'])



        },

        data: function() {
            return {

                chargerplus:false,
                allnotification:[]

            };
        },
        methods: {

            async loadingnotification(){

                this.chargerplus=true

                //'/m/12478855allnotifmesgiofdofiloadexp2u/:userpid/:ftoken'

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/12478855allnotifmesgiofdofiloadexp2u/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        t.chargerplus=false

                        if (response.data.success){
                            t.allnotification=response.data.datas.m
                        }
                        else{

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(function () {

                    })

            }


        },
        mounted() {

           this.allnotification=this.datanotification
            this.loadingnotification()
        }






    }
</script>

<style scoped>

    .un-read:hover{
        background-color: lightcyan;
    }

</style>