<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">


                                <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <div class="xl:col-span-3  col-span-12 mt-4 mb-4"></div>


                                <div class="xl:col-span-1  col-span-12 mt-4 mb-4">

                                </div>


                                <div class=" xl:col-span-2  col-span-12 mt-4 mb-4">

                                    <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size w-full"
                                            aria-label="Page Size" title="Affichage">
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                        <option value="250">250</option>
                                        <option value="300">300</option>
                                    </select>
                                </div>

                            </div>


                        </div>


                        <div class="box-body !p-0 mybodyheight300px">
                            <div class="table-responsive">

                                <!-- component -->
                                <div class="flex flex-col h-screen">
                                    <div class="flex-grow overflow-auto">

                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>

                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° document</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Motif / Raison</th>



                                                <template v-if="typedoc==='VDPC'">

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300
                                                    text-start">Caisse débiteur</th>

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300
                                                    text-start">Caisse créditeur</th>

                                                </template>


                                                <template v-if="typedoc==='VAPPROC'">

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300
                                                    text-start">Caisse créditeur</th>

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300
                                                    text-start">Caisse débiteur</th>

                                                </template>

                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant</th>
                                                <template v-if="typedoc==='VAPPROC' || typedoc==='VDPC'">

                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300
                                                    text-start">Etat du transfert</th>

                                                </template>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer le</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">




                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <td  data-label="N°Document  : "   >{{ parseFloat(index)+1 }}</td>
                                                    <td  data-label="N°Document  : "   >{{ datavalue.numdoc }}</td>
                                                    <td  data-label="Date  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="Motif / Raison : " >{{ datavalue.bmotif }}</td>
                                                    <template v-if="typedoc==='VDPC'">
                                                        <td  data-label="Caisse débiteur : " >{{ datavalue.caissedebiteur }}</td>
                                                        <td  data-label="Caisse créditeur : " >{{ datavalue.caissecrediteur }}</td>
                                                        <td data-label="Montant : " >{{  formatNumber(datavalue.montant) }}</td>
                                                        <template v-if="datavalue.etatdelatransaction===0">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-danger" role="alert">
                                                                    Transaction en cours de validation
                                                                </div>
                                                            </td>
                                                        </template>
                                                        <template v-if="datavalue.etatdelatransaction===1">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-success" role="alert">
                                                                    Transaction validée
                                                                </div>
                                                            </td>
                                                        </template>
                                                        <template v-if="datavalue.etatdelatransaction===2">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-danger" role="alert">
                                                                    Transaction annulée
                                                                </div>
                                                            </td>
                                                        </template>

                                                    </template>
                                                    <template v-if="typedoc==='VAPPROC'">
                                                        <td  data-label="Caisse créditeur : " >{{ datavalue.caissecrediteur }}</td>
                                                        <td  data-label="Caisse débiteur : " >{{ datavalue.caissedebiteur }}</td>
                                                        <td data-label="Montant : " >{{  formatNumber(datavalue.montant) }}</td>

                                                        <template v-if="datavalue.etatdelatransaction===0">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-danger" role="alert">
                                                                    Transaction en cours de validation
                                                                </div>
                                                            </td>
                                                        </template>
                                                        <template v-if="datavalue.etatdelatransaction===1">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-success" role="alert">
                                                                    Transaction validée
                                                                </div>
                                                            </td>
                                                        </template>
                                                        <template v-if="datavalue.etatdelatransaction===2">
                                                            <td data-label="Etat du transfert : " >
                                                                <div class="alert alert-danger" role="alert">
                                                                    Transaction annulée
                                                                </div>
                                                            </td>
                                                        </template>



                                                    </template>

                                                    <td  data-label="Créer le  : "   >{{ datavalue.creerler }}</td>

                                                    <td  data-label="Action  : "   >

                                                        <button style="width: 100%!important;" @click="ouvrirdocument(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-primary-full ">
                                                            <i class="ri-check-line"></i> Valider
                                                        </button>
                                                    </td>

                                                </tr>



                                            </template>


                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <formouvrirdoc ref="formouvrirdoc" @closed="load" ></formouvrirdoc>


</template>

<script>



    const axios =require('axios');

    import formouvrirdoc from "@/components/views/pages/caisse/validationtransaction/formouvrirdoc";


    import {currencyFormatDE,  randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                this.perPage=this.DefaultperPage;

              /*  if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return
                       post.namefournisseur.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }

                this.totalmontant=0;
                this.totalmontantrestant=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalmontant=parseInt(t.totalmontant)+parseInt(items.montantbcd);
                    t.totalmontantrestant=parseInt(t.totalmontantrestant)+parseInt(items.montantbcdtrt);
                })*/




                return this.paginate(mvalue) ;
            },
        },
        components: {

            formouvrirdoc,

        },

        data() {
            return {
                isdisplay:false,
                mname:"",
                titlemodal:"",
                titleca:"",
                titlecp:"",
                datatable:[],
                otherdatatable:[],
                listedupersonnel:[],
                datainputlibelleforms:[],
                datatiers:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                vtypc:"",

                totalmontant:0,
                totalmontantrestant:0,

                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            formatNumber(num) {

                return  currencyFormatDE(num)
            },

            btnajouter(){

                let Item={
                    pid:'',
                    datedoc:this.$store.state.dateouverturecaisse,
                    motif:'',
                    docnum:'',
                    ca:null,
                    cp:null,
                    montant:0,
                }

                this.$refs.modalform.show(this.mname,"add",this.typedoc,Item,this.titleca,this.titlecp,this.vtypc,this.otherdatatable);

            },


            ouvrir_viacompteclient(value,typereglement){

                let authoriationplslignedepense=0

                let mname

                let  Item= {
                    solde:value.solde,
                    pid:value.pid,
                    numreglement:'',
                    numfacture:value.numdoc,
                    namecltfrs:value.namecltfrs,
                    mtntotal:value.montanttotal,
                    mtntrestantapayer:value.montantrestant,
                    mtntrestantapayertrt:value.montantrestant,
                    montant:0,
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    docnum:'',
                    deposantname:'',
                    numcheque:'',
                    reglepar:'Caisse',
                    modereglement:null,
                    reglementbanque:null,
                    reglementcaisse:null,
                    params:null,
                    avance:null,
                }


                mname='Régler le document '+value.numdoc;

                this.$refs.modalform.show(mname,"add",this.typedoc,Item,this.otherdatatable,typereglement);

            },


            btnsuppression(value){

                if (value.etatdelatransaction===1 || value.etatdelatransaction==='1'){

                    toastr['error']( "Impossible d'annuler cette ligne. Veuillez contacter votre administrateur. ","<b></b>");
                    return
                }


                let titlemodal="VOULLEZ-VOUS VRAIMENT ANNULER LE DOCUMENT "+value.numdoc+" ?";


                value.typligne="A"+this.typedoc

                this.$refs.modalformsuppression.show(titlemodal,value,this.typedoc);

            },



            ouvrirdocument(value){

                let mname

                let  Item= value


                this.$refs.formouvrirdoc.show(mname,Item,this.titleca,this.titlecp,this.typedoc);

            },


            async load(){

                var caisse={
                    cf:this.$store.state.caisseinfo,
                    document:this.typedoc,
                    vtypc:this.vtypc
                }


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/fkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/895418init51_refdepdpcc11515/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.m.datas;
                            /*t.cp=res.data.m.cp;
                            t.ca=res.data.m.ca;*/
                            t.otherdatatable={
                                cp:res.data.m.cp,
                                ca:res.data.m.ca,
                            }
                             t.isdisplay=true;
                        }
                        else{
                            t.datatable=[];

                            t.otherdatatable={
                                cp:[],
                                ca:[],
                            }
                            t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadreturnotherdata(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/other222dksdk/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            t.otherdatatable=[];
                            t.otherdatatable=res.data.otherdata;
                        }
                        else{
                            t.otherdatatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },




            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },


            show(titlemodal,typedoc,vtypc){


                if(this.$store.state.caisseencour==='' ||
                    this.$store.state.caisseencour===''){

                    toastr['error']( "Veuillez sélectionner une caisse valide.")
                     this.$router.push({ name: 'tcaisse' });
                }
                else{

                    this.messagesucces="";
                    this.messageerror="";
                    this.titlemodal=titlemodal
                    this.typedoc=typedoc
                    this.vtypc=vtypc

                    if (this.typedoc==="VDPC"){

                        this.titlemodal="Liste des transferts de fonds de caisse auxilliaire"
                        this.mname=" Valider un transfert de fond de caisse auxilliaire"

                        this.titleca="Caisse auxilliaire"
                        this.titlecp="Caisse principale"

                    }

                    if (this.typedoc==="VAPPROC"){

                        this.titlemodal="Liste des transferts de fonds caisse principale"
                        this.mname=" Valider un transfert de fond caisse principale"

                        this.titleca="Caisse principale / auxilliaire"
                        this.titlecp="Caisse principale"

                    }

                   this.load()


                }

            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>