<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">


                                <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <div class="xl:col-span-3  col-span-12 mt-4 mb-4"></div>


                                <div class="xl:col-span-1  col-span-12 mt-4 mb-4">
                                    <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem] w-full">
                                        <i class="ri-add-line  align-middle"></i>Ajouter
                                    </button>
                                </div>



                                <div class=" xl:col-span-4  col-span-12 mt-4 mb-4">

                                    <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size form-control w-full"
                                            aria-label="Page Size" title="Affichage">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                    </select>
                                </div>


                                <div class=" xl:col-span-2  col-span-12 mt-4 mb-4">

                                    <div class="hs-dropdown ti-dropdown">
                                        <button class="ti-btn ti-btn-primary-full ti-dropdown-toggle !py-2"
                                                type="button" id="dropdownMenuButton1b"
                                                aria-expanded="false">
                                            AFFICHER LA COLONNE<i
                                                class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                        </button>
                                        <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                            aria-labelledby="dropdownMenuButton1b">


                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums5" type="checkbox" class="custom-control-input" id="activecolums5">
                                                        <label class="custom-control-label" for="activecolums5">&nbsp;&nbsp;&nbsp; Unité</label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums1" type="checkbox" class="custom-control-input" id="activecolums1">
                                                        <label class="custom-control-label" for="activecolums1">&nbsp;&nbsp;&nbsp; Article en sommeil</label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums2" type="checkbox" class="custom-control-input" id="activecolums2">
                                                        <label class="custom-control-label" for="activecolums2">&nbsp;&nbsp;&nbsp; Code barre</label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums3" type="checkbox" class="custom-control-input" id="activecolums3">
                                                        <label class="custom-control-label" for="activecolums3">&nbsp;&nbsp;&nbsp; Périssable</label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums4" type="checkbox" class="custom-control-input" id="activecolums4">
                                                        <label class="custom-control-label" for="activecolums4">&nbsp;&nbsp;&nbsp; Créer le</label>
                                                    </div>
                                                </a>
                                            </li>


                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums6" type="checkbox" class="custom-control-input" id="activecolums6">
                                                        <label class="custom-control-label" for="activecolums6">&nbsp;&nbsp;&nbsp; SUIVIE DE STOCK</label>
                                                    </div>
                                                </a>
                                            </li>

                                            <li>
                                                <a class="ti-dropdown-item" href="#">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="activecolums7" type="checkbox" class="custom-control-input" id="activecolums7">
                                                        <label class="custom-control-label" for="activecolums6">&nbsp;&nbsp;&nbsp; LISTE DES ACCOMPAGNEMENT</label>
                                                    </div>
                                                </a>
                                            </li>





                                        </ul>
                                    </div>
                                </div>

                                <div class=" xl:col-span-2  col-span-12 mt-4 mb-4">

                                    <div class="hs-dropdown ti-dropdown">
                                        <button class="ti-btn ti-btn-purple-full ti-dropdown-toggle !py-2"
                                                type="button" id="dropdownMenuButton2b"
                                                aria-expanded="false">
                                            AUTRE ACTION<i
                                                class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                        </button>
                                        <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                            aria-labelledby="dropdownMenuButton2b">


                                            <li>
                                                <button @click="btnmodallistearticletrt('ensommeil')" class="ti-dropdown-item">Afficher les articles/services inactifs</button>
                                            </li>

                                            <li>
                                                <button @click="btnmodallistearticletrt('suivistock')" class="ti-dropdown-item">Afficher les articles sans suivi de stock</button>
                                            </li>


                                            <li v-if="afficheview">
                                                <button @click="btnmaintenancestock()" class="ti-dropdown-item">Correction du stock apres insertion des données sur une date </button>
                                            </li>


                                            <li>
                                                <button  class="ti-dropdown-item">Mettre en veille les articles inexploiter depuis 4 mois( <span style="color: red">Bientôt disponible</span>)</button>
                                            </li>




                                        </ul>
                                    </div>
                                </div>

                            </div>


                            <div class="w-full grid grid-cols-1">


                            </div>






                        </div>


                        <div class="box-body !p-0 mybodyheight400px">
                            <div class="table-responsive">



                                <!-- component -->
                                <div class="flex flex-col h-screen">
                                    <div class="flex-grow overflow-auto">

                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th-->
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ARTICLE / SERVICES</th>
                                                <th v-if="activecolums5" scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">UNITE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CATALOGUE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">TYPE DE REGIME</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">PRIX D'ACHAT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">PRIX DE VENTE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">PRIX PROMO</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums6">SUIVIE DE STOCK</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums2">CODE BARRE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums3">PERISSABLE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums1">EN SOMMEIL</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums7">LISTE DES ACCOMPAGNEMENT</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" v-if="activecolums4">CREER LE</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="loadingtable">

                                            <template v-if="filterdatatable.length">

                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <!--td data-label="N°  : ">{{index+1}}</td-->
                                                    <td  data-label="ARTICLE / SERVICES  : "   >{{ datavalue.libelle }}</td>
                                                    <td v-if="activecolums5"  data-label="UNITE  : "   >{{ datavalue.unite }}</td>
                                                    <td  data-label="CATALOGUE  : "   >{{ datavalue.catalogue }}</td>
                                                    <td  data-label="TYPE DE REGIME  : "   >{{ datavalue.taxedimposition }}</td>
                                                    <td  data-label="PRIX D'ACHAT  : "   >{{ formatMoney(datavalue.pa)  }}</td>
                                                    <td  data-label="PRIX DE VENTE  : "   >{{ formatMoney(datavalue.pu) }}</td>
                                                    <td  data-label="PRIX PROMO  : "   >{{ formatMoney(datavalue.promo) }}</td>
                                                    <td v-if="activecolums6"   data-label="SUIVIE DE STOCK  : "   >{{ (datavalue.suividestock===1)?'Oui':'Non' }}</td>
                                                    <td v-if="activecolums2"  data-label="CODE BARRE  : "   >{{ datavalue.codebarre }}</td>
                                                    <td v-if="activecolums3"  data-label="PERISSABLE  : "   >{{ datavalue.perisable }}</td>
                                                    <td v-if="activecolums1"  data-label="EN SOMMEIL  : "   >{{ (datavalue.arsommeil===1)?'Oui':'Non' }}</td>
                                                    <td v-if="activecolums7"  data-label="LISTE DES ACCOMPAGNEMENT : "   >{{ (datavalue.arsommeil===1)?'Oui':'Non' }}</td>
                                                    <td v-if="activecolums4"   data-label="CREER LE  : "   >{{ datavalue.creerler }}</td>



                                                        <td  data-label="ACTION  : "   >


                                                        <div class="ti-btn-group">
                                                            <div class="hs-dropdown ti-dropdown">
                                                                <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                        id="dropdownMenuButton1"
                                                                        aria-expanded="false">
                                                                    Action<i
                                                                        class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                </button>
                                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                    aria-labelledby="dropdownMenuButton1">


                                                                    <li>
                                                                        <button style="width: 100%!important;" @click="btnmodifier(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-pen-nib-line"></i> Modifier
                                                                        </button>
                                                                    </li>


                                                                    <li>
                                                                        <button style="width: 100%!important;" @click="generercodebarre(datavalue.codebarre,datavalue.libelle)"    aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-qr-code-fill"></i> Générer code barre
                                                                        </button>
                                                                    </li>


                                                                    <li>
                                                                        <button style="width: 100%!important;" @click="btnsuppression(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-delete-bin-line"></i> Supprimer
                                                                        </button>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                        </div>




                                                    </td>

                                                </tr>



                                            </template>
                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>

                                            </template>


                                            <template v-else>

                                                <tr >
                                                    <td colspan="20">
                                                        <div class="animate-pulse">
                                                            <div class="h-4 bg-gray-200 mt-3 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-300 mb-6 rounded"></div>
                                                            <div class="h-4 bg-gray-200 mb-6 rounded"></div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>

                            </div>
                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalcodebarre ref="modalcodebarre"  ></modalcodebarre>
    <modalform ref="modalform"  @closed="load"></modalform>
    <modallistearticletrt ref="modallistearticletrt"  @closed="load"></modallistearticletrt>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>

</template>

<script>
    /* import templatesite from "@/components/template/apptemplate";
     import appheader from "@/components/template/appheader";
     import appsidebar from "@/components/template/appsidebar";*/


    import {mapGetters} from "vuex";

    const axios =require('axios');

    import modalform from "@/components/views/pages/stock/articles/form";
    import modalcodebarre from "@/components/views/pages/stock/articles/modal_codebarre";
    import modalformsuppression from "@/components/views/pages/stock/articles/formsuppression";
    import modallistearticletrt from "@/components/views/pages/stock/articles/modal_listearticletrt";

    import {cloneobjet, currencyFormatDE, isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

  /*  const isset = (accessor) => {
            try {
                // Note we're seeing if the returned value of our function is not
                // undefined or null
                return accessor !== undefined && accessor !== null
            } catch (e) {
                // And we're able to catch the Error it would normally throw for
                // referencing a property of undefined
                return false
            }


        }*/

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
          /*  searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

                // console.log("  oldvalue  searchtrierletableau        **************** ",oldvalue)
                // console.log("  newvalue   searchtrierletableau       **************** ",newvalue)

            }*/

        },
        computed:{
            ...mapGetters(['listedesarticles']),

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                // let mvalue=this.datatable;
                let mvalue=cloneobjet(this.listedesarticles)

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /*if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.removeline===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.libelle.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }



                return this.paginate(mvalue) ;
            },
        },
        components: {
            modalform,modalformsuppression,modalcodebarre,
            modallistearticletrt

        },

        data() {
            return {
                afficheview:true,
                loadingtable:true,
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                listedupersonnel:[],
                datatiers:[],
                otherdata:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                ismodalviewcodebarre:false,



                activecolums1:false,
                activecolums2:false,
                activecolums3:false,
                activecolums4:false,
                activecolums5:false,
                activecolums6:false,
                activecolums7:false,


                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 10,
                Defaultpage: 1,
                DefaultperPage: 10,
                pages: [],
            };
        },
        methods: {



            async btnmaintenancestock(){

                const t = this;
                t.$store.dispatch('lancerchargement',true)

                let $datedebut='2024-11-10',$datefin='2024-11-13';

                let url=process.env.VUE_APP_API_URL+'/maintenance/stock/line/'+this.$store.state.appsociete+'/'+$datedebut+'/'+$datefin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            console.log(res.data)

                           // t.datatable=[];
                          //  t.datatable=res.data.m;
                            // t.isdisplay=true;


                        }
                        else{
                           // t.datatable=[];


                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        // t.$store.dispatch('lancerchargement',false)


                    })




            },





            btnmodallistearticletrt(value) {

                this.$refs.modallistearticletrt.show(value);

            },


            generercodebarre(valuecodebarre,valuename) {

                this.$refs.modalcodebarre.show(valuecodebarre,valuename);

            },

            formatMoney(value){
                return currencyFormatDE(value)

            },

            btnajouter(){


                let mname="Enregistrer un article"


                let  Item=   {
                    pid:'',libelle:'',reference:'',suividestock:0,
                    articletype:0,
                    nbreusearticle:0,
                    usear:0,
                    stockminimum:0
                    ,lignetitre:''
                    ,lignedescription:''
                    ,lignedescriptiontotal:''
                    ,lignephotodegarde:''
                    ,lignesommeil:''
                    ,lignein_stock:''
                    ,lignecategorie:''
                    ,lignesous_categorie:''
                    ,lignemontant:''
                    ,ligneremise:''
                    ,lignenbredevue:''
                    ,ligneboutique:''
                    ,ligneboutiquepid:'',

                    datajsonarticles:{
                        typearticle:"Article",
                        perisable:false,
                        ensommeil:false,
                        tptva:1,
                        stockminimum:0,
                        stockmaximum:0,
                        magasin:null,
                        unite:null,
                        catalogue:null,
                        comptetva:{compteventeexport:null,comptefacturation:null,
                            compteventelocal:null},
                        normalisation:{taxedimposition:"E",tauxtaxedimposition:null,
                            tauxtaxespecifique:null,descriptiondutsapplique:null},

                        tarification:{prixvente1:0,prixvente2:0,prixvente3:0,prixvente4:0,prixvente5:0,
                            prixvente6:0,prixvente7:0,prixachat:0,prixvente:0,
                            typeprixvente:0,prixpromo:0, typttc:1.18,},
                        information:{info:'',info1:'',info2:'',info3:''},
                        paramdindication:{
                            param:null,param1:null,
                            param2:[],

                            param3:{
                                typeoperation:'default',
                                suiviedemballage:'Non',
                                emballage:null
                            }


                        },
                    },
                    articlesenligne:{
                        titreenligne:'....',
                        montantenligne:0,
                        montantremiseenligne:0,
                        minidescriptionenligne:'.....',
                        totaldescriptionenligne:'......',
                        categorieenligne:null,
                        souscategorieenligne:null,
                        lesphotosenligne:[],
                        articledisponible:true,
                        miseenligne:false,
                    },
                    articlesenrestaurant:{
                        accompagnement:[]
                    },
                    articlesclinique:[],

                };




                this.$refs.modalform.show(mname,"add",Item,this.datainputlibelleforms,this.otherdata);

            },

            btnmodifier(value){


                let mname="Modification de l'article : "+value.libelle


                let  Item=value;

                this.$refs.modalform.show(mname,"update",Item,this.datainputlibelleforms,
                    this.otherdata,value.jsonarticles,value.accompagnement);

            },

            async load(){
/*
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pstock771714dfe/ar/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                       // t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.datatable=[];
                            t.datatable=res.data.m;
                            // t.isdisplay=true;
                             t.loadingtable=true;

                        }
                        else{
                            t.datatable=[];
                           t.loadingtable=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                       // t.$store.dispatch('lancerchargement',false)


                    })

*/

                this.$store.dispatch('loadlistedesarticles')


            },


            async loadinputlibelleforms(){



                let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                if (isset($VALUEDATAPRICE)){

                    this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                }
                else{

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/12478855libelinputindex2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.datainputlibelleforms=[];

                            t.datainputlibelleforms=res.data.valuedataprice;

                            JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))


                        }
                        else{
                            t.datainputlibelleforms=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                }



            },

            async loadreturnotherdata(){

               /* let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                if (isset($VALUEDATAPRICE)){

                    this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                }
                else{*/


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/ar85474524otherdatadofodfidfoioienf55485/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.otherdata=[];

                            t.otherdata=res.data.otherdata;

                           // JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))

                        }
                        else{
                            t.otherdata=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
              //  }



            },



            btnsuppression(value){

                if (value.nbreusearticle===1 || value.nbreusearticle==='1'){

                    toastr['error']( "Impossible de supprimer cet article. Veuillez contacter votre administrateur. ","<b></b>");
                    return
                }


                let titlemodal="VOULLEZ-VOUS VRAIMENT SUPPRIMER "+value.libelle+" ?";

                this.$refs.modalformsuppression.show(titlemodal,value);

            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },

            show(titlemodal){


                this.messagesucces="";
                this.messageerror="";
                this.titlemodal=titlemodal;

                this.loadreturnotherdata();
                this.loadinputlibelleforms();
                //this.load();
                this.isdisplay=true;
            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>




























    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>