<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">


                                <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <div class="xl:col-span-3  col-span-12 mt-4 mb-4"></div>

                                <div class="xl:col-span-1  col-span-12 mt-4 mb-4">
                                    <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem] w-full">
                                        <i class="ri-add-line  align-middle"></i>Ajouter
                                    </button>
                                </div>


                                <div class=" xl:col-span-2  col-span-12 mt-4 mb-4">

                                    <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size w-full"
                                            aria-label="Page Size" title="Affichage">
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                        <option value="250">250</option>
                                        <option value="300">300</option>
                                    </select>
                                </div>

                            </div>


                            <div class="w-full flex grid-cols-2">

                                <div class="box-title mb-2 sm:mb-0"></div>

                                <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">

                                    <!--div class="grid sm:grid-cols-2 gap-6">
                                        <div class="space-y-2 mt-3 mb-3">
                                            <input type="date"  @change="load" v-model="datedebut" placeholder="Date début">
                                        </div>

                                        <div class="space-y-2 mt-3 mb-3">
                                            <input type="date" @change="load" v-model="datefin" placeholder="Date fin">
                                        </div>

                                    </div-->


                                </div>
                            </div>




                        </div>


                        <div class="box-body !p-0 mybodyheight300px">
                            <div class="table-responsive">

                                <!-- component -->
                                <div class="flex flex-col h-screen">
                                    <div class="flex-grow overflow-auto">

                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>

                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° document</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° bon</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">{{nametitre}}</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Régler par</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Motif</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Saisir par</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">

                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <td data-label="N°  : ">{{index+1}}</td>
                                                    <td  data-label="N° document  : "   >{{ datavalue.numdoc }}</td>
                                                    <td  data-label="N° bon  : "   >{{ datavalue.numfacture }}</td>
                                                    <td  data-label="Date  : "   >{{ datavalue.datop }}</td>
                                                    <td  :data-label="nametitre +' : ' "   >{{ datavalue.namecltfrs }}</td>
                                                    <td  data-label="Régler par : "   >{{ datavalue.reglepar }}</td>
                                                    <td  data-label="Motif  : "   >{{ datavalue.bmotif }}</td>
                                                    <td  data-label="Saisir par  : "   >{{ datavalue.saisirpar }}</td>
                                                    <td style="text-align: center;"  data-label="Montant  : "   >{{ formatNumber(datavalue.montant) }}</td>
                                                   <td  data-label="Action  : "   >
                                                        <button style="width: 100%!important;" @click="btnimprimer(datavalue)"  aria-label="anchor"  class=" ti-btn  ti-btn-primary-full">
                                                            <i class="ri-printer-line"></i> Imprimer
                                                        </button>

                                                    </td>

                                                </tr>


                                                <tr>
                                                    <td colspan="8" style="text-align: center" data-label="Total : ">Total</td>
                                                    <td style="    font-weight: 900;" data-label="Montant : ">{{formatNumber(totalmontant)+' FCFA'}}</td>
                                                    <td ></td>
                                                </tr>



                                            </template>


                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>

                            </div>
                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalform ref="modalform"  @closed="load"></modalform>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>
    <modalregelementavance ref="modalregelementavance" ></modalregelementavance>


</template>

<script>


    const axios =require('axios');

    import modalform from "@/components/views/pages/caisse/avance/form";
    import modalformsuppression from "@/components/views/pages/caisse/avance/formsuppression";


    import {currencyFormatDE,isValidDateString, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modalregelementavance from "@/components/views/pages/caisse/rapport/modal_rapport_regelement_avance";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                this.perPage=this.DefaultperPage;

                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {

                        return  post.namecltfrs.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }

                this.totalmontant=0;
                let t=this;
                mvalue.forEach(function (items) {
                    t.totalmontant=parseInt(t.totalmontant)+parseInt(items.montant);
                })




                return this.paginate(mvalue) ;
            },
        },
        components: {
            modalform,modalformsuppression,
            modalregelementavance

        },

        data() {
            return {
                isdisplay:false,
                nametitre:"",
                titlemodal:"",
                datatable:[],
                otherdatatable:[],
                listedupersonnel:[],
                datainputlibelleforms:[],
                datatiers:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                datedebut:"",
                datefin:"",

                totalmontant:0,
                totalmontantrestant:0,





                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {


            btnimprimer(value){



                let t=this

                let libelcltfrs,deposantname
                if (this.typedoc==="FRS"){

                    deposantname="NOM DU DEPOSANT"
                    libelcltfrs="Fournisseur"

                }
                else{
                   deposantname="NOM DU BENEFICIAIRE"
                    libelcltfrs="Client"
                }

                let tabvalue={
                    mtypdoc:"AVC"+this.typedoc,
                    numdoc:value.numdoc,
                    numfacture:value.numfacture,
                    namecltfrs:value.namecltfrs,
                    montant:value.montant,
                    mtnttotaldoc:value.mtnt,
                    mtntrestantapayer:0,
                    mtntrestant:0,
                    date:value.datop,
                    docobservation:value.motif,
                    deposantname:value.deposantname,
                    numcheque:value.numcheque,
                    reglepar:value.reglepar,
                    modereglement:value.modereglement,
                    reglementbanque:value.banque,
                    reglementcaisse:"",
                    avance:"",


                    libelcltfrs:libelcltfrs,
                    libeldeposantname:deposantname,
                    docsaisiepar:value.saisirpar,


                }



                this.$refs.modalregelementavance.show(tabvalue);



            },



            formatNumber(num) {

                return  currencyFormatDE(num)
            },

            btnajouter(){


                let authoriationplslignedepense=0

                let mname,Item={
                    datedoc:this.$store.state.appserverdate,
                    numreference:"",
                    montant:0,
                    mtntotal:0,
                    mtntrestantapayertrt:0,
                    namecltfrs:"",
                    numfacture:null,
                    montantfacture:0,
                    pid:'',
                    numreglement:'',
                    docobservation:'',
                    docnum:'',
                    reglepar:'Caisse',
                    modereglement:null,
                    reglementbanque:null,
                    reglementcaisse:null,
                    avance:null,
                    deposantname:'',
                    numcheque:'',

                }

                mname='Avance ';

                let mtypedoc="AVC"+this.typedoc

                this.$refs.modalform.show(mname,"add",mtypedoc,Item,this.otherdatatable);


            },

            async load(){


                if (!isValidDateString(this.datedebut) || !isValidDateString(this.datefin)){return }


                var caisse={
                    cf:this.$store.state.caisseinfo,
                    document:"AVC"+this.typedoc,
                    cc:randomString(),
                }


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/fkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/895418init51_refdepdpcc11515/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.m.datas;
                            t.isdisplay=true;
                        }
                        else{
                            t.datatable=[];
                            t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })






            },


            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },


            show(titlemodal,typedoc){


                if(this.$store.state.caisseencour==='' ||
                    this.$store.state.caisseencour===''){

                    toastr['error']( "Veuillez sélectionner une caisse valide.")
                    this.$router.push({ name: 'tcaisse' });
                }
                else{


                    this.messagesucces="";
                    this.messageerror="";
                    this.titlemodal=titlemodal

                    this.typedoc=typedoc

                    if (typedoc==='FRS'){

                      this.nametitre="Fournisseur"
                    }
                    else{
                        this.nametitre="Client"

                    }



                    this.datedebut=this.$store.state.appserverdate
                    this.datefin=this.$store.state.appserverdate


                    // this.isdisplay=true;

                    // this.loadinputlibelleforms()
                    this.loadreturnotherdata()
                    this.load()


                }

            },


            async loadreturnotherdata(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/other222dksdk/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                    ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            t.otherdatatable=[];
                            t.otherdatatable=res.data.otherdata;
                        }
                        else{
                            t.otherdatatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },








            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>