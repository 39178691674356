<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">

                                <div class="xl:col-span-6 mt-3  col-span-12">
                                    <label  class="form-label">Année</label>
                                    <select  @change="loadupdate" v-model="manne" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;"
                                            class="ti-form-select rounded-sm !py-2 !px-3 form-control w-full">
                                        <option v-for="(anne,index) of listedesannees" :key="index" :value="anne">{{anne}}</option>
                                    </select>
                                </div>

                                <div class="xl:col-span-6 mt-3 mb-3 col-span-12">
                                    <label  class="form-label">Affichage en</label>

                                    <select v-model="typaffiche" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;"
                                            class="ti-form-select rounded-sm !py-2 !px-3 form-control w-full">
                                        <option value="Graph">Graph</option>
                                        <option value="Tableau">Tableau</option>
                                    </select>


                                </div>



                            </div>



                        </div>



                        <div v-show="typaffiche==='Tableau'" class="box-body !p-0 mybodyheight300px">

                            <div  class="grid grid-cols-12 mt-7 gap-6">



                            <div class="table-responsive xl:col-span-6 col-span-12">

                                    <div class="box custom-box">
                                        <div class="box-header justify-between">
                                            <div class="box-title">
                                                TOP 10 DES MEILLEURS CLIENTS
                                            </div>

                                        </div>
                                        <div class="box-body">


                                            <div class="flex flex-col h-screen">
                                                <div class="flex-grow overflow-auto">

                                                    <table class="relative w-full border table mytable table-hover whitespace-nowrap overflow-x-scroll min-w-full">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CLIENT</th>
                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MONTANT</th>


                                                        </tr>
                                                        </thead>
                                                        <tbody>


                                                        <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in dataclient" :key="index">

                                                            <td  data-label="CLIENT  : "   >{{ datavalue.raisonsociale }}</td>
                                                            <td class="text-center" style="text-align: center;" data-label="MONTANT  : "   >{{ formatMoney(datavalue.montanttotal) }}</td>

                                                        </tr>





                                                        </tbody>

                                                    </table>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="box-footer border-t-0 hidden">

                                        </div>
                                    </div>

                            </div>



                            <div class="table-responsive xl:col-span-6 col-span-12">

                                <div class="box custom-box">
                                    <div class="box-header justify-between">
                                        <div class="box-title">
                                            TOP 10 DES CREANCES CLIENTS
                                        </div>

                                    </div>
                                    <div class="box-body">


                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                                <table class="relative w-full border table mytable table-hover whitespace-nowrap overflow-x-scroll min-w-full">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CLIENT</th>
                                                        <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MONTANT</th>


                                                    </tr>
                                                    </thead>
                                                    <tbody>


                                                    <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datacreance" :key="index">

                                                        <td  data-label="CLIENT  : "   >{{ datavalue.raisonsociale }}</td>
                                                        <td class="text-center" style="text-align: center;" data-label="MONTANT  : "   >{{ formatMoney(datavalue.montanttotal) }}</td>

                                                    </tr>





                                                    </tbody>

                                                </table>



                                            </div>
                                        </div>


                                    </div>
                                    <div class="box-footer border-t-0 hidden">

                                    </div>
                                </div>

                            </div>





                            </div>
                        </div>



                        <div v-show="typaffiche==='Graph'" class="box-body !p-0 mybodyheight500px">

                       <div  class="grid grid-cols-12 mt-7 gap-6">

                            <div class="xl:col-span-6 col-span-12">
                                <div class="box custom-box">
                                    <div class="box-header justify-between">
                                        <div class="box-title">
                                            TOP 10 DES MEILLEURS CLIENTS
                                        </div>

                                    </div>
                                    <div class="box-body">


                                        <div id="chart">
                                            <apexcharts ref="apexChart" type="pie" height="350"
                                                        :options="chartOptions1" :series="series1"></apexcharts>
                                        </div>



                                    </div>
                                    <div class="box-footer border-t-0 hidden">

                                    </div>
                                </div>
                            </div>


                            <div class="xl:col-span-6 col-span-12">
                                <div class="box custom-box">
                                    <div class="box-header justify-between">
                                        <div class="box-title">
                                            TOP 10 DES CREANCES CLIENTS
                                        </div>

                                    </div>
                                    <div class="box-body">


                                        <div id="chart2">
                                            <apexcharts ref="apexChart1" type="pie" height="350"
                                                        :options="chartOptions1" :series="series1"></apexcharts>
                                        </div>



                                    </div>
                                    <div class="box-footer border-t-0 hidden">

                                    </div>
                                </div>
                            </div>



                        </div>



                        </div>


                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>




</template>

<script>

    import VueApexCharts from "vue3-apexcharts";


    const axios =require('axios');


    import {currencyFormatDE, currentMonth, currentYear, getYear2020, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },

            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                let t=this

                t.mmontanttotal=0;

                mvalue.forEach(function ($line) {
                    t.mmontanttotal=parseFloat(t.mmontanttotal)+parseFloat($line.mmontant);
                })





                return this.paginate(mvalue) ;
            },
        },
        components: {
            apexcharts: VueApexCharts,

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",

                mmontanttotal:0,
                mmois:currentMonth(),
                manne:currentYear(),
                listedesannees:[],
                typaffiche:'Graph',

                datatable:[],
                afichagetable:[],
                alldata:[],

                allgraphsclient:[],
                allgraphscreance:[],

                dataclient:[],
                datacreance:[],


                labels:[],
                dataset:[],


                series: [],
                chartOptions: {
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: [],
                    colors:[],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },

                series1: [],
                chartOptions1: {
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: [],
                    colors:[],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {


            formatMoney(num) {

                return  currencyFormatDE(num)
            },


            async loadupdate(){


                        this.$store.dispatch('lancerchargement',true)

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/rapport/gescom/'+this.manne+'/'+this.mmois+'/'+this.$store.state.appsociete+'/rttu44cltessentiel785488kjuii_0287845854/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.dataclient=[];
                            t.datacreance=[];
                            t.allgraphsclient=[];
                            t.allgraphscreance=[];

                            t.dataclient=res.data.dt1;
                            t.datacreance=res.data.dt2;

                            t.allgraphsclient=res.data.gph1;
                            t.allgraphscreance=res.data.gph2;


                            t.series=t.allgraphsclient.dataset;
                            t.series1=t.allgraphscreance.dataset;

                            t.chartOptions.labels=t.allgraphsclient.labels;
                            t.chartOptions1.labels=t.allgraphscreance.labels;

                            t.chartOptions.colors=t.allgraphsclient.backgroundColor;
                            t.chartOptions1.colors=t.allgraphscreance.backgroundColor;



                            //t.$refs.apexChart.updateOptions(t.chartOptions);
                            //t.$refs.apexChart1.updateOptions(t.chartOptions1);


                            t.$refs.apexChart.updateOptions({
                                labels: t.allgraphsclient.labels,
                                colors: t.allgraphsclient.backgroundColor
                            });
                            t.$refs.apexChart1.updateOptions({
                                labels: t.allgraphscreance.labels,
                                colors: t.allgraphscreance.backgroundColor
                            });



                        }
                        else{
                            t.dataclient=[];
                            t.datacreance=[];
                            t.allgraphsclient=[];
                            t.allgraphscreance=[];

                            t.$refs.apexChart.updateOptions(t.chartOptions);
                            t.$refs.apexChart1.updateOptions(t.chartOptions1);


                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            async load(){


                        this.$store.dispatch('lancerchargement',true)

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egldsds74gescom8571714dfe/rapport/gescom/'+this.manne+'/'+this.mmois+'/'+this.$store.state.appsociete+'/rttu44cltessentiel785488kjuii_0287845854/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.dataclient=[];
                            t.datacreance=[];
                            t.allgraphsclient=[];
                            t.allgraphscreance=[];

                            t.dataclient=res.data.dt1;
                            t.datacreance=res.data.dt2;

                            t.allgraphsclient=res.data.gph1;
                            t.allgraphscreance=res.data.gph2;


                            t.series=t.allgraphsclient.dataset;
                            t.series1=t.allgraphscreance.dataset;

                            t.chartOptions.labels=t.allgraphsclient.labels;
                            t.chartOptions1.labels=t.allgraphscreance.labels;

                            t.chartOptions.colors=t.allgraphsclient.backgroundColor;
                            t.chartOptions1.colors=t.allgraphscreance.backgroundColor;

                            t.isdisplay=true;

                        }
                        else{
                            t.dataclient=[];
                            t.datacreance=[];
                            t.allgraphsclient=[];
                            t.allgraphscreance=[];
                            t.isdisplay=true;
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            show(titlemodal){


                this.messagesucces="";
                this.messageerror="";
                this.titlemodal="Principaux clients et montants dus au d'une année"

                this.datatable=[];

                this.mmois=currentMonth();
                    this.manne=currentYear();
                    this.listedesannees=getYear2020();

                this.load()



            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>