<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2">Personnalisation des libellés
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">


                            <div class="border-b-0 border-gray-200 dark:border-white/10">
                                <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                            id="customgeshs-tab-js-behavior-item-1" data-hs-tab="#customgeshs-tab-js-behavior-1" aria-controls="customgeshs-tab-js-behavior-1">
                                        Information libre
                                    </button>
                                    <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                            id="customgeshs-tab-js-behavior-item-2" data-hs-tab="#customgeshs-tab-js-behavior-2" aria-controls="customgeshs-tab-js-behavior-2">
                                        Adresse d'expédition
                                    </button>

                                </nav>
                            </div>



                        </div>


                        <div class="box-body !p-0">




                            <div id="customgeshs-tab-js-behavior-1" role="tabpanel"
                                 aria-labelledby="customgeshs-tab-js-behavior-item-1" class="">



                                <div  class="mt-3 grid lg:grid-cols-2 gap-6">



                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 1</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 2</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 3</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                        <p v-if="typeupdate==='frsclt'" class="help-block" style="color: red">Information libre 3 recupére par défaut les permis des chauffeur</p>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 4</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre4" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 5</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre5" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <template v-if="typeupdate==='achat'">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 6</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre6" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 7</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre7" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 8</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre8" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 9</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre9" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 10</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre10" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 11</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre11" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <!--div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Information libre 12</label>
                                        <input v-model="libelleachat.informationlibre.informationlibre12" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div-->

                                    </template>


                                </div>



                            </div>



                            <div id="customgeshs-tab-js-behavior-2" role="tabpanel"
                                 aria-labelledby="customgeshs-tab-js-behavior-item-2" class="hidden">


                                <div class="mt-3 grid lg:grid-cols-1 gap-6">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse 1</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre1" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                </div>

                                <div class="mt-3 grid lg:grid-cols-2 gap-6">


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse 2</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre2" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse 3</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre3" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>


                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse 4</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre4" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>

                                    <div class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse 5</label>
                                        <input v-model="libelleachat.adresseexpedition.informationlibre5" type="text" class="firstName my-auto ti-form-input
                                        rounded-sm"     required>

                                    </div>




                                </div>

                            </div>




                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>

                    <button  style="background: green"  class="ti-btn ti-btn-primary-full" @click="validationlibelle">
                        Enregistrer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    import * as JwtService from "@/common/jwt.service";


    export default {
        /* eslint-disable */
        name: "formsupp",


        computed:{


        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                typeupdate:"",
                datavalue:{},


                libelleachat:{},




            };
        },
        methods: {

            close(){


                this.selected()

                //this.$emit('closed');

            },

            selected(){

                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = {
                    typ: "update",
                    typeupdate: this.typeupdate,
                    value: this.libelleachat,
                };

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionnecustomlib', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },



            show(libelleachat,typeupdate){


               // this.titlemodal=titlemodal
                this.libelleachat=libelleachat
                this.typeupdate=typeupdate


                this.isdisplay=true;

            },


            async validationlibelle(){

                if (this.typeupdate==='achat'){

                    JwtService.unsetCUSTOMLIBELLE()

                    JwtService.setCUSTOMLIBELLE(JSON.stringify(this.libelleachat))


                }

                if (this.typeupdate==='frsclt'){

                    JwtService.unsetCUSTOMLIBELLEFRSCLT()

                    JwtService.setCUSTOMLIBELLEFRSCLT(JSON.stringify(this.libelleachat))


                }


                this.selected()
            },


        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 50%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>