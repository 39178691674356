<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>


            <div class="content" style="margin-top: 0">
                 <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <!--div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> Empty</h3>
                        </div-->

                    </div>

                    <div class="mt-6 mb-3 items-center justify-between">
                        <div>
                          <div class="alert alert-info text-center mt-7 mb-2"><h2>Modification de mot de passe</h2></div>
                            <!--div class="mb-2">
                                <label for="current-password" class="form-label">Mot de passe actuel</label>
                                <input v-model="Items.old" type="text" class="form-control w-full !rounded-md" id="current-password" placeholder="Veuillez saisir votre mot de passe actuel">
                            </div-->
                            <div class="mb-2">
                                <label for="new-password" class="form-label">Nouveau mot de passe</label>
                                <input v-model="Items.newmdp" type="password" class="form-control w-full !rounded-md" id="new-password" placeholder="Veuillez saisir votre nouveau mot de passe">
                            </div>
                            <div class="mb-0">
                                <label for="confirm-password" class="form-label">Confirmer mot de passe</label>
                                <input v-model="Items.cfdp" type="password" class="form-control w-full !rounded-md" id="confirm-password" placeholder="Veuillez confirmer votre nouveau mot de passe">
                            </div>
                        </div>
                    </div>




                    <button @click="modifiermotdepasse" class="ti-btn ti-btn-primary-full" href="javascript:void(0);" style="background: greenyellow">
                        Modifier
                    </button>





                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";


    import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,

        },

        data() {
            return {
                Items:{old:'',newmdp:'',cfdp:''},
                messagesucces:"",
                messageerror:"",

            };
        },
        methods: {


            async modifiermotdepasse(){

                if (/*this.Items.old==="" ||*/ this.Items.cfdp==="" || this.Items.newmdp==="" ){
                    toastr['error']( "Veuillez saisir toutes les informations.","<b></b>");
                    return
                }

                if (this.Items.cfdp!==this.Items.newmdp){
                    toastr['error']( "Le nouveau mot de passe ne sont pas identique.","<b></b>");
                    return
                }


                const t = this;


                t.$store.dispatch('lancerchargement',true)


                let url=process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/password/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .post(url,this.Items,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){

                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            JwtService.setAuthorization("")
                            t.$store.commit('userdata',{value:[]})
                            t.$store.commit('isAuthenticated',{value:false})
                            t.$store.commit('beartoken',{value:""})

                            JwtService.unsetTokenSociete()
                            JwtService.unsetToken()
                            JwtService.unsetTokenID()
                            JwtService.unsetTokenEXPIRESIN()


                            window.location.reload();


                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");

                            t.isdisplay=false;

                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },





        },
        mounted() {

          // this.loadfincontratdans6mois();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>