<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <!--div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>
                            <div class="flex flex-wrap gap-2">

                                <div class="mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full custom-placeholder" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>


                            </div>


                        </div-->

                        <div  class="grid lg:grid-cols-2 gap-6">


                            <div    class="space-y-2 mt-3">
                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">TYPE DE DOCUMENT</label>

                                <select v-model="typdocument"  class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option value="FV">FACTURE DE VENTE</option>
                                    <option value="REG">REGLEMENT CLIENT</option>
                                    <option value="DEP">PIECES DE CAISSE (DEPENSE)</option>

                                </select>

                             </div>

                            <div    class="space-y-2 mt-3">
                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">JOURNAL COMPTABLE</label>

                                <select v-model="journal"  class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option :value="null">SELECTIONNER UN JOURNAL COMPTABLE</option>
                                    <option v-for="(datavalue,index) of datajournaux" :key="index"  :value="datavalue">{{datavalue.name}}</option>


                                </select>

                             </div>


                            <div   class="space-y-2 mt-3 mb-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">PERIODE DE</label>
                                <input  v-model="datedebut"  type="date" class="firstName my-auto ti-form-input"   required>

                            </div>



                            <div   class="space-y-2 mt-3 mb-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">A</label>
                                <input  v-model="datefin"  type="date" class="firstName my-auto ti-form-input"   required>

                            </div>





                        </div>

                        <div class="box custom-box">
                            <div class="box-header flex items-center justify-between flex-wrap gap-4">
                                <div class="space-y-2">
                                    <button @click="generelesecriture" style="margin: 5px!important;" type="button" class="ti-btn ti-btn-danger
                                     !py-1 !px-2 !text-[0.75rem] !m-0"><i class="ri-refresh-line"></i> Générer les écritures comptables</button>

                                </div>

                                <div class="flexflex-wrap gap-2">



                                    <button @click="ExportExcel('xlsx','tabledata ')" style="margin: 5px!important;" type="button" class="ti-btn ti-btn-success !py-1 !px-2 !text-[0.75rem] !m-0"><i class="ri-download-2-fill"></i> Exporter le fichier (CSV)</button>
                                    <button @click="ExportTOTXT('tabledata')" style="margin: 5px!important;" type="button" class="ti-btn ti-btn-primary !py-1 !px-2 !text-[0.75rem] !m-0"><i class="ri-download-2-fill"></i> Exporter le fichier (TXT)</button>





                                </div>

                            </div>
                            <div class="box-body !p-0">
                                <div class="table-responsive">
                                    <div class="flex flex-col mybodyheight400px">
                                        <div class="flex-grow overflow-auto">


                                            <table  ref="tableau1" class="table table-striped table-bordered table-hover dataTable table-responsive
                        no-footer dtr-inline mytable">
                                                <thead>
                                                <tr>
                                                    <th>Journal</th>
                                                    <th>Date</th>
                                                    <th>Pièce</th>
                                                    <th>Ligne</th>
                                                    <th>Compte</th>
                                                    <th>Reférence</th>
                                                    <th>Elément immobilisé</th>
                                                    <th>Commande</th>
                                                    <th>Centre</th>
                                                    <th>Activité</th>
                                                    <th>Stock</th>
                                                    <th>Financement</th>
                                                    <th>Ana.divers</th>
                                                    <th>Compte de l'état</th>
                                                    <th>Débit</th>
                                                    <th>Crédit</th>
                                                    <th>Libellé</th>
                                                    <th>Pointage</th>
                                                    <th>Saisie par</th>
                                                    <th>Date saisie</th>
                                                    <th>Superviseur</th>
                                                    <th>Date modification</th>
                                                    <th>Col23</th>
                                                    <th>Col24</th>
                                                    <th>Col25</th>
                                                    <th>Col26</th>
                                                    <th>Col27</th>
                                                    <th>Col28</th>
                                                    <th>Col29</th>
                                                    <th>Col30</th>
                                                    <th>Col31</th>
                                                    <th>Col32</th>

                                                </tr>
                                                </thead>


                                                <tbody>
                                                <template v-if="filterdatatable.length">

                                                    <tr style="font-weight: 600;/*background: khaki;*/"
                                                        v-for="(datavalue,index) in filterdatatable" :key="index">

                                                        <td data-label="Journal  : ">{{ datavalue.journal }}</td>
                                                        <td data-label="Date  : ">{{ datavalue.dateoperation }}</td>
                                                        <td data-label="Pièce  : ">{{ datavalue.piece }}</td>
                                                        <td data-label="Ligne  : ">{{ datavalue.ligne }}</td>
                                                        <td data-label="Compte  : ">{{ datavalue.compte }}</td>
                                                        <td data-label="Reférence  : ">{{ datavalue.reference }}</td>
                                                        <td data-label="Elément immobilisé  : ">{{ datavalue.elmimmo }}</td>
                                                        <td data-label="Commande  : ">{{ datavalue.commande }}</td>
                                                        <td data-label="Centre  : ">{{ datavalue.centre }}</td>
                                                        <td data-label="Activité  : ">{{ datavalue.activite }}</td>
                                                        <td data-label="Stock  : ">{{ datavalue.stock }}</td>
                                                        <td data-label="Financement  : ">{{ datavalue.finacement }}</td>
                                                        <td data-label="Ana.divers  : ">{{ datavalue.anadivers }}</td>
                                                        <td data-label="Compte de l'état  : ">{{ datavalue.compteetat }}</td>
                                                        <td data-label="Débit  : ">{{ datavalue.debit }}</td>
                                                        <td data-label="Crédit  : ">{{ datavalue.credit }}</td>
                                                        <td data-label="Libellé  : ">{{ datavalue.libelle }}</td>
                                                        <td data-label="Pointage  : ">{{ datavalue.pointage }}</td>
                                                        <td data-label="Saisie par  : ">{{ datavalue.saisiepar }}</td>
                                                        <td data-label="Date saisie  : ">{{ datavalue.datesaisie }}</td>
                                                        <td data-label="Superviseur  : ">{{ datavalue.superviseur }}</td>
                                                        <td data-label="Date modification  : ">{{ datavalue.datemodification }}</td>
                                                        <td data-label="Col23  : ">{{ datavalue.col23 }}</td>
                                                        <td data-label="Col24  : ">{{ datavalue.col24 }}</td>
                                                        <td data-label="Col25  : ">{{ datavalue.col25 }}</td>
                                                        <td data-label="Col26  : ">{{ datavalue.col26 }}</td>
                                                        <td data-label="Col27  : ">{{ datavalue.col27 }}</td>
                                                        <td data-label="Col28  : ">{{ datavalue.col28 }}</td>
                                                        <td data-label="Col29  : ">{{ datavalue.col29 }}</td>
                                                        <td data-label="Col30  : ">{{ datavalue.col30 }}</td>
                                                        <td data-label="Col31  : ">{{ datavalue.col31 }}</td>
                                                        <td data-label="Col32  : ">{{ datavalue.col32 }}</td>

                                                    </tr>


                                                </template>



                                                <template v-else>
                                                    <tr >
                                                        <td colspan="45"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>




                                                </tbody>

                                            </table>


                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div class="box-footer border-t-0">

                                <div class="flex items-center">
                                    <div>
                                        Showing {{filterdatatable.length}} Entries <i class="bi bi-arrow-right ms-2 font-semibold"></i>
                                    </div>
                                    <div class="ms-auto">

                                        <nav aria-label="Page navigation" class="pagination-style-4">
                                            <ul class="ti-pagination mb-0">
                                                <li class="page-item">
                                                    <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                                </li>
                                                <li class="page-item">
                                                    <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                            :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                        {{ pageNumber }}
                                                    </button>
                                                </li>
                                                <li class="page-item">
                                                    <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <!--modalform @closed="loadreturnotherdata" ref="modalform"></modalform-->


</template>

<script>

    import {mapGetters} from "vuex";
    import {currentDate, currentDateTime, ExportExcel, exportToTxt, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

                // console.log("  oldvalue  searchtrierletableau        **************** ",oldvalue)
                // console.log("  newvalue   searchtrierletableau       **************** ",newvalue)

            }

        },
        computed:{
            ...mapGetters(['storeplancomptable']),
            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;


                return this.paginate(mvalue) ;
            },
        },
        components: {
            //modalform

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                datajournaux:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",


                typdocument:'FV',
                journal:null,
                datedebut:currentDate(),
                datefin:currentDate(),


                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 800,
                Defaultpage: 1,
                DefaultperPage: 800,
                pages: [],
            };
        },
        methods: {




            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt
                elt = this.$refs.tableau1
                ExportExcel(type,name, fn, dl,elt);
                // allfunction.exportToTxt(this.datatable,name);


            },

            ExportTOTXT(name) {

                exportToTxt(this.datatable,name);

            },


            exportToTxt() {
                // let fichiername='table_data_'+allfunction.currentDateTime()
                let fichiername='table_data'
                let txtContent = '';
                /*const headers = [
                    'Journal', 'Date', 'Pièce', 'Ligne','Compte', 'Reférence','Elément immobilisé', 'Commande', 'Centre', 'Activité',
                    'Stock', 'Financement', 'Ana.divers', 'Compte de l\'état','Débit', 'Crédit','Libellé', 'Pointage', 'Saisie par', 'Date saisie',
                    'Superviseur', 'Date modification', 'Col23', 'Col24','Col25', 'Col26','Col27', 'Col28', 'Col29', 'Col30',
                    'Col31', 'Col32'
                ];

                // Generate header row
                txtContent += headers.join('\t') + '\n';*/

                // Generate data rows
                this.datatable.forEach(user => {
                    const row = [
                        user.journal, user.dateoperation, user.piece,user.ligne, user.compte, user.reference,user.elmimmo, user.commande, user.centre,
                        user.activite, user.stock, user.finacement,user.anadivers, user.compteetat, user.debit,user.credit, user.libelle, user.pointage,
                        user.saisiepar, user.datesaisie, user.superviseur,user.datemodification, user.col23, user.col24,user.col25, user.col26, user.col27,
                        user.col28, user.col29, user.col30,user.col31, user.col32
                    ];
                    txtContent += row.join('\t') + '\n';
                });

                // Create a temporary anchor element
                const anchor = document.createElement('a');
                anchor.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txtContent));
                anchor.setAttribute('download', fichiername+'.txt');

                // Append the anchor element to the document body
                document.body.appendChild(anchor);

                // Trigger a click event on the anchor to start the download
                anchor.click();

                // Remove the anchor element from the document body
                document.body.removeChild(anchor);
            },

            onChange(e) {
                let files = e.target.files
                if (files.length) {
                    this.loadNumbers(files[0])
                }
            },
            loadNumbers(file) {
                var vm = this
                let reader = new FileReader()
                reader.onload = (e) => {
                    let fileContent = e.target.result
                    const lines = fileContent.split("\n");

                    let headers = lines[0].split("\t");

                    //  lines.slice(1).map(function (line, indexLine) {

                    for (var indexLine = 1; indexLine < lines.slice(1).length; indexLine++) {

                        // if (indexLine < 1) return // Jump header line


                        // console.log('line ******* '+indexLine,line)
                        var obj = {}
                        //var scurrentline = line.split("\t")
                        //var currentline = line.split(",")
                        var currentline = lines.slice(1)[indexLine].split(",")

//console.log('currentline  --------- ',currentline)
//console.log('indexLine  --------- ',indexLine)
                        /* headers.map(function(header, indexHeader){

                          })*/


                        for (let i = 0; i < 1; i++){

                            let bvar=currentline[i].split("\n")

                            let arrayrows = bvar[0].slice(bvar[0].indexOf("\n") + 1).split("\n");
                            let rows=arrayrows[0].split("\t")
                            /*console.log('rows[] ',rows[0])
                            console.log('rows[] ',rows[1])
                            console.log('rows[] ',rows[2])
                            console.log('rows[] ',rows[3])
                            console.log('rows[] ',rows[4])
                            console.log('rows[] ',rows[5])
                            console.log('rows[] ',rows[6])
                            console.log('rows[] ',rows[7])
                            console.log('rows[9] ',rows[8])*/
                            //console.log('rows[] ',rows)
                            vm.tp.push({
                                'journal':( rows[0] !== undefined || rows[0] !== null)?rows[0]:'',
                                'dateoperation':(rows[1]),
                                'piece':(rows[2]),
                                'ligne':rows[3],
                                'compte':rows[4],
                                'reference':rows[5],
                                'elmimmo':rows[6],
                                'commande':rows[7],
                                'centre':rows[8],
                                'activite':rows[9],
                                'stock':(rows[10]),
                                'finacement':(rows[11]),
                                'anadivers':(rows[12]),
                                'compteetat':rows[13],
                                'debit':rows[14],
                                'credit':rows[15],
                                'libelle':rows[16],
                                'pointage':rows[17],
                                'saisiepar':rows[18],
                                'datesaisie':rows[19],
                                'superviseur':(rows[20]),
                                'datemodification':(rows[21]),
                                'col23':(rows[22]),
                                'col24':rows[23],
                                'col25':rows[24],
                                'col26':rows[25],
                                'col27':rows[26],
                                'col28':rows[27],
                                'col29':rows[28],
                                'col30':rows[29],
                                'col31':rows[30],
                                'col32':rows[31],

                            })
                            // obj[header] = currentline[indexHeader]
                        }



                        //   result.push(obj)



                        //  if (indexLine > 10){ console.log('enter **** ',indexLine); break;}  // Jump header line

                        //   });
                    };


                    vm.datatable=vm.tp;
                }

                reader.readAsText(file)
            },


            async  generelesecriture(){
                //montantrecu:0,
                this.$store.dispatch('lancerchargement',true)


                if (this.typdocument!=='FV' &&
                    this.typdocument!=='REG' &&
                    this.typdocument!=='DEP'){this.$store.dispatch('lancerchargement',false); return ;}
                if (this.journal===null){this.$store.dispatch('lancerchargement',false); return ;}
                if (this.datedebut===null){this.$store.dispatch('lancerchargement',false); return ;}
                if (this.datefin===null){this.$store.dispatch('lancerchargement',false); return ;}

                let mjournal={
                    mdc:this.typdocument,
                    jn:this.journal,
                    datedebut:this.datedebut,
                    datefin:this.datefin,

                }

                const t = this;

                let url=process.env.VUE_APP_API_URL+'/compta/generationecriture/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .post(url,mjournal,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                    .then(function (res){


                        t.datatable=res.data.datas



                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))




            },

            async loadjournaux(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/compta/generationecriture/'+this.$store.state.appsociete+'/journaux/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.datajournaux=[];

                            t.datajournaux=res.data.m;

                            //console.log("otherdata ",t.otherdata)


                            // JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))

                        }
                        else{
                            t.datajournaux=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },



            close(){

                this.isdisplay=false;


            },

            show(){
                this.messagesucces="";
                this.messageerror="";
                this.titlemodal="Génération des écritures vente"
                this.loadjournaux()
                this.isdisplay=true;

            },



            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 95%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>