<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="col-md-12">
                        <div class="alert alert-info" style="text-align: center;border-radius: 25px;">
                            <h2>RAPPORT SUR ACHATS</h2>
                        </div>
                    </div>


                    <div style="background-color: #f2f4f5;padding: 15px" class="grid grid-cols-12 mt-7 gap-6">


                        <div   style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                            <div class="box custom-box text-white">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                            <i class="bi bi-bar-chart-line"></i>
                                        </div>
                                        <div class="flex-grow">
                                            <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                <h4 class="mb-0" style="color: black;font-weight: bold;">

                                                    Suivi de vos achats

                                                </h4>
                                                <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div   style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                            <div class="box custom-box text-white">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                            <i class="bi bi-cash-stack"></i>
                                        </div>
                                        <div class="flex-grow">
                                            <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                <h4 class="mb-0" style="color: black;font-weight: bold;">

                                                    Répartition du coût d'achat des articles par fournisseurs

                                                </h4>
                                                <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div   style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                            <div class="box custom-box text-white">
                                <div class="box-body">
                                    <div class="flex items-start">
                                        <div class="me-4">
                                            <i class="bi bi-bar-chart-line"></i>

                                        </div>
                                        <div class="flex-grow">
                                            <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                <h4 class="mb-0" style="color: black;font-weight: bold;">

                                                    Relevé des factures fournisseurs

                                                </h4>
                                                <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>











                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <!--modalopenform ref="modalopenform"  ></modalopenform>
    <modalimpressiondoc ref="modalimpressiondoc"  ></modalimpressiondoc-->

</template>

<script>



    /*

    import modalopenform from "@/components/views/pages/stock/mvtstock/modal_openform";
    import modalimpressiondoc from "@/components/views/pages/stock/mvtstock/modal_etatdocstock";

    */


    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {

        },
        computed:{


        },
        components: {

        },

        data() {
            return {
                isdisplay:false
            };
        },
        methods: {


            btnimpression(value){

                 this.$refs.modalimpressiondoc.show(value);

            },

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },



            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            show(titlemodal){


                this.messagesucces="";
                this.messageerror="";
                this.titlemodal="RAPPORT SUR ACHATS"
                this.isdisplay=true;

            },








        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>