<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" style="margin-top: 0.55rem;" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2" style="font-weight: bolder"> Modifier la quantité de l'article
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box-body">


                        <div class="grid grid-cols-12  gap-x-6 mt-3">

                            <div class="xl:col-span-12 col-span-12">

                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">ARTICLE</label>
                             <input  :value="Items.article.libelle" type="text" class="form-control" disabled>

                            </div>

                            <div class="xl:col-span-3  col-span-12 mt-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Pu</label>
                                <input disabled  v-model="Items.pu" type="number" min="0" class="form-control text-center" >

                            </div>

                            <div class="xl:col-span-3  col-span-12 mt-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">QUANTITE</label>

                                <input v-model="newqte"   type="number" min="0" class="form-control text-center" >

                            </div>


                            <div class="xl:col-span-3  col-span-12 mt-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">REMISE</label>

                                <input v-model="Items.remise"   type="text" min="0" class="form-control text-center">

                            </div>


                            <div  class="xl:col-span-3  col-span-12 mt-3">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">MONTANT</label>

                                <input v-model="calmtntht"  disabled  type="text" min="0" class="form-control text-center" >

                            </div>

                        </div>


                    </div>
                <div class="ti-modal-footer">
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        FERMER
                    </button>


                    <button @click="selected" type="button" class="hs-dropdown-toggle ti-btn ti-btn-success-full"  >
                        VALIDER
                    </button>


                </div>
            </div>
        </div>
    </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>



    export default {
        /* eslint-disable */
        name: "formsupp",
        watch: {


        },

        computed:{

            calmtntht(){


                return (parseFloat(this.Items.pu)*parseFloat(this.newqte))-parseFloat(this.Items.remise)

            }



        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                datavalue:"",
                titlemodal:"",
                Items:{},
                accompagnement:{},
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                newremise:0,
                oldremise:0,
                newqte:0,
                oldqte:0

            };
        },
        methods: {

            selected(selectprix){

                this.newremise=this.Items.remise

                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { item: this.Items,
                    newqte:this.newqte,oldqte:this.oldqte,
                    newremise:this.newremise,
                    oldremise:this.oldremise

                };

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('updatitem', elementSelectionne);


                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            close(){

                this.isdisplay=false;


            },


            show(Items){

                this.Items=Items

                this.newqte=this.Items.qtite
                this.oldqte=this.Items.qtite
                this.newremise=this.Items.remise
                this.oldremise=this.Items.remise


                this.isdisplay=true;

            },


        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .modalstyle{
        max-width: 50%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }

</style>