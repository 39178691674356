//require('dotenv').config();
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
//import { IonicVue, IonApp } from '@ionic/vue';
//import '@ionic/core/css/ionic.bundle.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from "@/common/jwt.service";
const toastr = require('toastr');
/*import $ from "jquery";
import 'jquery-migrate';
//import jquerymigrate from 'jquery-migrate';
window.$ = $;*/
//window.toastr = require('toastr');
import VueHtmlToPaper from 'vue-html-to-paper';
window.toastr = toastr;
import './assets/app.css';
import './registerServiceWorker'

//********************************************************************************//


axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    /* 'X-CSRF-TOKEN':document.querySelector('meta[name="csrf-token"]').getAttribute('content'),*/
    'Accept':'application/json',
    'Content-Type':'application/json',
    /*'Accept-Encoding':'gzip',*/
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
    'Access-Control-Allow-Headers': 'Content-Type',

}
//axios.defaults.mode='no-cors';
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
axios.defaults.credentials = 'same-origin';

// Configurer l'intercepteur de réponse Axios
axios.interceptors.response.use(
    function (response) {

        console.log("response.data *********** ",response.data)

        // Vérifier si la variable statuts est définie
        if (response.data.statuts && response.data.statuts === 400) {
            // Déconnecter l'utilisateur

        }
        // Retourner la réponse si tout est OK
        //return response;
    },
    function () {
        // Gérer les erreurs de réponse

        //console.log("error",error)

       // return Promise.reject(error);
    }
);



toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "5000",
    "hideDuration": "5000",
    "timeOut": "8000",
    "extendedTimeOut": "5000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};



const isset = (accessor) => {
    try {
        // Note we're seeing if the returned value of our function is not
        // undefined or null
        return accessor !== undefined && accessor !== null
    } catch (e) {
        // And we're able to catch the Error it would normally throw for
        // referencing a property of undefined
        return false
    }


}


router.beforeEach((to, from, next) => {
    /* eslint-disable */
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let isAuthenticated = store.state.isAuthenticated;
    const  tokenid=JwtService.getTokenID();
    const  tokenauth=JwtService.getToken();
    // let $userrole=JwtService.getTokenRole();
    let $userrole= 'Membre'

    let t=this


    // if (!isAuthenticated && tokenauth){
    //if (!isAuthenticated && isset(tokenauth) && requiresAuth){
    if (!isAuthenticated && isset(tokenauth)){
        //if (!isAuthenticated && tokenid){
        // if (!isAuthenticated){

        // store.dispatch('chektoken',{idtoken:tokenauth,expirein:''})
        store.dispatch('chektoken',{idtoken:isset(tokenauth)?tokenauth:""})
            .then((res) => {


                if(!res.data.refresh){


                    // return   next()
                    if (to.name === 'forgotpasswordtoken'){ // route d'forgotpasswordtoken

                        return  next()
                    }
                    else    if (to.name === 'forgotpassword'){ // route d'forgotpassword

                        return  next()
                    }
                    else if (to.name === 'register'){ // route d'inscription

                        return  next()
                    }
                    else if(to.name === 'login'){ // route de login

                        return  next()

                    }else if(to.name === 'registerref'){ // route de registerref

                        return  next()

                    }else  {
                         next({ name: 'login' })
                       // next({ name: '/' })

                    }


                }
                else{

                    // $userrole=JwtService.getTokenRole();
                    //$userrole=res.data.approle;
                    //JwtService.setAuthorization(tokenauth)

                   // store.dispatch('loadlistedesarticles');


                    if (to.name === 'register'
                        || to.name === 'login'
                        /*|| to.name !== 'dashboardbackend'*/
                    ){ // route d'inscription

                        return  next('/')
                       // return  next('/login')
                    }else

                    if (to.meta.roles.includes($userrole)) {

                        return   next()
                    }
                    else {

                        return    next('/')

                    }

                }






            })
            .catch((error) => {
                next({ name: 'login' })
                //next()


            });

        /*if (requiresAuth){

            next({ name: 'login' })

        }else{

            next()
        }*/






    }



    else  if (!isAuthenticated){  //il ne faut pas une authentification

       // console.log("isAuthenticated   888 ",isAuthenticated)
        //console.log("requiresAuth   888 ",requiresAuth)



        if (requiresAuth){
            next({ name: 'login' })
        }else{


            next()


        }




        /* if (to.name === 'forgotpasswordtoken'){ // route d'forgotpasswordtoken

             return  next()
         }
         else
         if (to.name === 'forgotpassword'){ // route d'forgotpassword

             return  next()
         }
         else   if (to.name === 'register'){ // route d'inscription

             return  next()
         }
         else if(to.name === 'login'){ // route de login

             return  next()

         }else if(to.name === 'registerref'){ // route de registerref

             return  next()

         }else  {
           //  next({ name: 'login' })
            // next({ name: '/' })
             next()
         }*/



    }
    else  {

        next()

    }






})


/*

if (process.env.NODE_ENV === 'production') {
    app.config.warnHandler = () => {};
}
*/




createApp(App)
    .use(VueAxios,axios)
    /*.use(toastr)*/
    .use(router)
    .use(VueHtmlToPaper)
    .use(store)
    /*.use(IonicVue)
    .component('IonApp', IonApp)*/
    .mount('#template');

