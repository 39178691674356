import { createRouter, createWebHistory } from 'vue-router'
import index from "@/components/views/index";
//import convertisseur from "@/components/views/convertisseur";
//import marketcup from "@/components/views/marketcup";
import notification from "@/components/views/notification";


/*

import tbdefinitionpersonnel from "@/components/views/pages/definition/personnel/index";
import tbdefinitionoccasionnel from "@/components/views/pages/definition/occasionnel/index";
import indedefinitionfichierbase from "@/components/views/pages/definition/tiers/fichiersdebase/index";
import tbpaie from "@/components/views/pages/paie/tb";
import tbpresence from "@/components/views/pages/presence/tb";
import trapport from "@/components/views/pages/rapport/tb";
import indexpaiepermanentencour from "@/components/views/pages/paie/permanent/tb";
import bulletindepaie from "@/components/views/pages/paie/permanent/bulletindepaie/bulletindepaie";
import bulletindepaieoccasionnel from "@/components/views/pages/paie/occasionnel/bulletindepaie/bulletindepaie";
import avancesursalairepaieoccasionnel from "@/components/views/pages/paie/occasionnel/avance_sur_salaire/index";
import honoraireaotachepaieoccasionnel from "@/components/views/pages/paie/occasionnel/honoraireao/index";
import traitementdebasedelapaiedespermanents from "@/components/views/pages/paie/permanent/retenue/index";
import heuresupplementairepaiedespermanents from "@/components/views/pages/paie/permanent/heuresupplementaire/index";
import indexpaieencouroccasionnel from "@/components/views/pages/paie/occasionnel/tb";

*/




//************************* HSIGCOM *****************************//
import tbdefinition from "@/components/views/pages/definition/tb";
import trapport from "@/components/views/pages/rapport/tb";
import tstock from "@/components/views/pages/stock/tb";
 import tcaisse from "@/components/views/pages/caisse/tb";
 import caisse_en_cour from "@/components/views/pages/caisse/indexcaisse";
 import tgestioncommerciale from "@/components/views/pages/gestioncommerciale/tb";
 import tsetting from "@/components/views/pages/setting/tb";
 import tableaudebord from "@/components/views/pages/tableaudebord/tb";
 import tmodifiermotdepasse from "@/components/views/pages/modifiermotdepasse";
 //import tableaudebord from "@/components/views/pages/tableaudebord/ok_old_tb";


import tcomptable from "@/components/views/pages/comptabilite/tb";
import tmenucomptable from "@/components/views/pages/comptabilite/indexcomptable";




import loginpage from "@/components/views/auth/login";
import registerpage from "@/components/views/auth/register";

const routes = [
    //******************************* HCLINIQUE **************************************--//

    {
        name:'tsetting',
        path: '/setting',
        component: tsetting,
        meta: {
            requiresAuth: true,
            roles: ['Administrateur']
        }
    },

    {
        name:'trapport',
        path: '/rapport',
        component: trapport,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'tcomptable',
        path: '/comptabilite',
        component: tcomptable,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'tmenucomptable',
        path: '/exercice/:periode',
        component: tmenucomptable,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'tableaudebord',
        path: '/tableaudebord',
        component: tableaudebord,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },




    {
        name:'tmodifiermotdepasse',
        path: '/motdepasse',
        component: tmodifiermotdepasse,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },


    {
        name:'tbdefinition',
        path: '/definition',
        component: tbdefinition,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'tstock',
        path: '/stock',
        component: tstock,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },
    {
        name:'tgestioncommerciale',
        path: '/gestion_commerciale',
        component: tgestioncommerciale,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'tcaisse',
        path: '/caisse',
        component: tcaisse,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name: 'caisseencour',
        path: '/caisse/caisse_en_cour',
        component: caisse_en_cour,
        meta: {
            requiresAuth: true,
            roles: ['Administrateur']
        }
    },

    {
        name:'login',
        path: '/login',
        component: loginpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
    },
    {
        name:'register',
        path: '/register',
        component: registerpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name:'acceuilpage',
        path: '/',
        component: index,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },




















    //***************************************************************//




   {
       name:'notification',
    path: '/notification',
    component: notification,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },


    // Autres routes définies ici...

    {
        path: '/#',
        redirect: '/login',
    },

    {
        path: '/:pathMatch(.*)*', // Route par défaut pour toutes les URL non définies
        redirect: '/login',
    },



]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
   history: createWebHistory(),
  routes
})

export default router




