<template>


    <div class="grid grid-cols-12 gap-x-6">

        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">Etat point de la caisse</h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                récapitulatif du chiffre d'affaire
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état de paiement global des caisses
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                voir les soldes en caisse et banque
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des dépenses
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des réglements
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des réglements clients
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des réglements fournisseurs
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des réglements bcd interne
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des avances clients
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des avances fournisseurs
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des retours de fond de caisse
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des approvisionnements par la banque / par la caisse
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>



        <div class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                état des opérations bancaire
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>










    </div>






</template>

<script>
    export default {

        /* eslint-disable */
        name: "caisse",
        components: {


        },
        watch: {

        },

        computed:{



        },
        data() {
            return {



            };
        },
        methods: {

            btninitcomponnentstock(){
                this.$refs.stockcomponnent.loadinitcomponnent()
            },

            btninitcomponnentstcaisse(){
                this.$refs.caissecomponnent.loadinitcomponnent()
            },



        },
        mounted() {

        }

    }
</script>

<style scoped>

</style>