<template>


    <div class="page">


    <!-- Start::Header -->

    <slot name="appheader"></slot>

    <!-- End::Header -->
    <!-- Start::app-sidebar -->

    <slot name="appsidebar"></slot>


    <!-- End::app-sidebar -->

    <!-- Start::content  -->

    <slot name="appcontent"></slot>

    <!-- End::content  -->




    <!-- ========== Search Modal ========== -->
    <slot name="appsearchmodal"></slot>
    <!-- ========== END Search Modal ========== -->


    <!-- Footer Start -->
    <footer class="footer mt-auto xl:ps-[15rem]  font-normal font-inter bg-white text-defaultsize leading-normal text-[0.813] shadow-[0_0_0.4rem_rgba(0,0,0,0.1)] dark:bg-bodybg py-4 text-center">
        <div class="container">
        <span class="text-gray dark:text-defaulttextcolor/50"> Copyright © <span id="year"></span> <a
                href="javascript:void(0);" class="text-defaulttextcolor font-semibold dark:text-defaulttextcolor">HGROUPE SARL</a>.

        </span>
        </div>
    </footer>
    <!-- Footer End -->

    </div>


    <!-- Back To Top -->
    <div class="scrollToTop">
        <span class="arrow"><i class="ri-arrow-up-s-fill text-xl"></i></span>
    </div>

    <div id="responsive-overlay" @click="clickdiv"></div>

</template>

<script>
   /* import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";*/

    export default {
        /* eslint-disable */
        name: "apptemplate",
        methods: {
            clickdiv(){


                let html = document.querySelector("html");
                let responsiveoverlay = document.querySelector("#responsive-overlay");

                html.removeAttribute('data-toggled');
                responsiveoverlay.classList.remove("active");

                html.setAttribute("data-toggled", "close");

            }


        },
        mounted() {



        },



    }
</script>

<style scoped>


    
    
    
    

</style>