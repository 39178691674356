<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">



                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">A4</option>
                                        <option value="A4landscape">A4 landscape</option>
                                        <option value="A5">A5</option>
                                        <option value="A5landscape">A5 landscape</option>
                                    </select>
                                </div>


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                    <button @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>


                                </div>




                            </div>



                            <div class="grid lg:grid-cols-3 gap-6 mt-4">





                                <div class="space-y-2">

                                </div>

                            </div>



                            <div class="table-responsive mybodyheight300px">


                                <div  class="my-5 page pagebreak"  >


                                    <div class="p-5">

                                        <section class="top-content mb-5 bb d-flex justify-content-between">
                                            <div class="logo">
                                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" style="width: 10%" class="img-fluid">
                                            </div>
                                            <div class="top-left">
                                                <div class="logo">
                                                    <!--img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid"-->

                                                    <!--qrcode :value="textToEncode" :size="size" :level="level"></qrcode-->
                                                    <!--qrcode :value="tabvalue.numdoc" :size="size" :level="level"></qrcode-->

                                                </div>
                                            </div>
                                        </section>

                                        <section class="mt-5">
                                            <div class="grid lg:grid-cols-2 gap-6">

                                                <div class="col-6">
                                                    <table class="table table-bordered">
                                                        <tbody >

                                                        <tr>
                                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                        </tr>
                                                        <tr>
                                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-6 ">
                                                    <table class="table table-bordered w-full">
                                                        <tbody >
                                                        <tr>
                                                            <td style="text-align: center" colspan="3" >{{tabvalue.signature.titredoc}}</td>

                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: center">NUMERO</td>
                                                            <td style="text-align: center">DATE</td>
                                                            <td style="text-align: center">N° BON </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="text-align: center">{{tabvalue.numdoc}}</td>
                                                            <td style="text-align: center">{{tabvalue.date}}</td>
                                                            <td style="text-align: center">{{tabvalue.othernumdoc}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="3" style="text-align: center">Opérateur : {{tabvalue.operateur}}</td>

                                                        </tr>



                                                        </tbody>
                                                    </table>


                                                </div>

                                            </div>

                                            <div class="flex mt-3">
                                                <div class="w-1/2 ml-auto">
                                                    <table class="table table-bordered  w-full">
                                                        <tbody >
                                                        <tr>
                                                            <td style="text-align: center" colspan="4" >{{tabvalue.jsoncltfrs.typcltfrs}}</td>

                                                        </tr>
                                                        <tr>
                                                            <td >RAISON SOCIAL : {{tabvalue.jsoncltfrs.raisonsociale}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >IFU : {{tabvalue.jsoncltfrs.ifu}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >TELEPHONE : {{tabvalue.jsoncltfrs.telephone}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td >Adresse : {{tabvalue.jsoncltfrs.adresse}}</td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>






                                        </section>

                                        <section   class="product-area mt-3">


                                            <table ref="exportable_table" class="table table-striped table-bordered
                                            table-hover dataTable no-footer dtr-inline mytable w-full">
                                                <thead>
                                                <tr>
                                                    <th style="    width: 35px;text-align: center">N°</th>
                                                    <th>Désignation</th>
                                                    <th>Quantité</th>
                                                    <th>Prix Unitaire</th>
                                                    <th >Montant</th>
                                                </tr>
                                                </thead>


                                                <tbody class="w-full">
                                                <template v-if="tabvalue.items.length">


                                                    <tr v-for="(item,index) in tabvalue.items" :key="index">
                                                        <td style="text-align: center;"> {{index+1}}</td>
                                                        <td v-if="item.autredesignation!==''">{{item.article.libelle+' ('+item.autredesignation+')'+' ('+item.article.taxedimposition+')'}} </td>
                                                        <td v-else>{{item.article.libelle+' ('+item.article.taxedimposition+')'}} </td>
                                                        <td style="text-align: center;">{{formatquantite(item.qtite)}}</td>
                                                        <td style="text-align: center;">{{formatMonney(item.pu)}}</td>
                                                        <td style="text-align: center;">{{formatMonney(item.mtnt)}}</td>

                                                    </tr>


                                                </template>



                                                <template v-else>
                                                    <tr >
                                                        <td colspan="18"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                    </tr>

                                                </template>

                                                </tbody>
                                                <tfoot v-if="tabvalue.items.length">


                                                <tr class="border border-defaultborder">
                                                    <td style="text-align: center;" colspan="4" >MONTANT HT</td>
                                                    <td style="text-align: center">{{formatMonney(tabvalue.montantht)}}</td>

                                                </tr>

                                                <tr v-if="parseFloat(tabvalue.montantremise)>0" class="border border-defaultborder">
                                                    <td style="text-align: center;" colspan="4" >REMISE</td>
                                                    <td style="text-align: center">{{formatMonney(tabvalue.montantremise)}}</td>

                                                </tr>

                                                <tr v-if="parseFloat(tabvalue.montantaib)!==0 && parseFloat(tabvalue.montantaib)!==-0" class="border border-defaultborder">
                                                    <td style="text-align: center;" colspan="4" >{{tabvalue.libaib}}</td>
                                                    <td style="text-align: center">{{formatMonney(tabvalue.montantaib)}}</td>

                                                </tr>

                                                <tr v-if="parseFloat(tabvalue.montanttva)>0" class="border border-defaultborder">
                                                    <td style="text-align: center;" colspan="4" >TVA (18%)</td>
                                                    <td style="text-align: center">{{formatMonney(tabvalue.montanttva)}}</td>

                                                </tr>

                                                <tr class="border border-defaultborder">
                                                    <td style="text-align: center;" colspan="4" >NET A PAYER</td>
                                                    <td style="text-align: center">{{formatMonney(tabvalue.montantnetapayer)}}</td>

                                                </tr>


                                                </tfoot>





                                            </table>

                                        </section>

                                        <section class="balance-info">
                                            <div class="row">
                                                <div class="col-12">

                                                    <p style="font-size: 17px;" class="text-center mt-1">
                                                        Arrêté le présent bon de commande à la somme de :
                                                        <strong>   {{numbertolettre(tabvalue.montantnetapayer)}}
                                                            ({{formatMonney(tabvalue.montantnetapayer)}}) Francs CFA.</strong>
                                                    </p>
                                                </div>
                                                <div class="col-12" v-if="tabvalue.texteavance!=='' && tabvalue.texteavance!=='null' ">
                                                    <ul style="    list-style-type: disclosure-closed;font-weight: 700;">
                                                        <li>{{tabvalue.texteavance}}</li>
                                                        <li>{{tabvalue.textesole}}</li>
                                                    </ul>


                                                </div>
                                            </div>
                                        </section>
                                        <section class="balance-info mt-3">






                                            <div class="row">

                                                <div class="col-12 mt-5" v-if="tabvalue.signature.datasignataires.length>0">
                                                    <table class="table  w-full table-bordered">
                                                        <tbody >

                                                        <tr>
                                                            <template v-if="tabvalue.signature.datasignataires.length===1">
                                                                <td v-for="(line,index) in tabvalue.signature.datasignataires" :key="index">
                                                                    <div style="float: right;margin-right: 85px;" >
                                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> {{line.mtitresignataire}} </p>

                                                                        <template v-if="line.msignaturesignataire!=='' && line.msignaturesignataire!==null">
                                                                            <img :src="line.msignaturesignataire" alt="" style="width: 100px;">
                                                                        </template>
                                                                        <template v-else>
                                                                            <br>
                                                                            <br>
                                                                            <br>
                                                                        </template>


                                                                        <p style="text-align: center;" >{{line.mnomsignataire}}</p>
                                                                    </div>
                                                                </td>
                                                            </template>
                                                            <template v-else>
                                                                <td v-for="(line,index) in tabvalue.signature.datasignataires" :key="index">
                                                                    <div  >
                                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> {{line.mtitresignataire}} </p>

                                                                        <template v-if="line.msignaturesignataire!==''  && line.msignaturesignataire!==null">
                                                                            <img :src="line.msignaturesignataire" alt="" style="width: 100px;">
                                                                        </template>
                                                                        <template v-else>
                                                                            <br>
                                                                            <br>
                                                                            <br>
                                                                            <br>
                                                                        </template>


                                                                        <p style="text-align: center;" >{{line.mnomsignataire}}</p>
                                                                    </div>
                                                                </td>
                                                            </template>


                                                        </tr>

                                                        </tbody>

                                                    </table>
                                                </div>


                                            </div>
                                        </section>

                                    </div>




                                </div>




                            </div>


                        </div>




                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <div class="row" v-show="false" v-if="Object.keys(tabvalue).length>0">

        <div id="invoice">
            <page id="printOrder-data2" ref="printorderetatf" class="my-5 page printOrder-data " :size="taillemodel">


                <div   class="my-5 page printOrder-data pagebreak"  >


                    <div class="p-5">

                        <section class="top-content mb-5 bb d-flex justify-content-between">
                            <div class="logo">
                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" style="width: 10%" class="img-fluid">
                            </div>
                            <div class="top-left">
                                <div class="logo">

                                </div>
                            </div>
                        </section>

                        <section class="mt-5">
                            <div class="row">

                                <div class="col-6">
                                    <table class="table table-bordered">
                                        <tbody >

                                        <tr>
                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                        </tr>
                                        <tr>
                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                        </tr>
                                        <tr>
                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6 ">
                                    <table class="table table-bordered w-full">
                                        <tbody >
                                        <tr>
                                            <td style="text-align: center" colspan="3" >{{tabvalue.signature.titredoc}}</td>

                                        </tr>
                                        <tr>
                                            <td style="text-align: center">NUMERO</td>
                                            <td style="text-align: center">DATE</td>
                                            <td style="text-align: center">N° BON </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center">{{tabvalue.numdoc}}</td>
                                            <td style="text-align: center">{{tabvalue.date}}</td>
                                            <td style="text-align: center">{{tabvalue.othernumdoc}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="text-align: center">Opérateur : {{tabvalue.operateur}}</td>

                                        </tr>



                                        </tbody>
                                    </table>


                                </div>

                            </div>

                            <div class="col-6 offset-6">

                                <table class="table table-bordered ">
                                    <tbody >
                                    <tr>
                                        <td style="text-align: center" colspan="4" >{{tabvalue.jsoncltfrs.typcltfrs}}</td>

                                    </tr>
                                    <tr>
                                        <td >RAISON SOCIAL : {{tabvalue.jsoncltfrs.raisonsociale}}</td>
                                    </tr>
                                    <tr>
                                        <td >IFU : {{tabvalue.jsoncltfrs.ifu}}</td>
                                    </tr>
                                    <tr>
                                        <td >TELEPHONE : {{tabvalue.jsoncltfrs.telephone}}</td>
                                    </tr>

                                    <tr>
                                        <td >Adresse : {{tabvalue.jsoncltfrs.adresse}}</td>
                                    </tr>

                                    </tbody>
                                </table>

                            </div>






                        </section>

                        <section   class="product-area mt-3">


                            <table ref="exportable_table" class="table table-striped table-bordered
                                            table-hover dataTable no-footer dtr-inline mytable ">
                                <thead>
                                <tr>
                                    <th style="    width: 35px;text-align: center">N°</th>
                                    <th>Désignation</th>
                                    <th>Quantité</th>
                                    <th>Prix Unitaire</th>
                                    <th >Montant</th>
                                </tr>
                                </thead>


                                <tbody class="w-full">
                                <template v-if="tabvalue.items.length">


                                    <tr v-for="(item,index) in tabvalue.items" :key="index">
                                        <td style="text-align: center;"> {{index+1}}</td>
                                        <td v-if="item.autredesignation!==''">{{item.article.libelle+' ('+item.autredesignation+')'+' ('+item.article.taxedimposition+')'}} </td>
                                        <td v-else>{{item.article.libelle+' ('+item.article.taxedimposition+')'}} </td>
                                        <td style="text-align: center;">{{formatquantite(item.qtite)}}</td>
                                        <td style="text-align: center;">{{formatMonney(item.pu)}}</td>
                                        <td style="text-align: center;">{{formatMonney(item.mtnt)}}</td>

                                    </tr>


                                </template>



                                <template v-else>
                                    <tr >
                                        <td colspan="18"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                    </tr>

                                </template>

                                </tbody>
                                <tfoot v-if="tabvalue.items.length">


                                <tr class="border border-defaultborder">
                                    <td style="text-align: center;" colspan="4" >MONTANT HT</td>
                                    <td style="text-align: center">{{formatMonney(tabvalue.montantht)}}</td>

                                </tr>

                                <tr v-if="parseFloat(tabvalue.montantremise)>0" class="border border-defaultborder">
                                    <td style="text-align: center;" colspan="4" >REMISE</td>
                                    <td style="text-align: center">{{formatMonney(tabvalue.montantremise)}}</td>

                                </tr>

                                <tr v-if="parseFloat(tabvalue.montantaib)!==0 && parseFloat(tabvalue.montantaib)!==-0" class="border border-defaultborder">
                                    <td style="text-align: center;" colspan="4" >{{tabvalue.libaib}}</td>
                                    <td style="text-align: center">{{formatMonney(tabvalue.montantaib)}}</td>

                                </tr>

                                <tr v-if="parseFloat(tabvalue.montanttva)>0" class="border border-defaultborder">
                                    <td style="text-align: center;" colspan="4" >TVA (18%)</td>
                                    <td style="text-align: center">{{formatMonney(tabvalue.montanttva)}}</td>

                                </tr>

                                <tr class="border border-defaultborder">
                                    <td style="text-align: center;" colspan="4" >NET A PAYER</td>
                                    <td style="text-align: center">{{formatMonney(tabvalue.montantnetapayer)}}</td>

                                </tr>


                                </tfoot>




                            </table>

                        </section>

                        <section class="balance-info">
                            <div class="row">
                                <div class="col-12">

                                    <p style="font-size: 17px;" class="text-center mt-1">
                                        Arrêté le présent bon de commande à la somme de :
                                        <strong>   {{numbertolettre(tabvalue.montantnetapayer)}}
                                            ({{formatMonney(tabvalue.montantnetapayer)}}) Francs CFA.</strong>
                                    </p>
                                </div>
                                <div class="col-12" v-if="tabvalue.texteavance!=='' && tabvalue.texteavance!=='null' ">
                                    <ul style="    list-style-type: disclosure-closed;font-weight: 700;">
                                        <li>{{tabvalue.texteavance}}</li>
                                        <li>{{tabvalue.textesole}}</li>
                                    </ul>


                                </div>
                            </div>
                        </section>
                        <section class="balance-info mt-3">




                            <div class="row">

                                <div class="col-12 mt-5" v-if="tabvalue.signature.datasignataires.length>0">
                                    <table class="table   table-bordered">
                                        <tbody >

                                        <tr>
                                            <template v-if="tabvalue.signature.datasignataires.length===1">
                                                <td v-for="(line,index) in tabvalue.signature.datasignataires" :key="index">
                                                    <div style="float: right;margin-right: 85px;" >
                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> {{line.mtitresignataire}} </p>

                                                        <template v-if="line.msignaturesignataire!=='' && line.msignaturesignataire!==null">
                                                            <img :src="line.msignaturesignataire" alt="" style="width: 100px;">
                                                        </template>
                                                        <template v-else>
                                                            <br>
                                                            <br>
                                                            <br>
                                                        </template>


                                                        <p style="text-align: center;" >{{line.mnomsignataire}}</p>
                                                    </div>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td v-for="(line,index) in tabvalue.signature.datasignataires" :key="index">
                                                    <div  >
                                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> {{line.mtitresignataire}} </p>

                                                        <template v-if="line.msignaturesignataire!==''  && line.msignaturesignataire!==null">
                                                            <img :src="line.msignaturesignataire" alt="" style="width: 100px;">
                                                        </template>
                                                        <template v-else>
                                                            <br>
                                                            <br>
                                                            <br>
                                                            <br>
                                                        </template>


                                                        <p style="text-align: center;" >{{line.mnomsignataire}}</p>
                                                    </div>
                                                </td>
                                            </template>


                                        </tr>

                                        </tbody>

                                    </table>
                                </div>


                            </div>
                        </section>

                    </div>



                </div>


            </page>

        </div>

    </div>






</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatDEnotdevice, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear,
        impression, NumbertoLettre,

    } from "@/allfunction";
    // import * as JwtService from "@/common/jwt.service";
    import qrcode from 'qrcode.vue'


    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                return mvalue ;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /* if (this.searchtrierletableau!==''){
                     let t=this;

                     mvalue = mvalue.filter( function (post) {

                         return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                     })

                 }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.nomprenom.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }

                /*/ let  t=this
                 t.calculsalairedebase=0
                 t.calculmontantsalairebrut=0
                 t.calculheuresupplementaire=0
                 t.calculmontantcnss=0
                 t.calculmontantits=0
                 t.calculmensualitepret=0
                 t.calculavancesursalaire=0
                 t.calculmontantcnsspatronale=0
                 t.calculmontantvps=0
                 t.calculnetapayer=0
                 t.calculmontanttotal=0

                 mvalue.forEach(function (value) {

                    t.calculmontanttotal=parseInt(t.calculmontantsalairebrut)+parseInt(value.montantsalairebrut)



                 })*/



                this.totalmensualite=0
                this.totalavancesursalaire=0
                this.totalnetapayer=0
                this.totalmontantcnss=0
                this.totalmontantits=0
                this.totalretenuesursalaire=0



                mvalue.forEach(item => {


                    this.totalmensualite=item.mensualitepret+this.totalmensualite
                    this.totalavancesursalaire=item.montantavancesursalaire+this.totalavancesursalaire
                    this.totalnetapayer=item.netapayer+this.totalnetapayer
                    this.totalmontantcnss=item.montantcnss+this.totalmontantcnss
                    this.totalmontantits=item.montantits+this.totalmontantits
                    this.totalretenuesursalaire=item.retenuesursalaire+this.totalretenuesursalaire

                })





                // return this.paginate(mvalue) ;
                //return mvalue ;
            },
        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            qrcode

        },

        data() {
            return {
                mmois:"",
                manne:"",
                typetableau:1,
                mfontsize:10,
                taillemodel:'A4',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                typbulletin:"",
                messageerror:"",
                messagesucces:"",
                viewdrop:false,
                tabvalue:{},



                activecolumsligne1:true,
                activecolumsligne2:true,
                activecolumsligne3:true,
                activecolumsligne4:false,
                activecolumsligne5:false,
                activecolumsligne6:true,
                activecolumsligne7:false,
                activecolumsligne8:false,
                activecolumsligne9:false,
                activecolumsligne10:false,
                activecolumsligne11:false,
                activecolumsligne12:true,
                activecolumsligne13:true,
                activecolumsligne14:true,
                activecolumsligne15:true,
                activecolumsligne16:true,

                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {


            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                    /*

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/stylelandscape1.css',
                            process.env.VUE_APP_URL+'print/stylelandscape.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }

                    */
                }

                if (this.taillemodel==="A4" ){

                    /*

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/styleportrait.css',
                            process.env.VUE_APP_URL+'print/styleportraisizebulletin.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }

                    */

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                        process.env.VUE_APP_URL+'print/styleportraisizebulletin.css'
                    ]

                }

                /* this.$htmlToPaper('printOrder-data2', options, () => {
                     console.log('Printing completed or was cancelled!');
                 });
                 */

                const printContent = this.$refs.printorderetatf.innerHTML;
                impression(printContent,$style,window.document.title)

            },

            formatquantite(valuemonney){


                return currencyFormatDEnotdevice(valuemonney)

            },


            formatMonney(value){
                return currencyFormatMonney(value)

            },


            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },



            formatnumber(value){
                return FormatNumber(value)

            },

            formatMonneydevice(valuemonney){


                return currencyFormatDE(valuemonney)

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },




            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

            async show(datavalue){

                const t = this;

                t.tabvalue=datavalue
                this.titlemodal="Impression"
                t.isdisplay=true;





            },




            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>