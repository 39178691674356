<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">Aperçu avant impression
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>






                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="Post">Post</option>
                                        <option value="A4">Portrait</option>
                                        <option value="A4landscape">Paysage</option>
                                    </select>
                                </div>


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Nombre d'exemplaires sur la feuille</label>
                                    <select v-model="nbrecopie" class="form-control mb-3">
                                        <option v-for="(nbre,index) of nbreemplaire" :value="nbre" :key="index">{{nbre}}</option>
                                    </select>
                                </div>




                            </div>



                            <button @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>



                            <div class="table-responsive mybodyheight300px">


                                <div ref="printorderdatadeux"  class="my-5 page pagebreak"  >


                                    <div :style="  nbrecopie===2?` width: 29.7cm; height: 21cm;margin: auto;`:``"
                                            :class="nbrecopie===1?`grid lg:grid-cols-1 gap-6 `:`grid lg:grid-cols-${nbrecopie} gap-6 row`">


                                    <template v-for="(value,index) of nbrecopie" :key="index">
                                 <div :class="nbrecopie===1?``:`col-md-6`">

                                     <div :style="(nbrecopie === 2 ? 'width: 14.8cm; height: 19cm;' : '') + (nbrecopie === 2 && index === 0 ? 'border-right: 2px dashed black;' : '')"  >

                                         <div class="p-5">


                                             <section >
                                                 <div >

                                                     <div >
                                                         <table class="table w-full">
                                                             <tbody >
                                                             <tr v-if="$store.state.etatlogo">
                                                                 <td style="    border-top: 0px solid #ddd;">
                                                                     <img :src="$store.state.etatlogo"
                                                                          style="display: block; margin-left: auto; margin-right: auto;height: 70px; width: 80px;" alt="">
                                                                 </td>
                                                             </tr>

                                                             <tr v-if="$store.state.etatraisonsocial">
                                                                 <td style="text-align: center" >
                                                                    {{$store.state.etatraisonsocial}}

                                                                 </td>
                                                             </tr>

                                                             <tr v-if="$store.state.etattelephone">
                                                                 <td style="text-align: center" >
                                                                    {{$store.state.etattelephone}}

                                                                 </td>
                                                             </tr>

                                                             <tr>
                                                                 <td style="text-align: center" >{{currentDateTime()}}</td>
                                                             </tr>
                                                             <tr>
                                                                 <td style="text-align: center" >Note N° : {{tabvalue.numdoc}}</td>
                                                             </tr>


                                                             </tbody>
                                                         </table>
                                                     </div>

                                                     <div >
                                                         <table class="table w-full">
                                                             <tbody >

                                                             <tr>
                                                                 <td >Caissier: {{tabvalue.docsaisiepar}}</td>
                                                             </tr>
                                                             <tr>
                                                                 <td >Date: {{tabvalue.date}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.ifu">
                                                                 <td >Ifu: {{tabvalue.ifu}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.client">
                                                                 <td >Client: {{tabvalue.client}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.serveur">
                                                                 <td >Serveur: {{tabvalue.serveur}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.commandetable">
                                                                 <td >Table: {{tabvalue.commandetable}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.vendeur">
                                                                 <td >Vendeur: {{tabvalue.vendeur}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.boutique">
                                                                 <td >Boutique: {{tabvalue.boutique}}</td>
                                                             </tr>

                                                             <tr v-if="tabvalue.params">
                                                                 <td >Params: {{tabvalue.params}}</td>
                                                             </tr>


                                                             </tbody>
                                                         </table>
                                                     </div>
                                                 </div>






                                             </section>

                                             <section class="product-area mt-3">
                                                 <table class="table table-bordered w-full">
                                                     <thead>
                                                     <tr>

                                                         <th>N°</th>
                                                         <th>Désignation</th>
                                                         <th >Qté</th>
                                                         <th>PU</th>
                                                         <th >Montant </th>
                                                     </tr>
                                                     </thead>
                                                     <tbody>
                                                     <template v-for="(item,index) in tabvalue.items" :key="index">
                                                         <tr>
                                                             <td> {{index+1}}</td>
                                                             <td>
                                                                 <template v-if="item.autredesignation===''">
                                                                     {{ item.article.libelle+'( '+ item.article.taxedimposition+' )' }}
                                                                 </template>
                                                                 <template v-else>
                                                                     {{ item.article.libelle+' ( '+item.autredesignation+' )'+'( '+ item.article.taxedimposition+' )'}}
                                                                 </template>

                                                             </td>
                                                             <td style="text-align: center;">{{formatNumber(item.qtite)}}</td>
                                                             <td style="text-align: center;">{{formatNumber(item.pu)}}</td>
                                                             <td style="text-align: center;">{{calculSomme(item.mtnt,item.remise,0)}}</td>
                                                         </tr>
                                                     </template>

                                                     </tbody>
                                                     <tfoot>

                                                     <tr>

                                                         <td style="text-align: center;" colspan="4">Montant Total</td>

                                                         <td style="text-align: center;">{{tabvalue.montantht}}</td>

                                                     </tr>


                                                     <tr>

                                                         <td style="text-align: center;" colspan="4">Remise</td>

                                                         <td style="text-align: center;">{{tabvalue.montantremise}}</td>

                                                     </tr>



                                                     <tr>

                                                         <td style="text-align: center;" colspan="4">Net à payer</td>

                                                         <td style="text-align: center;">{{tabvalue.montantnetapayer}}</td>

                                                     </tr>


                                                     <template v-if="typeimpression!=='note'">


                                                         <tr>

                                                             <td style="text-align: center;" colspan="4">Montant reçu</td>

                                                             <td style="text-align: center;">{{tabvalue.reglement}}</td>

                                                         </tr>

                                                         <tr v-if="tabvalue.montantmonnaie!==0">

                                                             <td style="text-align: center;" colspan="4">Relicat rendu</td>

                                                             <td style="text-align: center;">{{tabvalue.montantmonnaie}}</td>

                                                         </tr>

                                                         <tr v-else>

                                                             <td style="text-align: center;" colspan="4">Doit</td>

                                                             <td style="text-align: center;">{{tabvalue.montantmonnaie}}</td>

                                                         </tr>

                                                         <!-------------------- code MECEF-------------------------------->

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0">
                                                             <td colspan="5">CODE MECeF/DGI</td>
                                                         </tr>

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0">
                                                             <td  colspan="5">{{tabvalue.mecefsignature}}</td>
                                                         </tr>

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0" >
                                                             <td colspan="5">MECeF NIM : {{tabvalue.mecefnim}}</td>
                                                         </tr>

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0">
                                                             <td  colspan="5">MECeF Compteur : {{tabvalue.mecefcompteur}}</td>
                                                         </tr>

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0">
                                                             <td   colspan="5">MECeF Heure :{{tabvalue.mecefheure}}</td>
                                                         </tr>

                                                         <tr v-if="parseFloat(tabvalue.normalise)>0" >
                                                             <td colspan="5">
                                                                 <img width="60" height="60"
                                                                      style="  display: block; margin-left: auto; margin-right: auto;"
                                                                      :src="qrCodeImage"
                                                                      alt="" class="img-fluid img-thumbnail img-responsive">

                                                             </td>
                                                         </tr>

                                                     </template>

                                                     <tr>
                                                         <td style="text-align: center;font-size: 10px;" colspan="5">***Merci pour votre confiance***</td>
                                                     </tr>


                                                     <tr>
                                                         <td style="text-align: center;font-size: 10px;" colspan="5">***HSIGCOM contact : 97-40-87-08/61-81-43-20***</td>
                                                     </tr>



                                                     </tfoot>


                                                 </table>
                                             </section>

                                         </div>




                                 </div>

                                 </div>

                                    </template>

                                    </div>


                                </div>




                            </div>


                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";*/
    import {
        currencyFormatDE, currencyFormatMonney,
        currentDate,
        currentDateTime,
        ExportExcel, FormatNumber,
        getYear,
        impression, NumbertoLettre,
    } from "@/allfunction";
    import qrcode from 'qrcode.vue'
    import qrcodetoimage from "qrcode";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            soustitre(){
                this.taillemodel='Post';  return "FACTURE"
            },

            nbreemplaire(){
                this.nbrecopie=1
                if (this.taillemodel==="A4" || this.taillemodel==="A5" || this.taillemodel==="Post"){
                    return [1]
                }

                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape"){
                    return [1,2]
                }


            },



            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                return this.tabvalue.items;

            },



            qrCodeImage() {

                let mvalue=null;

                // Générer un QR code sous forme d'URL de données
                qrcodetoimage.toDataURL(this.tabvalue.mecefqrr, { width: 125, margin: 2 }, (err, url) => {
                    if (err) {
                        console.error(err);
                    } else {

                        mvalue=  url;
                    }
                });

                return mvalue;

            },

        },
        components: {
            /*templatesite,
            appheader,
            appsidebar,
            appmenu,*/
            qrcode

        },

        data() {
            return {
                mmois:"",
                manne:"",
                typeimpression:"",
                typetableau:1,
                nbrecopie:1,
                mfontsize:10,
                taillemodel:'Post',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                typbulletin:"",
                messageerror:"",
                messagesucces:"",
                viewdrop:false,
                tabvalue:{},

                datedebut:"",
                numpidcaisse:"",
                datefin:"",
                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],

                textToEncode: '',  // Initial text to encode in QR code
                size: 100,              // Size of the QR code
                level: 'L'              // Error correction level ('L', 'M', 'Q', 'H')
            };
        },
        methods: {


            calculSomme(p1,p2,p3){
                var number=Number(p1)+Number(p2)+Number(p3)
                return this.formatNumber(number)
            },

            formatNumber(p1){
                var number=Number(p1)
                return FormatNumber(number)
            },


            formatMonney(value){
                return currencyFormatMonney(value)

            },


            numbertolettre(chiffre){

                return NumbertoLettre(chiffre)

            },


            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            async print4 () {


                let t=this
                let $style=[];

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" || this.taillemodel==="A5landscape" ){

                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/stylelandscape1.css',
                        process.env.VUE_APP_URL+'print/stylelandscape.css'
                    ]


                }

                if (this.taillemodel==="A4" || this.taillemodel==="A5" ){


                    $style= [
                        process.env.VUE_APP_URL+'print/bootstrap.min.css',
                        process.env.VUE_APP_URL+'print/styleportrait.css',
                       // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }

                if (this.taillemodel==="Post" ){


                    $style= [

                        process.env.VUE_APP_URL+'print/stylepost.css',
                       // process.env.VUE_APP_URL+'print/custom_print_a4.css',
                    ]

                }

              /*/  $style= [

                    process.env.VUE_APP_URL+'print/custom_print_a4.css',
                ]*/





                setTimeout(() => {
                    const printContent = this.$refs.printorderdatadeux.innerHTML;
                    impression(printContent,$style,window.document.title)

                }, 500);

            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },

            currentDateTime(){
                return currentDateTime()

            },



            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                this.$emit('closed',this.typeimpression);

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            async show(tabvalue,typeimpression){


                this.tabvalue=tabvalue
                this.typeimpression=typeimpression
                this.isdisplay=true;
            },


        },


    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>