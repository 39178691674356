<template>
    <header class="app-header">
        <nav class="main-header !h-[3.75rem]" aria-label="Global">
            <div class="main-header-container ps-[0.725rem] pe-[1rem] ">

                <div class="header-content-left">
                    <!-- Start::header-element -->
                    <div class="header-element">
                        <div class="horizontal-logo">
                            <router-link :to="{name:'acceuilpage'}" class="header-logo">
                                <img src="/assets/logopaie.png" alt="logo" class="desktop-logo">
                                <img src="/assets/logopaie.png" alt="logo" class="toggle-logo">
                                <img src="/assets/logopaie.png" alt="logo" class="desktop-dark">
                                <img src="/assets/logopaie.png" alt="logo" class="toggle-dark">
                                <img src="/assets/logopaie.png" alt="logo" class="desktop-white">
                                <img src="/assets/logopaie.png" alt="logo" class="toggle-white">
                            </router-link>
                        </div>
                    </div>
                    <!-- End::header-element -->
                    <!-- Start::header-element -->
                    <div @click="clickbtnmenu" class="header-element md:px-[0.325rem] !items-center">
                        <!-- Start::header-link -->
                        <a aria-label="Hide Sidebar"
                           class="sidemenu-toggle animated-arrow  hor-toggle horizontal-navtoggle inline-flex items-center"
                           href="javascript:void(0);"><span></span></a>
                        <!-- End::header-link -->
                    </div>
                    <!-- End::header-element -->
                </div>



                <div class="header-content-right">




                    <!--Header Notifictaion -->
                    <div class="header-element py-[1rem] md:px-[0.65rem] px-2 notifications-dropdown header-notification
                    hs-dropdown ti-dropdown  md:!block [--placement:bottom-left]">
                        <button id="dropdown-notification" type="button"
                                class="hs-dropdown-toggle relative ti-dropdown-toggle !p-0 !border-0 flex-shrink-0  !rounded-full !shadow-none align-middle text-xs">
                            <i class="bx bx-bell header-link-icon  text-[1.125rem]"></i>
                            <span class="flex absolute h-5 w-5 -top-[0.25rem] end-0  -me-[0.6rem]">
              <span
                      class="animate-slow-ping absolute inline-flex -top-[2px] -start-[2px] h-full w-full rounded-full bg-secondary/40 opacity-75"></span>
              <span v-if="nbrenotification>0"
                      class="relative inline-flex justify-center items-center rounded-full  h-[14.7px] w-[14px] bg-secondary text-[0.625rem] text-white"
                      id="notification-icon-badge">{{nbrenotification}}</span>
            </span>
                        </button>
                        <div class="main-header-dropdown !-mt-3 !p-0 hs-dropdown-menu ti-dropdown-menu bg-white !w-[22rem] border-0 border-defaultborder hidden !m-0"
                             aria-labelledby="dropdown-notification">

                            <div class="ti-dropdown-header !m-0 !p-4 !bg-transparent flex justify-between items-center">
                                <p class="mb-0 text-[1.0625rem] text-defaulttextcolor font-semibold dark:text-[#8c9097] dark:text-white/50">Notifications</p>
                                <span class="text-[0.75em] py-[0.25rem/2] px-[0.45rem] font-[600] rounded-sm bg-secondary/10 text-secondary"
                                      id="notifiation-data">{{nbrenotification>0?nbrenotification+' Non lu(s)':''}}</span>
                            </div>
                            <div class="dropdown-divider"   :class="nbrenotification>0?'':'hidden'"></div>
                            <ul class="list-none !m-0 !p-0 end-0" id="header-notification-scroll"  :class="nbrenotification>0?'':'hidden'">
                                <li v-for="(messag,index) of datanotification" :key="index" class="ti-dropdown-item dropdown-item ">
                                    <div class="flex items-start">

                                        <div class="grow flex items-center justify-between">
                                            <div>
                                                <p class="mb-0 text-defaulttextcolor dark:text-[#8c9097] dark:text-white/50 text-[0.8125rem] font-semibold"><a
                                                        href="javascript:void(0);">{{messag.typnotification}}</a></p>
                                                <span class="text-[#8c9097] dark:text-white/50 font-normal text-[0.75rem] header-notification-text">
                                                    <div v-html="messag.messagetexte.substring(0,120)+'...'"></div>
                                                </span>
                                            </div>
                                            <div>
                                                <a aria-label="anchor" href="javascript:void(0);" class="min-w-fit text-[#8c9097] dark:text-white/50 me-1 dropdown-item-close1"><i
                                                        class="ti ti-x text-[1rem]"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                            </ul>

                            <div class="p-4 empty-header-item1 border-t mt-2">
                                <div class="grid">
                                    <router-link :to="{name: 'notification'}" class="ti-btn ti-btn-primary-full !m-0 w-full p-2">Voir toutes les notifications</router-link>
                                </div>
                            </div>
                            <div class="p-[3rem] empty-item1" :class="nbrenotification>0?'hidden':''">
                                <div class="text-center">
                                    <span class="!h-[4rem]  !w-[4rem] avatar !leading-[4rem] !rounded-full !bg-secondary/10 !text-secondary">
                                      <i class="ri-notification-off-line text-[2rem]  "></i>
                                    </span>
                                    <h6 class="font-semibold mt-3 text-defaulttextcolor dark:text-[#8c9097] dark:text-white/50 text-[1rem]">Aucune nouvelle notification</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-element header-fullscreen py-[1rem] md:px-[0.65rem] px-2">
                        <!-- Start::header-link -->
                        <a aria-label="anchor" @click="toggleFullScreen" href="javascript:void(0);"
                           class="inline-flex flex-shrink-0 justify-center items-center gap-2  !rounded-full font-medium dark:hover:bg-black/20 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-white/10 dark:focus:ring-offset-white/10">
                            <i v-if="btnfullscreen===false" class="bx bx-fullscreen full-screen-open header-link-icon"></i>
                            <i v-if="btnfullscreen===true" class="bx bx-exit-fullscreen full-screen-close header-link-icon "></i>
                        </a>
                    </div>

                    <div style="cursor: pointer;" class="header-element md:!px-[0.65rem] px-2 hs-dropdown !items-center ti-dropdown [--placement:bottom-left]">

                        <button id="dropdown-profile" type="button"
                                class="hs-dropdown-toggle ti-dropdown-toggle !gap-2 !p-0 flex-shrink-0 sm:me-2 me-0 !rounded-full !shadow-none text-xs align-middle !border-0 !shadow-transparent ">
                            <img class="inline-block rounded-full " src="/assets/avatar.jpg"  width="32" height="32" alt="Image Description">
                        </button>
                        <div class="md:block hidden dropdown-profile">
                            <p class="font-semibold mb-0 leading-none text-[#536485] text-[0.813rem] ">{{$store.state.appusername}}</p>
                        </div>
                        <div
                                class="hs-dropdown-menu ti-dropdown-menu !-mt-3 border-0 w-[11rem] !p-0 border-defaultborder hidden main-header-dropdown  pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end"
                                aria-labelledby="dropdown-profile">

                            <ul class="text-defaulttextcolor font-medium dark:text-[#8c9097] dark:text-white/50">

                                <li>
                                    <router-link :to="{name:'tmodifiermotdepasse'}" class="w-full ti-dropdown-item !text-[0.8125rem] !gap-x-0  !p-[0.65rem] !inline-flex">
                                        <i class="ti ti-user text-[1.125rem] me-2 opacity-[0.7]"></i>Modifier votre mot de passe
                                    </router-link>
                                </li>


                                <li><router-link class="w-full ti-dropdown-item !text-[0.8125rem] !p-[0.65rem] !gap-x-0 !inline-flex" @click="deconnexion" to="#"><i
                                        class="ti ti-logout text-[1.125rem] me-2 opacity-[0.7]"></i>Deconnexion</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <!-- End Header Profile -->


                    <!-- End::header-element -->
                </div>
            </div>
        </nav>
    </header>
</template>

<script>

  //  import {randomString} from "@/allfunction";

   const axios =require('axios');

    import {mapGetters} from "vuex";
  import {randomString} from "@/allfunction";
  import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "appheader",
        components: {

        },
        computed:{
            ...mapGetters(['lancerchargement','classmenupc','classdefautmenu','niveauclickmenu',
                'myscreenSize','isAuthenticated','nbrenotification','datanotification'])



        },
        data:()=>({
              btnfullscreen:false,


        }),
        methods: {

            toggleFullScreen() {
                const doc = window.document;
                const docEl = doc.documentElement;

                const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
                const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

                if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                    this.btnfullscreen=true;
                    requestFullScreen.call(docEl);
                } else {
                    this.btnfullscreen=false;
                    cancelFullScreen.call(doc);
                }
            },


            clickbtnmenu(){
               let html = document.querySelector("html");
               let responsiveoverlay = document.querySelector("#responsive-overlay");
               //console.log("responsiveoverlay ******* ",responsiveoverlay)
               // var element = document.getElementById("monElement");

                if (this.myscreenSize <= 990) { // ecran mobile
                    // Execute your code here for screen size <= 990px


                    if (html.getAttribute("data-toggled") === 'icon-overlay-close'){

                        html.removeAttribute('data-toggled');

                        responsiveoverlay.classList.remove("active");

                    }else{

                        responsiveoverlay.classList.add("active");

                        html.setAttribute("data-toggled", "icon-overlay-close");
                        html.setAttribute("data-vertical-style", "overlay");

                    }






                }else{

                    if (html.getAttribute("data-toggled") === 'icon-overlay-close'){

                        html.removeAttribute('data-toggled');



                    }else{



                        html.setAttribute("data-toggled", "icon-overlay-close");
                        html.setAttribute("data-vertical-style", "overlay");

                    }

                }




            },

            initmenu(){












            },


            async  deconnexion(){

                this.$store.dispatch('lancerchargement',true)
                /*let t=this;
                let response= axios
                    .post('/api/decokjkjkj884454854nnexion/'+this.$store.state.beartoken)
                */


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/login1478pojhgfderffg458755/logout/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification

                })
                let res= await response
                    .then(response => {

                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            t.$store.dispatch('beartoken',response.data.beartoken)

                            JwtService.setAuthorization("")
                            t.$store.commit('userdata',{value:[]})
                            t.$store.commit('isAuthenticated',{value:false})
                            t.$store.commit('beartoken',{value:""})
                            /* JwtService.setTokenID("");
                             JwtService.setTokenEXPIRESIN("");*/


                            JwtService.unsetTokenSociete()
                            JwtService.unsetToken()
                            JwtService.unsetTokenID()
                            JwtService.unsetTokenEXPIRESIN()


                            window.location.reload();

                        }
                        else{

                            t.$store.dispatch('lancerchargement',false)
                            /// toastr['error']( response.data.message, /*"<b>Connexion</b>"*/)


                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");

                            t.isdisplay=false;




                        }


                    })
                    .catch(

                        error => {
                            this.$store.dispatch('lancerchargement',false)
                            toastr['error']( "Une erreur est subvenue   " +
                                "  <br/> veuillez actualiser la page .",
                                "<b>Erreur</b>");


                        }
                    )

                    .finally(() =>  this.$store.dispatch('lancerchargement',false))




            },







        },
        mounted() {


            this.initmenu()



        }

    }
</script>

<style scoped>

</style>