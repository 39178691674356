<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div >
                    <!--div class="md:flex block items-center justify-between my-[1.5rem] page-header-breadcrumb">
                        <div>


                        </div>
                        <div class="btn-list md:mt-0 mt-2">
                            <button @click="init" type="button" class="ti-btn bg-primary text-white btn-wave !font-medium !me-[0.375rem] !ms-0 !text-[0.85rem] !rounded-[0.35rem] !py-[0.51rem] !px-[0.86rem] shadow-none">
                                <i class="ri-refresh-fill  inline-block"></i>Actualiser
                            </button>
                        </div>
                    </div-->




                        <div class="xl:col-span-12 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        <h2><strong>{{$store.state.intitulecaisse}}</strong></h2>

                                    </div>
                                    <div class="prism-toggle">
                                        <h2><strong>{{$store.state.dateouverturecaisse}}</strong></h2>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="grid grid-cols-12 gap-6">

                                    <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 col-span-12">
                                        <div class="box custom-box box-bg-primary text-white">
                                            <div class="box-body">
                                                <div class="flex items-start">
                                                    <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                            <i style="font-size: 55px" class="ri-money-cny-box-fill"></i>
                                                     </span>
                                                    </div>
                                                    <div class="flex-grow">
                                                        <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                            <h4 class="mb-0" style="color: white;font-weight: bold;">Solde en caisse</h4>
                                                            <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: white;font-weight: bold;" class="font-semibold mb-0">{{formatNumber($store.state.montantencaisse)}}</h5>
                                                            </span>
                                                        </div>
                                                        <div class="flex flex-wrap items-center justify-between">


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 col-span-12">
                                        <div class="box custom-box box-bg-primary text-white">
                                            <div class="box-body">
                                                <div class="flex items-start">
                                                    <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                            <i style="font-size: 55px" class="ri-money-cny-box-fill"></i>
                                                     </span>
                                                    </div>
                                                    <div class="flex-grow">
                                                        <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                            <h4 class="mb-0" style="color: white;font-weight: bold;">Total des dépenses</h4>
                                                            <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: white;font-weight: bold;" class="font-semibold mb-0">{{formatNumber($store.state.totaldepense)}}</h5>
                                                            </span>
                                                        </div>
                                                        <div class="flex flex-wrap items-center justify-between">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="xxl:col-span-4 xl:col-span-4 lg:col-span-6 md:col-span-6 col-span-12">
                                        <div class="box custom-box box-bg-primary text-white">
                                            <div class="box-body">
                                                <div class="flex items-start">
                                                    <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                            <i style="font-size: 55px" class="ri-money-cny-box-fill"></i>
                                                     </span>
                                                    </div>
                                                    <div class="flex-grow">
                                                        <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                            <h4 class="mb-0" style="color: white;font-weight: bold;">Total des recettes</h4>
                                                            <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: white;font-weight: bold;" class="font-semibold mb-0">{{formatNumber($store.state.totalrecette)}}</h5>
                                                            </span>

                                                        </div>
                                                        <div class="flex flex-wrap items-center justify-between">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    </div>



                                    <div style="background-color: #f2f4f5" class="grid grid-cols-12 mt-7 gap-6">


                                        <template  v-if="$store.state.ouverturedelacaisse===0">

                                        <div  v-if="$store.state.typc===1"  @click="btnmodaletatdespaiement('global')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                            <div class="box custom-box text-white">
                                                <div class="box-body">
                                                    <div class="flex items-start">
                                                        <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                        </div>
                                                        <div class="flex-grow">
                                                            <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                <h4 class="mb-0" style="color: black;font-weight: bold;">Etat de paiement global des caisses fermées</h4>
                                                                <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="$store.state.typc===1" @click="btnmodaletatdespaiement('all')"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Etat de paiement et de Billetage de la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        <div v-else  @click="btnmodaletatdespaiement('unique')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Etat de paiement et de Billetage de la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </template>

                                        <template  v-if="$store.state.ouverturedelacaisse!==0">

                                            <div v-if="accesfacturation" @click="btnfacturation" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/dd.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Facturation
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-if="accesdepense" @click="btnmodaldepense('DEP')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/calculette.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Dépense
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-if="accesreglementclt" @click="btnmoadalindexreglement('CLT')"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/money.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Réglement Client
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-if="accesreglementfrs" @click="btnmoadalindexreglement('FRS')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/money.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Réglement Fournisseur
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div v-if="accesreglementbcd" @click="btnmoadalindexreglementbcd" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/money.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Réglement BCD interne à la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/money.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Remboursement des retours sur vente
                                                                    </h4> <span style="color: red"> (Bientôt disponible)</span>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/money.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Remboursement des retours sur achat
                                                                    </h4><span style="color: red"> (Bientôt disponible)</span>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <template v-if="accestransfertdefondscaisse">

                                            <div v-if="this.$store.state.typc===0"  @click="btnmoadalindextransfertdefonddecaisse('DPC',this.$store.state.typc)" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/argent-transfert.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Transfert vers caisse principale
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else  @click="btnmoadalindextransfertdefonddecaisse('APPROC',this.$store.state.typc)"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/argent-transfert.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Transfert vers caisse auxiliaire/principale
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            </template>


                                            <div v-if="accesretourdefondsdecaisse" @click="btnmodaldepense('REF')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Retour de fonds de caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="accessavanceclient"  @click="btnmoadalindexavance('CLT')"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Avance client
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="accessavancefournisseur" @click="btnmoadalindexavance('FRS')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Avance fournisseur
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div  v-if="accesdepotencompteclt" @click="btnmodaldepense('DPCC')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Dépôt en compte Client
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="this.$store.state.typc===0" @click="btnmoadalvalidationtransaction('VAPPROC',this.$store.state.typc)" style="cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Valider un transfert de fonds de caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else @click="btnmoadalvalidationtransaction('VDPC',this.$store.state.typc)" style="cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Valider un transfert de fonds de caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="this.$store.state.typc===1"  @click="btnmoadalvalidationtransaction('VAPPROC',this.$store.state.typc)" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/tt.jpg" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Valider un transfert de fonds de caisse principal
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="$store.state.typc===1 && accesoperationbancaire" @click="btnmoadalindextransfertdefonddecaisse('OPB',this.$store.state.typc)"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Opération bancaire
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <template v-if="accessoldecaissebanque">
                                            <div v-if="$store.state.typc===1" @click="btnmodalsoldecaissebanque('ALL')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Voir le solde des caisses et banques
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div  v-else @click="btnmodalsoldecaissebanque('BANQUE')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Voir le solde des banques
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            </template>

                                            <div v-if="$store.state.typc===1 && accescreationbcdinternecaisse" @click="btnmoadalindexbcd" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/facture.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Créer la suivie de BCD interne à la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div v-if="$store.state.typc===1 && acceslistedepotsenbanque " @click="btnmoadalindextransfertdefonddecaisse('DPB',this.$store.state.typc)"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Dépôts en banque
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="$store.state.typc===1 && acceslistedesapprovisionnementscaissebanque" @click="btnmoadalindextransfertdefonddecaisse('APPRO',this.$store.state.typc)"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Approvisionnements caisse par la banque
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="accesenregistrerbordereauxdebanque" @click="btnmoadalindextransfertdefonddecaisse('BOR',this.$store.state.typc)"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/4706.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Enregistrement des bordereaux de banque
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="$store.state.typc===1" @click="btnmodaletatdespaiement('all')"  style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Etat de paiement et de Billetage de la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else  @click="btnmodaletatdespaiement('unique')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Etat de paiement et de Billetage de la caisse
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="$store.state.typc===1"  @click="btnmodaletatdespaiement('global')" style="    cursor: pointer;" class="xxl:col-span-3 xl:col-span-3 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-body">
                                                        <div class="flex items-start">
                                                            <div class="me-4">
                                                     <span class="avatar avatar-sm shadow-sm !rounded-full">
                                                          <img src="@/assets/img/imprim.png" alt="">
                                                     </span>
                                                            </div>
                                                            <div class="flex-grow">
                                                                <div class="flex flex-wrap items-center justify-between text-[.875rem] mb-1">

                                                                    <h4 class="mb-0" style="color: black;font-weight: bold;">
                                                                        Etat de paiement global des caisses fermées
                                                                    </h4>
                                                                    <span class="ms-1 text-[#8c9097] dark:text-white/50">
                                                                  <h5 style="color: black;font-weight: bold;" class="font-semibold mb-0"></h5>
                                                            </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            
                                            <div class="xxl:col-span-12 xl:col-span-12 lg:col-span-12 md:col-span-12 col-span-12"></div>
                                            
                                            <div  v-if="accestableauxdestock && $store.state.ouverturedelacaisse!==0" style="    cursor: pointer;" class="xxl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 col-span-12">
                                                <div class="box custom-box text-white">
                                                    <div class="box-header justify-between">

                                                        <div class="box-title">
                                                            Liste des articles au niveau de la caisse
                                                        </div>


                                                    </div>
                                                    <div class="box-body">


                                                        <div style="width: 100%;margin-bottom: 10px" class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                                                            <input v-model="searchwordallprod" class="form-control" placeholder="Search Table" id="searchDatatable2">
                                                        </div>

                                                        <div class="box-body !p-0 mybodyheight300px tableFixHeadindexcomponnent tableFixHeadindexcomponnent300">
                                                            <div class="table-responsive">

                                                                <!-- component -->
                                                                <div class="flex flex-col h-screen">
                                                                    <div class="flex-grow overflow-auto">


                                                                    <table style="height: 200px;overflow-y: scroll;" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline w-full">
                                                            <thead>
                                                            <tr>
                                                                <!--th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Magasin</th-->
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Article</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Stock</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Prix de vente</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <template v-if="filterdatatable.length">

                                                                <tr style="font-weight: 600;/*background: khaki;*/"
                                                                    v-for="(datavalue,index) in filterdatatable" :key="index">

                                                                    <!--td  data-label="Magasin  : "   >{{ datavalue.nommaga }}</td-->
                                                                    <td  data-label="Article  : " >{{ datavalue.libelle }}</td>
                                                                    <td style="text-align: center"  data-label="Stock  : " >{{ datavalue.rstck }}</td>
                                                                    <td style="text-align: center"  data-label="Prix de vente  : " >{{ formatNumber(datavalue.pu) }}</td>
                                                                </tr>




                                                            </template>

                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="4"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                </tr>

                                                            </template>




                                                            </tbody>


                                                        </table>







                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="xl:col-span-6 col-span-12">
                                                <div class="box custom-box">
                                                    <div class="box-header justify-between">
                                                        <div class="box-title">
                                                            Suivie des dépenses et récettes au cour du mois
                                                        </div>

                                                    </div>
                                                    <div class="box-body">



                                                        <apexcharts type="bar" height="350" :options="chartOptions4" :series="series4"></apexcharts>


                                                    </div>
                                                    <div class="box-footer border-t-0 hidden">

                                                    </div>
                                                </div>
                                            </div>



                                            <div class="xl:col-span-6 col-span-12" v-for="(graph,index) of allgraphs" :key="index">
                                                <div class="box custom-box">
                                                    <div class="box-header justify-between">
                                                        <div class="box-title">
                                                            Top 10 des articles/services les plus vendus au cour du mois de {{graph.moisencours}}
                                                        </div>

                                                    </div>
                                                    <div class="box-body">

                                                        <apexcharts type="pie" height="350" :options="graph.chartOptions" :series="graph.series"></apexcharts>

                                                    </div>
                                                    <div class="box-footer border-t-0 hidden">

                                                    </div>
                                                </div>
                                            </div>






                                        </template>















                                    </div>




                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>
                        </div>













                </div>
            </div>
        </template>

    </templatesite>

    <facturation2 ref="facturation2" @closed="closemodalfacturation"></facturation2>
    <facturation1 ref="facturation1" @closed="closemodalfacturation"></facturation1>
    <moadalindexavance ref="moadalindexavance" @closed="closemodal"></moadalindexavance>
    <modaldepense ref="modaldepense" @closed="closemodal"></modaldepense>
    <moadalindexreglement ref="moadalindexreglement" @closed="closemodal"></moadalindexreglement>
    <moadalindexbcd ref="moadalindexbcd" @closed="closemodal"></moadalindexbcd>
    <moadalindexreglementbcd ref="moadalindexreglementbcd" @closed="closemodal"></moadalindexreglementbcd>
    <moadalindextransfertdefonddecaisse ref="moadalindextransfertdefonddecaisse" @closed="closemodal"></moadalindextransfertdefonddecaisse>
    <moadalvalidationtransaction ref="moadalvalidationtransaction" @closed="closemodal"></moadalvalidationtransaction>
    <modalsoldecaissebanque ref="modalsoldecaissebanque" ></modalsoldecaissebanque>
    <modaletatdespaiement ref="modaletatdespaiement" ></modaletatdespaiement>

</template>

<script>
    import VueApexCharts from "vue3-apexcharts";
     import modaldepense from "@/components/views/pages/caisse/depense/index";
    import moadalindexreglement from "@/components/views/pages/caisse/reglement/index";
    import moadalindexbcd from "@/components/views/pages/caisse/bcd/index";
    import moadalindexreglementbcd from "@/components/views/pages/caisse/bcd/reglement/index";
    import moadalindextransfertdefonddecaisse from "@/components/views/pages/caisse/transfertdefonddecaisse/index";
    import moadalvalidationtransaction from "@/components/views/pages/caisse/validationtransaction/index";
    import moadalindexavance from "@/components/views/pages/caisse/avance/index";
    import modalsoldecaissebanque from "@/components/views/pages/caisse/rapport/modal_soldecaissebanque";
    import facturation1 from "@/components/views/pages/caisse/facturation/1/index";
    import facturation2 from "@/components/views/pages/caisse/facturation/2/formcaisse2";
     import modaletatdespaiement from "@/components/views/pages/caisse/rapport/modal_etatdespaiement";





    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/caisse/menu";
    import * as JwtService from "@/common/jwt.service";
    import {cloneobjet, currencyFormatDE, randomString} from "@/allfunction";
    import {mapGetters} from "vuex";
    const axios =require('axios');
    
    


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            facturation1,
            facturation2,
            modaldepense,
            moadalindexreglement,
            moadalindexbcd,
            moadalindexreglementbcd,
            moadalindextransfertdefonddecaisse,
            moadalindexavance,
            moadalvalidationtransaction,
            modalsoldecaissebanque,
            modaletatdespaiement,
            apexcharts: VueApexCharts,


        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },

        computed:{
            ...mapGetters(['listedesarticlespourlacaisse']),


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },

            filterdatatablestockminimum(){

                let mvalue=this.datatablestockminimum;

                return this.paginate(mvalue) ;

            },


            datatablearticle(){

                let mvalue=cloneobjet(this.listedesarticlespourlacaisse);

                return mvalue ;
            },

            filterdatatable(){

                let mvalue=cloneobjet(this.listedesarticlespourlacaisse);

                if (this.searchwordallprod!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return    post.libelle.toLowerCase().includes(t.searchwordallprod.toLowerCase())
                    })
                }

                return mvalue ;
            },



            accesoperationbancaire(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="operationbancaire"){


                        mvalue=(macces.acces !== 0);
                    }

                });



                return mvalue
            },


            pagefacturationcaisse(){
                let mvalue=0;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="pagefacturationcaisse"){


                        mvalue=macces.acces;
                    }

                });



                return mvalue
            },


            accescreationbcdinternecaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="creationbcdinternecaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesenregistrerbordereauxdebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="enregistrerbordereauxdebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementclt(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementclt"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementfrs(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementfrs"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesreglementbcd(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="reglementbcd"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            accesdepotencompteclt(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="depotencompteclt"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesretourdefondsdecaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="retourdefondsdecaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            accestransfertdefondscaisse(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="transfertdefondscaisse"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },


            acceslistedepotsenbanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="listedepotsenbanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            acceslistedesapprovisionnementscaissebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="listedesapprovisionnementscaissebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },

            accesannulerfacture(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="annulerfacture"){


                        mvalue=(macces.acces !== 0);
                    }

                });
                return mvalue
            },



            accesfacturation(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="facturationcaisse"){


                        mvalue=(macces.acces !== 0);
                    }



                });



                return mvalue
            },

            accessoldecaissebanque(){
                let mvalue=false;


                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="voirsoldebanque"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },

            accessavancefournisseur(){
                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="avancefournisseur"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },


            accessavanceclient(){
                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="avanceclient"){


                        mvalue=(macces.acces !== 0);
                    }

                });

                return mvalue
            },


            accesdepense(){

                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="depensecaisse"){


                        mvalue=(macces.acces !== 0);
                    }



                });


                return mvalue
            },

            accestableauxdestock(){

                let mvalue=false;



                this.$store.state.niveauaccescaisse.forEach(function(macces) {

                    if (macces.name==="tableauxdestock"){


                        mvalue=(macces.acces !== 0);
                    }



                });


                return mvalue
            },


            accesmycaisse(){

                if (Object.keys(this.$store.state.caisseinfo).length>0)
                {
                    return true
                }else{
                    return false
                }
            }







        },

        data() {
            return {
                searchword:"",
                searchwordallprod:"",
                datatablefindecontratpermannent:[],
               /* datatablearticle:[],*/
                datatabledocday:[],
                listedescaisses:[],
                datatablestockminimum:[],
                dateouverture:null,
                intitule:"",
                totaldepense:0,
                totalrecette:0,
                montantencaisse:0,



                allgraphs: [],

                series4: [],
                chartOptions4: {
                    colors:[],
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'FCFA'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "FCFA " + val + " "
                            }
                        }
                    }
                },






                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],

            };
        },
        methods: {


            btnmodaletatdespaiement(value){


                let mname,numpidcaisse="",datedouverture=""



                if (value==='global'){
                    mname='Etat de paiement global des caisses';
                }else{
                    mname='Etat de paiement de la caisse';

                }

                if (this. $store.state.ouverturedelacaisse!==0){


                    if (Object.keys(this.$store.state.caisseinfo).length>0){

                        numpidcaisse=this.$store.state.caisseinfo.cpid,
                            datedouverture=this.$store.state.caisseinfo.dateouverture

                    }

                }
                else{


                    numpidcaisse=this.$store.state.caisseencour,
                        datedouverture=""


                }


                this.$refs.modaletatdespaiement.show(mname,numpidcaisse,datedouverture,value);

            },


            btnmoadalindexreglement(typedoc){


                let mname


                if (typedoc==='CLT'){

                    mname='Liste des factures clients partiellement réglées ou non réglées';
                }

                if (typedoc==='FRS'){

                    mname='Liste des factures fournisseurs partiellement réglées ou non réglées';
                }

                this.$refs.moadalindexreglement.show(mname,typedoc);

            },


            btnmoadalindextransfertdefonddecaisse(typedoc,vtypc){


                let mname


                this.$refs.moadalindextransfertdefonddecaisse.show(mname,typedoc,vtypc);

            },

            btnmodalsoldecaissebanque(value){


                let mname


                this.$refs.modalsoldecaissebanque.show(value);

            },

            btnmoadalvalidationtransaction(typedoc,vtypc){


                let mname


                this.$refs.moadalvalidationtransaction.show(mname,typedoc,vtypc);

            },

            btnfacturation(){
                let mname


                if (this.pagefacturationcaisse===0 || this.pagefacturationcaisse==='0'){

                    this.$refs.facturation1.show(this.datatablearticle);

                }


                if (this.pagefacturationcaisse===1 || this.pagefacturationcaisse==='1'){

                    this.$refs.facturation2.show(this.datatablearticle);

                }



            },

            btnmoadalindexbcd(){


                let mname

                mname="Création d'un BCD interne  pour le suivie";


                this.$refs.moadalindexbcd.show(mname);

            },


            btnmoadalindexreglementbcd(){

                let mname

                mname="Liste des BCD interne";

                this.$refs.moadalindexreglementbcd.show(mname);

            },


            btnmoadalindexavance(value){

                let mname

                if (value==="CLT"){   mname="Liste des avances clients";}
                if (value==="FRS"){   mname="Liste des avances fournisseurs";}



                this.$refs.moadalindexavance.show(mname,value);

            },

            btnmodaldepense(typedoc){


                let mname

                let  Item= {
                    pid:'',
                    docnum:'',
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    beneficiaire:null,
                    document:this.typedoc,
                    mtnt:0,
                    alldepense:[],
                    params:{
                        param1:null,
                        param2:null,
                    },
                    jsondoc:{
                        key:''
                    },
                };

                if (typedoc==='DEP'){

                    mname='Liste des dépenses';
                }

                if (typedoc==='DPCC'){

                    mname='Liste des dépôts en compte client';
                }

                if (typedoc==='REF'){

                    mname='Liste des retours de fonds de caisse';
                }


                this.$refs.modaldepense.show(mname,typedoc);

            },



            formatNumber(num) {

                return  currencyFormatDE(num)
            },

            btnmoadalouverturecaisse(){


                let titlemodal="Ouverture de la caisse à la date du "+this.$tore.state.appserverdate

                let Item=value

                this.$refs.moadalouverturecaisse.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },

            btnopen(value){


                let titlemodal="Document "+value.numdoc

                let Item=value

                this.$refs.modalopenform.showupdate(titlemodal,"update",this.typedoc,Item,this.otherdata);

            },


            async inittableauarticle() {

                let $caisseencour=(Object.keys(this.$store.state.caisseinfo).length>0 && this.accestableauxdestock)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }

              /*  const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/otherallproddksdk/'+$caisseencour+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                 await response
                    .then(response => {

                        if (response.data.success){
                            t.datatablearticle=[]
                            t.datatablearticle=response.data.m

                        }
                        else{
                            t.datatablearticle=[]
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))

                */

                this.$store.dispatch('loadlistedesarticlespourlacaisse',{pidcaisseencour:$caisseencour})
            },


            closemodalfacturation(value){


                this.datatablearticle=value
                this.loadsoldecaisse();

            },

            closemodal(){
                this.loadsoldecaisse();
                this.loadsuiviecaissegraph();
                this.loadtop10article();
            },

            async loadsoldecaisse() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }

             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdozojdjsolde/'+this.$store.state.paramsystem.apikey+'/'+$caisseencour+'/'+this.$store.state.appsociete+'/895418init519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.$store.dispatch('totaldepense',response.data.m.totaldepense);
                            t.$store.dispatch('totalrecette',response.data.m.totalrecette);
                            t.$store.dispatch('montantencaisse',response.data.m.solde);
                        }
                        else{

                            t.$store.dispatch('totaldepense',0);
                            t.$store.dispatch('totalrecette',0);
                            t.$store.dispatch('montantencaisse',0);
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },

            async loadsuiviecaissegraph() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }

             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/graph/gg/'+$caisseencour+'/'+this.$store.state.appsociete+'/8954graph18ss519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {

                        if (response.data.success){

                            t.series4=response.data.xaxis;
                            t.chartOptions4.xaxis.categories=response.data.allgraphs
                            t.chartOptions4.colors=response.data.colors

                        }
                        else{

                            t.series4=[];
                            t.chartOptions4.xaxis.categories=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },

            async loadtop10article() {


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){return }


             const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/graph/gg/'+$caisseencour+'/'+this.$store.state.appsociete+'/8954toparticless519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {


                        if (response.data.success){

                            t.allgraphs=response.data.allgraphs

                        }
                        else{


                            t.allgraphs=[]

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally()



            },

            init(){
               // this.inittableauarticle();
                this.loadsoldecaisse();
                this.loadsuiviecaissegraph();
                this.loadtop10article();

            },



            async initcaisse(){

                this.$store.dispatch('lancerchargement',true)
                const t = this;

                t.dateouverture=null;
                t.intitule="";
                t.totaldepense=0;
                t.totalrecette=0;
                t.montantencaisse=0;
                var caisse={
                    'dateouverture':this.dateouverture,
                    'cc':this.$store.state.caisseencour,
                }



                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdozojdj/'+this.$store.state.appsociete+'/895418init519151911515/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })
                 await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            t.$store.dispatch('dateouverturecaisse',res.data.m.dateouverture);
                            t.$store.dispatch('ouverturedelacaisse',1);
                            t.$store.dispatch('caisseinfo',res.data.m.caisseinfo);

                            t.$store.dispatch('niveauaccescaisse',res.data.m.vue.niveaudacces);
                            t.$store.dispatch('intitulecaisse',res.data.m.vue.intitule);
                            /*t.$store.dispatch('totaldepense',res.data.caisse.vue.totaldepense);
                            t.$store.dispatch('totalrecette',res.data.caisse.vue.totalrecette);
                            t.$store.dispatch('montantencaisse',res.data.caisse.vue.solde);*/
                            t.$store.dispatch('typc',res.data.m.vue.typc);

                            t.listedescaisses=res.data.m.listedescaisses

                            //t.allbk=res.data.caisse.allbk


                            /*
                               t.$refs.refmenucaisse.updatevariable(t.allbk,t.accessoldecaissebanque);

                            t.dateouverture=res.data.caisse.dateouverture;
                               t.intitule=res.data.caisse.vue.intitule;
                               t.totaldepense=res.data.caisse.vue.totaldepense;
                               t.totalrecette=res.data.caisse.vue.totalrecette;
                               t.montantencaisse=res.data.caisse.vue.solde;
                               t.typc=res.data.caisse.vue.typc;*/

                            t.inittableauarticle()

                        }else{

                            //t.$store.dispatch('beartoken',res.data.beartoken)
                            toastr['error']( res.data.message,"<b></b>");
                           // t.loading=false
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(function () {
                       // t.$store.dispatch('lancerchargement',false)
                    })




            },



            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {


          if(this.$store.state.caisseencour===''){

                toastr['error']( "Veuillez sélectionner une caisse valide.")


                this.$router.push({ name: 'tcaisse' });
            }
            else{
                this.initcaisse();

                this.init()
            }








        }






    }
</script>

<style scoped>

    .custom-box{
        padding: 10px;
    }

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>