<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body" >
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                       {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                       {{messageerror}}
                                    </div>
                                </div>

                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;">Date : {{Items.datedoc}} / Saisie par : {{Items.saisiepar}}  </p>
                                </div>




                                <div class="ti-custom-validation mt-5" >

                                    <div v-if="typedoc!=='mvt_transfert'"  class="space-y-2 mt-3 mb-3">
                                        <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MAGASIN/DEPÔT/ENTREPÔT</label>
                                        <input type="text" class="firstName my-auto ti-form-input  rounded-sm" disabled :value="Items.mag.name">

                                    </div>



                                    <div v-if="typedoc==='mvt_transfert'" class="grid lg:grid-cols-2 gap-6">

                                        <div    class="space-y-2 mt-3 mb-3">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MAGASIN/DEPÔT/ENTREPÔT</label>
                                            <input type="text" class="firstName my-auto ti-form-input  rounded-sm" disabled :value="Items.mag.name">

                                        </div>

                                        <div   class="space-y-2 mt-3 mb-3">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">VERS MAGASIN/DEPÔT/ENTREPÔT</label>
                                            <input type="text" class="firstName my-auto ti-form-input  rounded-sm" disabled :value="Items.jsonotherdonne.magvers.name">

                                        </div>



                                    </div>






                                    <div class="space-y-2 mt-3 mb-3">
                                        <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MOTIF / RAISON</label>

                                        <textarea :value="Items.docobservation" disabled type="text" class="firstName my-auto ti-form-input  rounded-sm"
                                              cols="5" rows="2"></textarea>
                                    </div>

                                    <div v-if="Items.params.param1!==null" class="space-y-2 mt-3 mb-3">
                                        <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">PARAMETRE D'INDICATION</label>

                                        <input  type="text" class="firstName my-auto ti-form-input  rounded-sm" disabled :value="Items.params.param1.name">

                                    </div>



                                </div>
                            </div>



                        </div>
                    </div>



                        <div class="">



                            <div class="box-body !p-0 mybodyheight300px">
                                <div class="table-responsive">

                                    <!-- component -->

                                    <div class="flex flex-col h-screen">
                                        <div class="flex-grow overflow-auto">


                                            <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                <thead>
                                                <tr>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" colspan="2">DESIGNATION</th>
                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">QUANTITE</th>

                                                </tr>
                                                </thead>
                                                <tbody>

                                                <template v-if="listedatatable.length">

                                                <template  v-for="(datavalue,index) in listedatatable" :key="index">
                                                    <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light">

                                                        <td data-label="N°  : ">{{index+1}}</td>
                                                         <td  data-label="DESIGNATION  : " colspan="2"  >{{ datavalue.article.libelle }}</td>
                                                        <td  data-label="QUANTITE  : "   >{{ datavalue.qtite }}</td>

                                                    </tr>


                                                </template>



                                                </template>


                                                <template v-else>
                                                    <tr >
                                                        <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                    </tr>

                                                </template>



                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>
                            </div>





                        </div>






                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>

                    <button style="background: green"  class="ti-btn ti-btn-primary-full" @click="btnimpression">
                       <i class="ri-printer-line"></i> Imprimer
                    </button>

                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalimpressiondoc ref="modalimpressiondoc"  ></modalimpressiondoc>


</template>

<script>
   /* import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
   import { randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
   import modalimpressiondoc from "@/components/views/pages/stock/mvtstock/modal_etatdocstock";
   //import modallistedesarticles from "@/components/views/pages/stock/mvtstock/modal_listedesarticles";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{

            listeversmagasins(){
                let mvalue=[];
                this.Items.jsonotherdonne.magvers=null;
                if (this.Items.mag!==null){

                    mvalue = this.alldata.magasin.filter(m => m.pid !== this.Items.mag.pid)

                }

                return mvalue;
            },

            disableddoc(){

                return !!this.Items.docnum;
            },
            stock: function (){

                if (this.ItemsArticle.article!==null){
                    return parseFloat(this.ItemsArticle.article.rstck);
                    //  return "";
                }else{
                    return 0;
                    // return "disabled";
                }

            },





        },
        components: {
           /* templatesite,
            appheader,
            appsidebar,
            appmenu,

            modallistedesarticles*/

            modalimpressiondoc

        },

        data() {
            return {
                isdisplayviewarticle:false,
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                titlemodal:"",
                typedoc:"",
                typeaction:"add",
                Items: {},
                alldata: [],

                listedatatable:[],
                listedatatableproduit:[],
                mydocmag:'',

            };
        },
        methods: {

            btnimpression(){

                this.$refs.modalimpressiondoc.show(this.Items);

            },



            close(){

                this.listedatatableproduit=[];
                this.listedatatable=[];
                this.ItemsArticle={
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    etatline:0,
                    pid:''
                }
                this.isdisplayviewarticle=false;
                this.isdisplay=false;

                this.$emit('closed');

            },


          async  showupdate(titlemodal,typeaction,typedoc,Items,alldata){

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.Items=Items
                this.alldata=alldata
                this.messagesucces="";
                this.messageerror="";

              this.ItemsArticle={
                  docnum:Items.numdoc,
                  article:null,
                  qtite:0,
                  diqte:0,
                  pu:0,
                  pa:0,
                  mtnt:0,
                  mtntclt:0,
                  remise:0,
                  doc:'',
                  docpid:Items.pid,
                  docnumother:'',
                  docmag:'',
                  doctp:'',
                  linekey:'',
                  etatline:0,
                  pid:''
              }

                if (this.Items.pid!==""){this.isdisplayviewarticle=true}

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/stock/'+this.Items.numdoc+'/'+this.Items.pid+'/open/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.listedatatableproduit=[];
                            t.listedatatableproduit=res.data.m;

                             t.Items.jsonotherdonne=Items.jsonotherdonne;
                            t.Items.mag=Items.mag;
                            t.Items.pid=Items.pid;
                            t.ItemsArticle.docpid=Items.pid;
                            t.Items.params=Items.params;
                            t.Items.mtnt=Items.mtnt;
                            t.Items.docnum=Items.numdoc;


                            t.Items.docnumother=Items.docnumother;
                            t.Items.docpid=Items.pid;
                            t.Items.pid=Items.pid;
                            t.Items.infodoc=Items.infodoc;
                            t.Items.datedoc=Items.datedoc;
                            t.Items.doctyp=Items.doctyp;
                            t.Items.docobservation=Items.observations;


                            //t.listearticles=response.data.allprod;

                            t.listedatatable=res.data.alllignedoc;
                           t.mydocmag=Items.mag.pid;
                            t.ItemsArticle.docmag=Items.mag.pid;
                            //t.itemArticle.docnum=value.numdoc;
                            t.ItemsArticle.docnum=Items.pid;
                            t.ItemsArticle.doctp=Items.doctyp;
                            t.ItemsArticle.docnumother=Items.docotherref;


                            t.isdisplayviewarticle=true;
                            t.isdisplay=true;

                        }
                        else{
                            t.listedatatableproduit=[];
                           // t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })

            },

            newArticle(){
                this. ItemsArticle.docmag=this.mydocmag;
                this. ItemsArticle.article=null;
                this. ItemsArticle.qtite=0;
                this. ItemsArticle.pu=0;
                this. ItemsArticle.remise=0;
                this. ItemsArticle.mtnt=0;
                this. ItemsArticle.pid='';
                this. ItemsArticle.linekey='';
                this. ItemsArticle.etatline=0;
                this.ItemsArticle.spid='';
                this.disabled=false;
            },


            changeItem(value){

                let pid=this.ItemsArticle.pid;
               // let value=event;
                if (pid!=='') return;
                let dataSearch=[];

                if(value!==null){

                    if(this.listedatatable.length !==0){


                        dataSearch = this.listedatatable.filter(function (article) {

                            return  article.article.pid ===value.pid
                            //return article.pidar.includes(value.pid);
                        });


                        if(dataSearch.length !==0){
                            toastr['error']( value.libelle+' existe déjà dans la liste.',"<b></b>");
                            this.ItemsArticle.article=null;
                            this.ItemsArticle.qtite=0;
                            this.ItemsArticle.pu= 0
                        }else{
                            // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                            //this.itemArticle.pu= this.itemArticle.article.pu

                            this.gestionPu();


                        }


                    }else{
                        // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                        // this.itemArticle.pu=this.itemArticle.article.pu
                        //this.itemArticle.pu= this.itemArticle.article.pu
                        this.gestionPu();
                    }


                }


            },

            gestionPu(){

                //***************************** le bon ok ******************************//


                if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                    if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                        if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                            let cal=   parseInt(this.ItemsArticle.article.pa)/1.18
                            this.ItemsArticle.pa=Math.round(cal)
                            this.ItemsArticle.pu=this.ItemsArticle.article.pu

                        }
                        else{

                            let cal=   parseInt(this.ItemsArticle.article.pa)*0.18
                            this.ItemsArticle.pa=this.ItemsArticle.article.pa
                            this.ItemsArticle.pu=Math.round(cal)

                        }



                    }
                    else{

                        this.ItemsArticle.pa=this.ItemsArticle.article.pa
                        this.ItemsArticle.pu=this.ItemsArticle.article.pu

                    }






                }
                else{

                    this.itemArticle.pa=this.itemArticle.article.pa
                    this.itemArticle.pu=this.itemArticle.article.pu

                }



            },



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>