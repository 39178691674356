<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"> BILLETAGE DE LA CAISSE
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">

                        <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                            <div v-html="messagesucces">

                            </div>
                        </div>

                        <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                            <div v-html="messageerror">

                            </div>
                        </div>


                        <table class="table table-striped table-bordered table-hover dataTable
                        no-footer dtr-inline mytable w-full">
                            <thead>
                            <tr>
                                <th style="text-align: center">Montant </th>
                                <th style="text-align: center">Nombre</th>
                                <th style="text-align: center">Montant total</th>

                            </tr>
                            </thead>



                            <tbody>

                            <tr style="font-weight: 600;/*background: khaki;*/" v-for="(ligne,index) in  billetage.ligne" :key="index">

                                <td  data-label="Montant  : " style="width: 30%; text-align:center">{{ligne.intitule}}</td>
                                <td  data-label="Nombre  : " style="width: 30%; text-align:center"><input style="text-align:center" @change="calculmontant" type="number" v-model="ligne.nombre"> </td>
                                <td  data-label="Montant total  : " style="width: 40%; text-align:center">{{formatNumber(ligne.montanttotale)}} </td>

                            </tr>


                            </tbody>
                            <tfoot >

                            <tr>
                                <td  colspan="2" style="border-bottom: 0px solid #e9e9e9 ! important" > <p class="text-center mt-0 mb-0">Montant attendue </p> </td>
                                <td > <p style="text-align:center">{{formatNumber(billetage.montantattendu)}} </p> </td>
                            </tr>

                            <tr>
                                <td  colspan="2" style="border-bottom: 0px solid #e9e9e9 ! important" > <p class="text-center mt-0 mb-0">Montant Physique </p> </td>
                                <td > <p style="text-align:center">{{formatNumber(billetage.montantphisque)}} </p> </td>
                            </tr>

                            <tr>
                                <td    colspan="2" style="border-bottom: 0px solid #e9e9e9 ! important" > <p class="text-center mt-0 mb-0">Ecart </p> </td>
                                <td > <p style="text-align:center">{{formatNumber(billetage.ecart)}} </p> </td>
                            </tr>


                            </tfoot>


                        </table>





                    </div>
                <div class="ti-modal-footer">
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Non
                    </button>
                    <button @click="btnvalider"  class="ti-btn ti-btn-primary-full" href="javascript:void(0);" style="background: greenyellow">
                       Oui
                    </button>

                </div>
            </div>
        </div>
    </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    //import allfunction from "@/allfunction";
    import {currencyFormatDE, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",
        watch: {


        },

        computed:{




        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                datavalue:"",
                titlemodal:"",
                Items:{},
                typedoc:"",
                messageerror:"",
                messagesucces:"",
                billetage:{
                    ligne:[

                        {
                            intitule:'10.000 FCFA',
                            intituleenchiffre:10000,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'5.000 FCFA',
                            intituleenchiffre:5000,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'2.000 FCFA',
                            intituleenchiffre:2000,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'1.000 FCFA',
                            intituleenchiffre:1000,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'500 FCFA',
                            intituleenchiffre:500,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'250 FCFA',
                            intituleenchiffre:250,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'200 FCFA',
                            intituleenchiffre:200,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'100 FCFA',
                            intituleenchiffre:100,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'50 FCFA',
                            intituleenchiffre:50,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'25 FCFA',
                            intituleenchiffre:25,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'20 FCFA',
                            intituleenchiffre:20,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'10 FCFA',
                            intituleenchiffre:10,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'5 FCFA',
                            intituleenchiffre:5,
                            nombre:0,
                            montanttotale:0
                        },
                        {
                            intitule:'1 FCFA',
                            intituleenchiffre:1,
                            nombre:0,
                            montanttotale:0
                        },

                    ],
                    montantattendu:0,
                    montantphisque:0,
                    ecart:0,

                }
            };
        },
        methods: {



            formatNumber(num) {

                return  currencyFormatDE(num)
            },



            calculmontant(){
                let    montantattendu=0;
                let     montantphisque=0;
                let     ecart=0;


                this.billetage.ligne.forEach(function (ligne) {
                    ligne.montanttotale=ligne.nombre*ligne.intituleenchiffre
                    montantphisque=ligne.montanttotale+montantphisque
                })

                this.billetage.montantphisque=montantphisque;
                this.billetage.ecart=montantphisque-this.billetage.montantattendu;

            },


            close(){

                this.isdisplay=false;

                this.$emit('closed');


            },


            show(montantattendu){
                this.messagesucces="";
                this.messageerror="";
                this.billetage.montantattendu=montantattendu


                this.isdisplay=true;

            },


            async  btnvalider(){

                this.$store.dispatch('lancerchargement',true)

                if (this.billetage.montantattendu>0){


                    if (this.billetage.montantphisque<=0){

                        toastr['error']( "Le billetage de la caisse n'est pass valide. Veuillez reprendre.",
                            "<b>Caisse</b>")
                        this.$store.dispatch('lancerchargement',false)
                        return
                    }




                }

                var caisse={
                    dateouverture:this.$store.state.dateouverturecaisse,
                    cc:this.$store.state.caisseencour,
                    cinfo:this.$store.state.caisseinfo,
                    billetage:this.billetage
                }

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdo785544hffgzojdj/'+this.$store.state.appsociete+'/close/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){

                            t.messagesucces="";
                            t.messageerror="";

                            t.messagesucces=res.data.message;

                            toastr['success']( t.messagesucces,"<b></b>");


                           // t.$emit('closed',res.data.m);

                            t.$store.commit('listeallcaisses',{value:res.data.c})


                            t.$router.push({ name: 'tcaisse'});


                            t.isdisplay=false;


                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");

                            t.isdisplay=false;

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))




            },







        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .modalstyle{
        max-width: 50%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }

</style>