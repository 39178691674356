<template>


    <div class="grid grid-cols-12 gap-x-6">

        <div @click="btnmodalhistoriquedocument" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">HISTORIQUE DES DOCUMENT</h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div @click="btnmodalpointarticleparbon" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                               rapport des articles par bon
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div @click="btnmodalrapportsurvente" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                                rapports sur ventes
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div @click="btnmodalrapportsurachat" class="xl:col-span-4 lg:col-span-4
        md:col-span-4 sm:col-span-12 col-span-12"
             style="cursor: pointer;">

            <div class="box hrm-main-card primary">
                <div class="box-body">
                    <div class="flex items-start">
                        <div class="me-4">
                            <i style="font-size: 22px" class="ri-printer-line"></i>
                        </div>
                        <div class="flex-grow">

                            <h5 class="font-semibold mb-1 text-[0.9rem] uppercase">
                              rapports sur achats
                            </h5>

                        </div>
                    </div>
                </div>
            </div>


        </div>





    </div>


    <modalpointarticleparbon ref="modalpointarticleparbon"></modalpointarticleparbon>
    <modalhistoriquedocument ref="modalhistoriquedocument"></modalhistoriquedocument>
    <modalrapportsurvente ref="modalrapportsurvente"></modalrapportsurvente>
    <modalrapportsurachat ref="modalrapportsurachat"></modalrapportsurachat>



</template>

<script>
    import modalpointarticleparbon from "@/components/views/pages/comptabilite/editions/modal_point_article_parbon";
    import modalhistoriquedocument from "@/components/views/pages/gestioncommerciale/rapport/modal_historiquedocument";
    import modalrapportsurvente from "@/components/views/pages/gestioncommerciale/rapport/modal_rapportsurvente";
    import modalrapportsurachat from "@/components/views/pages/gestioncommerciale/rapport/modal_rapportsurachat";

    export default {

        /* eslint-disable */
        name: "commerciale",
        components: {
            modalpointarticleparbon,
            modalhistoriquedocument,
            modalrapportsurvente,
            modalrapportsurachat,

        },
        watch: {

        },

        computed:{



        },
        data() {
            return {



            };
        },
        methods: {


            btnmodalpointarticleparbon() {

                this.$refs.modalpointarticleparbon.show();

            },

            btnmodalrapportsurvente(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalrapportsurvente.show(titlemodal);
            },

            btnmodalrapportsurachat(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalrapportsurachat.show(titlemodal);
            },

            btnmodalhistoriquedocument(){

                let titlemodal ="Historique des documents commerciaux";

                this.$refs.modalhistoriquedocument.show(titlemodal);
            },





        },
        mounted() {

        }

    }
</script>

<style scoped>

</style>