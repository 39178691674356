<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between " style="display: block">



                            <div class="grid grid-cols-12  gap-x-6">


                                <div class=" xl:col-span-8  col-span-12 mt-4 mb-4">
                                    <input v-model="searchword" class="form-control form-control-lg w-full" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <div class="xl:col-span-3  col-span-12 mt-4 mb-4"></div>


                                <div class="xl:col-span-1  col-span-12 mt-4 mb-4">
                                    <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem] w-full">
                                        <i class="ri-add-line  align-middle"></i>Ajouter
                                    </button>
                                </div>



                                <div class=" xl:col-span-6  col-span-12 mt-4 mb-4">

                                    <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                        <option value="250">250</option>
                                        <option value="300">300</option>
                                    </select>
                                </div>


                                <!--div class=" xl:col-span-6  col-span-12 mt-4 mb-4">

                                    <select v-model="searchtrierletableau"   style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                        <option value="">Trier le tableau suivant / Annuler le trie</option>
                                        <option value="1">Absences / Congés annuler</option>
                                        <option value="0">Absences / Congés en cours</option>
                                    </select>
                                </div-->

                            </div>


                            <div class="w-full grid grid-cols-1">


                            </div>






                        </div>


                        <div class="box-body !p-0 mybodyheight300px">
                            <div class="table-responsive">

                                <!-- component -->
                                <div class="flex flex-col h-screen">
                                    <div class="flex-grow overflow-auto">

                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° document</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Date</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Bénéficiaire</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Obserbvation</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Montant</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N° bon</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer Par</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Créer le</th>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Action</th>

                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">

                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <td data-label="N°  : ">{{index+1}}</td>
                                                    <td  data-label="N° document  : "   >{{ datavalue.numdoc }}</td>
                                                    <td  data-label="Date  : "   >{{ datavalue.dateop }}</td>
                                                    <td  data-label="Bénéficiaire  : "   >{{ datavalue.beneficiaire }}</td>
                                                    <td  data-label="Observation  : "   >{{ datavalue.motifop }}</td>
                                                    <td  data-label="Montant  : "   >{{ formatNumber(datavalue.montant) }}</td>
                                                    <td  data-label="N°bon  : "   >{{ datavalue.numbon }}</td>
                                                    <td  data-label="Créer par  : "   >{{ datavalue.saisirpar }}</td>
                                                    <td  data-label="Créer le  : "   >{{ datavalue.creerler }}</td>

                                                     <td  data-label="Action  : "   >


                                                        <div class="ti-btn-group">
                                                            <div class="hs-dropdown ti-dropdown">
                                                                <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                        id="dropdownMenuButton1"
                                                                        aria-expanded="false">
                                                                    Action<i
                                                                        class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                </button>
                                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                    aria-labelledby="dropdownMenuButton1">



                                                                    <li>
                                                                        <button style="width: 100%!important;" @click="btnimprimer(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-printer-fill"></i> Imprimer
                                                                        </button>
                                                                    </li>



                                                                    <li v-if="datavalue.nbon===''">
                                                                        <button style="width: 100%!important;" @click="btnannulation(datavalue)"  aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ">
                                                                            <i class="ri-pen-nib-line"></i> Annuler l'opération
                                                                        </button>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                        </div>




                                                    </td>

                                                </tr>



                                            </template>


                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>

                            </div>
                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modalform ref="modalform"  @closed="load"></modalform>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>
    <modalrapportdeprefdpcc ref="modalrapportdeprefdpcc" ></modalrapportdeprefdpcc>


</template>

<script>


    import modalrapportdeprefdpcc from "@/components/views/pages/caisse/rapport/modal_rapport_dep_ref_dpcc";

    const axios =require('axios');

    import modalform from "@/components/views/pages/caisse/depense/form";
    import modalformsuppression from "@/components/views/pages/caisse/depense/annulervalidationouinon";


    import {currencyFormatDE, isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },


        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /*if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.removeline===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }



                return this.paginate(mvalue) ;
            },
        },
        components: {
            modalform,modalformsuppression,
            modalrapportdeprefdpcc

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                otherdatatable:[],
                listedupersonnel:[],
                datainputlibelleforms:[],
                datatiers:[],
                typedoc:"",
                messageerror:"",
                messagesucces:"",





                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            btnannulation(value){


                let titlemodal="VOULLEZ-VOUS VRAIMENT ANNULER LE DOCUMENT "+value.numdoc+" ?";


                value.typligne="A"+this.typedoc

                this.$refs.modalformsuppression.show(titlemodal,value,this.typedoc);

            },

            formatNumber(num) {

                return  currencyFormatDE(num)
            },

            btnajouter(){

                let authoriationplslignedepense=0

                let mname

                let  Item= {
                    pid:'',
                    docnum:'',
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    beneficiaire:null,
                    document:this.typedoc,
                    montant:0,
                    alldepense:[],
                    params:{
                        param1:null,
                        param2:null,
                    },
                    jsondoc:{
                        key:''
                    },
                };

                if (this.typedoc==='DEP'){

                    mname='Enregistrer une dépense';
                }

                if (this.typedoc==='DPCC'){

                    mname='Enregistrer un dépôt en compte client';
                }

                if (this.typedoc==='REF'){

                    mname='Enregistrer un retour de fonds de caisse';
                }


                this.$refs.modalform.show(mname,"add",this.typedoc,Item,this.otherdatatable,authoriationplslignedepense,this.datainputlibelleforms);

            },


            async load(){

                var caisse={
                    cf:this.$store.state.caisseinfo,
                    document:this.typedoc,
                }

                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/fkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/895418init51_refdepdpcc11515/'+randomString();
                let response =   axios  .post(url,caisse,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.m.datas;
                             t.isdisplay=true;
                        }
                        else{
                            t.datatable=[];
                            t.isdisplay=true;

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            async loadreturnotherdata(){

                  const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/caisse/othetdskd8755dsdhisd/other111dksdk/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                            t.otherdatatable=[];
                            t.otherdatatable=res.data.otherdata;
                        }
                        else{
                            t.otherdatatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },


            async loadinputlibelleforms(){



                let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                if (isset($VALUEDATAPRICE)){

                    this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                }
                else{
                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/12478855libelinputindex2iofdofiloadexp2/'+this.$store.state.appsociete+'/'+randomString();
                    let response =   axios  .get(url,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                    let res= await response
                        .then(function (res){
                            t.$store.dispatch('lancerchargement',false)


                            if(res.data.success){


                                t.datainputlibelleforms=[];

                                t.datainputlibelleforms=res.data.valuedataprice;

                                JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))


                            }
                            else{
                                t.datainputlibelleforms=[];

                            }


                        })
                        .catch((error)=>{

                                t.$store.dispatch('lancerchargement',false)
                            }
                        )
                        .finally(() =>{

                            t.$store.dispatch('lancerchargement',false)


                        })
                }



            },


            btnimprimer(value){

                let t=this

                if (t.typedoc==='DPCC' || t.typedoc==='REF'){
                    value.alldepense.push({motif:value.motif, montant:value.montant});
                }


                let tabvalue={
                    nbon:value.nbon,
                    numdoc:value.numdoc,
                    date:value.dateop,
                    montanttotal:value.montant,
                    montanttotaldelafacure:value.montant,
                    items:value.alldepense,
                    docsaisiepar:value.saisirpar,
                    benrficiaire:value.beneficiaire,
                    qrr:value.docnum,
                    patient:value.beneficiaire.name,
                    observations:value.motif,
                    typdoc:t.typedoc


                }



                this.$refs.modalrapportdeprefdpcc.show(tabvalue);


            },



            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },

            show(titlemodal,typedoc){


                if(this.$store.state.caisseencour==='' ||
                    this.$store.state.caisseencour===''){

                    toastr['error']( "Veuillez sélectionner une caisse valide.")
                     this.$router.push({ name: 'tcaisse' });
                }
                else{


                    this.messagesucces="";
                    this.messageerror="";
                    this.titlemodal=titlemodal

                    this.typedoc=typedoc

                    //this.isdisplay=true;

                   this.loadinputlibelleforms()
                   this.loadreturnotherdata()
                   this.load()


                }

            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>