<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"> FERMERTURE DE LA CAISSE
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">

                        <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                            <div v-html="messagesucces">

                            </div>
                        </div>

                        <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                            <div v-html="messageerror">

                            </div>
                        </div>

                      <div class="alert alert-info text-center">
                          <h3 >Voulez-vous vraiment fermer la caisse ?</h3>
                      </div>




                </div>
                <div class="ti-modal-footer">
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Non
                    </button>
                    <button @click="billetage"  class="ti-btn ti-btn-primary-full" href="javascript:void(0);" style="background: greenyellow">
                       Oui
                    </button>

                </div>
            </div>
        </div>
    </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <billetagecaisse @closed="close" ref="billetagecaisse"></billetagecaisse>

</template>

<script>

    import billetagecaisse from "@/components/views/pages/caisse/billetagecaisse";


    //import allfunction from "@/allfunction";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",
        watch: {


        },

        computed:{




        },
        components: {
            billetagecaisse

        },

        data() {
            return {
                isdisplay:false,
                datavalue:"",
                titlemodal:"",
                Items:{},
                typedoc:"",
                messageerror:"",
                messagesucces:"",
            };
        },
        methods: {

            close(){

                this.isdisplay=false;


            },


            show(){
                this.messagesucces="";
                this.messageerror="";

                this.isdisplay=true;

            },


            async billetage() {

                this.messageerror="";


                let $caisseencour=(this.$store.state.caisseencour!=="" && this.$store.state.caisseencour!==null)?this.$store.state.caisseencour:"";
                if ($caisseencour===""){

                    return }

                this.$store.dispatch('lancerchargement',true)

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/caisse/fkdfkdozojdjsolde/'+this.$store.state.paramsystem.apikey+'/'+$caisseencour+'/'+this.$store.state.appsociete+'/895418init519151911515/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                await response
                    .then(response => {
                        t.$store.dispatch('lancerchargement',false)

                        if (response.data.success){

                            t.$store.dispatch('totaldepense',response.data.m.totaldepense);
                            t.$store.dispatch('totalrecette',response.data.m.totalrecette);
                            t.$store.dispatch('montantencaisse',response.data.m.solde);
                            t.$refs.billetagecaisse.show(response.data.m.solde);

                        }
                        else{

                            t.messageerror="Une erreur est survenue lors du chargement du billetage. Veuillez actualiser la page ou contacter votre administrateur."

                            toastr['error']( 'Une erreur est survenue lors du chargement du billetage. Veuillez actualiser la page ou contacter votre administrateur.',"<b></b>");

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))



            },



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .modalstyle{
        max-width: 50%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }

</style>