<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h4 class="modal-title" id="staticBackdropLabel2">TRANSFORMATION
                    </h4>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                             TRANSFORMATION DU DOCUMENT {{datavalue.numdoc}}
                            </div>



                        </div>


                        <div class="box-body !p-0">
                            <!--div class="grid grid-cols-12  gap-x-6 mt-3"-->
                            <div class="grid lg:grid-cols-1 gap-6">

                               <template v-if="typededocument==='achat'">

                                     <div v-if="typedoc==='DAF'"   class="space-y-2 mt-3 mb-3">
                                           <button v-if="$store.state.accescomponent.cccommercialachatbondecommande===true" @click="validationtransformation('BCF')"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" >
                                               BON DE COMMANDE
                                           </button>
                                           <button v-if="$store.state.accescomponent.cccommercialachatbondereception===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BRF')">
                                               BON DE RECEPTION
                                           </button>
                                           <button v-if="$store.state.accescomponent.cccommercialachatfacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('FR')">
                                               FACTURE
                                           </button>
                                       </div>

                                     <div v-if="typedoc==='BCF'"   class="space-y-2 mt-3 mb-3">

                                           <button v-if="$store.state.accescomponent.cccommercialachatbondereception===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BRF')">
                                               BON DE RECEPTION
                                           </button>
                                           <button v-if="$store.state.accescomponent.cccommercialachatfacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('FR')">
                                               FACTURE
                                           </button>
                                       </div>

                                     <div v-if="typedoc==='BRF'"   class="space-y-2 mt-3 mb-3">

                                           <button v-if="$store.state.accescomponent.cccommercialachatfacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('FR')">
                                               FACTURE
                                           </button>
                                           <button v-if="$store.state.accescomponent.cccommercialachatretoursurachat===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('RF')">
                                               RETOURS SUR ACHATS
                                           </button>
                                       </div>

                                     <div v-if="typedoc==='FR'"   class="space-y-2 mt-3 mb-3">
                                         <button v-if="$store.state.accescomponent.cccommercialachatretoursurachat===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('RF')">
                                             RETOURS SUR ACHATS
                                         </button>
                                       </div>


                               </template>


                               <template v-if="typededocument==='vente'">

                                     <div v-if="typedoc==='PF'"   class="space-y-2 mt-3 mb-3">
                                           <button v-if="$store.state.accescomponent.cccommercialventebondecommande===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BC')">
                                               BON DE COMMANDE
                                           </button>
                                           <button  v-if="$store.state.accescomponent.cccommercialventebondepreparationlivraison===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BPL')">
                                              PREPARATION DU BON DE LIVRAISON
                                           </button>

                                           <button  v-if="$store.state.accescomponent.cccommercialventebondelivraison===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BL')">
                                               BON DE LIVRAISON
                                           </button>

                                           <button  v-if="$store.state.accescomponent.cccommercialventefacture===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('F')">
                                               FACTURE
                                           </button>
                                       </div>

                                     <div v-if="typedoc==='BC'"   class="space-y-2 mt-3 mb-3">

                                         <button  v-if="$store.state.accescomponent.cccommercialventebondepreparationlivraison===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BPL')">
                                             PREPARATION DU BON DE LIVRAISON
                                         </button>

                                         <button   v-if="$store.state.accescomponent.cccommercialventebondelivraison===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BL')">
                                             BON DE LIVRAISON
                                         </button>

                                         <button  v-if="$store.state.accescomponent.cccommercialventefacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('F')">
                                             FACTURE
                                         </button>
                                       </div>

                                     <div v-if="typedoc==='BPL'"   class="space-y-2 mt-3 mb-3">


                                         <button  v-if="$store.state.accescomponent.cccommercialventebondelivraison===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BL')">
                                             BON DE LIVRAISON
                                         </button>

                                         <button  v-if="$store.state.accescomponent.cccommercialventefacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('F')">
                                             FACTURE
                                         </button>
                                       </div>

                                     <div v-if="typedoc==='BL'"   class="space-y-2 mt-3 mb-3">

                                         <button  v-if="$store.state.accescomponent.cccommercialventefacture===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('F')">
                                             FACTURE
                                         </button>
                                         <button  v-if="$store.state.accescomponent.cccommercialventeretoursurvente===true"  style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BR')">
                                             RETOURS SUR VENTE
                                         </button>
                                       </div>

                                     <div v-if="typedoc==='F'"   class="space-y-2 mt-3 mb-3">

                                         <button  v-if="$store.state.accescomponent.cccommercialventeretoursurvente===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('BR')">
                                             RETOURS SUR VENTE
                                         </button>
                                       </div>

                                     <div v-if="typedoc==='BR'"   class="space-y-2 mt-3 mb-3">

                                         <button  v-if="$store.state.accescomponent.cccommercialventeretoursurvente===true" style="background: dodgerblue"  class="ti-btn ti-btn-primary-full w-full" @click="validationtransformation('FR')">
                                             FACTURE D'AVOIR
                                         </button>
                                       </div>


                               </template>







                            </div>
                        </div>



                    </div>






                </div>
                <div class="ti-modal-footer">

                    <!--button   class="ti-btn ti-btn-primary-full" @click="selected(null)" style="background: greenyellow">
                        Effacer la sélection
                    </button-->


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
  import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";

    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",


        computed:{


        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                datavalue:{},
                anctyp:"",
                typedoc:"",
                numdoc:"",
                keypid:"",
                mynewtypdoc:"",
                typededocument:"",




            };
        },
        methods: {

            close(){


                this.selected("0")

                //this.$emit('closed');

            },

            selected(value){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = {
                    typ: value,
                    numdoc: this.numdoc,
                    keypid: this.keypid,
                    mynewtypdoc: this.mynewtypdoc

                };

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionnetransformation', elementSelectionne);


                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },



            show(titlemodal,datavalue,typedoc,typededocument){

                this.titlemodal=titlemodal
                this.datavalue=datavalue
                this.typedoc=typedoc
                this.anctyp=typedoc
                this.typededocument=typededocument

               // this.loadreturnotherdata2()

                this.isdisplay=true;

            },


            async validationtransformation(newtypdoc){

                this.$store.dispatch('lancerchargement',true)


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfgescomporziprodpfachatventeegl745874stock771714dfe/gescom/default/valtransadd/'+newtypdoc+'/'+this.anctyp+'/'+this.datavalue.pid+'/'+this.typededocument+'/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .post(url,this.datavalue,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            toastr['success']( res.data.message,"<b></b>");

                            t.numdoc=res.data.numdoc
                            t.keypid=res.data.pid
                            t.mynewtypdoc=newtypdoc

                            t.selected("1")

                        }
                        else{
                            t.selected("0")

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }




            },


            async loadreturnotherdata(){

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/jkebfiuebffeznfoieznofibeubqsyudfyegl745874stock771714dfe/gescom/othetdskd8755dsdhisd/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.datatable=[];
                            t.datatable=res.data.otherdata.frsclt

                        }
                        else{
                           // t.datatable=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }




            },


            async loadreturnotherdata2(){



                /* let $VALUEDATAPRICE=JwtService.getVALUEDATAPRICE()

                 if (isset($VALUEDATAPRICE)){

                     this.datainputlibelleforms=JSON.parse($VALUEDATAPRICE);


                 }
                 else{*/




                const t = this;
                let url=process.env.VUE_APP_API_URL+'/ar85474524otherdatadofodfidfoioienf55485/ges/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){


                            t.otherdata=[];

                            t.otherdata=res.data.otherdata;

                            //console.log("otherdata ",t.otherdata)


                            // JwtService.setVALUEDATAPRICE(JSON.stringify(t.datainputlibelleforms))

                        }
                        else{
                            t.otherdata=[];

                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })
                //  }



            },





        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 50%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>