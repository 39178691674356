<template>


    <div v-if="isdisplay" class=" open ti-modal " aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)]
         flex items-center modalstyle " style="    margin-top: 0rem;" >
            <div class="ti-modal-content " style="width: 100%!important;" :style="`height:${myscreenSize>995?100+'%':myscreenHeight+'px'};`">

          <template v-if="myscreenSize>995">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                       {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                       {{messageerror}}
                                    </div>
                                </div>


                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;">Date: {{Items.datedoc}}  <template v-if="Items.docnum!==''"> / {{Items.docnum}}</template> </p>
                                </div>





                                <div class="ti-custom-validation mt-5 row" >
                                    <div class="grid lg:grid-cols-2 mt-5 mb-5 gap-6">

                                        <div class="space-y-2">
                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-12" >
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Client</label>


                                                    <input :placeholder="'Veuillez sélectionner un client'"  v-if="Items.jsonotherdonne.cltfrs===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.jsonotherdonne.cltfrs.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button v-if="!disableddoc"   @click="btnmodallistedescltfrs()"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                                    <button v-else   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="space-y-2">

                                            <div class="grid grid-cols-2  gap-x-6 mt-3">

                                                <div class="space-y-2 w-full">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Ifu client</label>
                                                    <input v-model="Items.jsonv.ifu" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Ifu du client sur la facture"   required>


                                                </div>


                                                <div class="space-y-2 w-full">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Nom client</label>
                                                    <input v-model="Items.jsonv.client" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Nom du client sur la facture"   required>

                                                </div>



                                            </div>

                                        </div>


                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de réglement</label>

                                            <select :disabled="disableddoc" v-model="Items.jsonotherdonne.moderglt" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option  :value="null">Séléctionner un mode de réglement</option>
                                                <option v-for="(datavalue,index) of alldata.modedereglement" :key="index"
                                                        :value="datavalue">{{datavalue.name}}</option>

                                            </select>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramétre d'indication</label>

                                            <select :disabled="disableddoc" v-model="Items.jsonv.param"
                                                    class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option  :value="null">Paramétre d'indication</option>
                                                <option v-for="(datavalue,index) of alldata.paramcaisse" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                            </select>
                                        </div>



                                    </div>





                                        <div class="mt-3 mb-3">

                                            <div  class="col-md-12 mb3 alert alert-info "><h3 class="text-center">Articles</h3></div>

                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-4  col-span-12">

                                                    <div class="box-body !p-0 mybodyheight350px">

                                                        <div class="mt-4 mb-4">
                                                            <input v-model="searchword" ref="modalInput" class="form-control form-control-lg custom-placeholder" type="text"
                                                                   placeholder="Rechercher un article"
                                                                   aria-label="form-control-lg example">
                                                        </div>

                                                        <div class="table-responsive">

                                                            <!-- component -->
                                                            <!--div class="flex flex-col h-screen"-->
                                                            <div class="flex flex-col mybodyheight280px">
                                                                <div class="flex-grow overflow-auto">

                                                                    <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ARTICLE</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">STOCK</th>

                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                        <template v-if="filterdatatablearticle.length">

                                                                            <tr @click="selected(datavalue)" class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatablearticle" :key="index">

                                                                                <td data-label="N°  : ">{{index+1}}</td>
                                                                                <td  data-label="ARTICLE : "   >{{ datavalue.libelle }}</td>
                                                                                <td  data-label="STOCK : "   >{{ datavalue.rstck }}</td>

                                                                            </tr>



                                                                        </template>



                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                            </tr>

                                                                        </template>



                                                                        </tbody>
                                                                    </table>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div class="mt-4 mb-4">

                                                        <input @keydown.enter="onEnter" type="text" class="form-control custom-placeholder"
                                                               v-model="lecteurdecodebarre"
                                                               placeholder="Lecteur de code barre">


                                                    </div>


                                                    <div class="mt-4 mb-4">


                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de réglement</label>

                                                            <select :disabled="disableddoc" v-model="Items.jsonotherdonne.moderglt" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                                <option  :value="null">Séléctionner un mode de réglement</option>
                                                                <option v-for="(datavalue,index) of alldata.modedereglement" :key="index"
                                                                        :value="datavalue">{{datavalue.name}}</option>

                                                            </select>

                                                    </div>


                                                    <div class="mt-4 mb-4">

                                                        <div class="grid grid-cols-2  gap-x-6 mt-3">

                                                            <div class="space-y-2 w-full">
                                                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Ifu client</label>
                                                                <input v-model="Items.jsonv.ifu" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Ifu du client sur la facture"   required>


                                                            </div>


                                                            <div class="space-y-2 w-full">
                                                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Nom client</label>
                                                                <input v-model="Items.jsonv.client" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Nom du client sur la facture"   required>

                                                            </div>



                                                        </div>


                                                    </div>





                                                </div>


                                                <div class="xl:col-span-8  col-span-12">


                                                    <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                        <div class="xl:col-span-12 col-span-10">

                                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">ARTICLE</label>
                                                                    <input placeholder="Veuillez sélectionner un article"  v-if="ItemsArticle.article===null" type="text"
                                                                           class="form-control" disabled>
                                                                    <input v-else :value="ItemsArticle.article.libelle" type="text" class="form-control" disabled>

                                                        </div>

                                                        <div class="xl:col-span-3  col-span-12 mt-3">
                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Pu</label>
                                                            <input disabled :value="prixdevente"   type="number" min="0" class="form-control text-center" >

                                                        </div>

                                                        <div class="xl:col-span-3  col-span-12 mt-3">
                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">QUANTITE</label>

                                                            <input v-model="storeqtite"   type="number" min="0" class="form-control text-center" >

                                                        </div>


                                                        <div class="xl:col-span-3  col-span-12 mt-3">
                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">REMISE</label>

                                                            <input v-model="ItemsArticle.remise"   type="text" min="0" class="form-control text-center" >

                                                        </div>


                                                        <div  class="xl:col-span-3  col-span-12 mt-3">
                                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">MONTANT</label>

                                                            <input v-model="montant" disabled  type="text" min="0" class="form-control text-center" >

                                                        </div>

                                                    </div>

                                                    <!--hr class="mb-[-15px]"-->
                                                    <div class="clearfix"></div>
                                                    <div class="form-group mt-[20px]">
                                                        <div class="row flex justify-end">
                                                            <div class="grid grid-cols-3 gap-x-6 mt-3">

                                                                <button @click="newArticle" class="ti-btn ti-btn-primary-full mt-2 mx-[6px]">
                                                                    <i class="ri-add-fill"></i> Nouveau
                                                                </button>
                                                                <button @click="SaveArticle()" class="ti-btn ti-btn-success-full mt-2 mx-[6px]" >
                                                                    <i class="ri-check-line"></i> Enregistrer
                                                                </button>
                                                                <button @click="deleteItemArticle()" class="ti-btn ti-btn-danger-full mt-2 mx-[6px]" >
                                                                    <i class="ri-delete-column"></i> Supprimer
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="box-body !p-0 mybodyheight300px">


                                                        <div class="table-responsive">

                                                            <div class="flex flex-col h-screen">
                                                                <div class="flex-grow overflow-auto">

                                                                    <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                                        <thead>
                                                                        <tr>

                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ETAT</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center" colspan="2">DESIGNATION</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">PU</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">QUANTITE</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">REMISE</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">MONTANT</th>
                                                                            <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-center">ACTION</th>

                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                        <template v-if="listedatatable.length">

                                                                            <template  v-for="(datavalue,index) in listedatatable" :key="index">
                                                                                <tr  class="border-t hover:bg-gray-200 dark:hover:bg-light">

                                                                                    <td  data-label="ETAT  : "   >

                                                                                        <span v-if="datavalue.etatline===1 || datavalue.etatline==='1'" class="badge mbadge !rounded-full bg-success/10 text-success">OK</span>
                                                                                        <span v-if="datavalue.etatline===2 || datavalue.etatline==='2' || datavalue.etatline===4 || datavalue.etatline==='4'" class="badge mbadge !rounded-full bg-danger/10 text-danger">Erreur</span>
                                                                                        <span v-if="datavalue.etatline===0 || datavalue.etatline==='0'" class="badge mbadge !rounded-full bg-info/10 text-info">En attente</span>

                                                                                    </td>
                                                                                    <td  data-label="DESIGNATION  : " colspan="2"  >
                                                                                        <template v-if="datavalue.autredesignation===''">
                                                                                            {{ datavalue.article.libelle }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{ datavalue.article.libelle+' ( '+datavalue.autredesignation+' )'}}
                                                                                        </template>

                                                                                    </td>
                                                                                    <td  data-label="PU  : "   >{{ datavalue.pu }}</td>
                                                                                    <td  data-label="QUANTITE  : "   >{{ formatNumber2(datavalue.qtite) }}</td>
                                                                                    <td  data-label="REMISE  : "   >{{ datavalue.remise }}</td>
                                                                                    <td  data-label="MONTANT  : "   >{{ currencyFormatDE(datavalue.calmtntht) }}</td>
                                                                                    <!--td  data-label="MONTANT  : "   >{{ calculSomme(datavalue.mtnt,datavalue.remise) }}</td-->


                                                                                    <td  data-label="ACTION  : "   >

                                                                                        <button   @click="modifierArticle(datavalue)"  aria-label="anchor"
                                                                                                  class="ti-btn ti-btn-primary-full">
                                                                                            <i class="ri-check-line"></i> Sélectionner
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                                <tr v-if="datavalue.etatline===2">
                                                                                    <td colspan="20"><div class="alert alert-danger text-center" v-html="datavalue.linemessage"></div></td>
                                                                                </tr>

                                                                            </template>

                                                                        </template>


                                                                        <template v-else>
                                                                            <tr >
                                                                                <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                            </tr>

                                                                        </template>



                                                                        </tbody>

                                                                        <tfoot v-if="listedatatable.length">


                                                                        <tr class="border border-defaultborder">
                                                                            <td style="text-align: end;" colspan="6" >MONTANT HT</td>
                                                                            <td>{{currencyFormatDE(Items.mtnt.doccalmtnttotalht)}}</td>
                                                                            <!--td>{{calculSomme(Items.mtnt.docmtnttotalht,Items.mtnt.docmtntremise)}}</td-->
                                                                            <td></td>
                                                                        </tr>

                                                                        <tr v-if="parseFloat(Items.mtnt.docmtntremise)>0" class="border border-defaultborder">
                                                                            <td style="text-align: end;" colspan="6" >REMISE</td>
                                                                            <td>{{currencyFormatDE(Items.mtnt.docmtntremise)}}</td>
                                                                            <td></td>
                                                                        </tr>


                                                                        <tr class="border border-defaultborder">
                                                                            <td style="text-align: end;" colspan="6" >NET A PAYER</td>
                                                                            <td>{{currencyFormatDE(Items.mtnt.docmtnttotalttc)}}</td>
                                                                            <td></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td style="    border-bottom: 0px solid #e9e9e9 ! important" colspan="4"></td>
                                                                            <td colspan="3"> <p class="text-center mt-0 mb-0" style="text-transform: uppercase">Montant reçu</p>  </td>
                                                                            <td colspan="2">   <input v-model="Items.jsonotherdonne.montantrecu" style="text-align: end;" min="0" class="form-control" type="number"> </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td style="    border-bottom: 0px solid #e9e9e9 ! important" colspan="4"></td>
                                                                            <td colspan="3"> <p class="text-center mt-0 mb-0 text-uppercase" style="text-transform: uppercase">Reliquat</p>  </td>
                                                                            <td colspan="2">   <h2  style="text-align: end;">{{reliquat}}</h2> </td>
                                                                        </tr>




                                                                        </tfoot>


                                                                    </table>


                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>




                                                </div>


                                        </div>

                                        </div>






                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                      <i class="ri-close-fill"></i>  Fermer
                    </button>


                    <button  :disabled="compteprocess>0"  @click="newinvoice"  class="ti-btn ti-btn-purple-full" >
                      <i class="ri-add-fill"></i>  Nouveau <template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>

                    <button :disabled="compteprocess>0" @click="note"  class="ti-btn ti-btn-primary-full">
                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="ri-printer-line"></i> Note &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>

                    <button style="background: green" :disabled="compteprocess>0"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                      <i class="ri-calculator-fill"></i>  Comptabilisé <template v-if="compteprocess>0">({{compteprocess}})</template>
                    </button>

                </div>
          </template>
         <template v-else>

            <div class="flex flex-col h-screen">
                <div class="ti-modal-header">
                    <h6 class="modal-title"  ><h3><strong>{{titlemodal}}</strong></h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <!-- Top Bar avec boutons défilables -->
                <!--header class="bg-blue-600 text-white px-4 py-3">
                    <div class="flex space-x-2 overflow-x-auto no-scrollbar">
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 1</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 2</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 3</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 4</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 5</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 6</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 8</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 9</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 10</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 11</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 12</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 13</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 14</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 15</button>
                    </div>
                </header-->

                <!-- Top Bar -->

                <header class="flex items-center justify-between   text-black-50 px-4 py-3">
                    <button v-if="!mobileentecommante" @click="entetedocument"  class=" ti-btn ti-btn-primary-full p-2 ">
                        &nbsp;&nbsp;Entête commande
                    </button>


                    <div class="hs-dropdown ti-dropdown">
                        <a aria-label="anchor" href="javascript:void(0);" class="flex items-center justify-center w-[1.75rem] h-[1.75rem] !text-defaulttextcolor !text-[0.8rem] !py-1 !px-2 rounded-sm bg-light border-light shadow-none !font-medium" aria-expanded="false"><i class="fe fe-more-vertical text-[0.8rem]"></i>
                        </a>
                        <ul class="hs-dropdown-menu ti-dropdown-menu hidden" style="">
                            <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block" href="javascript:void(0);">Action</a></li>
                            <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block" href="javascript:void(0);">Another action</a></li>
                            <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block" href="javascript:void(0);">Something else here</a></li>
                        </ul>
                    </div>


                </header>

                <!--div v-if="messageerror" class="flex items-center justify-between alert alert-danger  text-black-50 px-4 py-3 ">
                    <div v-html="messageerror"></div>
                </div>

                <div v-if="messagesucces" class="flex items-center justify-between alert alert-success  text-black-50 px-4 py-3 ">
                    <div v-html="messagesucces"></div>
                </div-->


                <!---div class="bg-blue-600 text-white px-4 py-3 flex items-center">

                    <button type="button" class="p-2 bg-blue-700 rounded-md mr-2" @click="scrollLeft()">
                        &larr;
                    </button>


                    <div id="scrollContainer" ref="scrollContainer"  class="flex space-x-2 overflow-x-auto no-scrollbar flex-1">
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 1</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 2</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 3</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 4</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 5</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 6</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                        <button class="p-2 bg-blue-500 rounded-md hover:bg-blue-700">Button 7</button>
                    </div>


                    <button type="button" class="p-2 bg-blue-700 rounded-md ml-2" @click="scrollRight()">
                        &rarr;
                    </button>
                </div-->

                <div v-if="mobilelistarticle" class="grid grid-cols-2 gap-6 text-white px-4 py-3 flex items-center">

                    <div class="space-y-2">
                        <input v-model="searchword" ref="modalInput" class="form-control form-control-lg custom-placeholder" type="text"
                               placeholder="Rechercher un article"
                               aria-label="form-control-lg example">
                    </div>

                    <div class="space-y-2">

                        <input @keydown.enter="onEnter" type="text" class="form-control custom-placeholder"
                               v-model="lecteurdecodebarre"
                               placeholder="Lecteur de code barre">


                    </div>


                </div>

                <!-- Content Area (Scrollable) -->
                <main class="flex-1 overflow-y-auto bg-gray-100 p-4" style="background-color: white">


                    <div class="space-y-4" v-if="mobilelistarticle">


                        <template v-if="filterdatatablearticle.length">


                                <div @dblclick="selectItemsmobile(datavalue)" style="cursor: pointer" v-for="(datavalue,index) in filterdatatablearticle" :key="index" class="p-4 bg-white rounded-lg shadow-md">

                                    <div class="flex items-center flex-grow leading-none">

                                        <div class="leading-none flex-grow">
                                            <span class="mt-2 mb-2 font-semibold block mb-2"><strong>{{datavalue.libelle}}</strong></span>
                                            <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">{{ datavalue.rstck }}</span>
                                        </div>
                                        <div v-if="datavalue.nbrevendue && datavalue.nbrevendue>0" class="text-end me-3">
                                                                <span style="color: red" class="block font-semibold mb-1">
                                                                    {{ datavalue.nbrevendue }}
                                                                </span>
                                        </div>

                                    </div>


                                </div>


                        </template>

                    </div>



                    <div class="space-y-4" v-if="mobileentecommante">

                        <div class="grid lg:grid-cols-2 mt-5 mb-5 gap-6">

                            <div class="space-y-2">
                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Client</label>


                                        <input :placeholder="'Veuillez sélectionner un client'"  v-if="Items.jsonotherdonne.cltfrs===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.jsonotherdonne.cltfrs.name" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                        <button v-if="!disableddoc"   @click="btnmodallistedescltfrs()"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                        <button v-else   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                    </div>
                                </div>
                            </div>

                            <div class="space-y-2">

                                <div class="grid md:grid-cols-2  gap-x-6 mt-3">

                                    <div class="space-y-2 w-full">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Ifu client</label>
                                        <input v-model="Items.jsonv.ifu" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Ifu du client sur la facture"   required>


                                    </div>


                                    <div class="space-y-2 w-full">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Nom client</label>
                                        <input v-model="Items.jsonv.client" type="text" class="firstName my-auto ti-form-input
                                             rounded-sm" placeholder="Nom du client sur la facture"   required>

                                    </div>


                                </div>

                            </div>


                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de réglement</label>

                                <select :disabled="disableddoc" v-model="Items.jsonotherdonne.moderglt" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Séléctionner un mode de réglement</option>
                                    <option v-for="(datavalue,index) of alldata.modedereglement" :key="index"
                                            :value="datavalue">{{datavalue.name}}</option>

                                </select>
                            </div>


                            <div class="space-y-2">
                                <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramétre d'indication</label>

                                <select :disabled="disableddoc" v-model="Items.jsonv.param"
                                        class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                    <option  :value="null">Paramétre d'indication</option>
                                    <option v-for="(datavalue,index) of alldata.paramcaisse" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                </select>
                            </div>



                        </div>



                        <div class="space-y-2 w-full">
                            <button @click="btncloseentetedocument" type="button"
                                    class="ti-btn ti-btn-danger-full">
                                <i class="ri-close-circle-fill"></i> Fermer</button>
                        </div>




                    </div>


                    <div class="space-y-4" v-if="mobilerecapitulatif">


                        <template v-if="listedatatable.length">


                            <div style="cursor: pointer" v-for="(datavalue,index) in listedatatable" :key="index" class="p-4 bg-white rounded-lg shadow-md">

                                <div class="flex items-center flex-grow leading-none">

                                    <div class="leading-none flex-grow">
                                        <span class="mt-2 mb-2 font-semibold block mb-2"><strong>
                                             <template v-if="datavalue.autredesignation===''">
                                            {{ datavalue.article.libelle }}
                                        </template>
                                        <template v-else>
                                            {{ datavalue.article.libelle+' ( '+datavalue.autredesignation+' )'}}
                                        </template>
                                        </strong></span>
                                        <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">PU: {{ datavalue.pu }}</span>
                                        <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Qte:{{ formatNumber2(datavalue.qtite) }}</span>
                                        <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Remise: {{ datavalue.remise }}</span>
                                        <span class="mt-2 mb-2 block text-[#8c9097] dark:text-white/50 text-[0.75rem]">Montant: {{ currencyFormatDE(datavalue.calmtntht) }}</span>



                                    </div>
                                    <div  class="text-end me-3">
                                        <button   @click="btnupdateitemsmodible(datavalue)"  aria-label="anchor"
                                                  class="ti-btn ti-btn-primary-full">
                                            <i class="ri-pencil-line"></i>
                                        </button>

                                        <button   @click="deleteItemArticlemobile(datavalue)"  aria-label="anchor"
                                                  class="ti-btn ti-btn-danger">
                                            <i class="ri-delete-bin-7-line"></i>
                                        </button>


                                    </div>


                                </div>


                            </div>


                        </template>


                        <div class="space-y-2 w-full">
                            <button @click="btncloseentetedocument" type="button"
                                    class="ti-btn ti-btn-danger-full">
                                <i class="ri-close-circle-fill"></i> Fermer</button>
                        </div>


                    </div>




                </main>

                <!-- Bottom Bar -->
                <footer class="flex items-center justify-around bg-white border-t border-gray-300 h-14">

                    <button   :disabled="compteprocess>0"  @click="newinvoice"  class="flex flex-col ti-btn items-center text-blue-600 hover:text-blue-800">
                         <i class="ri-add-line"></i>
                        <span class="text-xs">Nouveau</span>
                    </button>

                     <button  :disabled="compteprocess>0"  @click="note" class="flex flex-col ti-btn items-center text-blue-600 hover:text-blue-800">
                    <i class="ri-calculator-fill"></i>
                    <span class="text-xs">Note</span>
                   </button>


                    <button @click="btnrecapitulatif" class="flex flex-col ti-btn items-center text-blue-600 hover:text-blue-800">
                        <i class="ri-table-line"></i>
                        <span class="text-xs">Récapitulatif</span>
                    </button>

                    <button  :disabled="compteprocess>0"  @click="newinvoice" class="flex flex-col ti-btn items-center text-blue-600 hover:text-blue-800">
                        <i class="ri-money-dollar-box-line"></i>
                        <span class="text-xs">Comptabilisé</span>
                    </button>






                </footer>









            </div>

             
         </template>



            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modallistfrsclt @elementSelectionnecltfrs="handleElementSelectedcltfrs" ref="modallistfrsclt"></modallistfrsclt>

    <validationouinon ref="validationouinon" @aftervalidation="aftervalidation"></validationouinon>
    <modalfacturecomptoirepost ref="modalfacturecomptoirepost" @closed="closeimpression" ></modalfacturecomptoirepost>
    <modalmodifieritems ref="modalmodifieritems"  @updatitem="updateitemsmodible"></modalmodifieritems>

</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
    import {array_search, cloneobjet, currencyFormatDE, FormatNumber, isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modallistfrsclt from "@/components/views/pages/gestioncommerciale/achatvente/modal_listfrsclt";
    import validationouinon from "@/components/views/pages/caisse/depense/validationouinon";
    import modalfacturecomptoirepost from "@/components/views/pages/caisse/rapport/modal_facturecomptoirepost";
    import {mapGetters} from "vuex";
    import qrcodetoimage from "qrcode";
    import modalmodifieritems from "@/components/views/pages/caisse/facturation/1/modal_modifieritems";


    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {

            storeqtite: function(oldvalue,newvalue) {

                console.log("oldvalue ***** ",oldvalue)
                console.log("newvalue ***** ",newvalue)



                if(this.ItemsArticle.article!==null ){
                    let montantpu=0;
                    let mqte=0;
                    let $puprixvente=0;
                    let mpu=0;
                    let mpa=0;

                    this.ItemsArticle.qtite=parseFloat(this.storeqtite);



                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                        /*
                        if (this.isblbr===true){

                        */

                            if (this.ItemsArticle.qtite!==0 ) // recherche du PU dans PV(gestion des prix tarrifaires)
                            {
                                mqte=this.ItemsArticle.qtite;


                                if (this.ItemsArticle.article.pv.length>0 )
                                {
                                    let vm=this

                                    montantpu= $puprixvente
                                    this.ItemsArticle.article.pv.forEach(function (line) {


                                        if(line.mtyprix===vm.Items.jsonotherdonne.mtypprixdevente){


                                            if(parseFloat(line.min)<=parseFloat(mqte)){
                                                if(parseFloat(line.max)>=parseFloat(mqte)){

                                                    montantpu=line.pu

                                                }

                                            }
                                        }
                                    })


                                }
                                else{

                                    // montantpu= this.itemArticle.article.pu
                                    montantpu= $puprixvente
                                }




                            }
                            else{
                                // montantpu= this.itemArticle.article.pu
                                montantpu= $puprixvente

                            }



                            /*
                        }
                        else{

                            if (this.disabled===false){ // l'article n'existe pas dans la liste de la table
                                //montantpu= this.itemArticle.article.pu
                                montantpu= $puprixvente
                            }else{ //existe
                                montantpu= this.ItemsArticle.pu

                            }
                        }
                        */



                        //***************************** le bon ok ******************************//

                    /*

                        if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                            if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                                if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                    //let cal=   parseFloat(this.itemArticle.article.pu)/1.18
                                    let cal=   parseFloat(montantpu)/1.18
                                    // let cal=   parseFloat(this.itemArticle.article.pu)/parseFloat(this.itemArticle.article.typttc)
                                    let fixedcal=cal.toFixed(8);
                                    //this.itemArticle.pu=Math.round(cal)
                                    //this.itemArticle.pu=Math.round(fixedcal)
                                    this.ItemsArticle.pu=fixedcal
                                    this.ItemsArticle.pa=this.ItemsArticle.article.pu
                                    //this.itemArticle.pa=0

                                }
                                else{

                                    // let cal=   parseFloat(this.itemArticle.article.pu)*0.18
                                    let cal=   parseFloat(montantpu)*0.18
                                    this.ItemsArticle.pu=montantpu
                                    this.ItemsArticle.pa=Math.round(cal)

                                }



                            }
                            else{

                                // this.itemArticle.pu=this.itemArticle.article.pu
                                this.ItemsArticle.pu=montantpu
                                this.ItemsArticle.pa=this.ItemsArticle.article.pu
                                //this.itemArticle.pa=0

                            }






                        }
                        else{

                            //  this.itemArticle.pu=this.itemArticle.article.pu
                            this.ItemsArticle.pu=montantpu
                            this.ItemsArticle.pa=this.ItemsArticle.article.pu
                            //this.itemArticle.pa=0

                        }

                        */

                    this.ItemsArticle.pu=montantpu
                    this.ItemsArticle.pa=this.ItemsArticle.article.pu




                    }

            }


        },


        computed:{
            ...mapGetters(['listedesarticlespourlacaisse','myscreenSize','myscreenHeight']),



            isblbr: function (){

                    return true;

            },

            disableddoc(){

                return this.Items.docnum ?true:false;
            },

            reliquat(){

                return this.calculMonnaie(this.Items.mtnt.docmtnttotalttc,this.Items.jsonotherdonne.montantrecu);
            },

            montant()
            {




                let montancalcul=0;

                if(this.ItemsArticle.article!==null ){


                    if (this.ItemsArticle.qtite!==0 )
                    {

                            montancalcul=parseFloat(this.ItemsArticle.pu)*parseFloat(this.ItemsArticle.qtite);


                    }


                    if (isNaN(this.ItemsArticle.remise)) {
                        this.ItemsArticle.remise=0;
                    }


                    if (this.ItemsArticle.remise!==0 && !isNaN(this.ItemsArticle.remise) )
                    {
                        montancalcul=montancalcul-parseFloat(this.ItemsArticle.remise);
                    }

                }
                else {
                        this.ItemsArticle.pu=0;
                }
                montancalcul= Math.round(montancalcul)
                this.ItemsArticle.mtnt=montancalcul;

                return montancalcul


            },

            prixdevente: function (){

                if (this.ItemsArticle.article===null){
                    return 0;
                }
                else{
                    return this.ItemsArticle.pu;
                }

            },

            filterdatatablearticle(){
                this.datatablearticle=cloneobjet(this.listedesarticlespourlacaisse);;
                let mvalue=this.datatablearticle;


                this.perPage=this.DefaultperPage;


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.libelle.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }



                return mvalue ;
            },




        },
        components: {
            modallistfrsclt,
            modalfacturecomptoirepost,
            modalmodifieritems,
            validationouinon

        },

        data() {
            return {

                mobilelistarticle:true,
                mobilerecapitulatif:false,
                mobileentecommante:false,




                storeqtite:0,
                compteprocess:0,
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                searchword:"",
                lecteurdecodebarre:"",
                titlemodal:"",
                datatablearticle:[],
                listearticlescodebarre:[],
                listedatatable:[],
                alldata:{},
                datainputlibelleforms:{},
                typedoc:"",
                authoriationplslignedepense:0,
                typeaction:"add",
                Items: {},
                ItemsArticle:{
                    autredesignation:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''





                },

            };
        },
        methods: {

            btncloseentetedocument(){

                this.mobilelistarticle=true
                    this.mobilerecapitulatif=false
                    this.mobileentecommante=false
            },

            entetedocument(){

                this.mobilelistarticle=false
                    this.mobilerecapitulatif=false
                    this.mobileentecommante=true
            },

            btnupdateitemsmodible(datavalue){

                let mitems=cloneobjet(datavalue);

                this.$refs.modalmodifieritems.show(mitems);

            },

            updateitemsmodible($datavalue){

                this.ItemsArticle=$datavalue.item
                this.storeqtite=$datavalue.newqte


                this.mobilestoreqtite($datavalue.newqte)

                this.ItemsArticle.mtnt=this.montant;
                this.SaveArticle();


            },


            mobilestoreqtite(newvalue) {


                if(this.ItemsArticle.article!==null ){
                    let montantpu=0;
                    let mqte=0;
                    let $puprixvente=0;
                    let mpu=0;
                    let mpa=0;

                    this.ItemsArticle.qtite=parseFloat(newvalue);



                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                    if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                    /*
                    if (this.isblbr===true){

                    */

                    if (this.ItemsArticle.qtite!==0 ) // recherche du PU dans PV(gestion des prix tarrifaires)
                    {
                        mqte=this.ItemsArticle.qtite;


                        if (this.ItemsArticle.article.pv.length>0 )
                        {
                            let vm=this

                            montantpu= $puprixvente
                            this.ItemsArticle.article.pv.forEach(function (line) {


                                if(line.mtyprix===vm.Items.jsonotherdonne.mtypprixdevente){


                                    if(parseFloat(line.min)<=parseFloat(mqte)){
                                        if(parseFloat(line.max)>=parseFloat(mqte)){

                                            montantpu=line.pu

                                        }

                                    }
                                }
                            })


                        }
                        else{

                            // montantpu= this.itemArticle.article.pu
                            montantpu= $puprixvente
                        }




                    }
                    else{
                        // montantpu= this.itemArticle.article.pu
                        montantpu= $puprixvente

                    }



                    //***************************** le bon ok ******************************//

                    this.ItemsArticle.pu=montantpu
                    this.ItemsArticle.pa=this.ItemsArticle.article.pu




                }

            },


            btnrecapitulatif(){

                this.mobilelistarticle=false
                    this.mobilerecapitulatif=true
                    this.mobileentecommante=false
            },



            selectItemsmobile(datavalue){

                    let codebarre=datavalue.codebarre;
                    let dataSearch=[];
                    let listedesarticlescodebarre=this.listearticlescodebarre;
                    let selectarticle=datavalue;
                    let isfindarticle=0;


                    /*selectarticle=listedesarticlescodebarre.filter(function (article) {

                        return  article.codebarre ===codebarre
                    })*/


                    if (selectarticle!==null){

                        if(this.listedatatable.length !==0){


                            dataSearch = this.listedatatable.filter(function (article) {

                                return  article.article.codebarre ===codebarre
                                //return article.pidar.includes(value.pid);
                            });


                            if(dataSearch.length >0){
                                selectarticle=dataSearch
                                this.ItemsArticle=selectarticle[0];
                                let qte=1+parseFloat(selectarticle[0].qtite);
                                this.ItemsArticle.qtite=qte

                                let puvente=this.puvente();
                                var mitemarticle=this.ItemsArticle

                                this.ItemsArticle={
                                    article:selectarticle[0].article,
                                    qtite:qte,
                                    diqte:0,
                                    pu:parseFloat(puvente),
                                    pa:parseFloat(selectarticle[0].pa),
                                    mtnt:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    mtntc:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    calmtntht:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    docnumother:selectarticle[0].docnumother ,
                                    remise:selectarticle[0].remise,
                                    doctp:selectarticle[0].doctyp,
                                    pid:selectarticle[0].pid,
                                    docpid:selectarticle[0].docnum,
                                    docnum:selectarticle[0].docnum,
                                    autredesignation:selectarticle[0].autredesignation,

                                    linekey:selectarticle[0].linekey,
                                    mycheckkey:selectarticle[0].mycheckkey,
                                    etatline:0,
                                    accompagnement:selectarticle[0].accompagnement,
                                    ecartqte:selectarticle[0].ecartqte,
                                    otherdbdoc:selectarticle[0].otherdbdoc,
                                    reference:selectarticle[0].reference,
                                    stckinit:selectarticle[0].stckinit,
                                    tva:selectarticle[0].tva,
                                };

                                this.SaveArticle();


                            }
                            else{
                                let qte=1;
                                //var mitemarticle=this. itemArticle
                                this.ItemsArticle={
                                    article:selectarticle,
                                    qtite:qte,
                                    diqte:0,
                                    pu:parseFloat(selectarticle.pu),
                                    pa:parseFloat(selectarticle.pa),
                                    mtnt:parseFloat(selectarticle.pu) ,
                                    docnumother:'' ,
                                    remise:0,
                                    calmtntht:parseFloat(selectarticle.pu),
                                    doctp:'F',
                                    pid:"",
                                    docpid:this.Items.docpid,
                                    docnum:this.Items.docpid,


                                    autredesignation:"",
                                    linekey:"",
                                    mycheckkey:"",
                                    etatline:0,
                                    accompagnement:"",
                                    ecartqte:0,
                                    mtntc:parseFloat(selectarticle.pu),
                                    otherdbdoc:"",
                                    reference:"",
                                    stckinit:0,
                                    tva:0,

                                };

                                this.SaveArticle();
                            }




                        }
                        else{

                            let qte=1;
                            //var mitemarticle=this. itemArticle


                            this.ItemsArticle={
                                article:selectarticle,
                                qtite:qte,
                                diqte:0,
                                pu:parseFloat(selectarticle.pu),
                                pa:parseFloat(selectarticle.pa),
                                mtnt:parseFloat(selectarticle) ,
                                docnumother:'' ,
                                remise:0,
                                calmtntht:parseFloat(selectarticle.pu),
                                doctp:'F',
                                pid:"",
                                docpid:this.Items.docpid,
                                docnum:this.Items.docpid,


                                autredesignation:"",
                                linekey:"",
                                mycheckkey:"",
                                etatline:0,
                                accompagnement:"",
                                ecartqte:0,
                                mtntc:parseFloat(selectarticle.pu),
                                otherdbdoc:"",
                                reference:"",
                                stckinit:0,
                                tva:0,

                            };

                            this.SaveArticle();

                        }
                    }

                    else{

                        toastr['error']("Item not find","<b></b>");

                    }



            },




            scrollLeft() {
                const container = this.$refs.scrollContainer;
                container.scrollBy({ left: -100, behavior: 'smooth' });
            },
            scrollRight() {
                const container = this.$refs.scrollContainer;
                container.scrollBy({ left: 100, behavior: 'smooth' });
            },



            puvente()
            {
                let montantpu=0;
                let mqte=0;



                if(this.ItemsArticle.article!==null ){


                    if (this.ItemsArticle.qtite!==0 )
                    {
                        mqte=this.ItemsArticle.qtite;
                        if (this.ItemsArticle.article.pv.length>0 )
                        {
                            montantpu= this.ItemsArticle.article.pu
                            this.ItemsArticle.article.pv.forEach(function (line) {
                                if(parseFloat(line.min)<=parseFloat(mqte)){

                                    if(parseFloat(line.max)>=parseFloat(mqte)){

                                        montantpu=line.pu

                                    }

                                }
                            })


                        }
                        else{

                            montantpu= this.ItemsArticle.article.pu
                        }




                    }
                    else{
                        montantpu= this.ItemsArticle.article.pu


                    }



                    //***************************** le bon ok ******************************//
                    if (parseFloat(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                        if (parseFloat(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                            if(parseFloat(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                let cal=   parseFloat(montantpu)/1.18
                                this.ItemsArticle.pu=Math.round(cal)
                                montantpu=Math.round(cal)
                                this.ItemsArticle.pa=this.ItemsArticle.article.pu

                            }
                            else{

                                let cal=   parseFloat(this.ItemsArticle.article.pu)*0.18
                                this.ItemsArticle.pu=montantpu
                                this.ItemsArticle.pa=Math.round(cal)

                            }



                        }
                        else{

                            this.ItemsArticle.pu=montantpu
                            this.ItemsArticle.pa=this.ItemsArticle.article.pu

                        }






                    }
                    else{

                        this.ItemsArticle.pu=montantpu
                        this.ItemsArticle.pa=this.ItemsArticle.article.pu

                    }

                }
                else {
                    montantpu=0

                }


                return montantpu
            },


            onEnter(){



                if(this.lecteurdecodebarre!==''){


                    let codebarre=this.lecteurdecodebarre;
                    let dataSearch=[];
                    let listedesarticlescodebarre=this.listearticlescodebarre;
                    let selectarticle=null;
                    let isfindarticle=0;


                    selectarticle=listedesarticlescodebarre.filter(function (article) {

                        return  article.codebarre ===codebarre
                    })


                    if (selectarticle.length>0){



                        if(this.listedatatable.length !==0){


                            dataSearch = this.listedatatable.filter(function (article) {

                                return  article.article.codebarre ===codebarre
                                //return article.pidar.includes(value.pid);
                            });


                            if(dataSearch.length >0){
                                selectarticle=dataSearch
                                this.ItemsArticle=selectarticle[0];
                                let qte=1+parseFloat(selectarticle[0].qtite);
                                this.ItemsArticle.qtite=qte

                                let puvente=this.puvente();
                                var mitemarticle=this.ItemsArticle

                                this.ItemsArticle={
                                    article:selectarticle[0].article,
                                    qtite:qte,
                                    diqte:0,
                                    pu:parseFloat(puvente),
                                    pa:parseFloat(selectarticle[0].pa),
                                    mtnt:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    mtntc:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    calmtntht:(parseFloat(puvente)*parseFloat(qte))-parseFloat(selectarticle[0].remise) ,
                                    docnumother:selectarticle[0].docnumother ,
                                    remise:selectarticle[0].remise,
                                    doctp:selectarticle[0].doctyp,
                                    pid:selectarticle[0].pid,
                                    docpid:selectarticle[0].docnum,
                                    docnum:selectarticle[0].docnum,
                                    autredesignation:selectarticle[0].autredesignation,

                                    linekey:selectarticle[0].linekey,
                                    mycheckkey:selectarticle[0].mycheckkey,
                                    etatline:0,
                                    accompagnement:selectarticle[0].accompagnement,
                                    ecartqte:selectarticle[0].ecartqte,
                                    otherdbdoc:selectarticle[0].otherdbdoc,
                                    reference:selectarticle[0].reference,
                                    stckinit:selectarticle[0].stckinit,
                                    tva:selectarticle[0].tva,
                                };



                                this.SaveArticle();


                            }
                            else{
                                let qte=1;
                                //var mitemarticle=this. itemArticle


                                this.ItemsArticle={
                                    article:selectarticle[0],
                                    qtite:qte,
                                    diqte:0,
                                    pu:parseFloat(selectarticle[0].pu),
                                    pa:parseFloat(selectarticle[0].pa),
                                    mtnt:parseFloat(selectarticle[0].pu) ,
                                    docnumother:'' ,
                                    remise:0,
                                    calmtntht:parseFloat(selectarticle[0].pu),
                                    doctp:'F',
                                    pid:"",
                                    docpid:this.Items.docpid,
                                    docnum:this.Items.docpid,


                                    autredesignation:"",
                                    linekey:"",
                                    mycheckkey:"",
                                    etatline:0,
                                    accompagnement:"",
                                    ecartqte:0,
                                    mtntc:parseFloat(selectarticle[0].pu),
                                    otherdbdoc:"",
                                    reference:"",
                                    stckinit:0,
                                    tva:0,

                                };

                                this.SaveArticle();
                            }




                        }
                        else{

                            let qte=1;
                            //var mitemarticle=this. itemArticle


                            this.ItemsArticle={
                                article:selectarticle[0],
                                qtite:qte,
                                diqte:0,
                                pu:parseFloat(selectarticle[0].pu),
                                pa:parseFloat(selectarticle[0].pa),
                                mtnt:parseFloat(selectarticle[0].pu) ,
                                docnumother:'' ,
                                remise:0,
                                calmtntht:parseFloat(selectarticle[0].pu),
                                doctp:'F',
                                pid:"",
                                docpid:this.Items.docpid,
                                docnum:this.Items.docpid,


                                autredesignation:"",
                                linekey:"",
                                mycheckkey:"",
                                etatline:0,
                                accompagnement:"",
                                ecartqte:0,
                                mtntc:parseFloat(selectarticle[0].pu),
                                otherdbdoc:"",
                                reference:"",
                                stckinit:0,
                                tva:0,

                            };






                            this.SaveArticle();

                            //this.itemDoc.docnum=res.data.numdoc;
                            //this.itemDoc.docpid=res.data.keypid;



                        }




                    }

                    else{

                        toastr['error']("Item not find","<b></b>");

                    }


                }
                else{toastr['error']("lecteur not working","<b></b>");}

                this.lecteurdecodebarre="";

            },

            newinvoice(){

                this.listedatatable=[];
                this.ItemsArticle={
                    autredesignation:'',
                    docnum:'',
                    article:null,
                    qtite:0,
                    diqte:0,
                    pu:0,
                    pa:0,
                    mtnt:0,
                    mtntclt:0,
                    remise:0,
                    doc:'',
                    docpid:'',
                    docnumother:'',
                    docmag:'',
                    doctp:'',
                    linekey:'',
                    calmtntht:0,
                    etatline:0,
                    pid:''





                }
                this.Items= {
                    pid:'',
                    mag:null,
                    datedoc:this.$store.state.dateouverturecaisse,
                    docobservation:'',
                    docnum:'',
                    docnumother:'',
                    docpid:0,
                    doctyp:'',
                    infodoc:{
                        infolibre:'',
                        infolibre1:'',
                        infolibre2:'',
                        infolibre3:'',
                    },
                    jsonotherdonne:{
                        montantrecu:0,
                        tpdoc:"",
                        tredocument:"",
                        mtypprixdevente:"prixvente",
                        magvers:null,
                        collaborateur:null,
                        cltfrs:this.Items.jsonotherdonne.cltfrs,
                        moderglt:this.Items.jsonotherdonne.moderglt,
                        cdtreglement:null,
                        appliquetva:0,
                        aib:0,
                        adresseexpedition:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },
                        infodoc:{
                            infolibre:'',
                            infolibre1:'',
                            infolibre2:'',
                            infolibre3:'',
                        },



                    },
                    mtnt:{
                        montanthtassurance: 0,
                        docmtntaib: 0,
                        docmtntremise: 0,
                        docmtnttotalht: 0,
                        docmtnttotalttc: 0,
                        doctva: 0,
                        libaib: "AIB"


                    },
                    params:{
                        param1:null,
                        param2:null,
                        param3:null,
                        param4:null,
                    },
                    jsondoc:{},
                    jsonv:{

                        ifu:this.Items.jsonv.ifu,
                        client:this.Items.jsonv.client,
                        modereglement:this.Items.jsonv.modereglement,
                        indicateur:'',
                        indicateur1:'',
                        param:this.Items.jsonv.param,
                        param1:null,


                    }
                }


            },

            note(){

                let t=this
                if (this.listedatatable.length>0){

                    /*


                    let tabvalue={
                        numdoc:this.itemDoc.docnum,
                        date:this.itemDoc.datedoc,
                        client:this.itemDoc.jsonv.client,
                        params:'',
                        params4:'',
                        boutique:'',
                        vendeur:'',
                        items:this.listedatatable,
                        montantht:this.calculSomme(this.itemDoc.mtnt.docmtnttotalht,this.itemDoc.mtnt.docmtntremise,0),
                        montanttva:0,
                        montantremise:allfunction.currencyFormatDE(this.itemDoc.mtnt.docmtntremise),
                        montantnetapayer:this.itemDoc.mtnt.docmtnttotalttc,
                        reglement:this.itemDoc.jsonotherdonne.montantrecu,
                        montantmonnaie:this.calculMonnaie(this.itemDoc.mtnt.docmtnttotalttc,this.itemDoc.jsonotherdonne.montantrecu),
                        pourcentageprisenecharge:0,
                        docsaisiepar:this.$store.state.appusername,

                        normalise:'',
                        mecefsignature:'',
                        mecefnim:'',
                        mecefcompteur:'',
                        mecefheure:'',
                        mecefqrr:"",



                    }

                    if (t.itemDoc.jsonv.param!==null){tabvalue.params=t.itemDoc.jsonv.param.name}

                    t.$refs.modaletatnote.afficheetat(tabvalue);
                    */


                    // t.$emit('closed');


                    let items=[];

                    this.listedatatable.forEach(function (line) {
                        if (line.etatline!==2){ items.push(line) }
                    })

                    let tabvalue={
                        numdoc:this.Items.docnum,
                        date:this.Items.datedoc,
                        client:this.Items.jsonv.client,
                        ifu:this.Items.jsonv.ifu,
                        params:'',
                        params4:'',
                        boutique:'',
                        serveur:'',
                        commandetable:'',
                        vendeur:this.$store.state.appusername,
                        items:items,
                        montantht:this.calculSomme(this.Items.mtnt.docmtnttotalht,this.Items.mtnt.docmtntremise,0),
                        montanttva:0,
                        montantremise:currencyFormatDE(this.Items.mtnt.docmtntremise),
                        montantnetapayer:this.Items.mtnt.docmtnttotalttc,
                        reglement:this.Items.jsonotherdonne.montantrecu,
                        /*montantmonnaie:this.calculMonnaie(this.Items.mtnt.docmtnttotalttc,this.Items.jsonotherdonne.montantrecu),*/
                        montantmonnaie:0,
                        pourcentageprisenecharge:0,
                        docsaisiepar:this.$store.state.appusername,

                        normalise:'',
                        mecefsignature:'',
                        mecefnim:'',
                        mecefcompteur:'',
                        mecefheure:'',
                        mecefqrr:"",
                    }


                    if (t.Items.jsonv.param!==null){tabvalue.params=t.Items.jsonv.param.name}


                    this.$refs.modalfacturecomptoirepost.show(tabvalue,'note');


                }


            },



            formatNumber2(p1){
                var number=Number(p1)
                return FormatNumber(number)
            },

            selected(datavalue){

                this.changeItem(datavalue)

            },


            calculSomme(p1,p2,p3){

                var number=parseFloat(p1)+parseFloat(p2)+parseFloat(p3)
                return currencyFormatDE(number)
            },
            calculMonnaie(p1,p2){
                var number=parseFloat(p2)-parseFloat(p1)

                return currencyFormatDE(number)
            },


            handleElementSelectedcltfrs(elementSelectionne) {

                this.Items.jsonotherdonne.cltfrs=elementSelectionne.value

                if(this.Items.jsonotherdonne.cltfrs!==null){


                    this.Items.jsonv.client=this.Items.jsonotherdonne.cltfrs.name
                    this.Items.jsonv.ifu=this.Items.jsonotherdonne.cltfrs.ifu

                }else{
                    this.Items.jsonv.client='';
                    this.Items.jsonv.ifu='';

                }




            },

            btnmodallistedescltfrs(){


                let listedatable=[],
                    titlemodal="",
                    option=this.typedoc,
                    typedoc="";

                titlemodal="Liste des clients"
                listedatable=this.alldata.beneficiairecl
                option='vvente'


                //this.$refs.modallistfrsclt.show(titlemodal,listedatable,this.typededocument,this.datainputlibelleforms);

                let $CUSTOMLIBELLEFRSCLT=JwtService.getCUSTOMLIBELLEFRSCLT()


                if (isset($CUSTOMLIBELLEFRSCLT)){


                    $CUSTOMLIBELLEFRSCLT=JSON.parse($CUSTOMLIBELLEFRSCLT);

                }
                else{

                    $CUSTOMLIBELLEFRSCLT=this.$store.state.appcustomlibellefrsclt;

                    JwtService.setCUSTOMLIBELLEFRSCLT(JSON.stringify($CUSTOMLIBELLEFRSCLT))


                }


                this.$refs.modallistfrsclt.show(titlemodal,listedatable,option,this.datainputlibelleforms,$CUSTOMLIBELLEFRSCLT);

            },

            aftervalidation(elementSelectionne){

               this.Items.docnum=elementSelectionne.cnumdoc

                let t=this;



                if (t.typedoc==='DPCC' || t.typedoc==='REF'){
                    t.Items.alldepense.push({motif:t.Items.docobservation, montant:t.Items.montant});
                }


                let tabvalue={
                    nbon:"",
                    numdoc:t.Items.docnum,
                    date:t.Items.datedoc,
                    montanttotal:t.Items.montant,
                    montanttotaldelafacure:t.Items.montant,
                    items:t.Items.alldepense,
                    docsaisiepar:t.$store.state.appusername,
                    benrficiaire:t.Items.beneficiaire.name,
                    qrr:t.Items.docnum,
                    patient:t.Items.beneficiaire.name,
                    observations:t.Items.docobservation,
                    typdoc:t.typedoc


                }



                this.$refs.modalrapportdeprefdpcc.show(tabvalue);



            },

            close(){

                this.isdisplay=false;
                this.listearticlescodebarre=[];
                this.listedatatable=[];

                this.$emit('closed',this.datatablearticle);

            },


            closeimpression(element){

                if (element!=='note' || element!=='facture'){

                    this.newinvoice()

                }

            },



            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },

              async  saveItems(){

                  this.$store.dispatch('lancerchargement',true)

                  this.Items.cf=this.$store.state.caisseinfo;


                  if(this.$store.state.authorizeprix0 === "false"){


                      if (this.Items.jsonotherdonne.montantrecu===0 || this.Items.jsonotherdonne.montantrecu==='' ){

                          toastr['error']( 'Veuillez saisir le montant reçu pour comptabiliser la vente.',"<b></b>");
                          this.$store.dispatch('lancerchargement',false)

                          return
                      }

                  }

                  if(this.$store.state.authorizefacturactionpartiel === "false"){

                      if (this.Items.mtnt.docmtnttotalttc > this.Items.jsonotherdonne.montantrecu ){

                          toastr['error']( 'Le montant reçu est insuffisant, pour comptabiliser la facturation..',"<b></b>");
                          this.$store.dispatch('lancerchargement',false)

                          return
                      }


                  }


                  let value={
                      'cf':this.$store.state.caisseinfo,
                      'dpid':this.Items.pid,
                      'dmtnt':this.Items.jsonotherdonne.montantrecu,
                      'jsonotherdonne':this.Items.jsonotherdonne,
                      'jsonv':this.Items.jsonv,
                      'document':"FCOMPTOIRE",
                      'item':this.Items,
                  }


                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/doc/caisse/addfkdfkdozojdj_refdepdpcc/'+this.$store.state.appsociete+'/add895418init51_refdepdpcc11515/'+randomString();
                  let response =   axios  .post(url,value,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                      .then(function (res){
                          // t.$store.dispatch('beartoken',res.data.beartoken)
                          t.$store.dispatch('lancerchargement',false)
                          if(res.data.success){

                              t.messagesucces="";
                              t.messageerror="";

                              t.messagesucces=res.data.message;

                              toastr['success']( t.messagesucces,"<b></b>");

                              let normalise=0
                              let mecefsignature=""
                              let mecefnim=""
                              let mecefcompteur=""
                              let mecefheure=""
                              let mecefqrr=""
                              let urlimagegenerer=""


                              if (t.$store.state.authorizenormalisation==="true" || t.$store.state.authorizenormalisation===true){
                                  mecefsignature=res.data.m.mecef.mecefsignature
                                  mecefnim=res.data.m.mecef.mecefnim
                                  mecefcompteur=res.data.m.mecef.mecefcompteur
                                  mecefheure=res.data.m.mecef.mecefheure
                                  mecefqrr=res.data.m.mecef.mecefqrr
                                  normalise=res.data.m.mecef.normalise




                                  // Générer un QR code sous forme d'URL de données
                                  qrcodetoimage.toDataURL(mecefqrr, { width: 125, margin: 2 }, (err, url) => {
                                      if (err) {
                                          console.error(err);
                                      } else {

                                          urlimagegenerer=  url;
                                      }
                                  });

                              }


                              let items=[];

                              t.listedatatable.forEach(function (line) {
                                  if (line.etatline!==2){ items.push(line) }
                              })





                              let tabvalue={
                                  numdoc:t.Items.docnum,
                                  date:t.Items.datedoc,
                                  client:t.Items.jsonv.client,
                                  ifu:t.Items.jsonv.ifu,
                                  params:'',
                                  params4:'',
                                  boutique:'',
                                  serveur:'',
                                  commandetable:'',
                                  vendeur:t.$store.state.appusername,
                                  items:items,
                                  montantht:t.calculSomme(t.Items.mtnt.docmtnttotalht,t.Items.mtnt.docmtntremise,0),
                                  montanttva:0,
                                  montantremise:t.currencyFormatDE(t.Items.mtnt.docmtntremise),
                                  montantnetapayer:t.currencyFormatDE(t.Items.mtnt.docmtnttotalttc),
                                  reglement:t.currencyFormatDE(t.Items.jsonotherdonne.montantrecu),
                                  montantmonnaie:t.calculMonnaie(t.Items.mtnt.docmtnttotalttc,t.Items.jsonotherdonne.montantrecu),
                                  pourcentageprisenecharge:0,
                                  docsaisiepar:t.$store.state.appusername,


                                  normalise:normalise,
                                  mecefsignature:mecefsignature,
                                  mecefnim:mecefnim,
                                  mecefcompteur:mecefcompteur,
                                  mecefheure:mecefheure,
                                  mecefqrr:urlimagegenerer,

                              }

                              if (t.Items.jsonv.param!==null){tabvalue.params=t.Items.jsonv.param.name}

                              t.$refs.modalfacturecomptoirepost.show(tabvalue,'facture');




                          }
                          else{

                              let errormessage="";

                              t.messageerror="";
                              t.messagesucces="";
                              if (res.data.datamessage.length>0){

                                  for (let message of res.data.datamessage){

                                      if (errormessage===""){

                                          errormessage=message;

                                      }else{

                                          errormessage+=" , "+message;

                                      }


                                  }


                                  t.messageerror=errormessage;

                              }
                              else{
                                  t.messageerror=res.data.message
                              }


                              toastr['error'](t.messageerror,"<b></b>");

                              ///this.isdisplay=false;

                          }


                      })
                      .catch(

                          error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                              "<b></b>")
                      )
                      .finally(() => this.$store.dispatch('lancerchargement',false))











              },


              async  updateItems(){

                    this.$store.dispatch('lancerchargement',true)

                    if (this.Items.intitule==="" ){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return

                    }


                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tier/maj/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");


                                t.close()

                            }
                            else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },


            calcullistedatatable() {

                let vm=this;


                let $montantnontaxable=0,
                    $montantaib=0,
                    $montantcdtionreglemen=0,
                    $montanttva=0,
                    $montanthtarpvht=0,
                    $montanthtarpvttc=0,
                    $montanttotalht=0,
                    $montanttotalttc=0,
                    $montanttotaltrt =0,
                    $smontantremise=0,
                    $istva=0;

                if (this.listedatatable.length>0){

                    this.listedatatable.forEach(function (line) {

                        if (parseFloat(line.etatline)===0 || parseFloat(line.etatline)===1 || parseFloat(line.etatline)===4 ){




                            // $istva=0;
                            let $datajsonarticle=line.article;
                            let $lignemontantht=line.mtnt;
                            let $mtnt_remise=line.remise;

                            if($datajsonarticle.taxedimposition!=="A"){ //verification si l'article est taxable
                                if(vm.Items.jsonotherdonne.appliquetva==="1" || vm.Items.jsonotherdonne.appliquetva===1) /// verification si on applique la tva sur ce document
                                {
                                    //si oui

                                    $istva=1;

                                    if(parseFloat($datajsonarticle.tptva)===1) /// verification de l'application du tva sur cet article
                                    {
                                        //si oui
                                        $montanthtarpvttc=parseFloat($montanthtarpvttc)+parseFloat($lignemontantht);  //montant total des montants HT sur lequelle on applique la tva

                                    }
                                    else{
                                        $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht) ;//montant total des montants HT sur lequelle on pas applique la tva

                                    }



                                }
                                else{ // On applique pas la tv a sur le produit


                                    $montanthtarpvttc=0;
                                    $montanthtarpvht=parseFloat($montanthtarpvht)+parseFloat($lignemontantht); //montant total des montants HT sur lequelle on pas applique la tva

                                }

                            }
                            else{

                                $montantnontaxable=parseFloat($montantnontaxable)+parseFloat($lignemontantht) ;

                            }

                            $smontantremise=parseFloat($smontantremise)+parseFloat($mtnt_remise);
                        }
                    })



                    // $montanttotalht= floatval($montanthtarpvttc)+ floatval($montanthtarpvht);/// calcul du montant total ht sans le montant non taxable
                    $montanttotalht= parseFloat($montanthtarpvttc)+ parseFloat($montanthtarpvht)+parseFloat($montantnontaxable);/// calcul du montant total ht avec le montant non taxable

                    $montanttotaltrt="";

                    if ( $istva===1){

                        $montanttva= parseFloat($montanthtarpvttc)*0.18;////// calcul du montant de la TVA

                    }

                    let $aib=parseInt(vm.Items.jsonotherdonne.aib); // taux de AIB


                    let  $libaib="AIB";
                    if ($aib!==0){
                        $libaib="Montant AIB ("+$aib+" %)";

                        $montantaib=parseFloat($montanttotalht)*(parseInt($aib)/100);


                    }else {$montantaib=0;}


                    let $netfinancier=0;


                    if (this.typedoc==="DAF" || this.typedoc==="BCF" ||
                        this.typedoc==="RF" ||  this.typedoc==="FR" ||
                        this.typedoc==="FRF")
                    {
                        $montantaib=-$montantaib;
                        $netfinancier=$montanttotalht+$montantaib;



                        if ( $istva===1){
                            $montanttva= parseFloat($netfinancier)*0.18;////// calcul du montant de la TVA
                        }

                        $montanttotalttc=parseFloat($netfinancier)+parseFloat($montanttva);

                    }
                    else{

                        /////// calcul du montant ttc

                        $montanttotalttc=parseFloat($montanttotalht)+parseFloat($montantaib)+parseFloat($montanttva);


                    }


                    vm.Items.mtnt={
                        doctva:Math.round($montanttva),
                        docmtnttotalttc:Math.round($montanttotalttc),
                        docmtntaib:Math.round($montantaib),
                        docmtntremise:Math.round($smontantremise),
                        docmtnttotalht:Math.round($montanttotalht),
                        doccalmtnttotalht:Math.round($montanttotalht)+Math.round($smontantremise),
                        libaib:$libaib
                    };

                }
                else{
                    this.Items.mtnt={
                        doctva:Math.round(0),
                        docmtnttotalttc:Math.round(0),
                        docmtntaib:Math.round(0),
                        docmtntremise:Math.round(0),
                        docmtnttotalht:Math.round(0),
                        doccalmtnttotalht:Math.round(0),
                        libaib:"Montant AIB (0 %)"
                    }

                }




            },

            newArticle(){
                this. ItemsArticle.docmag=this.mydocmag;
                this. ItemsArticle.article=null;
                this. ItemsArticle.qtite=0;
                this. ItemsArticle.pu=0;
                this. ItemsArticle.remise=0;
                this. ItemsArticle.calmtntht=0;
                this. ItemsArticle.mtnt=0;
                this. ItemsArticle.pid='';
                this.storeqtite=0;
                this. ItemsArticle.autredesignation='';
                this. ItemsArticle.linekey='';
                this. ItemsArticle.etatline=0;
                this.ItemsArticle.spid='';
                this.disabled=false;

            },

            modifierArticle(value){

                let t=this;
                ///  let qte=this.formatNumber2(Number(value.qtite));
                let qte=value.qtite;
                var mitemarticle=this.ItemsArticle

                this.disabled=true;
                this.ItemsArticle={
                    article:value.article,
                    qtite:qte,
                    diqte:0,
                    pu:parseFloat(value.ppu),
                    pa:parseFloat(value.pu),
                    mtnt:value.mtnt ,
                    /* docnumother:mitemarticle.docnumother ,*/
                    docnumother:value.docnumother ,
                    remise:value.remise,
                    calmtntht:value.calmtntht,
                    //docnum:value.numdoc,
                    autredesignation:value.autredesignation,
                    doctp:value.doctyp,
                    pid:value.pid,
                    linekey:value.linekey,
                    etatline:value.etatline,
                    docpid:value.docnum,
                    docnum:value.docnum
                };

                this.storeqtite=qte;

            },

            changeItem(value){

                let pid=this.ItemsArticle.pid;
                // let value=event;
                if (pid!=='') return;
                let dataSearch=[];

                if(value!==null){

                    this.ItemsArticle.article=value

                    if(this.listedatatable.length !==0){


                        dataSearch = this.listedatatable.filter(function (article) {

                            return  article.article.pid ===value.pid
                            //return article.pidar.includes(value.pid);
                        });


                        if(dataSearch.length !==0){
                            toastr['error']( value.libelle+' existe déjà dans la liste.',"<b></b>");
                            this.ItemsArticle.article=null;
                            this.ItemsArticle.qtite=0;
                            this.ItemsArticle.pu= 0;
                            this.storeqtite=0;

                            let mvalue=dataSearch[0];
                            ///  let qte=this.formatNumber2(Number(value.qtite));

                            let qte=mvalue.qtite;
                            var mitemarticle=this.ItemsArticle
                            this.disabled=true;

                            this.ItemsArticle={
                                article:mvalue.article,
                                qtite:qte,
                                diqte:0,
                                pu:mvalue.ppu,
                                pa:mvalue.pa,
                                mtnt:mvalue.mtnt ,
                                docnumother:mitemarticle.docnumother ,
                                remise:mvalue.remise,
                                calmtntht:mvalue.calmtntht,
                                //docnum:value.numdoc,
                                autredesignation:mvalue.autredesignation,
                                doctp:mvalue.doctyp,
                                pid:mvalue.pid,
                                docpid:mvalue.docnum,
                                docnum:mvalue.docnum,


                                linekey:mvalue.linekey,
                                mycheckkey:mvalue.mycheckkey,
                                etatline:mvalue.etatline,
                                accompagnement:mvalue.accompagnement,
                                ecartqte:mvalue.ecartqte,
                                mtntc:mvalue.mtntc,
                                otherdbdoc:mvalue.otherdbdoc,
                                reference:mvalue.reference,
                                stckinit:mvalue.stckinit,
                                tva:mvalue.tva,

                            };

                            this.storeqtite=qte;






                        }else{
                            // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                            //this.itemArticle.pu= this.itemArticle.article.pu

                            this.gestionPu();


                        }


                    }
                else{
                        // console.log('this.itemArticle.article.pu',this.itemArticle.article.pu)
                        // this.itemArticle.pu=this.itemArticle.article.pu
                        //this.itemArticle.pu= this.itemArticle.article.pu
                        this.gestionPu();
                    }


                }



            },

            gestionPu(){

                    let $puprixvente=0;

                    //***************************** le bon ok ******************************
                    if (parseInt(this.Items.jsonotherdonne.appliquetva)===1){ //verifier que sur le document on veut appliquer la tva

                        if (parseInt(this.ItemsArticle.article.tptva)===1){ // Verifier que la tva est appliquable sur cet article


                            if(parseInt(this.ItemsArticle.article.typeprixvente)===1){ // verifier que si le prix est TTC ou pas

                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}



                                // let cal=   parseFloat(this.itemArticle.article.pu)/1.18
                                let cal=   parseFloat($puprixvente)/1.18
                                // let cal=   parseFloat(this.itemArticle.article.pu)/parseFloat(this.itemArticle.article.typttc)
                                let fixedcal=cal.toFixed(8);
                                //this.itemArticle.pu=Math.round(cal)
                                //this.itemArticle.pu=Math.round(fixedcal)
                                this.ItemsArticle.pu=fixedcal
                                //this.itemArticle.pa=this.itemArticle.article.pu
                                this.ItemsArticle.pa=0

                            }
                            else{
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                                if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                                //let cal=   parseFloat(this.itemArticle.article.pu)*0.18
                                let cal=   parseFloat($puprixvente)*0.18
                                //this.itemArticle.pu=this.itemArticle.article.pu
                                this.ItemsArticle.pu=$puprixvente
                                this.ItemsArticle.pa=Math.round(cal)

                            }



                        }
                        else{

                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                            if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                            this.ItemsArticle.pu=$puprixvente
                            this.ItemsArticle.pa=0
                            /*this.itemArticle.pu=this.itemArticle.article.pu
                            this.itemArticle.pa=this.itemArticle.article.pu*/

                        }


                    }
                    else{

                        /* this.itemArticle.pu=this.itemArticle.article.pu
                         this.itemArticle.pa=this.itemArticle.article.pu*/

                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente'){$puprixvente=this.ItemsArticle.article.pu;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente1'){$puprixvente=this.ItemsArticle.article.pu1;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente2'){$puprixvente=this.ItemsArticle.article.pu2;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente3'){$puprixvente=this.ItemsArticle.article.pu3;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente4'){$puprixvente=this.ItemsArticle.article.pu4;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente5'){$puprixvente=this.ItemsArticle.article.pu5;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente6'){$puprixvente=this.ItemsArticle.article.pu6;}
                        if (this.Items.jsonotherdonne.mtypprixdevente==='prixvente7'){$puprixvente=this.ItemsArticle.article.pu7;}


                        this.ItemsArticle.pu=$puprixvente
                        this.ItemsArticle.pa=0
                        /*this.itemArticle.pu=this.itemArticle.article.pu
                        this.itemArticle.pa=this.itemArticle.article.pu*/


                    }

            },


            async SaveArticle(){


                this.searchword="";

                if (this.Items.jsonotherdonne.cltfrs===null ){

                    this.messageerror='Veuillez séléctionner un client'

                    toastr['error']( 'Veuillez séléctionner un client',"<b></b>");
                    return
                }

                if (this.Items.jsonotherdonne.moderglt===null ){
                    this.messageerror='Veuillez séléctionner le mode de réglement'

                    toastr['error']( 'Veuillez séléctionner le mode de réglement',"<b></b>");
                    return
                }

                if (this.ItemsArticle.article===null)
                {
                    this.messageerror='Veuillez sélectionner un article valide'
                    toastr['error']( 'Veuillez sélectionner un article valide',"<b></b>");
                   return

                }

                if (parseFloat(this.ItemsArticle.qtite)===0 ||  parseFloat(this.ItemsArticle.mtnt)===0  ||  parseFloat(this.ItemsArticle.pu)===0)
                {
                    this.messageerror='Veuillez saisir toutes les informations(la quantité ou le prix)'
                    toastr['error']( 'Veuillez saisir toutes les informations(la quantité ou le prix)',"<b></b>");
                   return

                }


                let vm=this,
                    linekey="",
                    newqtite=0,
                    $mtnt=0,
                    $remise=0,
                    oldqtite=0,
                    mitems,
                    t=this;


                if (t.ItemsArticle.linekey===""){
                    linekey= randomString(20);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=t.ItemsArticle.pu;
                    //t.ItemsArticle.pu=t.ItemsArticle.pu;
                    t.ItemsArticle.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;


                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){


                            line.etatline=0;
                            oldqtite=line.qtite;
                            line.qtite=newqtite;
                            line.ppu=t.ItemsArticle.pu;
                            line.pu=t.ItemsArticle.pu;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);

                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour




                let url="",donneval={},response;

                if(t.Items.pid!==""){
                   url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/vline895418init51comptoirerefdepdpcc11515/'+randomString(280);

                    response =   axios  .post(url,mitems,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                }
                else{

                    this.$store.dispatch('lancerchargement',true)

                    t.Items.jsonotherdonne.tpdoc="facture"
                    t.Items.jsonotherdonne.tredocument="vente"
                    t.Items.cf=this.$store.state.caisseinfo
                    t.Items.jsonv.modereglement=t.Items.jsonotherdonne.moderglt
                    t.Items.jsonv.typ="VENC"
                    t.Items.jsonv.comptabilise=0

                   donneval={  'itemdoc':t.Items, 'itemArticle':mitems, }

                 url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/add895418init51comptoirerefdepdpcc11515/'+randomString(280);

                     response =   axios  .post(url,donneval,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                    })

                }

                let res= await response
                    .then(function (res){

                   t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){

                            t.messagesucces= res.data.message

                            toastr['success']( res.data.message,"<b></b>");


                            t.compteprocess--;



                            if(t.Items.pid===""){

                                t.ItemsArticle.docnum=res.data.numdoc;
                                t.Items.docnum=res.data.numdoc;
                                t.Items.docpid=res.data.keypid;
                                t.ItemsArticle.docnum=res.data.keypid;
                                t.ItemsArticle.docpid=res.data.keypid;
                                t.Items.pid=res.data.keypid;

                            }

                            //  if (line.pid===""){line.pid=res.data.key;}

                            let $search=""

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=1;

                                    $search=line.article.pid

                                    if (line.pid===""){

                                        line.pid=res.data.keyline;
                                        line.docnum=res.data.keypid;
                                        line.docpid=res.data.keypid;

                                    }

                                }
                            })

                            let $checkindex=array_search($search,t.datatablearticle,'pid')

                            if ($checkindex>=0){


                                if (parseFloat(t.datatablearticle[$checkindex].instock)===1){
                                    t.datatablearticle[$checkindex].rstck=res.data.pk


                                    t.$store.commit('listedesarticlespourlacaisse', { value:t.datatablearticle });



                                }


                            }





                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    t.messageerror="Une erreur c'est produite lors du traitement de l'opération." +
                                    " Veuillez réessayer l'opération. "+res.data.message;

                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },


            async deleteItemArticle(){




                if (this.ItemsArticle.article===null)
                {

                    toastr['error']( 'Veuillez sélectionner un article valide',"<b></b>");
                    return

                }

                if (parseFloat(this.ItemsArticle.qtite)===0 ||  parseFloat(this.ItemsArticle.mtnt)===0  ||  parseFloat(this.ItemsArticle.pu)===0)
                {

                    toastr['error']( 'Veuillez saisir toutes les informations(la quantité ou le prix)',"<b></b>");
                    return

                }




                this.ItemsArticle.spid=this.ItemsArticle.docnum


                const t = this;



                let vm=this,
                    linekey="",
                    newqtite=0,
                    $mtnt=0,
                    $remise=0,
                    oldqtite=0,
                    mitems ;


                if (t.ItemsArticle.linekey===""){
                    /*linekey= randomString(8);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);*/

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;
                            /*line.qtite=newqtite;
                            line.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);
*/


                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                if (t.ItemsArticle.pid===""){ // suppression d'un article non enregistrer dans la base de donnée

                    t.listedatatable.forEach(function (line,index) {

                        if (line.linekey===linekey){

                            t.listedatatable.splice(index,1);
                        }

                    })

                    this.newArticle();
                    this.calcullistedatatable();
                    return
                }


                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour

                //this.$store.dispatch('lancerchargement',true)
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/svline895418init51comptoirerefdepdpcc11515/'+randomString(280);
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //  t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");
                            let $search=""
                            t.compteprocess--;
                            t.listedatatable.forEach(function (line,index) {

                                if (line.linekey===linekey){

                                    $search=line.article.pid

                                    t.listedatatable.splice(index,1);
                                }
                            })

                            t.calcullistedatatable();

                            let $checkindex=array_search($search,t.datatablearticle,'pid')

                            if ($checkindex>=0){


                                if (parseFloat(t.datatablearticle[$checkindex].instock)===1){
                                    t.datatablearticle[$checkindex].rstck=res.data.pk


                                    t.$store.commit('listedesarticlespourlacaisse', { value:t.datatablearticle });



                                }


                            }





                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            // t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        //t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },



            async deleteItemArticlemobile(datavalue){

                this.ItemsArticle=datavalue

                //console.log("datavalue ************* ",datavalue)
                //console.log("this.ItemsArticle ************* ",this.ItemsArticle)


                if (this.ItemsArticle.article===null)
                {

                    toastr['error']( 'Veuillez sélectionner un article valide',"<b></b>");
                    return

                }

                if (parseFloat(this.ItemsArticle.qtite)===0 ||  parseFloat(this.ItemsArticle.mtnt)===0  ||  parseFloat(this.ItemsArticle.pu)===0)
                {

                    toastr['error']( 'Veuillez saisir toutes les informations(la quantité ou le prix)',"<b></b>");
                    return

                }




                this.ItemsArticle.spid=this.ItemsArticle.docnum


                const t = this;



                let vm=this,
                    linekey="",
                    newqtite=0,
                    $mtnt=0,
                    $remise=0,
                    oldqtite=0,
                    mitems ;


                if (t.ItemsArticle.linekey===""){
                    /*linekey= randomString(8);
                    t.ItemsArticle.linekey=linekey;
                    t.ItemsArticle.etatline=0
                    t.ItemsArticle.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                    t.ItemsArticle.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;

                    mitems=cloneobjet(t.ItemsArticle);

                    t.listedatatable.push(mitems);*/

                }
                else{

                    linekey=t.ItemsArticle.linekey;
                    newqtite=t.ItemsArticle.qtite;

                    t.listedatatable.forEach(function (line) {


                        if (line.linekey===linekey){
                            line.etatline=0;
                            oldqtite=line.qtite;
                            /*line.qtite=newqtite;
                            line.ppu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.pu=(t.typededocument==='vente')?t.ItemsArticle.pu:t.ItemsArticle.pa;
                            line.autredesignation=t.ItemsArticle.autredesignation
                            line.mtnt=t.ItemsArticle.mtnt
                            line.mtntclt=t.ItemsArticle.mtntclt
                            line.remise=t.ItemsArticle.remise
                            line.calmtntht=parseFloat(t.ItemsArticle.remise)+parseFloat(t.ItemsArticle.mtnt);
*/


                        }
                    })

                    mitems=cloneobjet(t.ItemsArticle);

                }

                if (t.ItemsArticle.pid===""){ // suppression d'un article non enregistrer dans la base de donnée

                    t.listedatatable.forEach(function (line,index) {

                        if (line.linekey===linekey){

                            t.listedatatable.splice(index,1);
                        }

                    })

                    this.newArticle();
                    this.calcullistedatatable();
                    return
                }


                t.newArticle();
                t.calcullistedatatable();
                t.compteprocess++; // compte le de process en cour

                //this.$store.dispatch('lancerchargement',true)
                let url=process.env.VUE_APP_API_URL+'/egl74587kjkjrfdkcaisse771714dfe/comptoire/caisse/'+this.$store.state.caisseencour+'/'+this.$store.state.appsociete+'/svline895418init51comptoirerefdepdpcc11515/'+randomString(280);
                let response =   axios  .post(url,mitems,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //  t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");
                            let $search=""
                            t.compteprocess--;
                            t.listedatatable.forEach(function (line,index) {

                                if (line.linekey===linekey){

                                    $search=line.article.pid

                                    t.listedatatable.splice(index,1);
                                }
                            })

                            t.calcullistedatatable();

                            let $checkindex=array_search($search,t.datatablearticle,'pid')

                            if ($checkindex>=0){


                                if (parseFloat(t.datatablearticle[$checkindex].instock)===1){
                                    t.datatablearticle[$checkindex].rstck=res.data.pk


                                    t.$store.commit('listedesarticlespourlacaisse', { value:t.datatablearticle });



                                }


                            }





                        }
                        else{


                            toastr['error']( res.data.message,"<b></b>");
                            //  t.$store.dispatch('lancerchargement',false)

                            t.compteprocess--;

                            t.listedatatable.forEach(function (line) {

                                if (line.linekey===linekey){
                                    line.etatline=2;


                                    line.linemessage="Une erreur c'est produite lors du traitement de l'opération." +
                                        " Veuillez réessayer l'opération. "+res.data.message;



                                }

                            })

                            // t.calcullistedatatable();



                        }


                    })
                    .catch(function (error) {
                        toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>");

                        t.compteprocess--;

                        t.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.etatline=2;
                                line.linemessage="Une erreur c'est produite lors du traitement de l'opération.Elle a échoué. Veuillez réessayer l'opération. ";

                            }

                        })

                        //t.calcullistedatatable();


                    })
                    .finally(function () {
                        //this.$store.dispatch('lancerchargement',false);

                        /*this.listedatatable.forEach(function (line) {

                            if (line.linekey===linekey){
                                line.linekey=2;

                            }

                        })*/


                    } )







            },


            show(titlemodal,typeaction,Items,datatablearticle,otherdatatable){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

                this.alldata=otherdatatable
                this.datatablearticle=datatablearticle
                this.listearticlescodebarre=datatablearticle
                this.Items=Items

                if (typeaction==='add'){



                }
                else{

                    this.Items.jsonotherdonne.montantrecu=0

                  //  console.log("value-Items ********* ",Items)

                    this.ItemsArticle.docnum=Items.numdoc;
                    this.Items.docnum=Items.numdoc;
                    this.Items.docpid=Items.pid;
                    this.ItemsArticle.docnum=Items.pid;
                    this.ItemsArticle.docpid=Items.pid;
                    this.Items.pid=Items.pid;

                    this.listedatatable=Items.alllignedoc
                   // t.newArticle();
                    this.calcullistedatatable();


                    /*

                    alllignedoc
:
Array(4)
0
:
{tva: 0, remise: 0, pu: 1400, mtnt: 1400, mtntc: 1400, …}
1
:
{tva: 0, remise: 0, pu: 1500, mtnt: 1500, mtntc: 1500, …}
2
:
{tva: 0, remise: 0, pu: 5000, mtnt: 5000, mtntc: 5000, …}
3
:
{tva: 0, remise: 0, pu: 650, mtnt: 4550, mtntc: 4550, …}
length
:
4
[[Prototype]]
:
Array(0)
client
:
"CLIENTS DIVERS"
creerler
:
"2024-06-26 20:25:14"
datedoc
:
"2024-06-25"
indicateur
:
""
indicateur1
:
""
jsonotherdonne
:
{montantrecu: 0, tpdoc: 'facture', tredocument: 'vente', mtypprixdevente: 'prixvente', magvers: null, …}
jsonv
:
{ifu: '', client: 'CLIENTS DIVERS', modereglement: {…}, indicateur: '', indicateur1: '', …}
modereglement
:
"ESPECE"
mtnt
:
{mtntht: 12450, mtntcc: 12450, remise: 0}
mtntcc
:
12450
mtntht
:
12450
nbon
:
""
nbp
:
"6"
numdoc
:
"F-00009282"
nuse
:
0
params
:
"{\"param1\": null, \"param2\": null, \"param3\": null, \"param4\": null}"
pid
:
"20242606hJ199294k0Kkkw03wcNkD61ku15N3GiKBHvk0ke8-L5n1c4Hv51li61q1qkri79x7G59Aqm4Hl3o4EKE202514"
remise
:
0
saisiepar
:
"D.SARATH"
t
:
"F"





                    */

                    /*this.name=name
                    this.typdoc=typdoc
                    this.typcltfrs=typcltfrs

                    t.itemDoc.jsonotherdonne.montantrecu=0
                    t.itemDoc.jsonotherdonne=value.jsonotherdonne;
                    t.itemDoc.jsonv=value.jsonv;
                    t.itemDoc.mag=value.mag;
                    t.itemDoc.pid=value.pid;
                    t.itemArticle.docpid=value.pid;
                    t.itemDoc.params=value.params;
                    t.itemDoc.mtnt=value.mtnt;
                    t.itemDoc.docnum=value.numdoc;
                    t.itemDoc.docnumother=value.docnumother;
                    t.itemDoc.docpid=value.pid;
                    t.itemDoc.pid=value.pid;
                    t.itemDoc.infodoc=value.infodoc;
                    t.itemDoc.datedoc=value.datedoc;
                    t.itemDoc.doctyp=value.doctyp;
                    t.itemDoc.docobservation=value.docobservation;
                    t.listearticles=alldata.allprod;
                    t.listearticlescodebarre=alldata.allprod;
                    t.listedatatable=value.alllignedoc;
                    // t.mydocmag=value.mag.pid;
                    // t.itemArticle.docmag=value.mag.pid;
                    t.itemArticle.docnum=value.numdoc;
                    t.itemArticle.docnum=value.pid;
                    t.itemArticle.doctp=value.doctyp;
                    t.itemArticle.docnumother=value.docotherref;
                    t.alldata=alldata
                    //t.itemArticle.pid=value.pid;
                   this.listearticles=alldata.allprod;
                    this.listearticlescodebarre=alldata.allprod; */

                }


                this.Items.jsonotherdonne.mtypprixdevente='prixvente'


                this.messagesucces="";
                this.messageerror="";

                this.isdisplay=true;

            },





        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .modalstyle{
        max-width: 100%!important;
    }


    .btnheigth{

        height: 65px;

    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>